//患者推介->通知列表页
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {connect,} from 'react-redux';
import {getNoticeList,noticeReaded} from '../../redux/actions/index';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import jiantoux from '../../assets/images/jiantoux.png'


class IntroduceNotify extends React.Component {
    constructor(props) {
        super(props);
        //属性定义
        this.state = {

        };

        this.noticeDetail = this.noticeDetail.bind(this);
    }


    //患者推介 -通知 -查看详情
    noticeDetail(item) {
        //此处客户要求改为都转到推介患者详情页面
        let notice={};
        notice.id=item.noticeId;

         //标记为已读
         this.props.noticeReaded(notice);

        const path = `${this.props.match.path}/detail`+item.id;
        this.props.history.push(path);
    }

    //加载
    componentDidMount() {
        this.props.getNoticeList(1)//noticeType==1 means 推介  while 2 means 追踪
    }

    //卸载
    componentWillUnmount(nextProps) {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }


    //渲染
    render() {
        //get react-material css styles
        const {classes, noticeList} = this.props;
        return (
            <div className={classes.rootDiv}>
                {(noticeList || []).map((item, index) => {
                    return (
                                <div key={item.noticeId}>
                                    <Grid container justify="flex-start" wrap="nowrap">
                                        <Typography className={classes.ReferralnoticeDiv}>{item.notice}</Typography>
                                    </Grid>
                                    <Grid container justify="flex-start" wrap="nowrap">
                                        <Typography className={classes.NoticedateDiv}>{item.createTime}</Typography>
                                    </Grid>
                                    <Grid container justify="flex-end" wrap="nowrap">
                                        <Link className={classes.linkDiv} component="button" variant="body2"onClick={() => {this.noticeDetail(item)}}>
                                            <span className={classes.xiangDiv}> 查看详情 </span>
                                            <img className={classes.jiantoux} src={jiantoux}/>
                                            <div className={classes.pointDiv}></div>
                                        </Link>
                                    </Grid>
                                    <hr className={classes.hr}></hr>
                                </div>
                            )
                    })
                }
            </div>
        );
    }
}

//redux region
const mapStateToProps = (state) => {
    return {
        noticeList: state.introduceReducer.noticeList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNoticeList: (noticeType) => {
            dispatch(getNoticeList(noticeType))
        },
        noticeReaded:(notice)=>{
            dispatch(noticeReaded(notice))
        }
    }
}
//end of redux regian

//css region
const styles = theme => ({
    rootDiv: {
        width: '100vw',
        marginTop: theme.spacing(2),
        backgroundColor: '#FFFFFF',
        height: '100vh',
        overflow: 'auto',
    },

    paper: {
        display: 'flex',
        width: '100vw',
        height: '18vw',
        justifyContent: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    NotificationboxDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
        height: '20vw'
    },

    ReferralnoticeDiv: {
        margin: theme.spacing(0,2),
        fontSize: '4.2vw',
        color: '#333333',
        textAlign: 'justify',
    },

    NoticedateDiv: {
        marginLeft: '3.5vw',
        fontSize: '3.7vw',
        color: '#999999',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    linkDiv: {
        margin: theme.spacing(0,2),
        color: '#00DDD8',
        fontSize: '3.5vw',
        display: 'flex',
        alignItems: 'center',
    },

    jiantoux: {
        height: '1.7vh',
        lineHeight: '1.7vh',
        marginLeft: '1vw',
    },
    xiangDiv: {
        color: '#00DDD8',
        fontSize: '3.5vw',
        padding: '0vw',
    },

    pointDiv: {
        position:'absolute',
        top: 0,
        right: '-5px',
        width: '6px',
        height: '6px',
        background: 'red',
        borderRadius: '50%',
    }

});
//end of css region

//here we inject react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(IntroduceNotify)));
