import React from 'react';
import {connect} from 'react-redux';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import {Switch} from "react-router-dom";
import {
    queryServiceProtocolInfo ,
   commitServiceSignAgreement
} from "../../redux/actions/patient_manage_action";
import Toast from "antd-mobile/lib/toast";
import SignaturePad from '../../lib/signature/index';
import Input from "@material-ui/core/Input/Input";
import Moment from "moment";
import {DatePicker,Modal as ModalAnt,Radio as RadioAnt} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";

import {signedConfirm} from "../../redux/actions/custom_action";




//患者管理-结核病家庭医生服务签约协议
class ServiceSignAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hospitalId:0,
            leadHospitalId:0,
            patientId: 0,
            signDoctor:'',//签约医生
            doctorHone:'',//签约医生联系电话
            people:'', //居民（户主）
            dirtct:'', //家庭督导员
            directPhone:'', //家庭督导员联系电话
            leadHospital:'',//指导单位
            dutyPeople:'',//指导单位负责人
            dutyPhone:'',//指导单位联系电话

            asignYear:'',
            asignMonth:'',
            asignDay:'',
            partATime:'', //甲方签字时间

            bsign:'', //乙方电子签名
            bGroup:'',

            directSignYear:'',//督导人签字年
            directSignMonth:'',//督导人签字月
            directSignDay:'',//督导人签字日
            directSignTime:'', //督导人签字时间

            leadSignYear:'',
            leadSignMonth:'',
            leadSignDay:'',
            leadSignTime:'',//指导单位负责人签字时间

            boxVisible:false,
            signed: 0

        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSKip = this.handleSKip.bind(this);
        // ant 时间选择器控件事件
        this.partATimePickerOnChange = this.partATimePickerOnChange.bind(this);
        this.directSignTimePickerOnChange = this.directSignTimePickerOnChange.bind(this);
        this.leadSignTimePickerOnChange = this.leadSignTimePickerOnChange.bind(this);
        this.handleEventCancel = this.handleEventCancel.bind(this);
        this.handleSubmitOk = this.handleSubmitOk.bind(this);
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

     // 弹框取消事件
    handleEventCancel() {
        this.setState({
            boxVisible: false
        })
    }

    handleSubmitOk(){
        let params = {
            userId: localStorage.getItem("userId"),
            patientId: this.props.location.state.patientId,
            signed: this.state.signed
        }
        this.props.signedConfirm(params)
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          signed: e.target.value,
        });
      };

    componentDidMount() {
        let params = {
            userId : localStorage.getItem("userId"),
            patientId : this.props.location.state.patientId
        }
        this.props.queryServiceProtocolInfo(params);
    }


    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serviceSignCommitRet != this.props.serviceSignCommitRet) {
            if (nextProps.serviceSignCommitRet?.code == 200) {
                Toast.info('操作成功!');
                //const path = `/doctorIndex/manage`;
                //this.props.history.push(path);
                this.doSkip()
            }
            if (nextProps.serviceSignCommitRet?.code == 201) {
                Toast.info('操作失败!'+nextProps.serviceSignCommitRet.msg);
            }
        }
        if(nextProps.signedRet != this.props.signedRet){
            if(nextProps.signedRet?.code === 200){
                Toast.info("提交成功！");
                this.doSkip()
            }
            if(nextProps.signedRet?.code === 201){
                Toast.info("提交失败！"+nextProps.signedRet.msg);
            }
        }
    }

    //提交event
    handleSubmit(){
        var signPad=this.refs.patientSign;
        var signData=signPad.toDataURL();
        const serviceSign = {
            followupId:this.props.location.state.followupId,
            hospitalId:this.state.hospitalId,
            leadHospitalId:this.state.leadHospitalId,
            patientId:this.state.patientId,
            signDoctor:this.state.signDoctor,
            doctorHone:this.state.doctorHone,
            people:this.state.people,
            dirtct:this.state.dirtct,
            directPhone:this.state.directPhone,
            leadHospital:this.state.leadHospital,
            dutyPeople:this.state.dutyPeople,
            dutyPhone:this.state.dutyPhone,
            bsign: signData,
            bGroup:this.state.bGroup,
            partATime: this.state.asignYear + '-' + this.state.asignMonth + '-' + this.state.asignDay,
            directSignTime: this.state.directSignYear + '-' + this.state.directSignMonth + '-' + this.state.directSignDay,
            leadSignTime: this.state.leadSignYear + '-' + this.state.leadSignMonth + '-' + this.state.leadSignDay

        }
       //this.props.history.push('/doctorIndex/manage',{type: 'caller'});
        this.props.commitServiceSignAgreement(serviceSign);
    }

   // 甲方签字时间
   partATimePickerOnChange(date, dateString) {
      this.setState({
         partATime:dateString,
         asignYear: dateString.split("年")[0],
         asignMonth: dateString.split("年")[1].split("月")[0],
         asignDay: dateString.split("月")[1].split("日")[0],
      })
   }

   // 督导人签字时间
   directSignTimePickerOnChange(date, dateString) {
      this.setState({
         directSignTime:dateString,
         directSignYear: dateString.split("年")[0],
         directSignMonth: dateString.split("年")[1].split("月")[0],
         directSignDay: dateString.split("月")[1].split("日")[0],
      })
   }

   // 指导单位负责人签字时间
   leadSignTimePickerOnChange(date, dateString) {
      this.setState({
         leadSignTime:dateString,
         leadSignYear: dateString.split("年")[0],
         leadSignMonth: dateString.split("年")[1].split("月")[0],
         leadSignDay: dateString.split("月")[1].split("日")[0],
      })
   }


    //跳过event
    handleSKip(){
       this.setState({
            boxVisible: true
       })
    }

    //执行跳过
    doSkip(){
        if(this.props.serviceProtocolInfo?.signMore == 1){
            this.props.history.push('/doctorIndex/manage/reliefdeal',{patientId: this.props.location.state.patientId});
        }else{
            this.props.history.push('/doctorIndex/manage',{type: 'caller'});
        }
        
    }

    render() {
        const { classes,serviceProtocolInfo } = this.props;
        this.state.hospitalId = serviceProtocolInfo.hospital
        this.state.patientId = serviceProtocolInfo.patientId
        this.state.leadHospitalId = serviceProtocolInfo.leadHospitalId
        this.state.partATime = Moment(new Date())
        this.state.directSignTime = Moment(new Date())
        this.state.leadSignTime = Moment(new Date())

        console.log(this.state.signed)

        return (
            <Switch>
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={10} className={classes.headers}><h5 className={classes.huanzhe}>结核病家庭医生服务签约协议</h5>
                        </Grid>
                        <Grid item xs={2} className={classes.headers} onClick={this.handleSKip}><h5 className={classes.huanzhe}>跳过</h5> </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>

                    <Grid className={classes.rootDiv}>
                        <Grid container justify="center" wrap="nowrap" spacing={0}>
                            <Typography className={classes.title1}>结核病家庭医生服务签约协议</Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>

                        <Typography gutterBottom>
                            <span className={classes.titleBold}>甲方：</span>
                            <Input name="aTown" value={serviceProtocolInfo.aTown} className={classes.input}
                                   onChange={this.handleInputChange}/><span className={classes.text}>乡（镇）</span>
                            <Input name="aVillage" value={serviceProtocolInfo.aVillage} className={classes.input}
                                   onChange={this.handleInputChange}/><span className={classes.text}>村卫生所</span>
                        </Typography>
                        <Typography gutterBottom>
                            <span className={classes.text}>签约医生：</span><Input name="signDoctor" value={this.state.signDoctor} className={classes.input} onChange={this.handleInputChange}/>
                            <span className={classes.text}>联系电话：</span><Input name="doctorHone" className={classes.input} value={this.state.doctorHone} onChange={this.handleInputChange}/>
                        </Typography>

                        <Typography gutterBottom>
                            <span className={classes.titleBold}>乙方：</span>
                            <Input name="bTown" value={serviceProtocolInfo.bTown} className={classes.input} onChange={this.handleInputChange}/><span className={classes.text}>乡（镇）</span>
                            <Input name="bVillage" value={serviceProtocolInfo.bVillage} className={classes.input} onChange={this.handleInputChange}/><span className={classes.text}>村</span>
                            <Input name="bGroup" value={serviceProtocolInfo.bGroup} className={classes.input} onChange={this.handleInputChange}/><span className={classes.text}>组</span>
                        </Typography>
                        <Typography gutterBottom>
                            <span className={classes.text}>居民（户主）：</span><Input name="people" value={this.state.people} onChange={this.handleInputChange}/>&nbsp;
                            <span className={classes.text}>家庭督导员:</span><Input name="dirtct" className={classes.input} value={this.state.dirtct} onChange={this.handleInputChange}/>&nbsp;
                            <span className={classes.text}>联系电话：</span><Input name="directPhone" value={this.state.directPhone} className={classes.input} onChange={this.handleInputChange}/>
                        </Typography>
                        <Typography gutterBottom>
                            <span className={classes.titleBold}>指导单位：</span>
                            <Input name="leadHospital" value={serviceProtocolInfo.leadHospital} onChange={this.handleInputChange}/><span className={classes.text}>卫生院</span><br/>
                            <span className={classes.text}>负责人:</span><Input name="dutyPeople" className={classes.input} value={this.state.dutyPeople} onChange={this.handleInputChange}/>&nbsp;
                            <span className={classes.text}>联系电话：</span><Input name="dutyPhone" value={this.state.dutyPhone} className={classes.input} onChange={this.handleInputChange}/>
                        </Typography>

                        <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Typography className={classes.textDiv} gutterBottom>
                                    为提高结核病患者治疗依从性，确保患者用药适量、规律、全程的治疗，保障结核病患者健康管理规范落实，本着平等、自愿的原则，乙方自愿委托甲方为签约服务医生。经甲、乙双方和指导单位协商，签订本协议。
                                <p className={classes.spanDiv}> 一、甲方职责  </p>
                                    <Typography className={classes.textDiv}> （一）为乙方提供健康管理随访服务，在服务过程中严格遵守国家法律法规，执行国家《结核病患者健康管理服务规范》，并接受监督；  </Typography>
                                    <Typography className={classes.textDiv}> （二）按照《结核病患者健康管理服务规范》要求进行随访管理，指导乙方使用电子药盒或者易督导微信公众号，乙方根据自身情况选择督导员，可以是社区医生，也可以是家属或志愿者；对于不选择家庭成员或者志愿者督导服药的，要在医生面视下督导服药，随访评估每月一次；对于由家庭成员或志愿者督导的情况，强化期或注射期每10天随访一次，继续期或非注射期每月随访一次，并记录随访情况；  </Typography>
                                    <Typography className={classes.textDiv}> （三）密切关注乙方服药情况，如出现不良反应及时采取措施，危急情况则紧急转诊，2周内主动随访转诊情况，最大限度地保证患者完成规定的疗程； </Typography>
                                    <Typography className={classes.textDiv}> （四）督促乙方定期复查，掌握病情变化，并做好记录； </Typography>
                                    <Typography className={classes.textDiv}> （五）记录乙方停止治疗的时间和原因，收集和上报患者“肺结核患者治疗记录卡”； </Typography>
                                    <Typography className={classes.textDiv}> （六）采取多种形式对签约家庭成员、邻居或密切接触者开展结核病防治知识健康教育；  </Typography>
                                    <Typography className={classes.textDiv}>  （七）乙方如从本辖区居住地迁出，要及时向上级专业机构报告，并记录； </Typography>
                                    
                                    <Typography className={classes.textDiv}> 以上七项服务为基本服务项目，不收取费用。甲乙双方可通过协商增加、细化服务项目。对乙方中行动不便的家庭成员，可提供首次上门服务。如涉及特殊收费项目，由双方协商确定。  </Typography>
                                    <p className={classes.spanDiv}> 二、乙方职责  </p>
                                    <Typography className={classes.textDiv}>  （一）乙方应尊重甲方，不得侵犯甲方合法权益； </Typography>
                                    <Typography className={classes.textDiv}> （二）乙方所有家庭成员应主动配合甲方开展服务，如实将服药情况和随访结果等信息、资料及时准确告知甲方；</Typography>

                                    <Typography className={classes.textDiv}> （三）积极参与、配合甲方开展健康教育活动，认真执行甲方制定的相关措施。</Typography>
                                    <p className={classes.spanDiv}> 三、指导单位的职责   </p>
                                    <Typography className={classes.textDiv}>  （一）乡镇卫生院建立工作联系制度，定期召开村级督导员例会，讨论签约服务中出现的情况，及时解决存在的问题； </Typography>
                                    <Typography className={classes.textDiv}> （二）加强对甲方的管理，定期检查签约服务任务落实情况，对未履行或完成质量不高的项目，责令限期完成落实；</Typography>
                                    <p className={classes.spanDiv}> 四、其他规定   </p>
                                    <Typography className={classes.textDiv}>  （一）乙方如对甲方服务不满意，可申请指导单位协调解决；</Typography>
                                    <Typography className={classes.textDiv}>  （二）甲方给乙方提供服务的过程中，因乙方对甲方隐瞒治疗方案或随访信息，不听从指导单位意见而影响疗效或服务质量的，其产生的后果由乙方承担。 </Typography>
                                    <Typography className={classes.textDiv}> （三）由家庭成员或志愿者作为督导者，在签约甲方备注处注明。</Typography>
                                    <Typography className={classes.textDiv}>  （四）本协议自签订之日起生效，有效期按照疗程时间而定，遇有政策变动、甲方人员调整或乙方人员变动以及其他不确定因素时，根据实际情况终止协议或调整协议条款。  </Typography>
                                    <Typography className={classes.textDiv}> （五）本协议为试点版本，如与国家相关法律、法规有抵触部分，以国家法律法规为准。</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        <p>
                        <Typography className={classes.titleBold} gutterBottom>
                            甲方：
                        </Typography>
                        <Typography className={classes.yearBold} gutterBottom>
                           <DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                        allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={this.state.partATime}
                                        onChange={this.partATimePickerOnChange}/>
                            {/*<Input name="asignYear" value={this.state.asignYear} className={classes.signDate} onChange={this.handleInputChange}/>年*/}
                            {/*<Input name="asignMonth" value={this.state.asignMonth} className={classes.signDate} onChange={this.handleInputChange}/>月*/}
                            {/*<Input name="asignDay" value={this.state.asignDay} className={classes.signDate} onChange={this.handleInputChange}/>日*/}
                            </Typography></p>
                        <p>
                            <Typography className={classes.titleBold} gutterBottom >
                                乙方（户主签名）：<SignaturePad ref='patientSign' name="bsign" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                            </Typography>
                            <Typography className={classes.yearBold} gutterBottom>
                                家庭督导员签名：<br/>
                               <DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                            allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                            defaultValue={this.state.directSignTime}
                                            onChange={this.directSignTimePickerOnChange}/>
                                {/*<Input name="directSignYear" value={this.state.directSignYear} className={classes.signDate} onChange={this.handleInputChange}/>年*/}
                                {/*<Input name="directSignMonth" value={this.state.directSignMonth} className={classes.signDate} onChange={this.handleInputChange}/>月*/}
                                {/*<Input name="directSignDay" value={this.state.directSignDay} className={classes.signDate} onChange={this.handleInputChange}/>日*/}
                            </Typography>
                        </p>

                        <p>
                            <Typography className={classes.textBold} gutterBottom >
                            指导单位负责人（签章）：
                            </Typography>
                            <Typography className={classes.yearBold} gutterBottom>
                               <DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                            allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                            defaultValue={this.state.leadSignTime}
                                            onChange={this.leadSignTimePickerOnChange}/>
                                {/*<Input name="leadSignYear" value={this.state.leadSignYear} className={classes.signDate} onChange={this.handleInputChange}/>年*/}
                                {/*<Input name="leadSignMonth" value={this.state.leadSignMonth} className={classes.signDate} onChange={this.handleInputChange}/>月*/}
                                {/*<Input name="leadSignDay" value={this.state.leadSignDay} className={classes.signDate} onChange={this.handleInputChange}/>日*/}
                            </Typography>
                        </p>
                        <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSubmit}>提交</Button>
                        <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSKip}>跳过</Button>
                    </Grid>

                    <ModalAnt title="签约确认"
                      visible={this.state.boxVisible}
                      afterClose={this.handleEventCancel}
                      onCancel={this.handleEventCancel}
                      destroyOnClose={true}
                      forceRender={true}
                      footer={[
                         <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                         <Button key="submit" onClick={this.handleSubmitOk}>提交</Button>,
                      ]}
                    >
                        <Grid item xs={10} className={classes.bindContent}>
                            <Grid className={classes.bindContentFlex}>
                                <label>是否进行了线下签约？</label>
                                <RadioAnt.Group onChange={this.onChange} value={this.state.signed} defaultValue={0}>
                                    <RadioAnt value={1} checked={this.state.signed === 1}>是</RadioAnt>
                                    <RadioAnt value={0} checked={this.state.signed === 0}>否</RadioAnt>
                                </RadioAnt.Group>
                            </Grid>
                        </Grid>
                    </ModalAnt>
                </div>
            </Switch>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        serviceProtocolInfo : state.patientManageReducer.serviceProtocolInfo,
        serviceSignCommitRet : state.patientManageReducer.serviceSignCommitRet,
        signedRet: state.customReducer.signedRet
    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
        queryServiceProtocolInfo: (params) => {  //定义的函数
            dispatch(queryServiceProtocolInfo(params)) // action里面定义的
        },
        commitServiceSignAgreement : (serviceSign) => {
            dispatch(commitServiceSignAgreement(serviceSign))
        },
        signedConfirm: (params) => {
            dispatch(signedConfirm(params))
        },
    };
};

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
    },
    spacemargin: {
        margin: theme.spacing(2),

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        width: '30vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    input: {
        margin: theme.spacing(0),
        width: '25vw'
    },

    signDate:{
        margin: theme.spacing(0),
        width: '15vw'
    },

    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw'
    },
    titleBold: {
       fontSize: '4.2vw',
        lineHeight: '8vw',
        textAlign: 'justify',
        margin: 0,
        fontWeight: '700',
        color: '#333333'
    },
    text: {
        fontSize: '4.2vw'
    },
    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },

    spanDiv: {
        textIndent: '0',
        fontWeight: '700',
    },


    buttonBox: {
        height: '30vw',

    },
    cardbutton: {
        width: '50vw',
        display: 'flex',
        flexDirection: 'row',
        marginbottom: '5vw',
        justifyContent: 'space-between',
    },
    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '43vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '7vw',
        marginbottom: '20vw',
        color: '#ffffff'
    },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    //modal弹窗
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    papersDiv: {
        width: '85vw',
        height: '55vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    // buttons: {
    //     width: '25vw',
    //     background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    //     marginTop: '1vw',
    //     height: '8vw',
    //     fontSize: '3.5vw',
    //     padding: 0,
    // },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },

    textDiv: {
        lineHeight: '8vw',
        fontSize: '4.2vw',
    },

    yearBold: {
        textAlign: 'right',
        marginBottom: '10vh',
        fontWeight: '700',
    },

    textBold: {
        marginBottom: '6vh',
        fontWeight: '700',
    },

    buttons: {
        margin: 'auto',
        fontSize: '4.8vW',
        marginBottom: '3vh',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },




    bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important',
        justifyContent: 'center',
     },
     bindModalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     },
     bindContent: {
        backgroundColor: '#FFFFFF'
     },


});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceSignAgreement));
