import {call, put, takeLatest} from "redux-saga/effects";
import * as ActionType from "../../actions/patients/consult-action";
import consultApi from "../../api/patients/consult";


//患者端我的姓名修改
export function* getConsultList(action) {
    try {
        const result = yield call(consultApi.getConsultList, action.patientId);
        if (result.error !== undefined) {
            //请求出错了
            //yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "GET_CONSULT_LIST_SUCCEED", result});
        }
    } catch (e) {
        //yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者咨询 发送消息
export function* sendMessage(action) {
    try {
        const result = yield call(consultApi.sendMessage, action.message);
        if (result.error !== undefined) {
            //请求出错了
            //yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            //发送完消息暂时先不管是否发出去了，后面再去严密处理
            //yield put({type: "GET_CONSULT_LIST_SUCCEED", result});
        }
    } catch (e) {
        //yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}



export default function* root() {
    yield takeLatest(ActionType.GET_CONSULT_LIST,getConsultList); //获取聊天历史记录
    yield takeLatest(ActionType.CONSULT_SEND_MESSAGE,sendMessage);
}
