import React, { Component } from "react";
import {connect} from 'react-redux';
import { createStyles, makeStyles, withStyles, withTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from "@material-ui/core/Input/Input";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import Head from "next/head";
import Moment from "moment";
import Toast from "antd-mobile/lib/toast";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import SignPadPlus from '../../lib/signPadPlus';

import { DatePicker } from "antd";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import  'moment/locale/zh-cn';
Moment.locale('zh-cn');




//import styles from "./index.module.css";
//import { LocalGroceryStore } from "@material-ui/icons";



const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});


function StyledRadio(props) {
    const styles = useStyles();

    return (
        <Radio
            className={styles.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)} />}
            icon={<span className={styles.icon} />}
            {...props}
        />
    );
}

const BeautyFormControlLabel = withStyles({
    root: {
       margin: 0
    },
    label: {
       fontSize: "1rem",
       color: "blue",
       fontWeight: 395
    }
 })(FormControlLabel);

const GreenCheckbox = withStyles({
    root: {
       color: "#00C9E4",
       "&$checked": {
          color: "#00C9E4"
       }
    },
    checked: {}
 })(props => <Checkbox color="default" {...props} />);

class MedicineScorePaper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            occupation: '',
            occupationOther: '',
            diagnosis: '',
            sputum: '',
            satisfaction: '',
            isForget: '',
            isLastWeekForget: '',
            isSelfReduce: '',
            isForgetTake: '',
            isLastTake: '',
            isSelfStop: '',
            isHard: '',
            isHardTimely: '',
            score: '',
            addTime: '',
            evaluate: '',
            doctorName: '',
            sign: '',
            addTime: Moment(new Date()), //添加日期
            addTimeStr: Moment().format('YYYY-MM-DD'),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addTimePickerOnChange = this.addTimePickerOnChange.bind(this);
        this.backTimePickerOnChange = this.backTimePickerOnChange.bind(this);  
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkoutEventFirst = this.checkoutEventFirst.bind(this);

    }

    componentDidMount() {
        this.setState({
            patientId: this.props.location?.state?.patientId,
            patientName: this.props.location?.state?.patientName
        })
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.patientMobileUpdateSucceedCode != this.props.patientMobileUpdateSucceedCode) {
            if (nextProps.patientMobileUpdateSucceedCode === 200) {
                this.props.history.go(-1);
            }
        }
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    addTimePickerOnChange(date, dateString) {
        this.setState({
            addTimeStr:dateString,
           //addTimeYear: dateString.split("年")[0],
           //addTimeMonth: dateString.split("年")[1].split("月")[0],
           //addTimeDay: dateString.split("月")[1].split("日")[0],
        })
     }
    backTimePickerOnChange(date, dateString) {
        this.setState({
            backTime:dateString,
        })
    }

    checkoutEventFirst(event){
        if(event.target.checked == true){
           this.setState({
               read: 1
           });
        }
        if(event.target.checked == false){
            this.setState({
                read: 0
            });
        }
    }  
    
    handleSubmit(){
        var signPad = this.refs.patientSign;
        var signData = signPad.toDataURL();
        if(signData == null || signData == ''){
            Toast.info("请签名!");
            return;
        }
        if(this.state.isForget == null || this.state.isForget == ''){
            Toast.info("评价问题(1)未填写!");
            return;
        }
        if(this.state.isLastWeekForget == null || this.state.isLastWeekForget == ''){
            Toast.info("评价问题(2)未填写!");
            return;
        }
        if(this.state.isSelfReduce == null || this.state.isSelfReduce == ''){
            Toast.info("评价问题(3)未填写!");
            return;
        }
        if(this.state.isForgetTake == null || this.state.isForgetTake == ''){
            Toast.info("评价问题(4)未填写!");
            return;
        }
        

        if(this.state.isLastTake == null || this.state.isLastTake == ''){
            Toast.info("评价问题(5)未填写!");
            return;
        }
        if(this.state.isSelfStop == null || this.state.isSelfStop == ''){
            Toast.info("评价问题(6)未填写!");
            return;
        }
        if(this.state.isHardTimely == null || this.state.isHardTimely == ''){
            Toast.info("评价问题(7)未填写!");
            return;
        }
        if(this.state.isHard == null || this.state.isHard == ''){
            Toast.info("评价问题(8)未填写!");
            return;
        }
        if(this.state.doctorName == null || this.state.doctorName == ''){
            Toast.info("医生姓名未填写!");
            return;
        }
        
        //计算score
        let score = 0;
        if(this.state.isForget == 0) score += 1;
        if(this.state.isLastWeekForget == 0) score += 1;
        if(this.state.isSelfReduce == 0) score += 1;
        if(this.state.isForgetTake == 0) score += 1;
        if(this.state.isLastTake == 1) score += 1;
        if(this.state.isSelfStop == 0) score += 1;
        if(this.state.isHard == 0) score += 1;
        if(this.state.isHardTimely > 0) score += this.state.isHardTimely*0.25;

        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            patientId: this.state.patientId,
            degree: this.state.degree,
            occupation: this.state.occupation,
            occupationOther: this.state.occupationOther,
            diagnosis: this.state.diagnosis,
            sputum: this.state.sputum,
            satisfaction: this.state.satisfaction,
            isForget: this.state.isForget,
            isLastWeekForget: this.state.isLastWeekForget,
            isSelfReduce: this.state.isSelfReduce,
            isForgetTake: this.state.isForgetTake,
            isLastTake: this.state.isLastTake,
            isSelfStop: this.state.isSelfStop,
            isHard: this.state.isHard,
            isHardTimely: this.state.isHardTimely,
            evaluate: this.state.evaluate,
            score: score,
            doctorName: this.state.doctorName,
            sign: signData,
            reportTime: this.state.addTimeStr

        }


        //const url = 'http://localhost:8080/api/custom/medicineScoreConfirm'
        const url = '/api/custom/medicineScoreConfirm'

        let that = this
        axios.post(url,params).then(function (rsp) {
            if(rsp.data.code == 200){
                Toast.info("提交成功！")
                const path = `/doctorIndex/manage/ServiceAgreement`;
                that.props.history.push(path, {"patientId": that.state.patientId});
            }else{
                Toast.info("提交失败！"+rsp.data.data.msg);
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
        

    }


    render() {
        const {classes} = this.props;
        let styles = classes

        return (
            <div className={styles.root}>
                {/*
                <Head>
                    <title>患者服药依从性评分量表</title>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width"
                    />
                </Head>
                */}
                
                
                <Grid container className={styles.headBackground} justifyContent="center">
                    <Typography variant="h6" component="h6" gutterBottom className={styles.topTitle}>
                        服药依从性评分
                    </Typography>
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="center" >
                    <Grid item xs={12}>
                        <Typography className={styles.hospital} color="textSecondary">
                            <span>患者姓名：{this.state.patientName}</span>
                        </Typography>
                    </Grid>

                </Grid>
                <div className={styles.space}> </div>

                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;文化程度：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justifyContent="flex-start" >
                            <RadioGroup row defaultValue="" name="degree" value={this.state.degree}
                                onChange={this.handleInputChange}>
                                <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="小学及以下" />
                                <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="初中" />
                                <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="高中" />
                                <FormControlLabel className={styles.OptionDiv} value="4" control={<StyledRadio />} label="高中以上" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={styles.space}> </div>

                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;家离县城（公里）：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="distanceCounty" value={this.state.distanceCounty} className={styles.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;职业：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justifyContent="flex-start" >
                            <RadioGroup row defaultValue="" name="occupation" value={this.state.occupation}
                                onChange={this.handleInputChange}>
                                <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="农民" />
                                <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="农民工" />
                                <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="城镇干居民" />
                                <FormControlLabel className={styles.OptionDiv} value="4" control={<StyledRadio />} label="职工" />
                                <FormControlLabel className={styles.OptionDiv} value="5" control={<StyledRadio />} label="学生" />
                                <FormControlLabel className={styles.OptionDiv} value="10" control={<StyledRadio />} label="其他" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;其他职业：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="occupationOther" value={this.state.occupationOther} className={styles.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;诊断：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="diagnosis" value={this.state.diagnosis} className={styles.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={8}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;治疗前痰菌检查结果：</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justifyContent="flex-start" >
                            <RadioGroup row defaultValue="" name="sputum" value={this.state.sputum}
                                onChange={this.handleInputChange}>
                                <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="阳性" />
                                <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="阴性" />
                                <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="未检痰" />
                            </RadioGroup>
                            <RadioGroup row defaultValue="" name="drugResistance" value={this.state.drugResistance}
                                onChange={this.handleInputChange}>
                                <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="耐药" />
                                <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="非耐药" />
                                <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="未检测" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={8}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;患者对村医服务的满意度：</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justifyContent="flex-start" >
                            <RadioGroup row defaultValue="" name="satisfaction" value={this.state.satisfaction}
                                onChange={this.handleInputChange}>
                                <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="很不满意" />
                                <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="不满意" />
                                <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="尚可" />
                                <FormControlLabel className={styles.OptionDiv} value="4" control={<StyledRadio />} label="满意" />
                                <FormControlLabel className={styles.OptionDiv} value="5" control={<StyledRadio />} label="很满意" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={styles.space}> </div>

                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" wrap="nowrap" >
                    <Grid item xs={12} >
                        <Typography className={styles.title} style={{ textAlign: 'left' }}>&nbsp;&nbsp;评价问题：  </Typography>
                    </Grid>
                </Grid>

                <div className={styles.space}> </div>
                <div className={styles.space}> </div>
                <div className={styles.space}> </div>


                <div className={styles.ask}>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 1、您是否有时忘记服药？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isForget" value={this.state.isForget}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>


                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 2、在过去的两周内，是否有一天或几天您忘记服药？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isLastWeekForget" value={this.state.isLastWeekForget}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>
                    

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 3、治疗期间，当您觉得症状加重或出现其他症状时，您是否未告知医生而自行减少药 量或停止服药？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isSelfReduce" value={this.state.isSelfReduce}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 4、当您外出时，您是否有时忘记随身携带药物？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isForgetTake" value={this.state.isForgetTake}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 5、昨天您服用了抗结核药物吗？您今天服药了抗结核药物吗？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isLastTake" value={this.state.isLastTake}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="两者皆是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 6、当您觉得自己的症状已经好转或消失时，您是否停止过服药？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isSelfStop" value={this.state.isSelfStop}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>


                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 7、您是否觉得要坚持抗结核治疗计划有困难？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isHard" value={this.state.isHard}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>

                    <Grid container justifyContent="flex-start" >
                        <Typography className={styles.textDiv}> 8、您觉得要记起按时按量服用抗结核药物很难吗？  </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-start" >
                        <RadioGroup row defaultValue="" name="isHardTimely" value={this.state.isHardTimely}
                            onChange={this.handleInputChange}>
                            <FormControlLabel className={styles.OptionDiv} value="4" control={<StyledRadio />} label="从来不" />
                            <FormControlLabel className={styles.OptionDiv} value="3" control={<StyledRadio />} label="偶尔" />
                            <FormControlLabel className={styles.OptionDiv} value="2" control={<StyledRadio />} label="有时" />
                            <FormControlLabel className={styles.OptionDiv} value="1" control={<StyledRadio />} label="经常" />
                            <FormControlLabel className={styles.OptionDiv} value="0" control={<StyledRadio />} label="所有时间" />
                        </RadioGroup>
                    </Grid>
                    <div className={styles.space}> </div>

            
                    <div className={styles.space}> </div>
                    <div className={styles.space}> </div>
                    
                    

                </div>

                <Grid container justifyContent="flex-start" >
                    
                    <Grid item xs={4}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;评估日期：</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker format="YYYY-MM-DD" suffixIcon=" " locale={locale}
                            defaultValue={this.state.addTime} onChange={this.addTimePickerOnChange}
                            allowClear={false} placeholder="请选择日期" className={classes.titleBold}
                        />
                    </Grid>

                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={8}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;服药依从性评价：</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="evaluate" value={this.state.evaluate} className={styles.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justifyContent="flex-start" >
                    <Grid item xs={3}>
                        <Typography className={styles.title} gutterBottom>&nbsp;&nbsp;评估医生：</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Input name="doctorName" value={this.state.doctorName} className={styles.input}
                            onChange={this.handleInputChange} />
                    </Grid>
                
                </Grid>
                <div className={styles.space}> </div>
                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Typography className={styles.title} color="textSecondary" gutterBottom>
                        &nbsp;&nbsp;医生签名：
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <SignPadPlus ref='patientSign' name="sign" clearButton="true" height={300} className={styles.SignaturePadDiv} />
                </Grid>
            
                <div className={styles.space}> </div>

                <div className={styles.ask}>
                    <Button variant="contained" color="primary" className={styles.buttons} onClick={this.handleSubmit}>提交</Button>
                </div>



            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    }
 }
 
 const mapDispatchToProps = (dispatch) => {
    return {
    }
 }

//css region
const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        lineHeight: '2rem',
      },
      titleBold: {
        margin: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
     },
      topTitle: {
        padding: '15px 0',
        color: '#FFFFFF',
      },
      
      hospital: {
        padding: '5px 10px',
      },
      
      headBackground: {
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      },
      
      dataDiv: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90vw',
        height: '13.3vw',
        fontSize: '4.2vw',
        borderBottom: '0.1vw solid #e6e6e6',
        margin: 'auto',
      },
      
      space: {
        height: '5px',
        margin: '5px',
      },
      
      inputFull: {
        width: '80%',
       
      },
      
      ask: {
        padding: '1.5rem',
      },
      
      buttons: {
        margin: 'auto',
        fontSize: '4.8vW',
        marginBottom: '3vh',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      },
      
      hideMe: {
        display: 'none',
      },
    
      rootGreen: {
        background: 'linear-gradient(to bottom,#2ba666, 50%, #2bb088)',
        height: '100%',
      },
    
      headMain: {
        padding: '0.8rem 0 !important',
      },
    
      fontHeadMain: {
        color: '#FFF',
        fontSize: '2rem !important',
        fontWeight: '900 !important',
        fontFamily: 'Arial,Helvetica,sans-serif !important',
      },
      fontHeadSub: {
        color: '#FFF',
        fontWeight: 'lighter !important',
      },
    
      cardBox: {
        margin: '1rem 0',
      },
    
      cardPapper: {
        width: '80%',
      },
    
      cardContent: {
        padding: '0 !important',
      },
    
      cardTitle: {
        margin: 0,
        color: 'green',
        textAlign: 'center',
        /*background-color: gray;*/
        
        fontSize: '0.75rem !important',
        lineHeight: '2rem !important',
      },
      cardTitleImge: {
        background: 'url("/images/bg-header-green.png") no-repeat',
        backgroundSize: '100% 100%',
      },
    
      cardTitleImgeYellow: {
        background: 'url("/images/bg-header-yellow.png") no-repeat',
        backgroundSize: '100% 100%',
      },
    
      cardInfo: {
        color: '#000',
        textAlign: 'center',
        fontSize: '1rem !important',
        fontWeight: '900 !important',
        lineHeight: '2.75rem !important',
      },
      
    
      cardTime: {
        color: 'gray',
        textAlign: 'center',
        fontSize: '1.25rem !important',
      },
    
      cardMedia: {
        marginTop: '2rem',
        height: '200px',
        padding: 0,
      },
      cardFooter: {
        fontSize: '1rem !important',
        color: '#ccc',
        lineHeight: '2rem !important',
        textAlign: 'center !important',
        margin: 'auto !important',
      },
    
      line90: {
        border: '#CCC solid 1px',
        width: '90%',
        margin: '0 5%',
      },
    
    
      rootYellow: {
        background: 'linear-gradient(to bottom,#fcb73f, 50%, #fca13f)',
        minHeight: '100%',
      },
    
      errorInfo: {
        fontSize: '0.5rem',
      },
      box1: {
        height: '1rem',
      },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MedicineScorePaper));
