import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import SignaturePad from '../../lib/signature/index';
import SignPadPlus from '../../lib/signPadPlus';
import Input from '@material-ui/core/Input';
import Toast from "antd-mobile/lib/toast";
import {queryTrackNotice,trackFeedbackSubmit} from "../../redux/actions/patient_manage_action";

import locale from "antd/es/date-picker/locale/zh_CN";
import {DatePicker} from 'antd';
import Moment from 'moment';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}

        />
    );
}

//患者追踪通知单
class TrackNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: localStorage.getItem("userId"),
            patientId: 0,
            traceId:0,
            trackNumber: 0,
            trackType: 0,
            trackResult: 0,
            excludeHospital: '',
            excludeDiagnose: '',
            excludeTime: "", //
            cityVisitHospital: "", //
            cityVisitTime: "", //
            countyVisitTime: "", //
            treatHospital: "", //
            relocate: "", //
            reject: "", //
            failType: 0, //
            dieTime: "", //
            dieReason: "", //
            other: "", //
            doctorName: "", //
            doctorPhone: "", //
            createTime: '',
            currentTimeYear: Moment(new Date()).format('YYYY'),
            currentTimeMonth: Moment(new Date()).format('MM'),
            currentTimeDay: Moment(new Date()).format('DD'),
            hasFeedback:0,

        }
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        
        this.excludeTimeOnchange = this.excludeTimeOnchange.bind(this);
        this.cityVisitTimeOnchange = this.cityVisitTimeOnchange.bind(this);
        this.countyVisitTimeOnchange = this.countyVisitTimeOnchange.bind(this);
        this.dieTimeOnchange = this.dieTimeOnchange.bind(this);
    }

    componentDidMount() {
        //this.state.patientId = this.props.location.state.patientId;
        this.state.traceId = this.props.match.params.traceId;

        let params = {
          //patientId : this.props.location.state.patientId,
          userId : this.state.userId,
          trackId: this.props.match.params.traceId
        }
        this.props._queryTrackNotice(params);
        document.getElementById('App').scrollIntoView(true);//为ture返回顶部，false为底部

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if(nextProps.trackNotice !== this.props.trackNotice){
            if(nextProps.trackNotice.patientId != undefined && nextProps.trackNotice.id != undefined){
                this.setState({
                    patientId: nextProps.trackNotice.patientId,
                    trackId: nextProps.trackNotice.id,
                })
            }
        }

    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    excludeTimeOnchange(event, value) {
       this.setState({excludeTime: value});
    }

    cityVisitTimeOnchange(event, value) {
        this.setState({cityVisitTime: value});
    }

    countyVisitTimeOnchange(event, value) {
        this.setState({countyVisitTime: value});
    }

    dieTimeOnchange(event, value) {
        this.setState({dieTime: value});
    }

    handleSubmit() {
        const path = `/doctorIndex/track/patientTrackFeedback`+this.state.traceId;
        var params = {
            trackId: this.state.trackId,
            actionType:'add'
        }
        if(this.props.trackNotice?.hasFeedback == 1) params.actionType = "edit"
        this.props.history.push(path,params);  // 跳转到追踪反馈
    }
    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const {classes, trackNotice} = this.props;
        return (
            <div id="App" className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h4 className={classes.huanzhe}>肺结核患者追踪单</h4></Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>

                <Grid className={classes.rootDiv}>
                    <Grid container justify="center" wrap="nowrap" spacing={0}>
                        <Typography className={classes.title1}>肺结核患者追踪单</Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.titleBold} gutterBottom>
                            <span className={classes.spanDiv}>{trackNotice?.hospitalName}</span>社区卫生服务中心：
                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Typography className={classes.titleBold} gutterBottom>
                                你中心辖区<span className={classes.spanDiv}>{trackNotice?.patientTown}</span>
                                街道/乡镇<span className={classes.spanDiv}>{trackNotice?.patientVillage}</span>
                                居委会/村<span className={classes.spanDiv}>{trackNotice?.patientAddress}</span>的患者（<span className={classes.spanDiv}>{trackNotice?.patientName}</span>，
                                <span className={classes.spanDiv}>{trackNotice?.patientAge}</span>岁，
                                性别：<span className={classes.spanDiv}>{trackNotice?.patientSex}</span>，联系电话：
                                <a href={"tel:"+trackNotice?.patientPhone}><span className={classes.spanDiv}>{trackNotice?.patientPhone}</span></a>，身份证号：<span className={classes.spanDiv}>{trackNotice?.idNumber}</span>），
                                已于
                                <span className={classes.spanDiv}>{trackNotice?.confirmTimeYear}</span>年 <span
                                className={classes.spanDiv}>{trackNotice?.confirmTimeMonth}月</span>
                                <span className={classes.spanDiv}>{trackNotice?.confirmTimeDay}</span>日被<span className={classes.spanDiv}>{trackNotice?.diagnoseHospital}</span>诊断为<span className={classes.spanDiv}>{trackNotice?.patientType}</span>，至今未到结核病定点医疗机构就诊。请接通知后立即对患者进行追踪，了解患者情况、动员其及时到结核病定点医疗机构进一步检查，并于三个工作日之内将患者追踪反馈单报我所。谢谢您的配合！
                            </Typography>
                        </Grid>
                    </Grid>






                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                我所联系人：
                               <Input name="doctorName"
                                      value={trackNotice?.xfHospitalDoctor}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalDoctorName" value={Paper5.sqHospitalDoctorName} onChange={this.handleInputChange}
                                    className={classes.inputb} inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                联系电话：
                               <Input name="doctorPhone"
                                      value={trackNotice?.xfHospitalPhone}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalPhone" value={Paper5.sqHospitalPhone}
                                    onChange={this.handleInputChange} className={classes.inputb}
                                    inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid className={classes.distance} container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="hospitalName"
                                    value={trackNotice?.xfHospitalName}
                                    onChange={this.handleInputChange}
                                    className={classes.inputa}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="createTimeYear"
                                    value={trackNotice?.createTimeYear}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />年
                                <Input
                                    name="createTimeMonth"
                                    value={trackNotice?.createTimeMonth}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />月
                                <Input
                                    name="createTimeDay"
                                    value={trackNotice?.createTimeDay}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />日
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" className={classes.buttonBox}>

                        <Grid className={classes.cardbutton}>
                            <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleSubmit}>
                                追踪反馈
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        trackFeedbackRet: state.patientManageReducer.trackFeedbackRet,
        trackNotice: state.patientManageReducer.trackNoticeRet?.data,

    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
       //查询随访基本信息
        _queryTrackNotice: (params) => {  //查询肺结核患者治疗管理反馈单 信息
            dispatch(queryTrackNotice(params)) // action里面定义的
        },
        _trackFeedbackSubmit: (params) => {
            dispatch(trackFeedbackSubmit(params)) //action/index页面
        }
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',


    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(0),
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
    },
    buttons: {
        margin: theme.spacing(1),
        width: '60vw',
    },
    hr: {
        height: '0.3vw',
        // backgroundColor:'#e6e6e6',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80vw',
    },
    input: {
        margin: theme.spacing(1),
        width: '88vw',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputs: {
        // margin: theme.spacing(1),
        width: '13vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },

    inputa: {
        // margin: theme.spacing(1),
        width: '40vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputb: {
        // margin: theme.spacing(1),
        width: '35vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw',
        // display:'inline-block'

    },


    titleBolds: {
        // marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },


    buttonBox: {
        height: '30vw',
        marginTop: theme.spacing(1),
    },

    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '91vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '5vw',
        marginbottom: '20vw',
    },
    spacemargin: {
        margin: theme.spacing(2),
        fontSize: '4.2vw',

    },

    spanDiv: {
        textDecoration: 'underline',
        // width: '19vw',
    },

    titleBold: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    // titleBold: {
    //     fontSize: '4.2vw',
    //     lineHeight: '8vw',
    //     textAlign: 'justify',
    //     margin: 0,
    //     color: '#333333'
    // },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    hideMe: {
        display: 'none',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackNotice));
