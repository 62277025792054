//声明模块
export const CUSTOM_MODULE_INFO = 'CUSTOM_MODULE_INFO' // 定制模块信息获取
export const CUSTOM_MODULE_INFO_SUCCESS = 'CUSTOM_MODULE_INFO_SUCCESS' // 定制模块信息获取成功


export const VALID_FOLLOWUP_CODE = 'VALID_FOLLOWUP_CODE' // 验证访视码
export const VALID_FOLLOWUP_CODE_SUCCESS = 'VALID_FOLLOWUP_CODE_SUCCESS' // 验证访视码

export const SEND_SMS_CODE_TO_PATIENT = 'SEND_SMS_CODE_TO_PATIENT' // 发送验证码
export const SEND_SMS_CODE_TO_PATIENT_SUCCESS = 'SEND_SMS_CODE_TO_PATIENT_SUCCESS' // 发送验证码成功

export const RE_ORDER_FOLLOWUP = 'RE_ORDER_FOLLOWUP' //重新预约随访
export const RE_ORDER_FOLLOWUP_SUCCESS = 'RE_ORDER_FOLLOWUP_SUCCESS' //重新预约随访成功


export const SIGNED_CONFIRM = 'SIGNED_CONFIRM'//已签约确认
export const SIGNED_CONFIRM_SUCCESS = 'SIGNED_CONFIRM_SUCCESS'//已签约确认成功


export const NOTICE_MANAGE = 'NOTICE_MANAGE' //通知数-患者管理
export const NOTICE_MANAGE_SUCCESS = 'NOTICE_MANAGE_SUCCESS' //通知数-患者管理

export const NOTICE_ALL = 'NOTICE_ALL' //通知数-全部
export const NOTICE_ALL_SUCCESS = 'NOTICE_ALL_SUCCESS' //通知数-全部

//患者治疗减免协议
export const SERVICE_RELIEF_DEAL_COMMIT = "SERVICE_RELIEF_DEAL_COMMIT"
export const SERVICE_RELIEF_DEAL_COMMIT_SUCCEED = "SERVICE_RELIEF_DEAL_COMMIT_SUCCEED"

export const SERVICE_RELIEF_DEAL_QUERY = "SERVICE_RELIEF_DEAL_QUERY"
export const SERVICE_RELIEF_DEAL_QUERY_SUCCEED = "SERVICE_RELIEF_DEAL_COMMIT_QUERY"

//督导员协议
export const SERVICE_DIRECT_DEAL_COMMIT = "SERVICE_DIRECT_DEAL_COMMIT"
export const SERVICE_DIRECT_DEAL_COMMIT_SUCCEED = "SERVICE_DIRECT_DEAL_COMMIT_SUCCEED"

//督导员答题
export const SERVICE_DIRECT_ANSWER_COMMIT = "SERVICE_DIRECT_ANSWER_COMMIT"
export const SERVICE_DIRECT_ANSWER_COMMIT_SUCCEED = "SERVICE_DIRECT_ANSWER_COMMIT_SUCCEED"


//添加随访图片
export const ADD_FOLLOWUP_IMAGE = "ADD_FOLLOWUP_IMAGE"
export const ADD_FOLLOWUP_IMAGE_SUCCESS = "ADD_FOLLOWUP_IMAGE_SUCCESS"


export const ADD_SCREENING_DIABETES = "ADD_SCREENING_DIABETES"
export const ADD_SCREENING_DIABETES_SUCCESS = "ADD_SCREENING_DIABETES_SUCCESS"


export const LIST_SCREENING_DIABETES = "LIST_SCREENING_DIABETES"
export const LIST_SCREENING_DIABETES_SUCCESS = "LIST_SCREENING_DIABETES_SUCCESS"

export const REMOVE_SCREENING_DIABETES = "REMOVE_SCREENING_DIABETES"
export const REMOVE_SCREENING_DIABETES_SUCCESS = "REMOVE_SCREENING_DIABETES_SUCCESS"

export const UPDATE_SCREENING_DIABETES = "UPDATE_SCREENING_DIABETES"
export const UPDATE_SCREENING_DIABETES_SUCCESS = "UPDATE_SCREENING_DIABETES_SUCCESS"

//老年人筛查
export const ADD_SCREENING_AGED = "ADD_SCREENING_AGED"
export const ADD_SCREENING_AGED_SUCCESS = "ADD_SCREENING_AGED_SUCCESS"

export const LIST_SCREENING_AGED = "LIST_SCREENING_AGED"
export const LIST_SCREENING_AGED_SUCCESS = "LIST_SCREENING_AGED_SUCCESS"

export const REMOVE_SCREENING_AGED = "REMOVE_SCREENING_AGED"
export const REMOVE_SCREENING_AGED_SUCCESS = "REMOVE_SCREENING_AGED_SUCCESS"

export const UPDATE_SCREENING_AGED = "UPDATE_SCREENING_AGED"
export const UPDATE_SCREENING_AGED_SUCCESS = "UPDATE_SCREENING_AGED_SUCCESS"

//糖尿病筛查
export const ADD_SCREENING_DIABETES_BJ = "ADD_SCREENING_DIABETES_BJ"
export const ADD_SCREENING_DIABETES_BJ_SUCCESS = "ADD_SCREENING_DIABETES_BJ_SUCCESS"


export const LIST_SCREENING_DIABETES_BJ = "LIST_SCREENING_DIABETES_BJ"
export const LIST_SCREENING_DIABETES_BJ_SUCCESS = "LIST_SCREENING_DIABETES_BJ_SUCCESS"

export const REMOVE_SCREENING_DIABETES_BJ = "REMOVE_SCREENING_BJ_DIABETES"
export const REMOVE_SCREENING_DIABETES_BJ_SUCCESS = "REMOVE_SCREENING_DIABETES_BJ_SUCCESS"

export const UPDATE_SCREENING_DIABETES_BJ = "UPDATE_SCREENING_DIABETES_BJ"
export const UPDATE_SCREENING_DIABETES_BJ_SUCCESS = "UPDATE_SCREENING_DIABETES_BJ_SUCCESS"

//密接统计季报
export const ADD_REPORTCONTACT = "ADD_REPORTCONTACT"
export const ADD_REPORTCONTACT_SUCCESS = "ADD_REPORTCONTACT_SUCCESS"

export const LIST_REPORTCONTACT = "LIST_REPORTCONTACT"
export const LIST_REPORTCONTACT_SUCCESS = "LIST_REPORTCONTACT_SUCCESS"

export const REMOVE_REPORTCONTACT = "REMOVE_REPORTCONTACT"
export const REMOVE_REPORTCONTACT_SUCCESS = "REMOVE_REPORTCONTACT_SUCCESS"

export const UPDATE_REPORTCONTACT = "UPDATE_REPORTCONTACT"
export const UPDATE_REPORTCONTACT_SUCCESS = "UPDATE_REPORTCONTACT_SUCCESS"


export const UPDATE_FOLLOWUP_SIGN = "UPDATE_FOLLOWUP_SIGN"
export const UPDATE_FOLLOWUP_SIGN_SUCCESS = "UPDATE_FOLLOWUP_SIGN_SUCCESS"


export const PATIENT_OUT_HOSPITAL  = "PATIENT_OUT_HOSPITAL"
export const PATIENT_OUT_HOSPITAL_SUCCESS  = "PATIENT_OUT_HOSPITAL_SUCCESS"

//定制模块获取
export function getCustomInfo(customInfo) {
    return {
        type:CUSTOM_MODULE_INFO,
        customInfo
    }
}

//验证访视码
export function validFollowupCode(params) {
    return {
        type:VALID_FOLLOWUP_CODE,
        params
    }
}


export function sendSmsToPatient(params) {
    return {
        type:SEND_SMS_CODE_TO_PATIENT,
        params
    }
}

export function reOrderFollowup(params) {
    return {
        type:RE_ORDER_FOLLOWUP,
        params
    }
}


export function signedConfirm(params) {
    return {
        type:SIGNED_CONFIRM,
        params
    }
}


export function noticeManage(params){
    return {
        type:NOTICE_MANAGE,
        params
    }
}

export function noticeAll(params){
    return {
        type:NOTICE_ALL,
        params
    }
}




//结核病治疗减免协议提交
export function commitReliefDeal(params){
    return {
        type : SERVICE_RELIEF_DEAL_COMMIT,
        params
    }
}

//督导员协议提交
export function commitDirectDeal(params){
    return {
        type : SERVICE_DIRECT_DEAL_COMMIT,
        params
    }
}

//督导员答题提交
export function commitDirectAnswer(params){
    return {
        type : SERVICE_DIRECT_ANSWER_COMMIT,
        params
    }
}

//添加图片提交
export function commitAddFollowupImage(params){
    return {
        type : ADD_FOLLOWUP_IMAGE,
        params
    }
}

export function screeningDiabetesAdd(params){
    return {
        type : ADD_SCREENING_DIABETES,
        params
    }
}

export function listScreeningDiabetesHistory(params){
    return {
        type : LIST_SCREENING_DIABETES,
        params
    }
}


export function screeningDiabetesRemove(params){
    return {
        type : REMOVE_SCREENING_DIABETES,
        params
    }
}

export function screeningDiabetesUpdate(params){
    return {
        type : UPDATE_SCREENING_DIABETES,
        params
    }
}

//老年人筛查
export function screeningAgedAdd(params){
    return {
        type : ADD_SCREENING_AGED,
        params
    }
}

export function listScreeningAgedHistory(params){
    return {
        type : LIST_SCREENING_AGED,
        params
    }
}


export function screeningAgedRemove(params){
    return {
        type : REMOVE_SCREENING_AGED,
        params
    }
}

export function screeningAgedUpdate(params){
    return {
        type : UPDATE_SCREENING_AGED,
        params
    }
}

//北京地区筛查
export function screeningDiabetesAddBJ(params){
    return {
        type : ADD_SCREENING_DIABETES_BJ,
        params
    }
}

export function listScreeningDiabetesHistoryBJ(params){
    return {
        type : LIST_SCREENING_DIABETES_BJ,
        params
    }
}


export function screeningDiabetesRemoveBJ(params){
    return {
        type : REMOVE_SCREENING_DIABETES_BJ,
        params
    }
}

export function screeningDiabetesUpdateBJ(params){
    return {
        type : UPDATE_SCREENING_DIABETES_BJ,
        params
    }
}


//密接统计季报
export function reportContactAdd(params){
    return {
        type : ADD_REPORTCONTACT,
        params
    }
}

export function listReportContactHistory(params){
    return {
        type : LIST_REPORTCONTACT,
        params
    }
}


export function reportContactRemove(params){
    return {
        type : REMOVE_REPORTCONTACT,
        params
    }
}

export function reportContactUpdate(params){
    return {
        type : UPDATE_REPORTCONTACT,
        params
    }
}

export function updateFollowupSign(params){
    return {
        type : UPDATE_FOLLOWUP_SIGN,
        params
    }
}

export function patientOutHospital(params){
    return {
        type : PATIENT_OUT_HOSPITAL,
        params
    }
}