import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import SignaturePad from '../../lib/signature/index';
import SignPadPlus from '../../lib/signPadPlus';
import Input from '@material-ui/core/Input';
import Toast from "antd-mobile/lib/toast";
import {queryTrackNotice,trackFeedbackSubmit,queryTrackFeedback} from "../../redux/actions/patient_manage_action";

import locale from "antd/es/date-picker/locale/zh_CN";
import {DatePicker} from 'antd';
import Moment from 'moment';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}

        />
    );
}

//患者追踪反馈单
class TrackFeddback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: localStorage.getItem("userId"),
            patientId: 0,
            traceId:0,
            id:0,
            trackNumber: 0,
            trackType: 0,
            trackResult: 0,
            excludeHospital: '',
            excludeDiagnose: '',
            excludeTime: null, //
            cityVisitHospital: "", //
            cityVisitTime: null, //
            countyVisitTime: null, //
            treatHospital: "", //
            relocate: "", //
            reject: "", //
            failType: 0, //
            failDescribe: '',
            dieTime: null, //
            dieReason: "", //
            other: "", //
            doctorName: "", //
            doctorPhone: "", //
            createTime: Moment(new Date()),
            currentTimeYear: Moment(new Date()).format('YYYY'),
            currentTimeMonth: Moment(new Date()).format('MM'),
            currentTimeDay: Moment(new Date()).format('DD'),

        }
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        
        this.excludeTimeOnChange = this.excludeTimeOnChange.bind(this);
        this.cityVisitTimeOnChange = this.cityVisitTimeOnChange.bind(this);
        this.countyVisitTimeOnChange = this.countyVisitTimeOnChange.bind(this);
        this.dieTimeOnChange = this.dieTimeOnChange.bind(this);

        this.handleTrackResultChange = this.handleTrackResultChange.bind(this);
    }

    componentDidMount() {
        //this.state.patientId = this.props.location.state.patientId;
        //this.state.findPerson = this.props.location.state.findPerson;
        this.state.traceId = this.props.match.params.traceId;

        let params = {
          //patientId : this.props.location.state.patientId,
          userId : this.state.userId,
          trackId: this.props.match.params.traceId
        }
        this.props._queryTrackNotice(params);
        document.getElementById('App').scrollIntoView(true);//为ture返回顶部，false为底部
        if(this.props.location.state.actionType == "edit"){
            this.props._queryTrackFeedback(params);
        }

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
       if(nextProps.trackNotice !== this.props.trackNotice){
          if(nextProps.trackNotice?.doctorName != undefined){
              this.setState({
                  doctorName:nextProps.trackNotice.doctorName
              })
          }
          if(nextProps.trackNotice?.doctorPhone != undefined){
            this.setState({
                doctorPhone:nextProps.trackNotice.doctorPhone
            })
        }
       }
        if (nextProps.trackFeedbackRet !== this.props.trackFeedbackRet) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.trackFeedbackRet.code === 200) {
                Toast.show("操作成功!");
                //this.props.history.push('/doctorIndex/manage',{type: 'caller'});
                this.props.history.goBack();
            }else {
                Toast.show("操作失败！"+nextProps.trackFeedbackRet.msg)
            }
        }
        if(nextProps.trackFeedbackInfoRet !== this.props.trackFeedbackInfoRet){
            if(nextProps.trackFeedbackInfoRet.code === 200 && nextProps.trackFeedbackInfoRet.data != undefined){
                let fd = nextProps.trackFeedbackInfoRet.data
                this.setState({
                    id: fd.id,
                    trackNumber: fd.trackNumber,
                    trackType: fd.trackType,
                    trackResult: fd.trackResult,
                    excludeHospital: fd.excludeHospital,
                    excludeDiagnose: fd.excludeDiagnose,
                    excludeTime: fd.excludeTime == undefined?null:Moment(fd.excludeTime,"YYYY-MM-DD"),
                    cityVisitHospital: fd.cityVisitHospital, //
                    cityVisitTime: fd.cityVisitTime == undefined?null:Moment(fd.cityVisitTime,"YYYY-MM-DD"), //
                    countyVisitTime: fd.countyVisitTime == undefined?null:Moment(fd.countyVisitTime,"YYYY-MM-DD"), //
                    treatHospital: fd.treatHospital, //
                    relocate: fd.relocate, //
                    reject: fd.reject, //
                    failType: fd.failType, //
                    failDescribe: fd.failDescribe,
                    dieTime: fd.dieTime == undefined?null:Moment(fd.dieTime,"YYYY-MM-DD"), //
                    dieReason: fd.dieReason, //
                    other: fd.other, //
                    doctorName: fd.doctorName, //
                    doctorPhone: fd.doctorPhone, //
                    createTime: fd.createTime,
                    currentTimeYear: fd.createTime.split("-")[0],
                    currentTimeMonth: fd.createTime.split("-")[1],
                    currentTimeDay: fd.createTime.split("-")[2],
                })
                //this.state.excludeTime = fd.excludeTime == undefined?null:Moment(fd.excludeTime,"YYYY-MM-DD")
                //console.log(this.state.excludeTime)
            }
        }
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    excludeTimeOnChange(date, dateString) {
        this.setState({excludeTime: date});
    }

    cityVisitTimeOnChange(date, dateString) {
        this.setState({cityVisitTime: date});
    }

    countyVisitTimeOnChange(date, dateString) {
        this.setState({countyVisitTime: date});
    }

    dieTimeOnChange(date, dateString) {
        this.setState({dieTime: date});
    }

    handleTrackResultChange(event){
        console.log(event)
        this.setState({
            trackResult: event.target.value
        });
    }

    handleSubmit() {

        if(this.state.trackResult == undefined || this.state.trackResult <= 0){
            Toast.show('请选择追踪结果');
            return false;
         }

        if(this.state.doctorName === ''){
           Toast.show('请输入我中心联系人');
           return false;
        }

        if(this.state.doctorPhone === ''){
           Toast.show('请输入联系人电话');
           return false;
        }

        const params = {
            patientId: this.state.patientId,
            trackId: this.state.traceId,
            id: this.state.id,
            trackNumber: this.state.trackNumber,
            trackType: this.state.trackType,
            trackResult: this.state.trackResult,
            excludeHospital: this.state.excludeHospital,
            excludeDiagnose: this.state.excludeDiagnose,
            excludeTime: this.state.excludeTime == undefined?"": Moment(this.state.excludeTime).format('YYYY-MM-DD'), 
            cityVisitHospital: this.state.cityVisitHospital,
            cityVisitTime: this.state.cityVisitTime == undefined?"": Moment(this.state.cityVisitTime).format('YYYY-MM-DD'),
            countyVisitTime: this.state.countyVisitTime == undefined?"": Moment(this.state.countyVisitTime).format('YYYY-MM-DD'),
            treatHospital: this.state.treatHospital,
            relocate: this.state.relocate,
            reject: this.state.reject,
            failType: this.state.failType,
            failDescribe: this.state.failDescribe,
            dieTime: this.state.dieTime == undefined?"": Moment(this.state.dieTime).format('YYYY-MM-DD'),
            dieReason: this.state.dieReason,
            other: this.state.other,
            doctorName: this.state.doctorName,
            doctorPhone: this.state.doctorPhone,
            createTime: this.state.currentTimeYear+'-'+this.state.currentTimeMonth+'-'+this.state.currentTimeDay,
        };
        
       this.props._trackFeedbackSubmit(params);
    }
    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const {classes, trackNotice} = this.props;
        
        return (
            <div id="App" className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h4 className={classes.huanzhe}>肺结核患者追踪反馈单</h4></Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>

                <Grid className={classes.rootDiv}>
                    <Grid container justify="center" wrap="nowrap" spacing={0}>
                        <Typography className={classes.title1}>肺结核患者追踪反馈单</Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.titleBold} gutterBottom>
                            <span className={classes.spanDiv}>{trackNotice?.xfHospitalName}</span>区结防所：
                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                我中心按要求对<span className={classes.spanDiv}>{trackNotice?.patientTown}</span>
                                街道/乡镇<span className={classes.spanDiv}>{trackNotice?.patientVillage}</span>
                                居委会/村<span className={classes.spanDiv}>{trackNotice?.patientAddress}</span>的患者<span className={classes.spanDiv}>{trackNotice?.patientName}</span>（<span
                                className={classes.spanDiv}>{trackNotice?.patientSex}</span>，
                                <span className={classes.spanDiv}>{trackNotice?.patientAge}</span>岁，联系电话：
                                <span className={classes.spanDiv}>{trackNotice?.patientPhone}</span>）进行追踪，情况如下：
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                追踪次数：
                               <Input name="trackNumber"
                                      value={this.state.trackNumber}
                                      onChange={this.handleInputChange}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                追踪方式： <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Grid item className={classes.titleBold}>
                                    <RadioGroup row aria-label="trackType" name="trackType" onChange={this.handleInputChange}>
                                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio checked={this.state.trackType == 1} />} label={<span className={classes.OptionDiv}>电话</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio checked={this.state.trackType == 2} />} label={<span className={classes.OptionDiv}>家访</span>}/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                追踪结果：
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 1}
                                    onChange={this.handleTrackResultChange}
                                    value="1"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '1' }}
                                />
                                1. 排除病例（<br/>医院名称：
                                        <Input name="excludeHospital" placeholder="请填写" value={this.state.excludeHospital}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>，
                                   <br/>诊断：<Input name="excludeDiagnose" placeholder="请填写" value={this.state.excludeDiagnose}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>，
                                   <br/>排除日期：<DatePicker locale={locale} format="YYYY-MM-DD" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={true} className="date-picker-border" value={this.state.excludeTime}
                                            onChange={this.excludeTimeOnChange} />
                                            ）
                                        

                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 2}
                                    onChange={this.handleTrackResultChange}
                                    value="2"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '2' }}
                                />
                                2. 至市结核病定点医疗机构就诊（
                                {trackNotice?.common == 1 && 
                                    <Input name="cityVisitHospital" placeholder="请填写市级定点医院名称" value={this.state.cityVisitHospital}
                                        onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>
                                }
                                {trackNotice?.common != 1 && 
                                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                        <Grid item className={classes.titleBold}>
                                            <RadioGroup row aria-label="cityVisitHospital" name="cityVisitHospital" onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="胸科医院" control={<StyledRadio checked={this.state.cityVisitHospital == "胸科医院"} />} label={<span className={classes.OptionDiv}>胸科医院</span>}/>
                                                <FormControlLabel className={classes.OptionDiv} value="解放军总医院第八医学中心" control={<StyledRadio  checked={this.state.cityVisitHospital == "解放军总医院第八医学中心"}  />} label={<span className={classes.OptionDiv}>解放军总医院第八医学中心</span>}/>
                                                <FormControlLabel className={classes.OptionDiv} value="市结控所" control={<StyledRadio  checked={this.state.cityVisitHospital == "市结控所"}  />} label={<span className={classes.OptionDiv}>市结控所</span>}/>
                                                <FormControlLabel className={classes.OptionDiv} value="北京老年医院" control={<StyledRadio checked={this.state.cityVisitHospital == "北京老年医院"} />} label={<span className={classes.OptionDiv}>北京老年医院</span>}/>
                                                
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                }
                            
                            就诊日期：<DatePicker locale={locale} format="YYYY-MM-DD" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={true} className="date-picker-border" value={this.state.cityVisitTime}
                                            onChange={this.cityVisitTimeOnChange} />）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 3}
                                    onChange={this.handleTrackResultChange}
                                    value="3"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '3' }}
                                />
                                3. 至区级结核病定点医疗机构就诊（就诊日期：<DatePicker locale={locale} format="YYYY-MM-DD" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" value={this.state.countyVisitTime}
                                            onChange={this.countyVisitTimeOnChange}/>）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 4}
                                    onChange={this.handleTrackResultChange}
                                    value="4"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '4' }}
                                />
                                4. 治疗中（在<Input name="treatHospital" placeholder="" value={this.state.treatHospital}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>进行抗结核治疗）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 5}
                                    onChange={this.handleTrackResultChange}
                                    value="5"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '5' }}
                                />
                                5. 迁址（病人已迁至<Input name="relocate" placeholder="" value={this.state.relocate}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 6}
                                    onChange={this.handleTrackResultChange}
                                    value="6"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '6' }}
                                />
                                6. 病人拒绝就诊（详细过程：<Input name="reject" placeholder="" value={this.state.reject}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 7}
                                    onChange={this.handleTrackResultChange}
                                    value="7"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '7' }}
                                />
                                7. 联系障碍（<Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Grid item className={classes.titleBold}>
                                    <RadioGroup row aria-label="failType" name="failType" onChange={this.handleInputChange}>
                                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio checked={this.state.failType == 1} />} label={<span className={classes.OptionDiv}>查无此人</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio checked={this.state.failType == 2} />} label={<span className={classes.OptionDiv}>手机空号</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio checked={this.state.failType == 3} />} label={<span className={classes.OptionDiv}>地址不实</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio checked={this.state.failType == 4} />} label={<span className={classes.OptionDiv}>信息有误</span>}/>
                                        
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            更正信息为：<Input name="failDescribe" placeholder="" value={this.state.failDescribe}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 8}
                                    onChange={this.handleTrackResultChange}
                                    value="8"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '8' }}
                                />
                                8. 死亡（已于<DatePicker locale={locale} format="YYYY-MM-DD" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" value={this.state.dieTime}
                                            onChange={this.dieTimeOnChange}/>，
                                因<Input name="dieReason" placeholder="" value={this.state.dieReason}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>死亡）
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <Radio
                                    checked={this.state.trackResult == 9}
                                    onChange={this.handleTrackResultChange}
                                    value="9"
                                    name="trackResult"
                                    inputProps={{ 'aria-label': '9' }}
                                />
                                9. 其他（其他未追踪到位：<Input name="other" placeholder="" value={this.state.other}
                                            onChange={this.handleInputChange} className={classes.inputa} inputProps={{'aria-label': 'description'}}/>）
                            </Typography>
                        </Grid>
                    </Grid>





                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                我中心联系人：
                               <Input name="doctorName"
                                      value={this.state.doctorName}
                                      onChange={this.handleInputChange}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalDoctorName" value={Paper5.sqHospitalDoctorName} onChange={this.handleInputChange}
                                    className={classes.inputb} inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                联系电话：
                               <Input name="doctorPhone"
                                      value={this.state.doctorPhone}
                                      onChange={this.handleInputChange}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalPhone" value={Paper5.sqHospitalPhone}
                                    onChange={this.handleInputChange} className={classes.inputb}
                                    inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid className={classes.distance} container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="hospitalName"
                                    value={trackNotice?.hospitalName}
                                    className={classes.inputa}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />社区卫生服务中心
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="currentTimeYear"
                                    value={this.state.currentTimeYear}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />年
                                <Input
                                    name="currentTimeMonth"
                                    value={this.state.currentTimeMonth}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />月
                                <Input
                                    name="currentTimeDay"
                                    value={this.state.currentTimeDay}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />日
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" className={classes.buttonBox}>

                        <Grid className={classes.cardbutton}>
                            <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleSubmit}>
                                提交
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        trackFeedbackInfoRet: state.patientManageReducer.trackFeedbackInfoRet,
        trackFeedbackRet: state.patientManageReducer.trackFeedbackRet,
        trackNotice: state.patientManageReducer.trackNoticeRet?.data,

    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
       //查询随访基本信息
        _queryTrackNotice: (params) => {  //查询肺结核患者治疗管理反馈单 信息
            dispatch(queryTrackNotice(params)) // action里面定义的
        },
        _trackFeedbackSubmit: (params) => {
            dispatch(trackFeedbackSubmit(params)) //action/index页面
        },
        _queryTrackFeedback: (params) => {
            dispatch(queryTrackFeedback(params)) //action/index页面
        }
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',


    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(0),
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
    },
    buttons: {
        margin: theme.spacing(1),
        width: '60vw',
    },
    hr: {
        height: '0.3vw',
        // backgroundColor:'#e6e6e6',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80vw',
    },
    input: {
        margin: theme.spacing(1),
        width: '88vw',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputs: {
        // margin: theme.spacing(1),
        width: '13vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },

    inputa: {
        // margin: theme.spacing(1),
        width: '40vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputb: {
        // margin: theme.spacing(1),
        width: '35vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw',
        // display:'inline-block'

    },


    titleBolds: {
        // marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },


    buttonBox: {
        height: '30vw',
        marginTop: theme.spacing(1),
    },

    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '91vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '5vw',
        marginbottom: '20vw',
    },
    spacemargin: {
        margin: theme.spacing(2),
        fontSize: '4.2vw',

    },

    spanDiv: {
        textDecoration: 'underline',
        // width: '19vw',
    },

    titleBold: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    // titleBold: {
    //     fontSize: '4.2vw',
    //     lineHeight: '8vw',
    //     textAlign: 'justify',
    //     margin: 0,
    //     color: '#333333'
    // },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    hideMe: {
        display: 'none',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackFeddback));
