import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {noticeDetail, noticeReaded} from '../../redux/actions/index';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import avatarImg from "../../assets/images/tj.png";
import returnImg from '../../assets/images/returnImg.png'
import tishi from '../../assets/images/tishi.png'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import phone from '../../assets/images/phone.png';

import gender from '../common/sex'

//我的推介-通知-3天详情页面
class IntroduceNotify3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            showModal: false,
            showsModal: false,
            id:0,
            introId:0,
            noticeType:6
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
    }

    componentDidMount() {
        this.state.introId = this.props.location.state.noticeId;
        this.state.id = this.props.location.state.id //通知主键Id

        this.props.getIntroduceNoticeById(this.state.introId)

        let notice ={}
        notice.id = this.state.id
        notice.type = this.state.noticeType

        this.props.noticeReaded(notice);
    }

    componentWillUnmount(nextProps) {
        if(nextProps != undefined){
            if (nextProps.isRead != this.props.isRead) {
                //获得发送结果之后，再调用路由切入对应页面
                if (nextProps.isRead === 200) {
                }
            }
        }
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    handleOpen = () => {
        this.setState({showModal: true});
    }

    closeModal = () => {
        this.setState({showModal: false});
    }

    ClosesModal = () => {
        this.setState({showsModal: false})
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        //get react-material css styles
        const {classes,introduceNoticeDetail} = this.props;

        return (
            //总的大盒子
            <div className={classes.root}>
                {/* 标题栏 箭头 通知详情 */}
                <Grid container className={classes.Patient}>
                    <img src={returnImg} className={classes.jiantou} onClick={this.handleNavBack}/>
                    <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.title}>通知详情</h4>
                    </Grid>
                </Grid>

                {/* 头像 */}
                <Grid container justify="center" className={classes.headPortrait}>
                    <Avatar src={avatarImg} className={classes.avatarSize}/>
                </Grid>

                {/* 介绍 */}
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>姓名：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDetail.name}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>性别：</Typography>
                    <Typography className={classes.fieldValue}>{gender(introduceNoticeDetail.sex)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>年龄：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDetail.age}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>电话：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDetail.telphone}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>现住址：</Typography>
                    <Typography className={classes.fieldValueDiv}>{introduceNoticeDetail.address}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>

                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>推介原因：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDetail.reason}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>

                <Grid container justify="space-between" className={classes.buttonBox}>
                    <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleOpen}>
                        再次发送推介码
                    </Button>
                    <a href={"tel:"+introduceNoticeDetail.telphone}>
                        <Button variant="contained" color="primary" className={classes.margin}>
                            <img src={phone} className={classes.phoneDiv}></img>拨打电话
                        </Button>
                    </a>
                </Grid>

                <Modal className={classes.modal} open={this.state.showModal} closeAfterTransition
                    BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                    <Grid item xs={10} className={classes.paper}>
                        <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                        <Grid item xs={12} className={classes.paperDiv}>
                            <Grid item xs={12} className={classes.tishiDiv}>
                                <img className={classes.tishi} src={tishi}></img>
                                <span className={classes.spanDiv}>患者推介发送成功!</span>
                            </Grid>
                            <Grid item xs={12} className={classes.buttonDiv}>
                                <Button variant="contained" color="primary" className={classes.buttons} onClick={this.closeModal}>确 &nbsp;认</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                <Modal className={classes.modal} open={this.state.showsModal} onClose={this.ClosesModal}
                    closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} >
                    <Grid className={classes.papers}>
                        <Grid container justify="center" alignItems="center" >
                            <Typography className={classes.ridDiv}> 呼叫</Typography>
                        </Grid>
                    </Grid>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        introduceNoticeDetail: state.introduceReducer.introduceNoticeDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIntroduceNoticeById: (noticeId)=> {
            dispatch(noticeDetail(noticeId))
        },
        noticeReaded :(notice) => {
        dispatch(noticeReaded(notice))
    }
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: '#FFFFFF',
        height: '100vh',
    },

    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    paper: {
        width: '85vw',
        height: '43vw',
        backgroundColor: '#ffffff'
    },

    papers: {
        width: '85vw',
        backgroundColor: '#ffffff',
        fontSize: '4vw',

    },
    ridDiv: {
        fontSize: '4.2vw',
        padding: theme.spacing(2),
    },

    waraing: {
        height: '10vw',
        margin: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)'
    },

    tishi: {
        height: '10vw'
    },

    paperDiv: {
        height: '15vw',
        flexDirectio: 'column'
    },

    buttonDiv: {
        height: '18vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },

    buttons: {
        width: '30vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '4vw',
        padding: 0
    },

    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },

    spanDiv: {
        fontSize: '3.5vw',
        marginLeft: '4vw'
    },

    hrs: {
        margin: 0,
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },

    titles: {
        marginLeft: '5vw',
        marginTop: 0,
        marginBottom: '1vw',
        lineHeight: '13vw',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'

    },

    titleDiv: {
        height: '15vw',
        display: 'flex',
        flex: 1
    },

    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0
    },

    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2)
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '14vw'
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)'
    },

    title: {
        color: '#ffffff',
        fontWeight: '500',
        lineHeight: '0vw'
    },

    card: {
        maxWidth: '100vw',
        height: '100vh'
    },

    media: {
        height: 0,
        paddingTop: '56.25%',
    },

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },

    expandOpen: {
        transform: 'rotate(180deg)'
    },

    margin: {
        height: '12vw',
        width: '38vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },

    headPortrait: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },

    avatarSize: {
        width: '22vw',
        height: '22vw'
    },

    fieldLable: {
        marginLeft: theme.spacing(2),
        lineHeight: '10vw',
        height: '10vw',
        fontSize: '4.2vw',
        color: '#333333'
    },

    fieldValue: {
        color: '#999999',
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        height: '10vw'
    },

    fieldValueDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999'
    },

    cardjiuzhen: {
        width: '90vw',
        height: '32vh',
        marginLeft: '5vw'
    },

    cardbutton: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },

    titleDIV: {
        width: '90vw',
        marginLeft: '3vw'
    },

    textshuru: {
        width: '92vw',
        marginLeft: '3vw',
        marginTop: '1vw',
        height: '20vw',
        backgroundColor: 'rgba(36,225,221,0.05)',
        border: '0.1vw solid #e6e6e6',
        paddingLeft: '1.5vw',
        paddingTop: '1vw'
    },

    buttonBox: {
        width: '100vw',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },

    phoneDiv: {
        height: '6vw',
        marginRight: '1vw'
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntroduceNotify3));
