import * as ActionTypes from '../actions/patient_manage_action'

// The initial application state
let initialState = {
    patients: {},
    medicationRecord: {},
    sputumRecord: {},
    takeMedicineAdmin: {},
    getTakeMedicineStatus: 0,
    patientDetail: {},
    takeMedicine: {},
    manageNotice: [],
    manageWait: [],
    createFirstFollowUpCode: -1,
    followUpVisitPaperResult: -1,
   followMessage: '',
    PatientStopTreat: {},
    ConfirmationManagement: {},
    Paper5: {},
    feedbackSubmissionCode: 500,
    SubmitreturnCode: 500,
    followUpVisitPaperNewInfo2: {},

    followUpBasicInfo:{},
    fileUrl:'',
    followUpHistoryList:[],
    stopTreat:{},
    stopTreatInfo:{},
    sureManageOk:500,
    readInfo:{},
    confirmStopTreat:{},
    serviceProtocolInfo:{},
    serviceSignCommitCode : 500,
    finishTreatCode : 500,
    firstFollowUpId:0,
    followUpId : 0,  //随访Id
    yaoheList: [],
   bandingCode: 500,
   untyingCode: 500,
   sureManageRet: '',
}


// Takes care of changing the application state
function patientManageReducer(state = initialState, action) {

   switch (action.type) {
        case ActionTypes.MY_PATIENTS_SUCCEED://患者管理 - 我的患者请求成功
            let patients = {}
            if(action.result.code === 200){
                patients = action.result.data
            }
            console.log(action.result)
            return {...state, patients: patients, retCode:action.result.code}

        case ActionTypes.MEDICATION_RECORD_SUCCEED: //患者管理-我的患者-服药记录
            return {...state, medicationRecord: action.result}
        case ActionTypes.QUERY_CHECK_SPUTUM_SUCCEED: //患者管理-患者详情-查痰记录
            return {...state, sputumRecord: action.result}

        case ActionTypes.PATIENT_DETAIL_SUCCEED://患者管理-我的患者-患者详情
            let patientDetail = {}
            if(action.result.code === 200){
                patientDetail = action.result.data
            }
            return {...state, patientDetail: patientDetail,retCode:action.result.code}

        case ActionTypes.PATIENT_TAKE_MEDICINE_SUCCEED://患者管理-我的患者 - 服药记录- 服药详情
            return {...state, takeMedicine: action.result}

        case ActionTypes.POST_MANAGENOTICE_SUCCEED://患者管理 - 患者通知列表请求成功
            return {...state, manageNotice: action.result};

       case ActionTypes.BANG_DING_JIE_BANG_SUCCEED://患者管理 - 绑定接口
          return {...state, bandingCode: action.result};

       case ActionTypes.YAO_HE_UNTYING_SUCCEED://患者管理 - 解绑接口
          return {...state, untyingCode: action.result};

       case ActionTypes.YAO_HE_LIST_SUCCEED://患者管理 - 药盒列表
          return {...state, yaoheList: action.result};

        case ActionTypes.POST_MANAGEWAIT_SUCCEED://患者管理 - 待随访请求成功
            if(action.result){
                return {...state, manageWait: action.result.data?.rows, retCode:action.result.code};
            }else{
                return {...state, manageWait: new Array()};

            }
        case ActionTypes.SURE_MANAGE_OK:
            return {...state, sureManageRet: action.result}

        case ActionTypes.POST_FOLLOWUP_4_SUCCEED://患者管理 - 患者通知列表请求成功
            let retData1 = {}
            if(action.result.code === 200){
                retData1 = action.result.data
            }
            return {...state, PatientStopTreat: retData1,retCode:action.result.code};

        case ActionTypes.POST_FOLLOWUP_1_SUCCEED://患者管理 - 通知列表-新患者确认管理通知单
            let retData = {}
            if(action.result.code === 200){
                retData = action.result.data
            }
            return {...state, ConfirmationManagement: retData,retCode:action.result.code};

        case ActionTypes.POST_FOLLOWUP_5_SUCCEED://患者管理 - 通知列表-患者治疗管理反馈单
            return {...state, Paper5: action.result};

        case ActionTypes.FEEDBACKS_SUBMISSION_SUCCEED://患者管理 - 通知列表-患者治疗管理反馈单（提交）
            return {...state, feedbackSubmissionRet: action.result};

       case ActionTypes.POST_TAKE_MEDICINE_EVENT_SUCCEED://患者管理 -通知列表 - 点击服药成功
          return {...state, takeMedicineAdmin: action.result};

       case ActionTypes.GET_TAKE_MEDICINE_STATUS_SUCCEED://患者管理 -通知列表 - 查询服药状态
          return {...state, getTakeMedicineStatus: action.result.takeMedicine};

        case ActionTypes.SUBMITRETURN_SUCCEED://患者管理 - 通知列表-患者治疗管理反馈单（返回）
            return {...state, SubmitreturnCode: action.result};

        case ActionTypes.POST_FOLLOWPAPER_SUCCEED://患者管理 - 待随访- 日常随访请求 (提交)
            return {...state, followUpVisitPaperRet: action.result,followUpVisitPaperResult: action.result.code, followMessage:action.result.msg, followUpId:action.result.followUpId};

        case ActionTypes.QUERY_FIRST_FOLLOWUP_SUCCEED:
            return {...state,followUpBasicInfo:action.result};

        case ActionTypes.POST_FOLLOWPAPERNEWINFO_SUCCEED://患者管理 - 待随访- 首次随访请求 (提交)
            return {...state, createFirstFollowUpRet: action.result};

        case ActionTypes.POST_FOLLOWPAPERNEWINFOTWO_SUCCEED://患者管理 - 待随访- 首次随访枚举值请求 (提交)
            return {...state, followUpVisitPaperNewInfo2: action.result};

        case ActionTypes.UPLOAD_FILE_SUCCEED:
            return {...state, fileUrl: action.result};

        case ActionTypes.FOLLOW_UP_HISTORY_SUCCEED:
            return {...state, followUpHistoryList: action.result}

        case ActionTypes.STOP_FOLLOW_UP_SUCCEED:
            return {...state,stopTreat:action.result,stopTreatInfo:action.result.stopTreat}

        case ActionTypes.FINISH_TREAT_SUCCEED:
            return {...state,finishTreatCode:action.result}

        case ActionTypes.READ_SUCCEED:
            return {...state, readInfo:action.result}

        case ActionTypes.CONFIRMSTOPTREAT_SUCCEED:
            return {...state, confirmStopTreat:action.result}

        case ActionTypes.QUERY_SERVICE_PROTOCOL_INFO_SUCCEED:
            return {...state,serviceProtocolInfo:action.result}

        case ActionTypes.SERVICE_SIGN_AGREEMENT_COMMIT_SUCCEED:
            return {...state,serviceSignCommitRet:action.result}

        //密接单
        case ActionTypes.CLOSE_SCREENING_LIST_SUCCEED:
            return {...state,closeScreeningCode:action.result}

        case ActionTypes.QUERY_TRACK_NOTICE_SUCCEED:
            return {...state,trackNoticeRet:action.result}

        case ActionTypes.TRACK_FEEDBACK_SUBMIT_SUCCEED:
            return {...state,trackFeedbackRet:action.result}

        case ActionTypes.QUERY_TRACK_FEEDBACK_SUCCEED:
            return {...state,trackFeedbackInfoRet:action.result}

        default:
            return state
    }

}

export default patientManageReducer
