import {call, put, takeLatest} from "redux-saga/effects";
import * as ActionTypes from "../actions/constants";

import Api from "../api/api.js";

//医生端-登录
export function* doctorLogin(action) {
    try {
        const result = yield call(Api.doctorLogin, action.loginInfo);
        if (result.error !== undefined) {
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "DOCTOR_LOGIN_ACTION_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//获取住址信息
export function* getRegionListInfoAll() {
    try {
        const result = yield call(Api.getRegionListInfo);
        if (result.error !== undefined) {
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SET_REGIONLIST", regionObject: result.data});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//获取推介原因列表
export function* getReasonListInfoAll() {
    try {
        const result = yield call(Api.getReasonListInfo);
        if (result.error !== undefined) {
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SET_REASONLIST", reasonObject: result.data});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者推介->新建推介
export function* submitIntroduceNewInfo(action) {
    try {
        const result = yield call(Api.submitIntroduceNewInfo, action.introNewInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SUBMITINTRODUCENEWINFO_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者推介-我的推介 列表数据
export function* getMyIntroduceList(action) {
    try {
        //先初始化
        const result = yield call(Api.getMyIntroduceList, action.doctorId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MYINTRODUCELIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//提交推介信息
export function* submitSendIntroduceInfo(action) {
    try {
        const result = yield call(
            Api.submitSendIntroduceInfo,
            action.sendIntroduceInfo
        );
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SUBMITSENDINTRODUCEINFO_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者推介-我的推介-查看详情
export function* introduceDetail(action) {
    const result = yield call(Api.introduceDetail, action.introId);
    try {
        if (result.error !== undefined) {
            yield  put({type: "SERVER_CONNECT_FAILED", message: result.error});
        }
        else {
            yield put({type: "INTRODUCE_DETAIL_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }

}

//患者推介-通知 列表数据
export function* getNoticeList(action) {
    try {
        //先初始化
        const result = yield call(Api.getNoticeList,action.noticeType);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "NOTICELIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//通知已读
export function* noticeReaded(action){
    try {
        //先初始化
        const result = yield call(Api.noticeReaded,action.notice);

        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "NOTICE_READED_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者推介-通知 查看详情
export function* getIntroduceNoticeDetail(action) {
    try {
        const result = yield call(Api.getIntroduceNoticeDetail, action.noticeId)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "INTRODUCE_NOTICE_DETAIL_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* getIntroduceNotice7Days(action) {
    try {
        const result = yield call(Api.getIntroduceNotice7Days, action.noticeId)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "INTRODUCE_NOTICE_DAYS7_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者推介通知-已就诊
export function* getIntroduceNotifyTreated(action) {
    try {
        const result = yield call(Api.getIntroduceNotifyTreated, action.noticeId)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "INTRODUCE_NOTIFY_TREATED_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//获取患者追踪-列表-查看详情
export function* getTrackAppointment(action) {
    try {
        const result = yield call(Api.getTrackAppointment, action.noticeId)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "TRACK_APPO_INTMENT_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//获取患者追踪-查看详情-追踪失败
export function* postTrackFailure(action) {
    try {
        const result = yield call(Api.postTrackFailure, action.failureReason)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "TRACK_FAILURE_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}



//获取追踪列表
export function* getMyTrackList(action) {
    try {
        const result = yield call(Api.getMyTrackList, action.doctorId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MYTRACKLIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//获取追踪通知列表
export function* getMyTrackNoticeList(action) {
    try {
        const result = yield call(Api.getMyTrackNoticeList,action.noticeType);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MYTRACKNOTICELIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//提交预约信息
export function* submitAppointmentInfo(action) {
    try {
        const result = yield call(Api.submitAppointmentInfo, action.appointInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SUBMITAPPOINTMENTINFO_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//提交预约信息
export function* submitAppointmentReserveInfo(action) {
    try {
        const result = yield call(
            Api.submitAppointmentReserveInfo,
            action.appointReserveInfo
        );
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SUBMITAPPOINTMENTRESERVEINFO_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//获取我的信息
export function* getMyInfo(action) {
    try {
        const result = yield call(Api.getMyInfo, action.myProfile);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "MYINFO_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//医生端我的资料
export function* getMyView(action) {
    try {
        const result = yield call(Api.getMyView, action.userId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "GET_MYVIEW_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//医生端我的手机号码修改
export function* postMobileDiv(action) {
    try {
        const result = yield call(Api.postMobileDiv, action.phoneNumber);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_PHONENUMBER_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//患者端我的姓名修改
export function* postNameDiv(action) {
    try {
        const result = yield call(Api.postNameDiv, action.doctorName);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "POST_NAMEDIV_SUCCEED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//
//患者基本新闻信息
export function* getPatientBasicNewsList(action) {
    try {
        //先初始化
        const result = yield call(Api.getPatientBasicNewsList, action.doctorId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_BASIC_NEWS_LIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//患者消息新闻信息
export function* getPatientMessageNewsList(action) {
    try {
        //先初始化
        const result = yield call(Api.getPatientMessageNewsList);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_MESSAGE_NEWS_LIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//患者新闻详情信息
export function* getPatientNewsDetail(action) {
    try {
        //先初始化
        const result = yield call(Api.getPatientNewsDetail, action.newsId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_NEWS_DETAIL_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//医生基本新闻信息
export function* getDoctorBasicNewsList(action) {
    try {
        //先初始化
        const result = yield call(Api.getDoctorBasicNewsList);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "DOCTOR_BASIC_NEWS_LIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//医生消息新闻信息
export function* getDoctorMessageNewsList(action) {
    try {
        //先初始化
        const result = yield call(Api.getDoctorMessageNewsList);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "DOCTOR_MESSAGE_NEWS_LIST_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}
//医生新闻信息详情
export function* getDoctorNewsDetail(action) {
    try {
        //先初始化
        const result = yield call(Api.getDoctorNewsDetail, action.newsId);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "DOCTOR_NEWS_DETAIL_ARRIVED", result});
        }
    } catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


//医生端 教育宣讲
export function* getDoctorTeachMessage(action) {
   try {
      //先初始化
      const result = yield call(Api.getDoctorTeachMessage, action.newsId);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: "DOCTOR_NEWS_DETAIL_ARRIVED", result});
      }
   } catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}


export default function* root() {
    //医生端 登录
    yield takeLatest(ActionTypes.DOCTOR_LOGIN_ACTION, doctorLogin);

    //患者推介begin
    yield takeLatest(ActionTypes.GET_REGIONLIST, getRegionListInfoAll);// 获取住址信息
    yield takeLatest(ActionTypes.GET_REASONLIST, getReasonListInfoAll);// 获取住址信息
    yield takeLatest(ActionTypes.POST_INTRODUCENEWINFO, submitIntroduceNewInfo); //新建推介
    yield takeLatest(ActionTypes.GET_MYINTRODUCELIST, getMyIntroduceList);//我的推介
    yield takeLatest(ActionTypes.INTRODUCE_DETAIL, introduceDetail);//我的推介-查看详情
    yield takeLatest(ActionTypes.GET_NOTICELIST, getNoticeList);//患者推介-通知列表
    yield takeLatest(ActionTypes.NOTICE_READED, noticeReaded);//患者推介-通知列表
    yield takeLatest(ActionTypes.INTRODUCE_NOTICE_DETAIL, getIntroduceNoticeDetail);//患者推介-通知列表
    yield takeLatest(ActionTypes.INTRODUCE_NOTICE_DAYS7, getIntroduceNotice7Days);
    yield takeLatest(ActionTypes.INTRODUCE_NOTIFY_TREATED, getIntroduceNotifyTreated);//患者推介-通知列表-已就诊

    //医生端我的
    yield takeLatest(ActionTypes.GET_MYVIEW, getMyView); //医生端我的资料
    yield takeLatest(ActionTypes.POST_PHONENUMBER, postMobileDiv); //医生端我的手机号码修改提交
    yield takeLatest(ActionTypes.POST_NAMEDIV, postNameDiv); //医生端我的姓名修改提交

    yield takeLatest(ActionTypes.GET_PATIENT_BASIC_NEWS_LIST, getPatientBasicNewsList);//患者新闻
    yield takeLatest(ActionTypes.GET_PATIENT_MESSAGE_NEWS_LIST, getPatientMessageNewsList);//患者新闻
    yield takeLatest(ActionTypes.GET_PATIENT_NEWS_DETAIL, getPatientNewsDetail);//患者新闻
    yield takeLatest(ActionTypes.GET_DOCTOR_BASIC_NEWS_LIST, getDoctorBasicNewsList);//医生新闻
    yield takeLatest(ActionTypes.GET_DOCTOR_MESSAGE_NEWS_LIST, getDoctorMessageNewsList);//医生新闻
    yield takeLatest(ActionTypes.GET_DOCTOR_NEWS_DETAIL, getDoctorNewsDetail);//医生新闻

    yield takeLatest(ActionTypes.GET_DOCTOR_TEACH_MESSAGE, getDoctorTeachMessage);//医生端 教育宣讲


    //患者推介end

    //患者追踪begin
    yield takeLatest(ActionTypes.GET_MYTRACKLIST, getMyTrackList);
    yield takeLatest(ActionTypes.GET_MYTRACKNOTICELIST, getMyTrackNoticeList);
    yield takeLatest(ActionTypes.TRACK_APPO_INTMENT, getTrackAppointment);//患者追踪-通知列表-患者详情
    yield takeLatest(ActionTypes.TRACK_FAILURE, postTrackFailure);//患者追踪-患者详情-追踪失败

    yield takeLatest(ActionTypes.POST_APPOINTMENTINFO, submitAppointmentInfo);
    yield takeLatest(ActionTypes.POST_APPOINTMENTRESERVEINFO, submitAppointmentReserveInfo);

    yield takeLatest(ActionTypes.POST_SENDINTRODUCEINFO, submitSendIntroduceInfo);
    yield takeLatest(ActionTypes.GET_MYINFO, getMyInfo);

}
