import * as ActionTypes from '../actions/constants'
import {POST_NAMEDIV} from "../actions/constants";

// The initial application state
let initialState = {
    myView:{},
    returnCode:500,
    // return:201

}

// Takes care of changing the application state
function myInfoReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MYINFO_ARRIVED://获取到了我的信息
    return {...state,myInfo:action.result}

    case ActionTypes.GET_MYVIEW_SUCCEED://医生端我的资料
        return {...state, myView: action.result}

      case ActionTypes. POST_PHONENUMBER_SUCCEED://医生端我的手机号码修改提交
          return {...state, returnCode: action.result}

      case ActionTypes. POST_NAMEDIV_SUCCEED://医生端我的姓名修改提交
          return {...state, returnCode: action.result}

    default:
      return state
  }
}

export default myInfoReducer
