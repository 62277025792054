import React from 'react';
import { connect } from 'react-redux';
//弹出框
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

// 引入moment 时间组件
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import Button from '@material-ui/core/Button';
import { Upload, message, Icon } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Api from '../../redux/api/api';
import Toast from "antd-mobile/lib/toast";
import axios from "axios";
import { HighlightSharp } from '@material-ui/icons';
import { Modal as ModalAnt, Radio as RadioAnt } from "antd";

import SignPadPlus from '../../lib/signPadPlus';

import { commitAddFollowupImage, updateFollowupSign } from "../../redux/actions/custom_action";

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio className={classes.root} disableRipple color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} {...props} />
    );
}


//随访历史详情页
class FollowUpHistoryDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            userId: localStorage.getItem("userId"),
            pic: '',
            patientId: 0,
            noticeId: 0,
            followupType: 0, //随访地点
            issue: '',
            useMethod: 0,    //用法用量（1:每日；2:间歇）
            drugType: 0,
            directType: 0,
            missDay: 0,
            wrongReact: 0,
            complication: 0,
            referralDept: '',
            referralReason: '',
            result: '',
            suggestion: '',
            nextTimeStr: '',
            treatMonthAmount: 0,
            smoke: 0,
            drink: 0,
            smokeDay: 0,
            drinkDay: 0,
            nextSmoke: 0,
            nextDrink: 0,

            followUpTime: '',
            nextFollowUpTime: '',

            nextTimeYear: " ",
            nextTimeMonth: " ",
            nextTimeDay: " ",

            orderDate: this.props.history.location.state.orderDate,
            actualTime: "",
            actualTimeYear: '',
            actualTimeMonth: '',
            actualTimeDay: '',

            drugTimeYear: '',
            drugTimeMonth: '',
            drugTimeDay: '',

            nextDrugTimeYear: '',
            nextDrugTimeMonth: '',
            nextDrugTimeDay: '',

            doctorId: 0,
            doctorName: '',

            open: false,
            vertical: 'top',
            horizontal: 'center',
            warnMsg: '',
            stopTreat: '',
            wrongReactTextInput: 'none',  // 不良反应说明input显示状态
            wrongReactDesc: "", // 不良反应说明
            complicationText: 'none',  // 判断并发症有时，添加input
            complicationDesc: "",  // 并发症说明
            issueOthers: "",
            checkBoxArray: [],
            drugTypeArrayCheck: [],

            hideOther: 1,
            resistMethod: "",
            removeVisible: false,
        };
    }

    componentDidMount() {
        this.state.detail = this.props.location.state.detail
        this.setState({
            id: this.state.detail.id,
            patientId: this.state.detail.patientId,
            patientName: this.state.detail.patientName,
            patientNumber: this.state.detail.patientNumber,
            followUpTime: this.state.detail.followUpTime,
            treatMonth: this.state.detail.treatMonth == null ? 0 : this.state.detail.treatMonth,
            directId: this.state.detail.directId,
            directType: this.state.detail.directId,
            followUpType: this.state.detail.followUpType,
            issue: this.state.detail.issue,
            firstArrayCheck: this.state.detail.issue.split(","),
            issueOthers: this.state.detail.issueOthers,
            smoke: this.state.detail.smoke,
            nextSmoke: this.state.detail.nextSmoke,
            drink: this.state.detail.drink,
            nextDrink: this.state.detail.nextDrink,
            resistMethod: this.state.detail.resistMethod,
            useMethod: this.state.detail.useMethod,
            drugType: this.state.detail.drugType,
            drugTypeArrayCheck: this.state.detail.drugTypeStr?this.state.detail.drugTypeStr.split(","):[],
            missDay: this.state.detail.missDay == null ? 0 : this.state.detail.missDay,
            wrongReact: this.state.detail.wrongReact,
            complication: this.state.detail.complication,
            referralDept: this.state.detail.referralDept,
            referralReason: this.state.detail.referralReason,
            result: this.state.detail.result,
            suggestion: this.state.detail.suggestion,
            nextTime: this.state.detail.nextTime,
            doctorName: this.state.detail.doctorName,
            wrongReactDesc: this.state.detail.wrongReactDesc,   // 服药不良反应说明
            complicationDesc: this.state.detail.complicationDesc, // 并发症或合并症说明
            wrongReactTextInput: this.state.detail.wrongReact == 0 ? "none" : "block",
            complicationText: this.state.detail.complication == 0 ? "none" : "block",
            showDirectAI: this.state.detail.showDirectAI,
            pic: this.state.detail.attachment ? this.state.detail.attachment.split(",") : '', //上传照片
            sign: this.state.detail.sign, //签名
        })

        this.pickerOnChange = this.pickerOnChange.bind(this)
        this.nextPickerOnChange = this.nextPickerOnChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.checkoutEventFirst = this.checkoutEventFirst.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.askRemove = this.askRemove.bind(this)
        this.handleRemoveCancel = this.handleRemoveCancel.bind(this)
        this.checkoutDrugType = this.checkoutDrugType.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.confirmRemoveImg = this.confirmRemoveImg.bind(this)
        this.clickeImg = this.clickeImg.bind(this)
        this.updateSign = this.updateSign.bind(this)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.updateSignRet !== this.props.updateSignRet) {
            if (nextProps.updateSignRet.code === 200) {
                if(nextProps.updateSignRet.data.sign != null){
                    this.setState({
                        sign: nextProps.updateSignRet.data.sign,
                    })
                }
                if(nextProps.updateSignRet.data.signTown != null){
                    this.setState({
                        signTown: nextProps.updateSignRet.data.signTown,
                    })
                }
                if(nextProps.updateSignRet.data.signVillage != null){
                    this.setState({
                        signVillage: nextProps.updateSignRet.data.signVillage,
                    })
                }
                Toast.show("更新签名成功！");
            }
            if (nextProps.updateSignRet.code === 201) {
                Toast.show("更新签名失败！" + nextProps.updateSignRet.msg);
            }
        }
    }

    updateSign(signData) {
        const params = {
            id: this.state.id,
            patientId: this.state.patientId,
            sign: signData,
        }
        this.props.updatePatientSign(params);
    }

    // picker  onchange事件
    pickerOnChange(date, dateString) {
        this.setState({
            followUpTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })
    }


    //下次随访时间
    nextPickerOnChange(date, dateString) {
        this.setState({
            nextTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        //console.log(name+","+value)

    }
    checkoutEventFirst(event) {
        if (event.target.checked == true) {
            this.state.firstArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.firstArrayCheck.indexOf(event.target.value);
            this.state.firstArrayCheck.splice(numberInit, 1);
        }

        if (this.state.firstArrayCheck.indexOf("11") >= 0) {
            this.setState({
                hideOther: 0
            })
        } else {
            this.setState({
                hideOther: 1
            })
        }
    }
    checkoutDrugType(event) {
        console.log(event)
        console.log(this.state.drugTypeArrayCheck)
        if (event.target.checked == true) {
            this.state.drugTypeArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.drugTypeArrayCheck.indexOf(event.target.value);
            this.state.drugTypeArrayCheck.splice(numberInit, 1);
        }
        this.setState({
            drugTypeStr: this.state.drugTypeArrayCheck.join(",")
        })
    }

    //点击提交按钮
    handleUpdate() {

        //将数据打包成json,然后调用dispatch将数据发送出去
        const followUpInfo = {
            id: this.state.id,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            followupType: this.state.followUpType, //随访地点
            // issue: this.state.issue, //症状及体征
            issue: this.state.firstArrayCheck.join(","), //症状及体征
            issueOthers: this.state.issueOthers,  // 其他描述
            useMethod: this.state.useMethod==0?'':this.state.useMethod, //用法
            //drugType: this.state.drugType, //药品剂型
            drugTypeStr: this.state.drugTypeArrayCheck.join(","),
            directType: this.state.directType,//督导人员
            missDay: this.state.missDay,//漏服天数
            wrongReact: this.state.wrongReact, //不良反应
            complication: this.state.complication, //并发症
            referralDept: this.state.referralDept,//科室
            referralReason: this.state.referralReason,//原因
            result: this.state.result,//两周随访结果
            suggestion: this.state.suggestion,//处理建议
            nextTimeStr: this.state.nextTimeStr,
            treatMonthAmount: this.state.treatMonth,//治疗月序
            smoke: this.state.smoke,// 是否抽烟
            drink: this.state.drink,//饮酒
            smokeDay: this.state.smokeDay,
            drinkDay: this.state.drinkDay,
            nextSmoke: this.state.nextSmoke,//抽烟下次目标
            nextDrink: this.state.nextDrink,//喝酒下次目标
            nextTime: this.state.nextTime,//下次随访时间
            actualTime: this.state.followUpTime,//实际随访时间
            doctorId: this.state.doctorId,
            doctorName: this.state.doctorName,
            complicationDesc: this.state.complicationDesc, // 并发症说明
            wrongReactDesc: this.state.wrongReactDesc,   // 不良反应说明
            resistMethod: this.state.resistMethod
         };
        //提交首次随访
        const url = Api.getRootUrl()+'/api/followUp/editDailyFollowUp'
        let that = this;
        axios.post(url, followUpInfo).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.handleNavBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })


    }
    handleRemove(){
        const followUpInfo = {
            id: this.state.id,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
        }
        //删除首次随访
        const url = Api.getRootUrl()+'/api/followUp/remove'
        let that = this;
        axios.post(url, followUpInfo).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.handleNavBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    askRemove() {
        this.setState({
            removeVisible: true
        })
    }

    handleRemoveCancel() {
        this.setState({
            removeVisible: false
        })
    }



    //点击返回
    handleNavBack() {
        window.history.go(-1);
    }


    clickeImg(imgUrl) {

        this.setState({
            open: true,
            removeImg: imgUrl
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    confirmRemoveImg() {
        this.setState({
            open: false
        })

        let imgUrl = this.state.removeImg

        //const url = 'http://localhost:8080/api/followUp/removeImg'

        const url = Api.getRootUrl() + '/api/followUp/removeImg'
        let params = { id: this.state.detail?.id, pic: imgUrl }
        console.log(this.state.pic)

        let pics = this.state.pic.filter(function (item) {
            return item !== imgUrl;
        })
        this.setState({
            pic: pics
        })

        axios.post(url, params).then(function (rsp) {
            console.log(rsp)
            if (rsp.data.code === 200) {

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    render() {
        const { classes } = this.props;

        var that = this;
        let pics = '';

        let fileList = new Array();

        //上传
        const uploadFile = {
            name: 'file',
            action: Api.getRootUrl()+'/api/uploadFile',
            multiple: false,
            beforeUpload: (file) => {
                const isPNG = file.type === 'image/png';
                const isJPG = file.type === 'image/jpeg';
                if (!isPNG && !isJPG) {
                  message.error(`只支持PNG和JPG格式！`);
                  return false;
                }
                return isPNG || isJPG || Upload.LIST_IGNORE;
            },
            onChange(info) {
                console.log(pics)
                console.log(info)
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {

                    if(info.fileList != null && info.fileList.length > 0){
                        if(info.fileList[0].response.code == 200){
                            message.success(`${info.file.name} 上传成功`);
                            (that.state.pic || []).map((item, index) => {
                                fileList.push(item)
                            })
                            info.fileList.map((item, index) => {
                                if (fileList.indexOf(item.response.data) < 0) {
                                    fileList.push(item.response.data)
        
                                    let params = {
                                        id: that.state.id,
                                        patientId: that.state.patientId,
                                        imgUrl: item.response.data,
                                    }
                                    that.props.addFollowupImage(params)
                                }
        
                            })
                            that.setState({
                                pic: fileList
                            })
                        }else{
                           message.error(`${info.file.name} 上传失败!`);
                        }
                     }


                    


                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败.`);
                }
                return pics;
            }
        };

        
        return (
            <div className={classes.root}>

                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            确认删除该张照片吗？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.confirmRemoveImg}>确认</Button>
                        <Button onClick={this.handleClose} autoFocus>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者随访服务记录表</h5></Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                <Grid container justify="center" wrap="nowrap" spacing={2}>
                    <Typography className={classes.titleMain} h5 color="textSecondary" gutterBottom>肺结核患者随访服务记录表</Typography>
                </Grid>
                <Grid className={classes.tableDIV}>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                姓名：<span className={classes.title}>{this.state.patientName}</span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                登记号：<span className={classes.title}>{this.state.patientNumber}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                随访时间：<span className={classes.title}>
                                    <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                        allowClear={false}
                                        placeholder="请选择日期" className="date-picker-border"
                                        value={Moment(this.state.followUpTime)}
                                        onChange={this.pickerOnChange} />
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                治疗月序：<span className={classes.title}>
                                    第<Input name="treatMonth" className={classes.input} value={this.state.treatMonth} onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />月
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>督导人员：</Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="directType" aria-label="directType" onChange={this.handleInputChange} value={this.state.directType}>
                                <FormControlLabel className={classes.OptionDiv} value="1" checked={this.state.directType == 1} control={<StyledRadio />} label="医生" />
                                <FormControlLabel className={classes.OptionDiv} value="2" checked={this.state.directType == 2} control={<StyledRadio />} label="家属" />
                                <FormControlLabel className={classes.OptionDiv} value="3" checked={this.state.directType == 3} control={<StyledRadio />} label="自服药" />
                                <FormControlLabel className={classes.OptionDiv} value="4" checked={this.state.directType == 4} control={<StyledRadio />} label="志愿者" />
                                <FormControlLabel className={this.state.showDirectAI == 1?classes.OptionDiv:classes.hideMe} value="5" checked={this.state.directType == 5} control={<StyledRadio />} label="智能工具" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>随访方式：</Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="followUpType" onChange={this.handleInputChange} value={this.state.followUpType}>
                                <FormControlLabel className={classes.OptionDiv} value="1" checked={this.state.followUpType == 1} control={<StyledRadio />} label="门诊" />
                                <FormControlLabel className={classes.OptionDiv} value="2" checked={this.state.followUpType == 2} control={<StyledRadio />} label="家庭" />
                                <FormControlLabel className={classes.OptionDiv} value="3" checked={this.state.followUpType == 3} control={<StyledRadio />} label="电话" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                症状及体征：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <RadioGroup row name="issue">
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("0") > -1} value="0" control={<GreenCheckbox />} label="没有症状" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("1") > -1} value="1" control={<GreenCheckbox />} label="咳嗽咳痰" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("2") > -1} value="2" control={<GreenCheckbox />} label="低热盗汗" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("3") > -1} value="3" control={<GreenCheckbox />} label="咳血或血痰" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("4") > -1} value="4" control={<GreenCheckbox />} label="胸痛消瘦" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("5") > -1} value="5" control={<GreenCheckbox />} label="恶心纳差" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("6") > -1} value="6" control={<GreenCheckbox />} label="关节疼痛" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("7") > -1} value="7" control={<GreenCheckbox />} label="头痛失眠" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("8") > -1} value="8" control={<GreenCheckbox />} label="视物模糊" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("9") > -1} value="9" control={<GreenCheckbox />} label="皮肤瘙痒、皮疹" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("10") > -1} value="10" control={<GreenCheckbox />} label="耳鸣听力下降" />
                                    <FormControlLabel className={classes.OptionDiv} onChange={this.checkoutEventFirst} checked={this.state.firstArrayCheck?.indexOf("11") > -1} value="11" control={<GreenCheckbox />} label="其他" />
                                </RadioGroup>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                其他：<br />
                                <Input id="standard-multiline-static"
                                    name="issueOthers"
                                    value={this.state.issueOthers}
                                    onChange={this.handleInputChange}
                                    className={classes.textField}
                                    inputProps={{ 'aria-label': 'description' }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>

                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                生活方式评估：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                吸烟：目前: <Input name="smoke" value={this.state.smoke} onChange={this.handleInputChange} className={classes.inputShort} inputProps={{ 'aria-label': 'description' }}
                                />支/天，下次目标:<Input name="nextSmoke" value={this.state.nextSmoke} onChange={this.handleInputChange} className={classes.inputShort} inputProps={{ 'aria-label': 'description' }} />支/天
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                饮酒：目前:<Input name="drink" className={classes.inputShort} onChange={this.handleInputChange} value={this.state.drink} inputProps={{ 'aria-label': 'description' }} />两/天，下次目标:<Input
                                    name="nextDrink" value={this.state.nextDrink} onChange={this.handleInputChange} className={classes.inputShort} inputProps={{ 'aria-label': 'description' }} />两/天
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-star" direction="column" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>用药情况：</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-star" direction="column" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                化疗方案：<Input name="resistMethod" value={this.state.resistMethod} onChange={this.handleInputChange}
                                    className={classes.inputName} inputProps={{ 'aria-label': 'description' }} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>用法：</Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row defaultValue={this.state.useMethod} name="useMethod" value={this.state.useMethod} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.useMethod == 1} value="1" control={<StyledRadio />} label="每日" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.useMethod == 2} value="2" control={<StyledRadio />} label="间歇" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>药品剂型：</Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row defaultValue="1" name="drugTypeStr" value={this.state.drugTypeStr} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} label="固定剂量复合制剂" checked={this.state.drugTypeArrayCheck?.indexOf("1") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="1" color="primary" />} />
                                <FormControlLabel className={classes.OptionDiv} label="散装药" checked={this.state.drugTypeArrayCheck?.indexOf("2") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="2" color="primary" />} />
                                <FormControlLabel className={classes.OptionDiv} label="板式组合药" checked={this.state.drugTypeArrayCheck?.indexOf("3") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="3" color="primary" />} />
                                <FormControlLabel className={classes.OptionDiv} label="注射剂" checked={this.state.drugTypeArrayCheck?.indexOf("4") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="4" color="primary" />} />
                            {/* 
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.drugType == 1} value="1" control={<StyledRadio />} label="固定剂量复合制剂" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.drugType == 2} value="2" control={<StyledRadio />} label="散装药" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.drugType == 3} value="3" control={<StyledRadio />} label="板式组合药" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.drugType == 4} value="4" control={<StyledRadio />} label="注射剂" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.drugType == 24} value="24" control={<StyledRadio />} label="散装药+注射剂" />
                                */}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                漏服药次数：<Input name="missDay" className={classes.input} value={this.state.missDay} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />天
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                服药不良反应：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="wrongReact" value={this.state.wrongReact} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} value="1" checked={this.state.wrongReact == 1} control={<StyledRadio />} label="有" />
                                <FormControlLabel className={classes.OptionDiv} value="2" checked={this.state.wrongReact == 2} control={<StyledRadio />} label="无" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Input name="wrongReactDesc" value={this.state.wrongReactDesc}
                        style={{ display: this.state.wrongReactTextInput }}
                        inputProps={{ 'aria-label': 'description' }}
                        onChange={this.handleInputChange}
                    />
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                并发症或合并症：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row defaultValue={this.state.complication} name="complication" value={this.state.complication} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.complication == 1} value="1" control={<StyledRadio />} label="有" />
                                <FormControlLabel className={classes.OptionDiv} checked={this.state.complication == 2} value="2" control={<StyledRadio />} label="无" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Input name="complicationDesc" value={this.state.complicationDesc}
                        style={{ display: this.state.complicationText }}
                        inputProps={{ 'aria-label': 'description' }}
                        onChange={this.handleInputChange}
                    />
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                患者不良反应就诊：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                就诊机构及科室：<Input name="referralDept" value={this.state.referralDept} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                原因：<Input name="referralReason" value={this.state.referralReason} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                2周内随访结果：<Input name="result" value={this.state.result} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                处理意见：<Input name="suggestion" value={this.state.suggestion} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                下次随访时间：<DatePicker locale={locale} format="YYYY年MM月DD日"
                                    className="date-picker-border"
                                    value={Moment(this.state.nextTime)}
                                    placeholder="请选择日期"
                                    onChange={this.nextPickerOnChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                            管理医生：<Input name="doctorName" value={this.state.doctorName} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography className={classes.photosDIV} color="textSecondary" gutterBottom>
                            上传照片：<Upload {...uploadFile} showUploadList={{ "showDownloadIcon": false }} name="file">
                                <Button style={{ display: "" }} className={classes.buttonsTwo}>
                                    <Icon type="upload" /> 添加照片
                                </Button>
                            </Upload>
                            {/*(this.state.pic || []).map((item,index) => {
                            return (
                                        <img style={{display:""}} key={index} justify="flex-start" src={item} onClick={()=>this.clickeImg(item)} className={classes.picSize}></img>
                                    )
                            })
                        */}

                            <ImageList rowHeight={180} className={classes.imageList}>
                                {(this.state.pic || []).map((item) => (
                                    <ImageListItem key={item}>
                                        <img src={item} alt={item.title} />
                                        <ImageListItemBar
                                            title={item.title}
                                            onClick={() => this.clickeImg(item)}
                                            subtitle={<span>点击删除</span>}
                                            actionIcon={
                                                <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                签名：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <SignPadPlus ref='patientSign' onSave={this.updateSign} name="sign" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                已签预览：<img src={this.state.sign} width={300} />
                            </Typography>
                        </Grid>
                    </Grid>


                    {/*<Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                            患者（家属）签字：<img src={this.state.pic} height={300} />
                            </Typography>
                        </Grid>
                    </Grid>*/}
                    <Grid container justify="center" wrap="nowrap" spacing={2}>
                        <Grid item xs={12} className={classes.buttonDiv}>
                            <Button variant="contained" color="secondary" className={classes.buttonUpdate}
                                onClick={this.handleUpdate}>
                                更新
                            </Button>
                            <Button variant="contained" color="secondary" className={classes.buttonRemove}
                                onClick={this.askRemove}>
                                删除
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{height:'1.25rem'}}></div>
                </Grid>

                <ModalAnt title="删除确认"
                    visible={this.state.removeVisible}
                    afterClose={this.handleRemoveCancel}
                    onCancel={this.handleRemoveCancel}
                    destroyOnClose={true}
                    forceRender={true}
                    footer={[
                        <Button key="submit" onClick={this.handleRemove}>提交</Button>,
                        <Button key="back" onClick={this.handleRemoveCancel}>取消</Button>,
                    ]}
                >
                    <Grid item xs={10} className={classes.bindContent}>
                        <Grid className={classes.bindContentFlex}>
                            <label>是否进行删除？</label>
                        </Grid>
                    </Grid>
                </ModalAnt>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        updateSignRet: state.customReducer.updateFollowupSignRet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFollowupImage: (params) => {
            dispatch(commitAddFollowupImage(params))
        },
        updatePatientSign: (params) => {
            dispatch(updateFollowupSign(params))
        },
    }
}

//css region
const styles = theme => ({
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
    },
    buttonUpdate: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    buttonRemove: {
        width: '25vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    titleMain: {
        marginLeft: '3vw',
        fontSize: '5.5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    file: {
        display: 'none',
        visibility: 'hidden',
    },
    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontWeight: '300',
        width: '91vw',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    input: {
        width: '13vw'
    },
    inputs: {
        marginBottom: theme.spacing(1),
        width: '88vw'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    inputDiv: {
        width: '12vw',
        textAlign: 'center',
    },
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500'
    },

    titleBold: {
        margin: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    doctor: {
        marginTop: theme.spacing(1),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    title: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },
    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },
    click: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#00C9E4',
        textDecoration: 'none',
        underline: 'none'
    },

    clickDiv: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },

    tableDIV: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    inputShort: {
        width: '10vw',
    },
    hideMe: {
        display:'none'
     },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpHistoryDetails));
