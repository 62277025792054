import React from 'react';
import logo from '../assets/images/yidudao.png';
import {connect} from 'react-redux';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {doctorLogin} from '../redux/actions/index';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import {purple} from '@material-ui/core/colors';
import yonghu from '../assets/images/yonghu.png';
import mima from '../assets/images/mima.png';
import Grid from '@material-ui/core/Grid';
import MainView from './MainView';
import {Route, Switch} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Toast from "antd-mobile/lib/toast";
import TencentCaptcha from '@carpenter/react-tencent-captcha';
import md5 from 'md5';
import Checkbox from '@material-ui/core/Checkbox';


class AuthView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {

      };
   }

   

   componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    localStorage.setItem("userName",params.get("userName"))
    localStorage.setItem("userId",params.get("userId"))
    localStorage.setItem("hospitalId",params.get("hospitalId"))
    localStorage.setItem("doctorId",params.get("doctorId"))
    localStorage.setItem("token",params.get("token"))
    this.props.history.push(`/doctorIndex`);
   }


   //fired when props changed
   componentWillReceiveProps(nextProps) {
      
   }

   

   render() {
      const {classes} = this.props;
      return (
         <Switch>
            <Grid className={classes.root}>
               

            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      
   }
}

//css region
const styles = theme => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      color: '#333333',
      backgroundColor: '#FFFFFF',
   }
   
});

//end css region

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuthView));
