import {call, put, takeLatest} from "redux-saga/effects";
import * as ActionType from "../actions/custom_action";

import api from "../api/custom.js";

//获取患者对应的定制id
export function* getCustomInfo(action) {
    try {
        const result = yield call(api.queryCustomInfo,action.customInfo);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "CUSTOM_MODULE_INFO_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//验证访视码
export function* validFollowupCode(action) {
    try {
        const result = yield call(api.validFollowupCode,action.params);
        console.log(result)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "VALID_FOLLOWUP_CODE_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* sendSmsToPatient(action) {
    try {
        const result = yield call(api.sendSmsToPatient,action.params);
        console.log(result)
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SEND_SMS_CODE_TO_PATIENT_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* reOrderFollowup(action) {
    try {
        const result = yield call(api.reOrderFollowup,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "RE_ORDER_FOLLOWUP_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* signedConfirm(action) {
    try {
        const result = yield call(api.signedConfirm,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SIGNED_CONFIRM_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* noticeManage(action) {
    
    try {
        const result = yield call(api.noticeManage,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "NOTICE_MANAGE_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* noticeAll(action) {
    
    try {
        const result = yield call(api.noticeAll,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "NOTICE_ALL_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* reliefDealConfirm(action) {
    try {
        const result = yield call(api.reliefDealConfirm,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SERVICE_RELIEF_DEAL_COMMIT_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* directDealConfirm(action) {
    try {
        const result = yield call(api.directDealConfirm,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SERVICE_DIRECT_DEAL_COMMIT_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* directAnswerConfirm(action) {
    try {
        const result = yield call(api.directAnswerConfirm,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "SERVICE_DIRECT_ANSWER_COMMIT_SUCCEED", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* addFollowupImageConfirm(action) {
    try {
        const result = yield call(api.addFollowupImage,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "ADD_FOLLOWUP_IMAGE_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* addScreeningDiabetes(action) {
    try {
        const result = yield call(api.addScreeningDiabetes,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "ADD_SCREENING_DIABETES_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* listScreeningDiabetes(action) {
    try {
        const result = yield call(api.listScreeningDiabetes,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "LIST_SCREENING_DIABETES_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* removeScreeningDiabetes(action) {
    try {
        const result = yield call(api.removeScreeningDiabetes,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "REMOVE_SCREENING_DIABETES_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* updateScreeningDiabetes(action) {
    try {
        const result = yield call(api.updateScreeningDiabetes,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPDATE_SCREENING_DIABETES_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//老年人筛查start
export function* addScreeningAged(action) {
    try {
        const result = yield call(api.addScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "ADD_SCREENING_AGED_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* listScreeningAged(action) {
    try {
        const result = yield call(api.listScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "LIST_SCREENING_AGED_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* removeScreeningAged(action) {
    try {
        const result = yield call(api.removeScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "REMOVE_SCREENING_AGED_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* updateScreeningAged(action) {
    try {
        const result = yield call(api.updateScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPDATE_SCREENING_AGED_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//老年人筛查end


//密接季报统计start
export function* addReportContact(action) {
    try {
        const result = yield call(api.addScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "ADD_REPORTCONTACT_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* listReportContact(action) {
    try {
        const result = yield call(api.listScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "LIST_REPORTCONTACT_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* removeReportContact(action) {
    try {
        const result = yield call(api.removeScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "REMOVE_REPORTCONTACT_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* updateReportContact(action) {
    try {
        const result = yield call(api.updateScreeningAged,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPDATE_REPORTCONTACT_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

//密接季报统计end

export function* updateFollowupSign(action) {
    try {
        const result = yield call(api.updateFollowupSign,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPDATE_FOLLOWUP_SIGN_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* patientOutHospital(action) {
    try {
        const result = yield call(api.patientOutHospital,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "PATIENT_OUT_HOSPITAL_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}


export function* addScreeningDiabetesBJ(action) {
    try {
        const result = yield call(api.addScreeningDiabetesBJ,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "ADD_SCREENING_DIABETES_BJ_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* listScreeningDiabetesBJ(action) {
    try {
        const result = yield call(api.listScreeningDiabetesBJ,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "LIST_SCREENING_DIABETES_BJ_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* removeScreeningDiabetesBJ(action) {
    try {
        const result = yield call(api.removeScreeningDiabetesBJ,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "REMOVE_SCREENING_DIABETES_BJ_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export function* updateScreeningDiabetesBJ(action) {
    try {
        const result = yield call(api.updateScreeningDiabetesBJ,action.params);
        if (result.error !== undefined) {
            //请求出错了
            yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
        } else {
            yield put({type: "UPDATE_SCREENING_DIABETES_BJ_SUCCESS", result});
        }
    }catch (e) {
        yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
    }
}

export default function* root() {
    yield takeLatest(ActionType.CUSTOM_MODULE_INFO,getCustomInfo); //获取患者对应的定制id
    yield takeLatest(ActionType.VALID_FOLLOWUP_CODE,validFollowupCode); //获取患者对应的定制id
    yield takeLatest(ActionType.SEND_SMS_CODE_TO_PATIENT,sendSmsToPatient); //给患者发送短信验证码
    yield takeLatest(ActionType.RE_ORDER_FOLLOWUP,reOrderFollowup); //给患者发送短信验证码
    yield takeLatest(ActionType.SIGNED_CONFIRM,signedConfirm); //已签约确认
    yield takeLatest(ActionType.NOTICE_MANAGE,noticeManage); //通知数-管理
    yield takeLatest(ActionType.NOTICE_ALL,noticeAll); //通知数-全部
    yield takeLatest(ActionType.SERVICE_RELIEF_DEAL_COMMIT,reliefDealConfirm); //已签约确认
    yield takeLatest(ActionType.SERVICE_DIRECT_DEAL_COMMIT,directDealConfirm); //督导协议确认
    yield takeLatest(ActionType.SERVICE_DIRECT_ANSWER_COMMIT,directAnswerConfirm); //督导答题
    yield takeLatest(ActionType.ADD_FOLLOWUP_IMAGE,addFollowupImageConfirm); //督导答题
    yield takeLatest(ActionType.ADD_SCREENING_DIABETES,addScreeningDiabetes); //添加糖尿病筛查
    yield takeLatest(ActionType.LIST_SCREENING_DIABETES,listScreeningDiabetes); //糖尿病筛查
    yield takeLatest(ActionType.REMOVE_SCREENING_DIABETES,removeScreeningDiabetes); //糖尿病筛查
    yield takeLatest(ActionType.UPDATE_SCREENING_DIABETES,updateScreeningDiabetes); //糖尿病筛查
    yield takeLatest(ActionType.UPDATE_FOLLOWUP_SIGN,updateFollowupSign); //更新随访签名
    yield takeLatest(ActionType.PATIENT_OUT_HOSPITAL,patientOutHospital); //患者出院
    yield takeLatest(ActionType.ADD_SCREENING_DIABETES_BJ,addScreeningDiabetesBJ); //北京糖尿病筛查
    yield takeLatest(ActionType.LIST_SCREENING_DIABETES_BJ,listScreeningDiabetesBJ); //北京糖尿病筛查
    yield takeLatest(ActionType.REMOVE_SCREENING_DIABETES_BJ,removeScreeningDiabetesBJ); //北京糖尿病筛查
    yield takeLatest(ActionType.UPDATE_SCREENING_DIABETES_BJ,updateScreeningDiabetesBJ); //北京糖尿病筛查
    yield takeLatest(ActionType.ADD_SCREENING_AGED,addScreeningAged); //添加老年人筛查
    yield takeLatest(ActionType.LIST_SCREENING_AGED,listScreeningAged); //老年人筛查
    yield takeLatest(ActionType.REMOVE_SCREENING_AGED,removeScreeningAged); //老年人筛查
    yield takeLatest(ActionType.UPDATE_SCREENING_AGED,updateScreeningAged); //老年人筛查
}