import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
// import {} from '../../redux/actions/index';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import avatarImg from "../../assets/images/tj.png";
import Avatar from '@material-ui/core/Avatar';
import returnImg from '../../assets/images/returnImg.png'


//咨询医生-医生简介
class ConsultDoctor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            showModal: false,
            showsModal: false,
        };
        this.handleNavBack = this.handleNavBack.bind(this);
    }

    handleLoginClick() {
        this.resetAll();
        this.setState({
            tabulation: true
        });
        const path = `${this.props.match.path}/vinview`;
        this.props.history.push(path);
    }

    handClick() {
        this.resetAll();
        this.setState({
            showcase: true
        });
    }

    leLoginClick() {
        this.resetAll();
        this.setState({
            business: true
        });
    }

    resetAll() {
        this.setState({
            tabulation: false,
            showcase: false,
            business: false,
        });
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    //点击返回按钮
    handleNavBack() {
        this.props.history.goBack();
    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>

                <Grid container className={classes.Patient}>
                    <img src={returnImg} alt="" className={classes.jiantou} onClick={this.handleNavBack}/>
                    <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.title}>医生简介</h4>
                    </Grid>
                </Grid>

                <Grid className={classes.paper}>
                    <Grid>
                        <Avatar src={avatarImg} className={classes.avatar}/>
                    </Grid>
                    <Grid>
                        <Typography className={classes.nameDiv} component="p">
                            张无忌
                        </Typography>
                        <Typography className={classes.hosPital} component="p">
                            北京三甲医院
                        </Typography>
                        <Typography className={classes.hosPital} component="p">
                            职称：主治医师
                        </Typography>

                    </Grid>
                </Grid>


                <hr className={classes.hr}></hr>
                <Grid className={classes.handerDiv}>
                    <Grid container xs={12} justify="flex-start">
                        <Typography>
                            <h3 className={classes.subTitle}>医生简介</h3>
                        </Typography>
                    </Grid>
                    <Grid container xs={12} justify="flex-start">
                        <Typography className={classes.IntroducTion}>
                            清华大学附属北京清华长庚医院感染性疾病科执行主任，原系解放军第309医院全军结核病研究所结核科主任。解放军总医院/医学院呼吸系病硕士生导师，河北省北方学院呼吸内科硕士生导师，中央保健委会诊专家，国家药监局新药评审专家，难治性肺结核、结核性脑膜炎、胸膜炎、肾结核、结核性腹膜炎及淋巴结核等的治疗和经纤维支气管镜下介入下各种治疗，各种复杂疑难感染性疾病的诊治，长期不明原因发热的诊断、鉴别及治疗。
                        </Typography>
                    </Grid>
                </Grid>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        userId: state.SendUserIdReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // setOnline: (online) => {
        //     dispatch(setOnline(online))
        // },
    }
}
const styles = theme => ({
    root: {
        flexGrow: 1,
        background:'#FFFFFF',
        height: '100vh',
        overflow: 'hidden'

    },

    nameDiv: {
        fontSize: '4.5vw',
        color: '#333333',
        fontWeight: '600',
        marginBottom: '1vw',
        marginTop: '1.5vw'

    },

    hosPital: {
        fontSize: '3vw',
        color: '#999999'
    },

    subTitle: {
        margin: theme.spacing(0),
        color: '#333333',
        fontSize: '5vw',
        fontWeight: '600',
    },

    IntroducTion: {
        marginTop: theme.spacing(1),
        color: '#999999',
        fontSize: '4vw',
        textAlign: 'justify',
        lineHeight: ' 6.5vw ',
    },

    paper: {
        display: 'flex',
        height: '33vw',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },

    hr: {
        border: '1.5vw solid #ededed'
    },

    handerDiv: {
        margin: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '83vw',
        height: '14vw'
    },

    title: {
        margin: 0,
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '14vw',
    },

    span: {
        lineHeight: '5vh',
        marginRight: '3vw',
        color: '#999999'
    },

    footerDiv: {
        alignSelf: 'flex-end',
        marginTop: '17vw',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        border: '0.1vw solid #e6e6e6',
        width: '100vw',
        height: '14vw',
    },

    bott: {
        width: '80vw',
        height: '14vw',
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
    },

    buttonon: {
        outline: 'none',
        width: '12vw',
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        marginBottom: '1vw',
        color: '#55E8E5',
        filter: 'none',
    },

    buttonons: {
        outline: 'none',
        width: '16vw',
        height: '16vw',
        marginBottom: '3vw',
        borderRadius: '50%',
        border: '0.1vw solid #e6e6e6',
        backgroundColor: '#e6e6e6',
        color: '#55E8E5',
        filter: 'none',

    },

    buttonsdiv: {
        outline: 'none',
        width: '12vw',
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        marginBottom: '1vw',
        filter: 'grayscale(100%)',
    },

    buttonssdiv: {
        outline: 'none',
        width: '16vw',
        height: '16vw',
        marginBottom: '3vw',
        borderRadius: '50%',
        border: '0.1vw solid #e6e6e6',
        backgroundColor: '#e6e6e6',
        filter: 'grayscale(100%)',
    },

    imgdiv: {
        height: '6vw',

    },
    imgsdiv: {
        height: '7vw',
    },

    ps: {
        fontSize: '2.7vw',
        margin: 0,
    },

    avatar: {
        width: '20vw',
        height: '20vw',
        maxWidth: '20vw',
        maxHeight: '20vw',
        // borderRadius: "50%",
        marginLeft: theme.spacing(2),
        marginTop: '2.5vw',
        marginRight: '2.5vw'
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsultDoctor));