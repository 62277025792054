import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import SignaturePad from '../../lib/signature/index';
import SignPadPlus from '../../lib/signPadPlus';
import Input from '@material-ui/core/Input';
import Toast from "antd-mobile/lib/toast";
import {
   postfeedbackSubmission,
   queryFollowUpVisitPaper5
} from "../../redux/actions/patient_manage_action";

import locale from "antd/es/date-picker/locale/zh_CN";
import {DatePicker} from 'antd';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}

        />
    );
}

//肺结核患者治疗管理反馈单
class FollowUpVisitPaper5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: localStorage.getItem("userId"),
            patientId: 0,
            followupId:0,
            direction: '',
            value: 0,
            index: 0,
            doctorId: 0,
            patientNoticeId: 0,
            patientnoticeid: "", //通知id
            failreasontype: null, //原因
            followuptype: "", //随访方式
            directtype: "", //督导人
            followupaddress: "", //随访地址其他
            pic: "", //签名图片
            sqHospitalDoctorName: "", //社区医院联系人
            sqHospitalPhone: "", //社区医院联系电话
            currentTimeYear: "", //当前年
            currentTimeMonth: "", //当前月
            currentTimeDay: "", //当前日
            newaddress: "", //迁至新地址
            otherreason: "", //未联系到其他原因
            hospitalId: 0,
            params:{},
            findPerson: 0,
            smartType: 0,

            outHospitalTime: '',
            rejectReason:'',
            rejectReasonOther:'',

        }
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.doctorNameInputOnChange = this.doctorNameInputOnChange.bind(this);
        this.doctorPhoneNameInputOnChange = this.doctorPhoneNameInputOnChange.bind(this);
        
        this.inHospitalTimeOnchange = this.inHospitalTimeOnchange.bind(this);
    }

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId;
        this.state.findPerson = this.props.location.state.findPerson;

        let params = {
          patientId : this.props.location.state.patientId,
          patientNoticeId: this.props.location.state.patientNoticeId,
          userId : this.state.userId
        }
        this.props.queryFollowUpVisitPaper5(params);
        document.getElementById('App').scrollIntoView(true);//为ture返回顶部，false为底部

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
       if(nextProps.Paper5 && nextProps.Paper5.sqHospitalPhone){
          this.setState({
             currentTimeYear: nextProps.Paper5.createYear,
             currentTimeMonth: nextProps.Paper5.createMonth,
             currentTimeDay: nextProps.Paper5.createDay,
             sqHospitalDoctorName: nextProps.Paper5.sqHospitalDoctorName,
             sqHospitalPhone: nextProps.Paper5.sqHospitalPhone,
          })
       }
        if (nextProps.feedbackSubmissionRet !== this.props.feedbackSubmissionRet) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.feedbackSubmissionRet.code === 200) {
                Toast.show("操作成功!");
                //this.props.history.push('/doctorIndex/manage',{type: 'caller'});
                var params = {
                    patientId: this.props.location.state.patientId,
                    followUpId:this.props.Paper5.followupId,
                    parperData: this.props.Paper5
                 }
                if(this.props.location.state.closeSingle === 'colse'){
                    this.props.history.push('/doctorIndex/manage',{type: 'caller'});
                } else {
                    const path = `/doctorIndex/manage`;
                    this.props.history.push(path, params);  // 跳转到患者管理主页
                }
            }else {
                Toast.show("操作失败！"+nextProps.feedbackSubmissionRet.msg)
            }
        }
    }

    // 我中心联系人
   doctorNameInputOnChange(e){
      this.setState({
         sqHospitalDoctorName: e.target.value
      })
   }
    // 我中心联系人电话
   doctorPhoneNameInputOnChange(e){
      this.setState({
         sqHospitalPhone: e.target.value
      })
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    //住院时间
    inHospitalTimeOnchange(event, value) {
       this.setState({inHospitalTime: value});
    }

    handleSubmit() {
        //将数据打包成json,然后调用dispatch将数据发送出去
        var signPad=this.refs.patientSign;
        var signData=signPad.toDataURL();

        if(this.state.sqHospitalDoctorName === ''){
           Toast.show('请输入我中心联系人');
           return false;
        }

        if(this.state.sqHospitalPhone === ''){
           Toast.show('请输入联系人电话');
           return false;
        }

        if(this.state.failreasontype === 4 && this.state.inHospitalTime.length < 4){
            Toast.show("请填写输入时间");
            return false;
        }

        // if(!(/^1[3456789]\d{9}$/.test(this.state.sqHospitalPhone))){
        //    Toast.show('联系人手机号格式错误，请重新输入！')
        //    return false;
        // }

        const feedBack = {
            feedbackId: this.state.feedbackId, //提交ID
            patientNoticeId: this.state.patientNoticeId, //通知ID
            patientId: this.state.patientId, //患者id
            userId: localStorage.getItem("userId"), //登录用户id
            failReasonType: this.state.failreasontype, //原因
            followUpType: this.state.followuptype, //随访方式
            directType: this.state.directtype, //督导类型
            followUpAddress: this.state.followupaddress, //随访地址其他
            sign: signData, //签名图片
            sqHospitalDoctorName: this.state.sqHospitalDoctorName === '' ? this.props.Paper5.sqHospitalDoctorName : this.state.sqHospitalDoctorName,//社区医院联系人
            currentTimeYear: this.state.currentTimeYear === '' ? this.props.Paper5.createYear : this.state.currentTimeYear, //当前年
            currentTimeMonth: this.state.currentTimeMonth === '' ? this.props.Paper5.createMonth : this.state.currentTimeMonth, //当前月
            currentTimeDay: this.state.currentTimeDay === '' ? this.props.Paper5.createDay : this.state.currentTimeDay, //当前日
            newAddress: this.state.newaddress, //迁至新地址
            otherReason: this.state.otherreason, //未联系到其他原因
            hospitalId: this.props.Paper5.sqHospitalId,
            sqHospitalPhone: this.state.sqHospitalPhone === '' ? this.props.Paper5.sqHospitalPhone : this.state.sqHospitalPhone,
            smartType: this.state.smartType,
            inHospitalTime: this.state.inHospitalTime,
            rejectReason: this.state.rejectReason,
            rejectReasonOther: this.state.rejectReasonOther,
        };
        //const path = '/doctorIndex/manage/CloseScreening';   // 跳转到密接单
        var params = {
           patientId: this.props.location.state.patientId,
           followUpId:this.props.Paper5.followupId,
           parperData: this.props.Paper5
        }
       this.props.postfeedbackSubmission(feedBack);

       /*
       //  // 判断如果是查无此人则返回待随访
        if(this.props.location.state.closeSingle === 'colse'){
           this.props.history.push('/doctorIndex/manage',{type: 'caller'});
        } else {
           const path = `/doctorIndex/manage`;
           this.props.history.push(path, params);  // 跳转到患者管理主页
        }
        */
    }
    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const {classes, Paper5} = this.props;
        return (
            <div id="App" className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h4 className={classes.huanzhe}>肺结核患者治疗管理反馈单</h4></Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>

                <Grid className={classes.rootDiv}>
                    <Grid container justify="center" wrap="nowrap" spacing={0}>
                        <Typography className={classes.title1}>肺结核患者治疗管理反馈单</Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Typography className={classes.titleBold} gutterBottom>
                            <span className={classes.spanDiv}>{Paper5.hospitalName}</span>区结防所：
                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                我中心按要求对<span className={classes.spanDiv}>{Paper5.patientTown}</span>
                                街道/乡镇<span className={classes.spanDiv}>{Paper5.patientVillage}</span>
                                居委会/村的患者<span className={classes.spanDiv}>{Paper5.patientName}</span>（<span
                                className={classes.spanDiv}>{Paper5.patientSex}</span>，
                                <span className={classes.spanDiv}>{Paper5.patientAge}</span>岁，联系电话：
                                <span className={classes.spanDiv}>{Paper5.patientPhone}</span>）进行联系，情况如下：
                            </Typography>
                        </Grid>
                    </Grid>

                    <div id="find" className={this.state.findPerson == 1?"":classes.hideMe}>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                1. 联系到患者，并进行访视（访视地点： <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Grid item className={classes.titleBold}>
                                    <RadioGroup row aria-label="gender" name="followuptype" onChange={this.handleInputChange}>
                                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label={<span className={classes.OptionDiv}>家庭</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label={<span className={classes.OptionDiv}>社区门诊</span>}/>
                                        <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label={<span className={classes.OptionDiv}>其他</span>}/>
                                        <Input name="followupaddress" placeholder="请填写" value={this.state.followupaddress}
                                            onChange={this.handleInputChange} className={classes.input} inputProps={{'aria-label': 'description'}}/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                                患者选择：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item className={classes.titleBold}>
                            <RadioGroup row defaultValue="1" aria-label="gender" name="directtype" value={this.state.directtype} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>社区卫生服务中心督导</span>} />
                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>家庭成员督导</span>} />
                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>志愿者督导</span>} />
                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>自服药，患者服从社区督导管理</span>} />
                                
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                智能工具选择：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item className={classes.titleBold}>
                            <RadioGroup row defaultValue="0" aria-label="gender" name="smartType" value={this.state.smartType} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>APP微信小程序</span>} />
                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>电子药盒</span>} />
                                <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>无</span>} />
                            </RadioGroup>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                （患者/家属签字） 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <SignPadPlus ref='patientSign' name="pic"
                                                        value={this.state.pic} onChange={this.handleInputChange} clearButton="true"
                                                        height={200} className={classes.SignaturePadDiv}/>
                    </Grid>
                    </div>

                    <div id="notFind" className={this.state.findPerson !== 1?"":classes.hideMe}>
                    {/*未联系到此人*/}
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                2. 目前无法管理，其原因是：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0} >
                        <Grid item className={classes.titleBold}>
                            <RadioGroup row defaultValue="1" aria-label="gender" name="failreasontype"
                                        value={this.state.failreasontype} onChange={this.handleInputChange}>
                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>查无此人（电话号码错误/地址不实）</span>}/>
                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>患者已迁至</span>} />
                                <Input name="newaddress" placeholder="请填写" value={this.state.newaddress}
                                        onChange={this.handleInputChange} className={classes.input} inputProps={{'aria-label': 'description'}}/>
                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>未联系上其他</span>}/>
                                <Input name="otherreason" placeholder="请填写" value={this.state.otherreason} onChange={this.handleInputChange}
                                    className={classes.input} inputProps={{'aria-label': 'description'}}/>
                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>患者住院</span>}/>
                                <DatePicker className={classes.bindOptionRightStyle}
                                    locale={locale}
                                    showTime
                                    format="YYYY-MM-DD"
                                    placeholder="请选择日期"
                                    onChange={this.inHospitalTimeOnchange}/>
                                
                                <FormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>患者拒绝服从社区督导管理自服药</span>} />
                                <Input name="rejectReason" placeholder="备注沟通过程及拒访原因" value={this.state.rejectReason}
                                        onChange={this.handleInputChange} className={classes.input} inputProps={{'aria-label': 'description'}}/>
                                <FormControlLabel className={classes.OptionDiv} value="10" control={<StyledRadio/>}
                                                  label={<span className={classes.OptionDiv}>其他联系上但未管理</span>} />
                                <Input name="rejectReasonOther" placeholder="备注未落实社区管理的情况" value={this.state.rejectReasonOther}
                                        onChange={this.handleInputChange} className={classes.input} inputProps={{'aria-label': 'description'}}/>

                            </RadioGroup>
                        </Grid>
                    </Grid>
                    </div>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                我中心联系人：
                               <Input name="sqHospitalDoctorName"
                                      value={this.state.sqHospitalDoctorName}
                                      onChange={this.doctorNameInputOnChange}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalDoctorName" value={Paper5.sqHospitalDoctorName} onChange={this.handleInputChange}
                                    className={classes.inputb} inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                联系电话：
                               <Input name="sqHospitalPhone"
                                      value={this.state.sqHospitalPhone}
                                      onChange={this.doctorPhoneNameInputOnChange}
                                      className={classes.inputb}
                                      inputProps={{'aria-label': 'description'}}
                               />
                                {/*<Input name="sqHospitalPhone" value={Paper5.sqHospitalPhone}
                                    onChange={this.handleInputChange} className={classes.inputb}
                                    inputProps={{'aria-label': 'description'}}/>*/}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid className={classes.distance} container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="sqHospitalName"
                                    value={Paper5.sqHospitalName}
                                    onChange={this.handleInputChange}
                                    className={classes.inputa}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />社区卫生服务中心
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <Input
                                    name="currentTimeYear"
                                    value={this.state.currentTimeYear}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />年
                                <Input
                                    name="currentTimeMonth"
                                    value={this.state.currentTimeMonth}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />月
                                <Input
                                    name="currentTimeDay"
                                    value={this.state.currentTimeDay}
                                    onChange={this.handleInputChange}
                                    className={classes.inputs}
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                />日
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" className={classes.buttonBox}>

                        <Grid className={classes.cardbutton}>
                            <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleSubmit}>
                                提交
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        serverInfo: state.serverInfo,
        Paper5: state.patientManageReducer.Paper5,
        feedbackSubmissionRet: state.patientManageReducer.feedbackSubmissionRet,
        SubmitreturnCode: state.patientManageReducer.SubmitreturnCode,

    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
       //查询随访基本信息
       queryFollowUpVisitPaper5: (params) => {  //查询肺结核患者治疗管理反馈单 信息
            dispatch(queryFollowUpVisitPaper5(params)) // action里面定义的
        },
        postfeedbackSubmission: (feedBack) => {
            dispatch(postfeedbackSubmission(feedBack)) //action/index页面
        }
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',


    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(0),
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
    },
    buttons: {
        margin: theme.spacing(1),
        width: '60vw',
    },
    hr: {
        height: '0.3vw',
        // backgroundColor:'#e6e6e6',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80vw',
    },
    input: {
        margin: theme.spacing(1),
        width: '88vw',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputs: {
        // margin: theme.spacing(1),
        width: '13vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },

    inputa: {
        // margin: theme.spacing(1),
        width: '40vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    inputb: {
        // margin: theme.spacing(1),
        width: '35vw',
        textAlign: 'center ',
        height: '4vw',
        lineHeight: '4vw',
    },
    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw',
        // display:'inline-block'

    },


    titleBolds: {
        // marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },


    buttonBox: {
        height: '30vw',
        marginTop: theme.spacing(1),
    },

    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '91vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '5vw',
        marginbottom: '20vw',
    },
    spacemargin: {
        margin: theme.spacing(2),
        fontSize: '4.2vw',

    },

    spanDiv: {
        textDecoration: 'underline',
        // width: '19vw',
    },

    titleBold: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        color: '#333333'
    },

    // titleBold: {
    //     fontSize: '4.2vw',
    //     lineHeight: '8vw',
    //     textAlign: 'justify',
    //     margin: 0,
    //     color: '#333333'
    // },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    hideMe: {
        display: 'none',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper5));
