//患者端主页面
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import ChatWindow from './consultDoctor/ChatWindow';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MedicineWidget from './medicineWidget/MedicineWidget';
import ConsultDoctor from './consultDoctor/ConsultDoctor';
import LoginView from './myWidget/LoginView';
import KnowledgeView from './Knowledge/KnowledgeView';
import wodezhuye from '../assets/images/wodezhuye.png';
import zixunyisheng from '../assets/images/zixunyishneg.png';
import jiankangzhishi from '../assets/images/jiankangzhishi.png';
import zhuye from '../assets/images/zhuye.png';

//患者端服药康复页面
class MainView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         bottomValue: 0,
         rows: [],
         showHome: null
      };
   }

   componentDidMount() {
   }

   componentWillUnmount() {
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.serverInfo !== this.props.serverInfo) {
      }
   }

   render() {
      //get react-material css styles
      const {classes} = this.props;
      let showHomeStatus = '';
      if(window.location.search && window.location.search !== null && window.location.search !== ''){
         showHomeStatus = window.location.search.split("flag=")[1]+'';
      }
      if(showHomeStatus == '2' ){
         this.state.bottomValue = showHomeStatus*1
      }
      //console.log(this.state.bottomValue)
      if(this.state.bottomValue == 2){
         showHomeStatus = '2'
         //console.log("hide")
      }else{
         //console.log("display")
      }
      return (
         <Switch>
            <Route path={`${this.props.match.path}/detail`} component={ConsultDoctor}/>
            {/*<Route path={`${this.props.match.path}/chatWindow`} component={ChatWindow}/>*/}
            <React.Fragment >
               <Grid container className={classes.bodyDiv9}>
               {/* 患者康复 健康知识 咨询医生 我的 */}
               {(() => {
                     switch (this.state.bottomValue) {
                        // 服药康复页
                        case 0:
                           return <MedicineWidget history={this.props.history} match={this.props.match}/>;
                        // 健康知识页
                        case 1:
                           return <KnowledgeView history={this.props.history} match={this.props.match}/>;
                        // 咨询医生页
                        case 2:
                           return <ChatWindow history={this.props.history} match={this.props.match}/>;
                        // 我的
                        case 3:
                           return <LoginView history={this.props.history} match={this.props.match}/>;
                        // 服药康复页
                        default:
                           return <MedicineWidget/>;
                     }
                  }
               )()}
               </Grid>
               {/* 四个主菜单 */}
               <Grid container
                     className={(showHomeStatus=='11'||showHomeStatus=='9'||showHomeStatus=='2'||showHomeStatus=='12')?classes.dispalyNoneFooter:classes.footerDiv}
                     spacing={0} justify="center" alignContent="center">
                  <BottomNavigation
                     value={this.state.bottomValue}
                     onChange={(event, newValue) => {
                        this.setState({bottomValue: newValue,})
                        if(newValue == 2){
                           this.setState({showHome: '2'})
                        }
                     }}
                     showLabels
                     className={classes.root}
                  >
                     <BottomNavigationAction label="服药康复"
                                             icon={<img className={classes.imgDiv} src={wodezhuye}></img>}/>
                     <BottomNavigationAction label="健康知识"
                                             icon={<img className={classes.imgDiv} src={zixunyisheng}></img>}/>
                     <BottomNavigationAction label="咨询医生"
                                             icon={<img className={classes.imgDiv} src={jiankangzhishi}></img>}/>
                     <BottomNavigationAction label="我的" icon={<img className={classes.imgDiv} src={zhuye}></img>}/>
                  </BottomNavigation>
               </Grid>
            </React.Fragment>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {}
}

const mapDispatchToProps = (dispatch) => {
   return {
      login: (userInfo) => {
         // dispatch(loginRequest(userInfo))
      },
   }
}


//css region
const styles = theme => ({
   rootDiv: {
      background: '#ffffff',

   },
   headerDiv: {
      width: '100vw',
      height: '4%',
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
      color: '#ffffff',
   },

   bodyDiv: {
      width: '100vw',
      height: '86%',
   },

   bodyDiv9: {
      width: '100vw',
      height: '90vh',
      overflow:'scroll',
   },

   reseeDiv: {
      width: '100vw',
      height: '3vh',
      background: '#ffffff',
      color: '#000000',
      marginTop: '1vh',
   },
   dispalyNoneFooter: {
      display: 'none'
   },
   footerDiv: {
      width: '100vw',
      height: '10%',
      marginTop: 'auto',
      marginBottom: '1px',
   },

   medicineCard: {
      marginTop: '1vh',
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
   },

   IconAlarm: {
      width: '20vw',
      height: '20vw',
      color: '#FFFFFF',
   },

   typoText: {
      textAlign: 'center',
   },

   list: {
      width: '100%',
      maxHeight: '30vh',
      overflow: 'auto',
   },

   unfeedButton: {
      outline: 'none',
      width: '50px',
      height: '50px',
      textAlign: 'center',
      border: 'none',
      backgroundColor: '#bdc0c1',
      borderRadius: '50px',
      marginTop: '2vh',
   },

   actionButtonLeft: {

      marginLeft: '1px',
   },

   actionButtonRight: {

      marginRight: '1px',
      marginLeft: 'auto',
   },
   imgDiv: {
      height: '7vw'
   }
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainView));
