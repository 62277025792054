
export default function manageType(flag) {
    if(flag==1){
        return "微信"
    }

    if(flag ==2){
        return "药盒"
    }
};
