import React from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FollowUpVisitPaper5 from './FollowUpVisitPaper5'
import { Route, Switch } from "react-router-dom";
import clsx from 'clsx';
import { postFollowUpVisitPaper1, sureManage } from "../../redux/actions/patient_manage_action";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import TextField from "@material-ui/core/TextField/TextField";
import Modal from "@material-ui/core/Modal/Modal";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Toast from "antd-mobile/lib/toast";
import moment from "moment";

import Api from "../../redux/api/api";
import axios from "axios";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, Modal as ModalAnt, Select, TimePicker, ConfigProvider } from 'antd';

const { confirm } = Modal;
const { Option } = Select;

const useStyles = makeStyles({
    root: {

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio className={classes.root} disableRipple color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />} {...props} />
    );
}

//患者管理-肺结核患者治疗管理通知单
class FollowUpVisitPaper1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            patientId: 0,
            direction: '',
            value: 0,
            index: 0,
            sputumCheckResultId: 0,
            address: '',
            caseNumber: '',
            date: moment().format('YYYY-MM-DD'),
            publishVisable: false,
            hospitals: [],
            hospitalSelectValue: '',
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.openNoPerson = this.openNoPerson.bind(this);
        this.openSureManage = this.openSureManage.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.rePublishCancel = this.rePublishCancel.bind(this);
        this.rePublishClick = this.rePublishClick.bind(this);
        this.rePublishOk = this.rePublishOk.bind(this);
        this.bindSelectEventChange = this.bindSelectEventChange.bind(this);
        this.loadVillageHospital = this.loadVillageHospital.bind(this);
    }

    //点击确认管理
    openSureManage(patientNoticeId,skipOrder) {
        if(skipOrder == 1){
            this.setState({
                skipOrder: skipOrder
            })
            this.handleOk(patientNoticeId)
            return
        }
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    handleOk(patientNoticeId) {
        let obj = {}
        obj.id = patientNoticeId;
        obj.firstTrackAddress = this.state.address
        obj.firstTrackTimeStr = this.state.date
        obj.patientId = this.state.patientId
        obj.caseNumber = this.state.caseNumber
        obj.needCaseNumber = this.props.ConfirmationManagement.needCaseNumber

        this.props.sureManage(obj);
        this.closeModal();
    }

    //查无此人
    openNoPerson(item) {
        const path = `${this.props.match.path}/paper5`;
        var params = {
            findPerson: 0,
            patientId: item.patientId,
            patientNoticeId: item.patientNoticeId,
            closeSingle: 'colse'
        }
        this.props.history.push(path, params)
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId;
        this.props.postFollowUpVisitPaper1(this.state.patientId);
    }


    //fired when props changed
    componentWillReceiveProps(nextProps) {
        console.log(nextProps.sureManageRet)
        if (nextProps.sureManageRet != this.props.sureManageRet) {

            if (nextProps.sureManageRet.code == 200) {
                Toast.info('操作成功!');
                //const path = `/doctorIndex/manage`;
                //this.props.history.push(path);
                if(this.state.skipOrder == 1){
                    const path = `/doctorIndex/manage`;
                    this.props.history.push(path);
                    return
                }
                const path = `${this.props.match.path}/paper5`;
                //确认管理跳转到管理反馈通知单
                this.props.history.push(path, { "followupId": 0, "findPerson": 1, "patientId": this.state.patientId });

            }
            if (nextProps.sureManageRet.code == 201) {
                Toast.info('操作失败!' + nextProps.sureManageRet.msg);
            }
        }
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    rePublishCancel() {
        this.setState({
            publishVisable: false,
            hospitalSelectValue: '',
        })
    }

    loadVillageHospital() {
        const url = Api.getRootUrl() + '/api/region/getVillageHospitalByTown'
        const params = {
            patientId: this.state.patientId,
            hospitalId: this.state.hospitalId,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
        }
        let that = this;
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                that.setState({
                    hospitals: rsp.data.data?.hospitals
                })
            } else {
                Toast.info("获取医院列表失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("获取医院列表失败，请稍后再试！")
        })
    }

    // 下拉框选中事件
    bindSelectEventChange(event) {
        this.setState({ hospitalSelectValue: event });
    }

    rePublishClick() {
        this.loadVillageHospital()
        this.setState({
            publishVisable: true,
        })
    }

    rePublishOk() {
        const url = Api.getRootUrl() + '/api/patient/republish'
        const params = {
            patientId: this.state.patientId,
            hospitalId: this.state.hospitalSelectValue,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
        }
        let that = this;
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.props.history.goBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    render() {
        const { classes, ConfirmationManagement, } = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/paper5`} component={FollowUpVisitPaper5} />

                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={10} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者治疗管理通知单</h5></Grid>
                        <Grid item justify="center" className={classes.hideMe1} xs={2}>
                            <Typography h6 className={classes.title} onClick={this.rePublishClick} >下发</Typography>
                        </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>

                    <Grid className={classes.rootDiv}>
                        <Grid container justify="center" wrap="nowrap" spacing={0}>
                            <Typography className={classes.title1}>肺结核患者治疗管理通知单</Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Typography className={classes.titleBold} gutterBottom>
                                <span className={classes.spanDiv}>{ConfirmationManagement.hospitalName}</span>社区卫生服务中心：
                            </Typography>
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0}>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Typography className={classes.titleBold} gutterBottom>
                                    你中心辖区<span className={classes.spanDiv}>{ConfirmationManagement.patientTown}</span>
                                    街道/乡镇<span className={classes.spanDiv}>{ConfirmationManagement.patientVillage}</span>
                                    居委会/村<span className={classes.spanDiv}>{ConfirmationManagement.patientAddress}</span>的患者（<span className={classes.spanDiv}>{ConfirmationManagement.patientName}</span>，
                                    <span className={classes.spanDiv}>{ConfirmationManagement.patientAge}</span>岁，
                                    性别：<span className={classes.spanDiv}>{ConfirmationManagement.patientSex}</span>，联系电话：
                                    <a href={"tel:" + ConfirmationManagement.patientPhone}><span className={classes.spanDiv}>{ConfirmationManagement.patientPhone}</span></a>），
                                    身份证号：<span className={classes.spanDiv}>{ConfirmationManagement.idNumber}</span>已于
                                    <span className={classes.spanDiv}>{ConfirmationManagement.confirmTimeYear}</span>年 <span
                                        className={classes.spanDiv}>{ConfirmationManagement.confirmTimeMonth}月</span>
                                    <span className={classes.spanDiv}>{ConfirmationManagement.confirmTimeDay}</span>日被诊断为<span className={classes.spanDiv}>{ConfirmationManagement.patientType}</span>，并进行抗结核治疗。请接本通知单后三天内与患者及其家属联系进行首次入户访视
                                    ，对其进行健康教育并确定患者督导管理方式，并将结果反馈我所。谢谢您的配合!
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Typography className={classes.titleBold} gutterBottom>
                                患者情况如下：
                            </Typography>
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    1.患者类型： <span className={classes.spanDiv}>{ConfirmationManagement.patientTreatType}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    2.诊断结果： <span className={classes.spanDiv}>{ConfirmationManagement.jhjDiagnosisResultStr}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    3.痰菌检查结果情况：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>

                            <Grid item className={classes.titleBold}>
                                <RadioGroup row aria-label="gender" name="customized-radios">
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />}
                                        label='阳性' checked={ConfirmationManagement.sputumCheckResultId == '1'} />
                                    <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />}
                                        label='阴性' checked={ConfirmationManagement.sputumCheckResultId == '2'} />
                                    <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />}
                                        label='未查痰' checked={ConfirmationManagement.sputumCheckResultId == '3'} />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    4.结核菌耐药情况：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item className={classes.titleBold}>
                                <RadioGroup row aria-label="gender" name="customized-radios">
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />}
                                        label='耐药' checked={ConfirmationManagement.jHjCheckResultId == '1'} />
                                    <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />}
                                        label='非耐药' checked={ConfirmationManagement.jHjCheckResultId == '2'} />
                                    <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />}
                                        label='未检测' checked={ConfirmationManagement.jHjCheckResultId == '3'} />
                                </RadioGroup>
                            </Grid></Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    5.抗结核治疗单位：<span className={classes.spanDiv}>{ConfirmationManagement.kjhTreatHospital}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    6.患者登记号：<span className={classes.spanDiv}>{ConfirmationManagement.patientNumber}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    7.化疗方案为：<span className={classes.spanDiv}>{ConfirmationManagement.resistMethod}</span>
                                </Typography>
                                <Typography className={classes.titleBold} gutterBottom className={ConfirmationManagement.takeType === undefined ? classes.hideMe : classes.hideMe}>
                                    &nbsp;&nbsp;治疗方式：<span className={classes.spanDiv}>{ConfirmationManagement.takeType}</span>
                                </Typography>
                                <Typography className={classes.titleBold} gutterBottom className={ConfirmationManagement.takeMedicine === undefined ? classes.hideMe : ''}>
                                    &nbsp;&nbsp;药品用法：<span className={classes.spanDiv}>{ConfirmationManagement.takeMedicine}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0} className={ConfirmationManagement.inHospitalTime === undefined ? classes.hideMe : ''}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    8.住院信息：<span className={classes.spanDiv}><u>{ConfirmationManagement.inHospitalTime}</u>至<u>{ConfirmationManagement.outHospitalTime}</u>于<u>{ConfirmationManagement.inHospitalName}</u></span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0} className={ConfirmationManagement.tuberculosisTypeStr === undefined ? classes.hideMe : ''}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    结核分型：<span className={classes.spanDiv}>{ConfirmationManagement.tuberculosisTypeStr}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0} className={ConfirmationManagement.contacts === undefined ? classes.hideMe : ''}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    患者其他联系人：<span className={classes.spanDiv}>{ConfirmationManagement.contacts}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0} className={ConfirmationManagement.weight === undefined ? classes.hideMe : ''}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    体重(KG)：<span className={classes.spanDiv}>{ConfirmationManagement.weight}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0} className={ConfirmationManagement.remark === undefined ? classes.hideMe : ''}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    备注：<span className={classes.spanDiv}>{ConfirmationManagement.remark}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    我所联系人：<span className={classes.spanDiv}>{ConfirmationManagement.xfHospitalDoctorName}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    联系电话：<span className={classes.spanDiv}><a href={"tel:" + ConfirmationManagement.xfHospitalPhone}>{ConfirmationManagement.xfHospitalPhone}</a></span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid className={classes.distance} container justify="flex-end" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    <span className={classes.spanDiv}>{ConfirmationManagement.xfHospitalName}</span>
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-end" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    <span className={classes.spanDiv}>{ConfirmationManagement.currentTimeYear}</span>年
                                    <span className={classes.spanDiv}>{ConfirmationManagement.currentTimeMonth}</span>月
                                    <span className={classes.spanDiv}>{ConfirmationManagement.currentTimeDay}</span>日
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid container justify="space-evenly" className={classes.buttonBox}>
                            <Button variant="contained" className={classes.margin} onClick={() => {this.openSureManage(ConfirmationManagement.patientNoticeId,ConfirmationManagement.skipOrder)}}>
                                确认管理
                            </Button>
                            <Button variant="contained" className={classes.margin} onClick={() => { this.openNoPerson(ConfirmationManagement) }}>
                                无法管理
                            </Button>
                        </Grid>
                        <Modal
                            className={classes.modal}
                            open={this.state.showModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Grid item xs={10} className={classes.papersDiv}>
                                <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                                <Grid item xs={12} justify='center' className={classes.paperDiv}>
                                    <Grid item className={classes.tishiDiv}>
                                        对患者预约首访并加入患者管理
                                    </Grid>
                                    <Grid item xs={12} className={classes.shijianDiv}>
                                        预约时间：
                                        <TextField id="date" name="date" type="date" className={classes.textField} value={this.state.date}
                                            InputLabelProps={{ shrink: true, }} onChange={this.handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.shijianDiv}>
                                        预约地点： <YddInput name="address" placeholder='请输入地址' value={this.state.address} onChange={this.handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} className={ConfirmationManagement.needCaseNumber == 1 ? classes.shijianDiv : classes.hideMe}>
                                        患者编号： <YddInput name="caseNumber" placeholder='请输入患者编号' value={this.state.caseNumber} onChange={this.handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.buttonDiv}>
                                        <Button variant="contained" color="primary" className={classes.buttons}
                                            onClick={() => { this.handleOk(ConfirmationManagement.patientNoticeId) }} >确定</Button>
                                        <Button variant="contained" color="primary" className={classes.butt}
                                            onClick={this.closeModal}>取消</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Modal>
                    </Grid>


                    <ModalAnt title="再次下发患者"
                        visible={this.state.publishVisable}
                        afterClose={this.rePublishCancel}
                        onCancel={this.rePublishCancel}
                        destroyOnClose={true}
                        forceRender={true}
                        footer={[
                            <Button key="back" onClick={this.rePublishCancel}>取消</Button>,
                            <Button key="submit" onClick={this.rePublishOk}>提交</Button>,
                        ]}
                    >
                        <Grid item xs={10} className={classes.bindContent}>
                            <Grid className={classes.bindContentFlex}>
                                <ConfigProvider>
                                    <Select showSearch defaultValue={this.state.hospitalSelectValue == '' ? '请选择接收单位...' : this.state.hospitalSelectValue}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => 
                                            option.props.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={this.bindSelectEventChange}
                                        className={classes.bindOptionRightStyle}
                                    >
                                        {this.state.hospitals?.map((column, index) => (
                                            <Option value={column.id}>{column.name}</Option>
                                        ))}
                                    </Select>
                                </ConfigProvider>
                            </Grid>

                        </Grid>
                    </ModalAnt>

                </div>
            </Switch>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        retCode: state.patientManageReducer.retCode,
        ConfirmationManagement: state.patientManageReducer.ConfirmationManagement,
        //sureManageOk: state.patientManageReducer.sureManageOk,
        sureManageRet: state.patientManageReducer.sureManageRet
    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
        postFollowUpVisitPaper1: (patientId) => {  //定义的函数
            dispatch(postFollowUpVisitPaper1(patientId)) // action里面定义的
        },
        sureManage: (sureManageObj) => {
            dispatch(sureManage(sureManageObj))
        }
    };
};

//css region
const styles = theme => ({
    bindOptionRightStyle: {
        marginLeft: '7%',
        width: '90%',
    },
    root: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
    },
    spacemargin: {
        margin: theme.spacing(2),

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        width: '30vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },


    input: {
        margin: theme.spacing(1),
        width: '5vw'
    },
    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw'
    },
    titleBold: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        textAlign: 'justify',
        margin: 0,
        color: '#333333'
    },
    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },

    spanDiv: {
        textDecoration: 'underline',
    },


    buttonBox: {
        height: '30vw',

    },
    cardbutton: {
        width: '50vw',
        display: 'flex',
        flexDirection: 'row',
        marginbottom: '5vw',
        justifyContent: 'space-between',
    },
    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '43vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '7vw',
        marginbottom: '20vw',
        color: '#ffffff'
    },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    //modal弹窗
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    papersDiv: {
        width: '85vw',
        height: '65vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },
    hideMe: {
        display: 'none'
    },

});
const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            marginLeft: theme.spacing(1),
            fontSize: 15,
            width: '45vw',
            height: '4vw',
            border: '1px solid #333333',
            outline: 'none',

            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    }),
)(InputBase);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper1));
