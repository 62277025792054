import React from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import introduceStatus from '../common/introduceStatus'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import avatar from "../../assets/images/tj.png";
import Grid from '@material-ui/core/Grid';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {getMyIntroduceList} from '../../redux/actions/index';
import Avatar from "@material-ui/core/Avatar/Avatar";

//患者推介-我的推介页面
class IntroduceMy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleDetailClick = this.handleDetailClick.bind(this);
    }

    componentDidMount() {
        const doctorId = localStorage.getItem("doctorId");
        this.props.getMyIntroduceList(doctorId)
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event) {
        this.setState({
            introduceReason: event.target.value,
        })
    }

    handleDetailClick(index) {
        //应该是在登录成功之后,才调用
        const path = `${this.props.match.path}/detail`+index;
        this.props.history.push(path);
    }

    //点击全部推介人数
    handleAllIntroduce(){
    }

    //点击未到位人数
    handleOutPlace(){
    }

    //点击到位未诊断人数
    handleInPlace(){
    }

    //点击已诊断人数
    handleReserve(){
    }


    render() {
        //get react-material css styles
        const {classes, introduceList} = this.props;
        return (
            <Grid className={classes.root}>
                {/*统计信息*/}
                <Grid container item xs={12} justify="space-between" alignItems='center' className={classes.sta}>

                    <Grid container item xs={4} justify='center' alignContent='center'>
                        <Typography><span className={classes.staLable}>全部推介</span><br/>
                            <span className={classes.title} onClick={this.handleAllIntroduce}>{introduceList.count}</span>&nbsp;<span
                                className={classes.staLable}>人</span>
                        </Typography>
                    </Grid>

                    <Grid container item xs={4} wrap='wrap' justify='center'>
                        <Typography><span className={classes.staLable}>已推介</span><br/>
                            <span className={classes.title} onClick={this.handleOutPlace}>{introduceList.initial}</span>&nbsp;<span
                                className={classes.staLable}>人</span>
                        </Typography>
                        <hr className={classes.hr}></hr>
                        <Typography><span className={classes.staLable}>已到位未诊断</span> <br/>
                            <span className={classes.title} onClick={this.handleInPlace}>{introduceList.inplace}</span>&nbsp;<span
                                className={classes.staLable}>人</span>
                        </Typography>
                    </Grid>
                    <Grid container item xs={4} justify='center' alignContent='center'>
                        <Typography><span className={classes.staLable}>已诊断</span><br/>
                            <span className={classes.title} onClick={this.handleReserve}>{introduceList.reserve}</span>&nbsp;<span
                                className={classes.staLable}>人</span>
                        </Typography>
                    </Grid>

                </Grid>

                {(introduceList.list || []).map((item, index) => {
                    return (
                        <Paper key={item.id} className={classes.paper} onClick={() => {this.handleDetailClick(item.id);}}>
                            <Grid className={classes.headPortrait}>
                                <Avatar src={avatar} className={classes.avatar}/>
                                <Link className={classes.click} component="button" variant="body2">查看详情</Link>
                            </Grid>
                            <Grid>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p">患者姓名：{item.name}</Typography>
                                    <Typography component="p">推介时间：{item.createTime}</Typography>
                                    <Typography component="p">推介机构：{item.hospitalName == null ? '' : item.hospitalName}</Typography>
                                    <Typography component="p" className={classes.reasonDiv}>推介原因：{item.reason}</Typography>
                                    <Typography component="p">推介状态：{introduceStatus(item.status)}</Typography>
                                </ThemeProvider>
                            </Grid>
                        </Paper>
                    )
                })
                }
            </Grid>
        );
    }

}

//redux region
const mapStateToProps = (state) => {
    return {
        introduceList: state.introduceReducer.introduceList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMyIntroduceList: (doctorId) => {
            dispatch(getMyIntroduceList(doctorId));
        },
    }
}
//end of redux regian

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2),
        backgroundColor: '#FFFFFF',
        height: '100vh',
        overflow: 'auto',
    },

    paper: {
        display: 'flex',
        // height: '33vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
        marginBottom: theme.spacing(2),
        marginLeft:  'auto',
        marginRigth:  'auto',
        padding: theme.spacing(1, 1)


        // display: 'flex',
        // width: '86vw',
        // marginBottom: theme.spacing(2),
        // margin: 'auto',
        // border: '2vh',
        // padding: '3vw',
        // alignItems: 'center',
        // alignContent: 'center',
        // borderRadius: '16px',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        // boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
        // textAlign: 'justify',
        // overflow:' hidden',
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
},

    staLable: {
        fontSize: '4.2vw'
    },

    title: {
        fontSize: '6vw',
        fontWeight: 'bold',
        color: '#00DDD8'
    },

    headPortrait: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '23vw',
        justifyContent: 'center',
        marginRight: '5vw',
        marginLeft: '2vw'
    },

    avatar: {
        width: '16vw',
        height: '16vw',
        marginBottom: '1vw',
    },

    click: {
        color: '#00DDD8',
        fontSize: '3.5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        width: '50vw',
    },
    boddeDiv: {
        width: '60vw'
    },

    sta: {
        marginBottom: '2vw'
    },

});
//end of css region

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body: {
                fontSize: '2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});

//here we inject react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(IntroduceMy)));
