import * as ActionTypes from '../../actions/patients/index-action'
// import {GET_MYVIEW} from "../actions/constants";

// The initial application state
let initialState = {
//我的信息
   remindItem: {},
   remindAddSucceed: 500,
   remindList: [],
   onCloseSucceed: 500,
}

// Takes care of changing the application state
function medicineReducer(state = initialState, action) {
   switch (action.type) {
      case ActionTypes.ADD_REMIND://获取到了我的信息
         return {...state, remindItem: action.remindItem}
      case ActionTypes.ADD_REMIND_SUCCEED:// 添加提醒
         return {...state, remindAddSucceed: action.result}
      case ActionTypes.REMIND_LIST_ACTION_SUCCEED:// 提醒列表
         return {...state, remindList: action.result}
      case ActionTypes.REMIND_ON_CLOSE_SUCCEED:// 开关提醒
         return {...state, onCloseRemind: action.result}

      default:
         return state
   }

}

export default medicineReducer
