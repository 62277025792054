import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
   followUpHistory,
   followUpVisitPaper,
   queryFollowUpBasicInfo,
   uploadFile
} from '../../redux/actions/patient_manage_action';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import Toast from "antd-mobile/lib/toast";

//弹出框
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import {amber, green} from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
// 引入antd 时间组件
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {screeningDiabetesAddBJ} from "../../redux/actions/custom_action";
import {listScreeningDiabetesHistoryBJ} from "../../redux/actions/custom_action";

const useStyles1 = makeStyles(theme => ({
   success: {
      backgroundColor: green[600],
   },
   error: {
      backgroundColor: theme.palette.error.dark,
   },
   info: {
      backgroundColor: theme.palette.primary.main,
   },
   warning: {
      backgroundColor: amber[700],
   },
   icon: {
      fontSize: 20,
   },
   iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
   },
   message: {
      display: 'flex',
      alignItems: 'center',
   },
}));


function MySnackbarContentWrapper(props) {
   const classes = useStyles1();
   const {className, message, variant, ...other} = props;
   const Icon = variantIcon[variant];

   return (
      <SnackbarContent
         className={clsx(classes[variant], className)}
         aria-describedby="client-snackbar"
         message={
            <span id="client-snackbar" className={classes.message}>
                      <Icon className={clsx(classes.icon, classes.iconVariant)}/>
               {message}
                    </span>
         }
         {...other}
      />
   );
}

const variantIcon = {
   success: CheckCircleIcon,
   warning: WarningIcon,
   error: ErrorIcon,
   info: InfoIcon,
};

const useStyles = makeStyles({
   root: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   icon: {
      borderRadius: '50%',
      width: 14,
      height: 14,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
         outline: '2px auto rgba(19,124,189,.6)',
         outlineOffset: 2,
      },
      'input:hover ~ &': {
         backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
         boxShadow: 'none',
         background: 'rgba(206,217,224,.5)',
      },
   },
   checkedIcon: {
      backgroundColor: '#00C9E4',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
         display: 'block',
         width: 14,
         height: 14,
         backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
         content: '""',
      },
      'input:hover ~ &': {
         backgroundColor: '#00C9E4',
      },
   },

});

function StyledRadio(props) {
   const classes = useStyles();

   return (
      <Radio
         className={classes.root}
         disableRipple
         color="default"
         checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
         icon={<span className={classes.icon}/>}
         {...props}
      />
   );
}

//糖尿病筛查添加
class ScreeningDiabetesBJPaper extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         year: 2021,
         quarter: null,
         number: null,
         screeningNumber: null,
         suspiciousNumber:null,
         inspectNumber: null,
         diagnosisNumber: null,
         followupNumber: null,
         introduceNumber: null,
      };

      this.handleNavBack = this.handleNavBack.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
   }

   componentDidMount() {
      
   }

   componentWillUnmount(){
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.screeningDiabetesAddResult != this.props.screeningDiabetesAddResult) {
         if (nextProps.screeningDiabetesAddResult.code == 200) {
            Toast.info('添加糖尿病筛查成功!');
            let params = {
               userId: localStorage.getItem("userId"),
               token: localStorage.getItem("token")
            }
            this.props.queryScreeningDiabetesHistory(params)
            this.handleNavBack();
         }
         if(nextProps.screeningDiabetesAddResult.code == 201){
            Toast.info("添加失败！"+nextProps.screeningDiabetesAddResult.msg);
         }
      }
   }

   

   //获取表单值
   handleInputChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });

   }

   //点击提交按钮
   handleSubmit() {

      const screengingInfo = {
         token: localStorage.getItem("token"),
         userId: localStorage.getItem("userId"),
         year: this.state.year,
         quarter:this.state.quarter,
         number1: this.state.number1,
         screeningNumber1:this.state.screeningNumber1,
         suspiciousNumber1:this.state.suspiciousNumber1,
         
         number2: this.state.number2,
         screeningNumber2:this.state.screeningNumber2,
         suspiciousNumber2:this.state.suspiciousNumber2,

         number3: this.state.number3,
         screeningNumber3:this.state.screeningNumber3,
         suspiciousNumber3:this.state.suspiciousNumber3,

         reporter: this.state.reporter,
         remark: this.state.remark,
        
      };

      this.props.saveInfo(screengingInfo);
   }

   //点击返回
   handleNavBack() {
      this.props.history.goBack();
   }


   render() {
      
      
      const {classes} = this.props;


      return (
         <div className={classes.root}>
            <Grid item xs={12} className={classes.Patient}>
               <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
               <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>糖尿病患者肺结核筛查</h5></Grid>
            </Grid>
            <Typography h5 noWrap className={classes.spacemargin}></Typography>
            <Grid container justify="center" wrap="nowrap" spacing={2}>
               <Typography className={classes.titleMain} h5 color="textSecondary" gutterBottom>糖尿病患者肺结核筛查</Typography>
            </Grid>
            <Grid className={classes.tableDIV}>
               <Typography h5 noWrap className={classes.spacemargin}></Typography>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        年份：
                        <Input name="year" value={this.state.year} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                        &nbsp;&nbsp;季度：
                        <Input name="quarter" value={this.state.quarter} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        Ⅱ型糖尿病患者
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        人数：
                        <Input name="number1" value={this.state.number1} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        可疑症状调查人数：
                        <Input name="screeningNumbe1r" value={this.state.screeningNumber1} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        发现可疑症状者人数：
                        <Input name="suspiciousNumber1" value={this.state.suspiciousNumber1} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>



               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                     65岁及以上老人
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        人数：
                        <Input name="number2" value={this.state.number2} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        可疑症状调查人数：
                        <Input name="screeningNumber2" value={this.state.screeningNumber2} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        发现可疑症状者人数：
                        <Input name="suspiciousNumber2" value={this.state.suspiciousNumber2} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>


               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                     65岁及以上老人同时患Ⅱ型糖尿病
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        人数：
                        <Input name="number3" value={this.state.number3} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        可疑症状调查人数：
                        <Input name="screeningNumber3" value={this.state.screeningNumber3} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        发现可疑症状者人数：
                        <Input name="suspiciousNumber3" value={this.state.suspiciousNumber3} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        报告人：
                        <Input name="reporter" value={this.state.reporter} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        备注：
                        <Input name="remark" value={this.state.remark} onChange={this.handleInputChange}
                                      className={classes.inputs} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>



               <Grid container justify="center" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Button variant="contained" color="primary" className={classes.buttons}
                             onClick={this.handleSubmit}>
                        提交
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Snackbar anchorOrigin={{vertical: this.state.vertical, horizontal: this.state.horizontal}}
                      open={this.state.open} autoHideDuration={1500}>
               <MySnackbarContentWrapper variant="success" className={classes.success} message={this.state.warnMsg}/>
            </Snackbar>

            
         </div>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      screeningDiabetesAddResult: state.customReducer.screeningDiabetesAddResult,
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      //提交筛查信息
      saveInfo: item => {
         dispatch(screeningDiabetesAddBJ(item));
      },
      queryScreeningDiabetesHistory: (params) => {
         dispatch(listScreeningDiabetesHistoryBJ(params))
      }
   }
}

//css region
const styles = theme => ({
   root: {
      flexGrow: 1,
      overflow: 'hidden',
      background: '#ffffff',
   },
   Patient: {
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '8vh',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   titleMain: {
      marginLeft: '3vw',
      fontSize: '5.5vw',
      lineHeight: '8vw',
      color: '#333333',
      fontWeight: '700',
   },
   jiantou: {
      height: '4vw',
      float: 'left',
      marginLeft: '4vw'
   },
   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '77vw',
      height: '8vh',
   },
   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
   },
   paper: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
   },
   file: {
      display: 'none',
      visibility: 'hidden',
   },
   buttonsTwo: {
      margin: theme.spacing(1),
      position: 'relative',
      width: '25vw',
      height: '8vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      border: '1px solid #00C9E4',
      borderRadius: '5px',
      padding: '4px 12px',
      overflow: 'hidden',
      color: '#fff',
      textDecoration: 'none',
      lineHeight: '8vw',
      fontSize: '4.2vw'
   },

   buttons: {
      margin: theme.spacing(2),
      fontSize: '4.8vW',
      width: '91vw',
      height: '12vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',

   },
   textField: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      fontWeight: '300',
      width: '91vw',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
   },
   input: {
      width: '13vw'
   },
   inputs: {
      marginBottom: theme.spacing(1),
      width: '88vw'
   },
   inputTexts: {
      // width: '28vw'
   },

   inputName: {
      marginBottom: theme.spacing(1),
      fontWeight: '500',
      width: '67vw'
   },

   inputDiv: {
      width: '12vw',
      textAlign: 'center',
   },
   rootDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
   },
   parge: {
      width: '100vw',
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '30vh'
   },
   title1: {
      color: '#000000',
      fontSize: '4vw',
      fontWeight: '500'
   },

   titleBold: {
      margin: theme.spacing(0),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   doctor: {
      marginTop: theme.spacing(1),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   title: {
      marginBottom: 0,
      fontSize: '4.2vw',
      lineHeight: '8vw',
      fontWeight: '500',
      color: '#333333'
   },
   margin: {
      margin: theme.spacing(2),
   },

   spacemargin: {
      margin: theme.spacing(2),

   },
   click: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: '#00C9E4',
      textDecoration: 'none',
      underline: 'none'
   },

   clickDiv: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
   },

   tableDIV: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },

   inputShort: {
      width: '10vw',
   },

   hideMe: {
      display:'none'
   },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreeningDiabetesBJPaper));
