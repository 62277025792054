import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {getLoginView, loginOutBranch} from "../../redux/actions/patients/login-action";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {Button} from '@material-ui/core';
import returnImg from '../../assets/images/returnImg.png'
import {Route, Switch} from "react-router-dom";
import NameDiv from './NameDiv';
import MobileDiv from './MobileDiv';
import PasswordDiv from './PasswordDiv';
import jiantoua from '../../assets/images/jiantoua.png'
import Toast from "antd-mobile/lib/toast";


//患者端我的-设置页
class SettingView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         viewportWidth: 0,
         viewportHeight: 0,
         direction: '',
         value: 0,
         index: 0,
         showModal: false,
         showsModal: false,
         myInfo: {},
         name: '',
         phone: '',
         patientId: localStorage.getItem("patientId"),
         userId: localStorage.getItem("userId")
      };
      this.handleNameClick = this.handleNameClick.bind(this);
      this.handlePhone = this.handlePhone.bind(this);
      this.handlePasswordClick = this.handlePasswordClick.bind(this);
      this.handleReturnClick = this.handleReturnClick.bind(this);
      this.loginOutEvent = this.loginOutEvent.bind(this);
   }


   //点击修改手机号
   handlePhone(phone) {
      const path = `${this.props.match.path}/mobile`;
      this.props.history.push(path, {"phone": phone});
   }

   //点击更改姓名
   handleNameClick(name) {
      const path = `${this.props.match.path}/realName`;
      this.props.history.push(path, {"name": name});
   }

   // 退出登录
   loginOutEvent() {
      this.props.loginOutView();
   }

   //点击更改密码
   handlePasswordClick() {
      const path = `${this.props.match.path}/password`;
      this.props.history.push(path);
   }

   //点击返回
   handleReturnClick() {
      this.props.history.goBack();
   }


   componentDidMount() {
      //请求我的信息
      this.state.phone = this.props.location.state.phone
      this.state.name = this.props.location.state.realName
      this.props.getLoginView(this.state.patientId)
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.loginOut && nextProps.loginOut.code) {
         if (nextProps.loginOut.code == 200) {
            Toast.show("退出登录成功！");
            localStorage.clear();
            this.props.history.push('/patient');
            return false;
         }
      }
      if (this.props.myInfo != nextProps.myInfo) {
         this.setState({
            myInfo: nextProps.myInfo,
         })
      }
   }

   render() {
      const {classes} = this.props;
      return (
         <Switch>
            <Route path={`${this.props.match.path}/realName`} component={NameDiv}/>
            <Route path={`${this.props.match.path}/Mobile`} component={MobileDiv}/>
            <Route path={`${this.props.match.path}/password`} component={PasswordDiv}/>


            <Grid className={classes.root}>

               <Grid container className={classes.Patient}>
                  <img src={returnImg} className={classes.jiantou} onClick={this.handleReturnClick}/>
                  <Grid container justify="center" className={classes.headers}>
                     <h4 className={classes.titleDiv}>设置</h4>
                  </Grid>
               </Grid>

               <Grid className={classes.settingDiv}>
                  <Grid container justify="space-between" wrap="nowrap" direction="row" spacing={2}>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           设置密码
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           <Link onClick={this.handlePasswordClick} className={classes.span}> <img
                              className={classes.jiantoua} src={jiantoua}/></Link>
                        </Typography>
                     </Grid>

                  </Grid>
                  <hr className={classes.hr}></hr>

                  <Grid container justify="space-between" wrap="nowrap" direction="row" spacing={2}>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           设置手机号
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           <Link onClick={() => {
                              this.handlePhone(this.state.phone)
                           }}
                                 className={classes.span}><img
                              className={classes.jiantoua} src={jiantoua}/></Link>
                        </Typography>
                     </Grid>

                  </Grid>
                  <hr className={classes.hr}></hr>


                  <Grid container justify="space-between" wrap="nowrap" direction="row" spacing={2}>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           设置姓名
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                           <Link onClick={() => {
                              this.handleNameClick(this.state.name)
                           }}
                                 className={classes.span}><img
                              className={classes.jiantoua} src={jiantoua}/></Link>
                        </Typography>
                     </Grid>
                  </Grid>
                  <hr className={classes.hr}></hr>
               </Grid>


               <Grid container justify="center">
                  <ColorButton variant="contained" onClick={this.loginOutEvent}
                               className={classes.outDiv}>退出登录</ColorButton>
               </Grid>

            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      myInfo: state.patientLoginReducer.myInfo,
      loginOut: state.patientLoginReducer.loginOut
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      getLoginView: (patientId) => {
         dispatch(getLoginView(patientId))
      },
      loginOutView: () => {
         dispatch(loginOutBranch())
      }
   }
}

const styles = theme => ({
   root: {
      flexGrow: 1,
      overflow: 'hidden',
      height: '100vh',
      backgroundColor: '#ededed'
      // padding: theme.spacing(0, 3),
   },
   // spacemargin: {
   //   margin: theme.spacing(1),

   // },
   Patient: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '14vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      lineHeight: '12vw',
      margin: 0
   },
   span: {
      lineHeight: '5vh',
      marginRight: theme.spacing(2),
      color: '#999999'
   },
   spans: {
      lineHeight: '5vh',
      marginRight: theme.spacing(5),
      color: '#999999'
   },
   title: {
      lineHeight: '10vw',
      height: '10vw',
      fontSize: '4.2vw',
      marginLeft: theme.spacing(2),
      // marginRight: theme.spacing(4),
      color: '#333333',
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
   },

   titleDiv: {
      color: '#ffffff',
      fontWeight: '500',
      // wordSpacing:'normal',
      lineHeight: '0vw'
   },


   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',
      // margin: '2vw',
      width: '100vw',
   },


   footerDiv: {
      alignSelf: 'flex-end',
      marginTop: '17vw',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      border: '0.1vw solid #e6e6e6',
      width: '100vw',
      height: '14vw',
   },
   bott: {
      width: '80vw',
      height: '14vw',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
   },
   buttonon: {
      outline: 'none',
      width: '12vw',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      color: '#55E8E5',
      filter: 'none',
   },
   buttonons: {
      outline: 'none',
      width: '16vw',
      height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',

   },
   buttonsdiv: {
      outline: 'none',
      width: '12vw',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      filter: 'grayscale(100%)',
   },
   buttonssdiv: {
      outline: 'none',
      width: '16vw',
      height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      filter: 'grayscale(100%)',
   },
   imgdiv: {
      height: '6vw',

   },
   imgsdiv: {
      height: '7vw',
   },
   ps: {
      fontSize: '2.7vw',
      margin: 0,
   },

   titles: {
      lineHeight: '10vw',
      height: '10vw',
      fontSize: '4.2vw',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      // height: '10vw',
      color: '#333333',
      padding: 0,
      marginBottom: theme.spacing(0),
   },
   spanaDIV: {
      lineHeight: '10vw',
      height: '10vw',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      color: '#999999'
   },

   settingDiv: {
      backgroundColor: '#ffffff',
      marginBottom: theme.spacing(2),
      // marginRight: theme.spacing(1),
      color: '#333333',
      marginTop: theme.spacing(1),

   },

   outDiv: {
      lineHeight: '14vw',
      height: '14vw',
      fontSize: '4.2vw',
      width: '100%',
      color: '#333333',
      padding: 0
   },

   jiantou: {
      height: '4vw',
      marginLeft: theme.spacing(2),
   },

   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '85vw',
      height: '14vw',
   },

   jiantoua: {
      height: '1.7vh',
      lineHeight: '1.7vh',
      marginLeft: '1vw',
      // marginRight: theme.spacing(4),

   },

});

const ColorButton = withStyles(theme => ({
   root: {
      color: theme.palette.getContrastText("#333333"),
      border: 'none',
      outlined: "none",
      fullWidth: "true",
      boxShadow: 'none',
      backgroundColor: "#ffffff",
      '&:hover': {
         backgroundColor: "#ffffff",
         boxShadow: 'none',
      },
   },
}))(Button);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingView));
