/* 患者管理主页面 */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {getPatientMessageNewsList} from '../../redux/actions/index';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HealthInfo1 from '../../assets/images/healthinfo1.jpg';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const StyledAvatar = withStyles({
    root: {
        borderRadius: 3,
        border: 0,
        height: 72,
        width: 72,
        marginRight: 3,
    },
})(Avatar);


//健康资讯页面
class MessageKnowledge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.handleDetailClick = this.handleDetailClick.bind(this);
        this.props.getPatientMessageNewsList()
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }
    handleDetailClick(index) {
        //应该是在登录成功之后,才调用
        const path = `${this.props.match.path}/newsdetail`+index;
        this.props.history.push(path);
    }
    handleChangeIndex() {

    }

    render() {
        //get react-material css styles
        const {classes, newsList} = this.props;
        return (
            <Grid className={classes.rootDiv}>
                {(newsList.data || []).map((item, index) => {
                    return (
                        <Paper key={item.id} className={classes.paper} onClick={() => {
                            this.handleDetailClick(item.id);
                        }}>
                            <Grid container justify="flex-start">
                            <Grid item xs={3}>
                                <ListItemAvatar>
                                    <StyledAvatar alt="Remy Sharp" src={item.photo1 === "" ? HealthInfo1 : item.photo1}/>
                                </ListItemAvatar>
                            </Grid>
                            <Grid item xs={9} container alignItems="stretch">
                                <Typography className={classes.subtitleDIV} noWrap>{item.title}</Typography>
                                <Typography className={classes.contentDIV} noWrap></Typography>
                                <Grid container justify="space-between">
                                    <Typography className={classes.dateDIV}>{item.createTime}</Typography>
                                     <Typography className={classes.readDIV}>{item.readcount}阅读</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                      </Paper>
                    )
                })
                }

            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newsList: state.newsReducer.patientMessageNewsList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //请求我的推介列表数据
        getPatientMessageNewsList: () => {
            dispatch(getPatientMessageNewsList())
        },
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        flexGrow: 1,
        overflow: 'hidden',
        height: '100vh',
        margin: theme.spacing(2),
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    dateDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw'

    },
    readDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw'
    },

    subtitleDIV: {
        padding: '0',
        color: '#333333',
        fontSize: '4.2vw',
        marginTop: '0vw'
    },

    contentDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '4vw',
        marginTop: '0vw'
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(MessageKnowledge)));
