import * as ActionTypes from '../actions/constants'

let initialState = {
    code:500,
    loginSucceed:500,
    patientLoginSucceed:500,
}

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.DOCTOR_LOGIN_ACTION_SUCCEED:
            if(action.result.code == 200){
                localStorage.setItem("userId", action.result.data.id)
                localStorage.setItem("doctorId",action.result.data.doctorId)
                localStorage.setItem("hospitalId",action.result.data.hospitalId)
                localStorage.setItem("username",action.result.data.username)
                localStorage.setItem("token",action.result.data.token)
            }
            return {...state, loginSucceed:action.result.code,loginRet:action.result}
        default:
            return state
    }
}

export default loginReducer
