//待随访
import React from 'react';
import {connect} from 'react-redux';
import {createStyles, makeStyles, withStyles, withTheme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import avatar from "../../assets/images/tj.png";
import photo from '../../assets/images/phones.png'
import contact from '../../assets/images/zy2.png'
import signImg from '../../assets/images/sign.png'
import dealImg from '../../assets/images/zy8.png'
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {queryManageWait} from '../../redux/actions/patient_manage_action';
import {getCustomInfo, validFollowupCode, sendSmsToPatient,reOrderFollowup} from "../../redux/actions/custom_action";
import Toast from "antd-mobile/lib/toast";

import clsx from 'clsx';
import locale from "antd/es/date-picker/locale/zh_CN";
import Button from '@material-ui/core/Button';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";

import FollowUpType from '../common/followUpType'
import { isSameMinute } from 'date-fns/esm';

import {DatePicker, Modal as ModalAnt, Select, TimePicker, ConfigProvider} from 'antd';

import InputBase from "@material-ui/core/InputBase/InputBase";

import TextField from "@material-ui/core/TextField/TextField";


const useStyles = makeStyles({
    root: {
 
       '&:hover': {
          backgroundColor: 'transparent',
       },
    },
    icon: {
       borderRadius: '50%',
       width: 16,
       height: 16,
       boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
       backgroundColor: '#f5f8fa',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
       '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
       },
       'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
       },
       'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
       },
    },
    checkedIcon: {
       backgroundColor: '#00C9E4',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
       '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
       },
       'input:hover ~ &': {
          backgroundColor: '#00C9E4',
       },
    },
 
 });


function StyledRadio(props) {
    const classes = useStyles();
    return (
       <Radio
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
          icon={<span className={classes.icon}/>}
          {...props}
       />
    );
 }

//我的患者-待随访主页面
class ManageWait extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorId:localStorage.getItem("doctorId"),
            userId:localStorage.getItem("userId"),
            userItem: "",
            boxVisible: false,   // 弹框
            followupCode: "",
            disableSendSms: false,
            orderBoxVisible:false
        };
        this.toAddFollowUp = this.toAddFollowUp.bind(this);
        this.queryFollowUpCode = this.queryFollowUpCode.bind(this)
        this.showFollowupCodeBox = this.showFollowupCodeBox.bind(this)
        this.jumpToAddFollowUp = this.jumpToAddFollowUp.bind(this)
        this.handleEventCancel = this.handleEventCancel.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmitOk = this.handleSubmitOk.bind(this)
        this.handleSendSms = this.handleSendSms.bind(this)
        this.handleOrder = this.handleOrder.bind(this)
        this.goFeedback = this.goFeedback.bind(this)
        this.goShDeal = this.goShDeal.bind(this)
        this.goSign = this.goSign.bind(this)
        this.goFollowUpHistory = this.goFollowUpHistory.bind(this)
    }


    ClosesModal = () => {
        this.setState({showsModal: false})
    }

    componentDidMount() {
        const userInfo = {
            userId:this.state.userId,
            doctorId : this.state.doctorId
        }
        this.props.queryManageWait(userInfo)
    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        if(nextProps.customInfoRet !== this.props.customInfoRet){
            if(nextProps.customInfoRet.customId == 0){
                Toast.show("访视已过期，请重新预约！");
                this.showOrderBox();
            }else if(nextProps.customInfoRet.customId > 0){
                this.showFollowupCodeBox()
            }else{
                this.jumpToAddFollowUp()
            }
        }
        if(nextProps.validFollowupRet !== this.props.validFollowupRet){
            if(nextProps.validFollowupRet.code === 200){
                this.jumpToAddFollowUp()
            }else{
                Toast.show(nextProps.validFollowupRet.msg);
            }
        }
        if(nextProps.sendSmsRet !== this.props.sendSmsRet){
            if(nextProps.sendSmsRet.code === 200){
                Toast.show("发送成功！");
            }else{
                Toast.show(nextProps.sendSmsRet.msg);
                this.setState({
                    disableSendSms:false
                })
            }
        }
        if(nextProps.orderRet !== this.props.orderRet){
            if(nextProps.orderRet.code === 200){
                Toast.show("预约成功！");
                this.setState({
                    orderBoxVisible:false,
                    followupCode: ""
                })
                const userInfo = {
                    userId:this.state.userId,
                    doctorId : this.state.doctorId
                }
                this.props.queryManageWait(userInfo)
            }else{
                Toast.show("预约失败："+nextProps.sendSmsRet.msg);
            }
            
        }
        if(nextProps.retCode === 403){
            window.location.href = "/doctor"
        }
    }

    handleChange(event) {
        this.setState({
            
        })
    }

    goFeedback(item){
        console.log("go feedback");
        const path = '/doctorIndex/manage/PatientFeedback';   // 跳转到反馈单
        var params = {
          findPerson: 1,
          patientId: item.patientId,
          followUpId:0,
          patientNoticeId: item.noticeId
        }
       this.props.history.push(path, params);
    }
    goShDeal(item){
        const path = '/doctorIndex/manage/reliefDeal';   // 跳转到反馈单
        var params = {
          patientId: item.patientId,
        }
       this.props.history.push(path, params);
    }
    goSign(item){
        const path = '/doctorIndex/manage/FamilyAgreement';
        var params = {
            patientId: item.patientId,
            followUpId: 0,
        }
        this.props.history.push(path, params);  // 跳转到家庭协议
    }

    //访视码
    queryFollowUpCode(item) {
        this.setState({
            userItem: item,
        })
        let customInfo = {}
        customInfo.patientId = item.patientId
        customInfo.moduleCode = 3001
        this.props.getCustomInfo(customInfo)
    }

    showFollowupCodeBox(){
        this.setState({
            boxVisible: true,
            followupCode: ""
        })
        console.log("show box")
    }

    showOrderBox(){
        this.setState({
            orderBoxVisible: true,
        })
        console.log("show order box")
    }

    //跳转到添加随访记录
    jumpToAddFollowUp(){
        this.toAddFollowUp(this.state.userItem)
    }

    //添加随访记录
   toAddFollowUp(item) {
      let followUpType = item.followUpType;
      if (followUpType === 1) {
         const path = `${this.props.match.path}/followupvisitpaper`;  //首次随访(FollowUpVisitPaper3)
         this.props.history.push(path, {
            "patientId": item.patientId,
            "followUpType": followUpType,
            "patientName": item.patientName,
            "followUpId": item.followUpType
         });
      } else {
         const path = `${this.props.match.path}/followupvisitpaper2`; //日常随访(FollowUpVisitPaper2)
         this.props.history.push(path, {
            "patientId": item.patientId,
            "followUpType": followUpType,
            "patientName": item.patientName,
            "followUpId": item.followUpType,
            "orderDate": item.orderDate,
            "treatMonth": item.treatMonth,
         });
      }
   }

   // 弹框取消事件
   handleEventCancel() {
        this.setState({
            followupCode: "",
            boxVisible: false,
            disableSendSms: false,
            orderBoxVisible: false
        })
    }

    //随访码更新
    handleInputChange(event){
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    // 弹框确认事件
    handleSubmitOk() {
        let boxParams = {};
        if (this.state.followupCode === "") {
            Toast.show("请输入访视码！");
            return;
        }
        if (this.state.followupCode.length !== 6) {
            Toast.show("访视码为六位有效数字！");
            return;
        }
        boxParams.patientId = this.state.userItem.patientId;
        boxParams.followupCode = this.state.followupCode;
        
        this.props.validFollowupCode(boxParams);

        this.setState({
            disableSendSms: false
        })
    }

    handleSendSms(){
        let patientId = this.state.userItem.patientId;
        let params = {}
        params.patientId = patientId;
        params.userId = this.state.userId
        this.props.sendSmsToPatient(params);
        this.setState({
            disableSendSms: true
        })
    }

    handleOrder(){
        let patientId = this.state.userItem.patientId
        let params = {}
        params.patientId = patientId
        params.date = this.state.date
        params.address = this.state.address
        this.props.orderFollowup(params)
    }

    goFollowUpHistory(item) {
        const path = `${this.props.match.path}/followuphistory`; //随访历史
        this.props.history.push(path, item);
    }

    render() {
        //get react-material css styles
        const {classes, followUpWaitingList} = this.props;
        return (
            <Grid className={classes.rootDiv}>
                {(followUpWaitingList || []).map((item) => {
                    let subDays = -100
                    if(item.orderDate.length > 3){
                        subDays = (new Date().getTime() - new Date(item.orderDate).getTime())/1000/3600/24;
                    }
                    console.log(subDays);
                    
                    return (

                        <Grid item xs={12}  key={item.id} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                              className={classes.paper}>
                            <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                <Grid> <img src={avatar} className={classes.avatar} onClick={() => { this.goFollowUpHistory(item); }}/> </Grid>
                                <Grid className={classes.m1}></Grid>
                                <Grid className={classes.m1}></Grid>
                                <Grid>
                                    <Link className={classes.click} component="button" variant="body2" onClick={() => { this.queryFollowUpCode(item); }}>添加随访记录</Link>
                                </Grid>
                            </Grid>

                            <Grid item xs={8}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p" className={classes.title}>
                                        患者姓名:{item.patientName}
                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        联系电话：{item.patientMaskPhone}
                                        <a href={"tel:"+item.patientTelphone}>
                                        <img
                                        href={"tel:"+item.patientTelphone}
                                        alt=""
                                        src={photo}
                                        className={classes.phone}
                                        />
                                        </a>
                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        随访类型：{FollowUpType(item.followUpType)}
                                        <a className={item.supplyTag == 1?'':classes.hideMe}>
                                        <img
                                        onClick={() => this.goFeedback(item)}
                                        alt=""
                                        src={contact}
                                        className={classes.phone}
                                        />
                                        </a>
                                        <a className={item.supplyDeal == 1?'':classes.hideMe}>
                                        <img
                                        onClick={() => this.goShDeal(item)}
                                        alt=""
                                        src={dealImg}
                                        className={classes.phone}
                                        />
                                        </a>
                                        <a className={item.supplySign == 1?'':classes.hideMe}>
                                        <img
                                        onClick={() => this.goSign(item)}
                                        alt=""
                                        src={signImg}
                                        className={classes.phone}
                                        />
                                        </a>
                                    </Typography>
                                    <Typography component="p" className={subDays > 0?classes.title_red:(subDays > -5?classes.title_orange:classes.title)}>
                                        应处理日期：{item.orderDate}
                                    </Typography>
                                    <Typography component="p" className={classes.title}>
                                        预约地点:{item.orderPlace}
                                    </Typography>
                                    {/*<Typography component="p" className={classes.title}>*/}
                                        {/*地址：{item.adddress}*/}
                                    {/*</Typography>*/}
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    )
                })
                }
                <Modal className={classes.modal} open={this.state.showsModal} onClose={this.ClosesModal} closeAfterTransition BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}>
                    <Grid className={classes.papers}>
                        <Grid container justify="center" alignItems="center" >
                            <Typography className={classes.ridDiv}> 呼叫</Typography>
                        </Grid>
                    </Grid>
                </Modal>

                <ModalAnt title="请输入访视码"
                      visible={this.state.boxVisible}
                      afterClose={this.handleEventCancel}
                      onCancel={this.handleEventCancel}
                      destroyOnClose={true}
                      forceRender={true}
                      footer={[
                         <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                         <Button key="submit" onClick={this.handleSubmitOk}>提交</Button>,
                      ]}
               >
                  <Grid item xs={10} className={classes.bindContent}>
                     <Grid className={classes.bindContentFlex}>
                        <YddInput name="followupCode" placeholder='  6位数字' value={this.state.followupCode} onChange={this.handleInputChange} autoComplete="false" />
                        <Button name="sendSms" color="primary" className={classes.buttons} disabled={this.state.disableSendSms} onClick={this.handleSendSms}>发送</Button>
                     </Grid>
                  </Grid>
               </ModalAnt>

               <Modal
                            className={classes.modal}
                            open={this.state.orderBoxVisible}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Grid item xs={10} className={classes.papersDiv}>
                                <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                                <Grid item xs={12} justify='center' className={classes.paperDiv}>
                                    <Grid item className={classes.tishiDiv}>
                                        对患者预约随访
                                    </Grid>
                                    <Grid item xs={12} className={classes.shijianDiv}>
                                        预约时间：
                                        <TextField id="date" name="date" type="date" className={classes.textField} value={this.state.date}
                                            InputLabelProps={{shrink: true,}} onChange={this.handleInputChange}/>
                                    </Grid>
                                    <Grid item xs={12} className={classes.shijianDiv}>
                                        预约地点： <YddInput name="address" placeholder='首次访视请输入地址' value={this.state.address} onChange={this.handleInputChange}/>
                                    </Grid>
                                    <Grid item xs={12} className={classes.buttonDiv}>
                                        <Button variant="contained" color="primary" className={classes.buttons}
                                                onClick={()=>{this.handleOrder()}} >确定</Button>
                                        <Button variant="contained" color="primary" className={classes.butt}
                                                onClick={this.handleEventCancel}>取消</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                </Modal>
            </Grid>
        );
    }

}

//redux region
const mapStateToProps = (state) => {
    return {
        retCode: state.patientManageReducer.retCode,
        doctorId: state.appReducer.doctorId,
        followUpWaitingList: state.patientManageReducer.manageWait,
        userItem: state.userItem,
        customInfoRet: state.customReducer.customInfoRet,
        validFollowupRet: state.customReducer.validFollowupRet,
        sendSmsRet: state.customReducer.sendSmsRet,
        orderRet: state.customReducer.orderRet
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        queryManageWait: (userInfo) => {
            dispatch(queryManageWait(userInfo));
        },
        getCustomInfo: (customInfo) => {
            dispatch(getCustomInfo(customInfo));
        },
        validFollowupCode: (params) => {
            dispatch(validFollowupCode(params));
        },
        sendSmsToPatient: (params) => {
            dispatch(sendSmsToPatient(params));
        },
        orderFollowup: (params) => {
            dispatch(reOrderFollowup(params));
        },
    }
}
//end of redux regian

//css region
const styles = theme => ({
    m1: {
        height: '1vh'
    },
    rootDiv: {
        flexGrow: 1,
        padding: theme.spacing(0, 2),
        backgroundColor: '#ffffff',
        paddingTop: theme.spacing(2),
    },

    phone: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        width: '4vw'
    },

    paper: {
        marginBottom: theme.spacing(2),
        border: '2vh',
        padding: '2vw',
        borderRadius: '16px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)'
    },

    title: {
        fontSize: '4.2vw',
        color: '#333333',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    title_red: {
        fontSize: '4.2vw',
        color: 'red',
        marginRight: theme.spacing(0),
        padding: '0',
    },
    title_orange: {
        fontSize: '4.2vw',
        color: 'orange',
        marginRight: theme.spacing(0),
        padding: '0',
    },

    titleDiv: {
        height: '15vw',
        display: 'flex',
        flex: 1
    },

    papers: {
        width: '85vw',
        backgroundColor: '#ffffff',
        fontSize: '4.2vw'
    },

    ridDiv: {
        fontSize: '4.2vw',
        padding: theme.spacing(2)
    },

    hrs: {
        margin: 0,
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },

    avatar: {
        width: '16vw',
        height: '16vw',
        maxWidth: '16vw',
        maxHeight: '16vw',
        marginLeft: theme.spacing(1),
        borderRadius: "50%"
    },

    click: {
        color: '#00DDD8',
        fontSize: '3.2vw'
    },

    titles: {
        marginLeft: '5vw',
        marginTop: 0,
        marginBottom: '1vw',
        lineHeight: '13vw',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'

    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    avatarDiv: {
        marginLeft: '1vw',
        marginRight: '4vw',
    },

    bindOptionLeftStyle: {
        width: '35% !important',
        paddingLeft: '5%'
     },
     bindOptionRightStyle: {
        width: '60%'
     },
     bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important',
        justifyContent: 'center',
     },
     bindModalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     },
     bindContent: {
        backgroundColor: '#FFFFFF'
     },

     buttons: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        marginLeft: '3.5vw',
    },

    //model
    papersDiv: {
        width: '85vw',
        height: '55vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },
    hideMe: {
        display: 'none'
    }

});
//end of css region

const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            body1: {
                fontSize: '4.2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});

const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            marginLeft: theme.spacing(1),
            fontSize: 15,
            width: '45vw',
            height: '4vw',
            border: '1px solid #333333',
            outline: 'none',

            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    }),
)(InputBase);

//here we inject react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(ManageWait)));
