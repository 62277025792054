import React from 'react';
import { connect } from 'react-redux';
//弹出框
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Toast from "antd-mobile/lib/toast";

import { Route, Switch } from "react-router-dom";

// 引入moment 时间组件
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

import ScreeningDiabetesHistoryPaper from './ScreeningDiabetesHistoryPaper';
import ScreeningAgedHistoryPaper from '../screeningAgedPaper/ScreeningAgedHistoryPaper';

import { ThemeProvider } from '@material-ui/styles';
import avatar from "../../assets/images/tj.png";
import av_old from '../../assets/images/avatar_old.png';
import av_tnb from '../../assets/images/avatar_tnb.png';
import { createMuiTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            body1: {
                fontSize: '4.2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio className={classes.root} disableRipple color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} {...props} />
    );
}


//随访导航页
class ScreeningNavPaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleNavBack = this.handleNavBack.bind(this);

    }

    componentDidMount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    //点击返回
    handleNavBack() {
        window.history.go(-1);
    }

    handleNavGo(tag){
        if(tag === 1){
            this.props.history.push(`${this.props.match.path}/diabetes`);
        }
        if(tag === 2){
            this.props.history.push(`${this.props.match.path}/aged`);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/diabetes`} component={ScreeningDiabetesHistoryPaper} />
                <Route path={`${this.props.match.path}/aged`} component={ScreeningAgedHistoryPaper} />
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>结核病筛查</h5></Grid>
                        <Grid item xs={3} className={classes.headers} onClick={this.handleClickAdd}><h5 className={classes.next}></h5> </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>

                    <div className={classes.listDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                            className={classes.paper}>
                            <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                <Grid> <img src={av_tnb} className={classes.avatar} /> </Grid>
                            </Grid>


                            <Grid item xs={8}  onClick={() => { this.handleNavGo(1); }}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p" className={classes.title}>
                                        糖尿病筛查(季度)
                                    </Typography>
                                    <Typography component="p" className={classes.subTitle}>
                                        对本辖区内糖尿病患者进行结核病筛查
                                    </Typography>
                                </ThemeProvider>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'
                            className={classes.paper}>
                            <Grid item xs={3} container direction="column" justify="center" className={classes.avatarDiv}>
                                <Grid> <img src={av_old} className={classes.avatar} /> </Grid>
                            </Grid>

                            <Grid item xs={8} onClick={() => { this.handleNavGo(2); }}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p" className={classes.title}>
                                        老年人筛查(年度)
                                    </Typography>
                                    <Typography component="p" className={classes.subTitle}>
                                        对本辖区内老年人进行结核病筛查
                                    </Typography>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        //background: '#ffffff',
    },
    listDiv: {
        padding: theme.spacing(),
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    spacemargin: {
        margin: theme.spacing(1),

    },
    paper: {
        marginBottom: theme.spacing(2),
        border: '2vh',
        padding: '2vw',
        borderRadius: '16px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)'
    },
    avatar: {
        width: '16vw',
        height: '16vw',
        maxWidth: '16vw',
        maxHeight: '16vw',
        marginLeft: theme.spacing(1),
        borderRadius: "50%"
    },
    avatarDiv: {
        marginLeft: '1vw',
        marginRight: '4vw',
    },
    title: {
        fontSize:'5vw',
        fontWeight: '600',
        paddingTop: '1rem',
    },
    subTitle: {
        fontSize:'3vw',
        fontWeight: '200',
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreeningNavPaper));
