import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {getMyTrackNoticeList, noticeReaded} from '../../redux/actions/index';
import jiantoux from '../../assets/images/jiantoux.png'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";


//患者追踪-> 通知列表页面
class TrackNoticeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleDetailClick = this.handleDetailClick.bind(this);
    }

    //患者追踪 -通知 -查看详情
    handleDetailClick(item) {

        let notice={};
        notice.type=2;
        notice.id=item.noticeId;
        this.props.noticeReaded(notice);
        console.log(item)
        if(item.trackType === 12001){
            //北京区域的追踪
            const path = `${this.props.match.path}/patientTrackNotice` + item.id;
            this.props.history.push(path);
            return;
        }

        const path = `${this.props.match.path}/patientTrackDetail` + item.id;
        this.props.history.push(path);
    }

    //加载
    componentDidMount() {
        this.props.getTrackNoticeList(2)
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps != undefined) {
            if (nextProps.isRead != this.props.isRead) {
                //获得发送结果之后，再调用路由切入对应页面
                if (nextProps.isRead == 200) {
                }
            }
        }
    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    }


    handleChangeIndex(index) {
        this.setState({index: index});
    }

    render() {
        //get react-material css styles
        const {classes, tracknoticeList} = this.props;
        return (
            <div className={classes.rootDiv}>
                {(tracknoticeList || []).map((item) => {
                    return (
                        <div key={item.noticeId}>
                            <Grid container justify="flex-start" wrap="nowrap">
                                <Typography className={classes.ReferralnoticeDiv}>{item.notice}</Typography>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap">
                                <Typography className={classes.NoticedateDiv}>{item.createTime}</Typography>
                            </Grid>
                            <Grid container justify="flex-end" wrap="nowrap">
                                <Link className={classes.linkDiv} component="button" variant="body2" onClick={() => { this.handleDetailClick(item) }}>
                                    <span className={classes.xiangDiv}> 查看详情 </span>
                                    <img className={classes.jiantoux} src={jiantoux}/>
                                    <div className={classes.pointDiv}></div>
                                </Link>
                            </Grid>
                            <hr className={classes.hr}></hr>
                        </div>
                    )
                })
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        tracknoticeList: state.trackReducer.tracknoticeList,
        isRead: state.trackReducer.isRead
    }
}

//把请求路径映射到属性
const mapDispatchToProps = (dispatch) => {
    return {
        getTrackNoticeList: (noticeType) => {
            dispatch(getMyTrackNoticeList(noticeType))
        },
        noticeReaded: (notice) => {
            dispatch(noticeReaded(notice))
        }
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        width: '100vw',
        marginTop: theme.spacing(2),
        backgroundColor: '#FFFFFF',
        height: '100vh',
        overflow: 'auto',
    },

    paper: {
        display: 'flex',
        width: '100vw',
        height: '18vw',
        // marginBottom: '4vw',
        justifyContent: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    NotificationboxDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
        height: '20vw'
    },

    ReferralnoticeDiv: {
        margin: theme.spacing(0,2),
        fontSize: '4.2vw',
        color: '#333333',
        textAlign: 'justify',
    },

    NoticedateDiv: {
        marginLeft: '3.5vw',
        fontSize: '3.7vw',
        color: '#999999',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },
    linkDiv: {
        margin: theme.spacing(0,2),
        color: '#00DDD8',
        fontSize: '3.5vw',
        display: 'flex',
        alignItems: 'center',
    },

    jiantoux: {
        height: '1.7vh',
        lineHeight: '1.7vh',
        marginLeft: '1vw',
    },

    xiangDiv: {
        color: '#00DDD8',
        fontSize: '3.5vw',
        padding: '0vw',
    },

    pointDiv: {
        position:'absolute',
        top: 0,
        right: '-5px',
        width: '6px',
        height: '6px',
        background: 'red',
        borderRadius: '50%',
    }


});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackNoticeList));
