import React from 'react';
import clsx from 'clsx';
import {connect} from 'react-redux';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {getTrackAppointment,submitAppointmentInfo} from '../../redux/actions/index';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import InputBase from '@material-ui/core/InputBase';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import avatar from "../../assets/images/tj.png";
import axxxx from '../../assets/images/axxxx.png'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Trackfailure from './Trackfailure';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import {amber, green} from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';
import Toast from "antd-mobile/lib/toast";

import {Route, Switch} from "react-router-dom";
import gender from "../common/sex";
import moment from "moment";


const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const {className, message, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                      <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
                    </span>
            }
            {...other}
        />
    );
}

//患者追踪详情页面
class PatientAppointment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            noticeId: 0,
            noticeType: 0,
            traceId: 0,
            patientName:'',
            date : moment().format('YYYY-MM-DD'),
            address:'',
            vertical: 'top',
            horizontal: 'center',
            visible:false,
            showsModal:false,
            idNumber: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleFailed = this.handleFailed.bind(this);
        this.handlequeding = this.handlequeding.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleFailed(id) {
        const path = `${this.props.match.path}/trackfailure`+id;
        this.props.history.push(path);
    }

    //预约就诊 提交
    handlequeding() {
        if(this.state.traceId === "") {
            Toast.show("请输入患者姓名!");
            return;
        }
        //2020-01-02
        if(this.state.date.length != 10) {
            Toast.show("请输入预约时间!");
            return;
        }

        if(this.state.address === "") {
            Toast.show("请输入地址!");
            return;
        }
        const params = {
            id: this.state.traceId,//追踪id
            date: this.state.date,//日期
            address: this.state.address//地址
        }

        this.props.submitAppointmentInfo(params);
    }

    //弹出预约窗口
    handleAppointment = (trackAppointment) => {
        this.state.patientName = trackAppointment.name;
        this.setState({showModal: true});
    }


    closeModal = () => {
        this.setState(
            {showModal: false}
            );
    }
    ClosesModal = () => {
        this.setState({showsModal: false})
    }

    componentDidMount() {
        let currentDate = new Date();
        this.setState({date : currentDate})
        this.state.traceId = this.props.match.params.traceId;
        this.props.getTrackAppointment(this.state.traceId);
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.appointmentSucceedCode !== this.props.appointmentSucceedCode) {

            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.appointmentSucceedCode === 200) {
                this.state.warnMsg="预约成功";
                this.setState({
                    visible: true
                })
                this.closeModal();
                const refreshPath = `/doctorIndex/track/patientTrackDetail`+this.state.traceId;
                this.props.history.push(refreshPath);
            }else{
                this.state.warnMsg="预约失败";
                this.setState({
                    visible: true
                })
                this.closeModal();
                //TODO弹框提示 请勿重复提交预约!
            }
        }
    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    //获取表单属性的值
    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    //后退按钮
    handleNavBack() {
        this.props.history.goBack();
    }

    //关闭snackbar
    handleClose = () => {
        this.setState({ visible: false });
      };

    render() {
        const {classes, trackAppointment} = this.props;
        return (
            <Switch>
                <Route exact path={`${this.props.match.path}/trackfailure:traceId`} component={Trackfailure}/>
                <Grid item xs={12} className={classes.card}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid container justify="center" className={classes.headers}>
                            <h4 className={classes.huanzhe}>患者追踪详情</h4>
                        </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="center" spacing={0}>
                        <Avatar alt="Remy Sharp" src={avatar} className={classes.avatar}/>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            姓名：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.name}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            性别：
                        </Typography>
                        <Typography className={classes.span}>{gender(trackAppointment.sex)}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            年龄：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.age}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            身份证：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.idNumber}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            现住址：
                        </Typography>
                        <Typography className={classes.fieldValueDiv}>{trackAppointment.address}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            通知原因：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.reason}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            追踪状态：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.patientStatus}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            失败原因：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.outcomeReason}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            已预约日期：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.reserveTime}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            已预约医院：
                        </Typography>
                        <Typography className={classes.span}>{trackAppointment.reserveAddress}</Typography>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <CardContent>
                        <Grid container justify="center">
                            <Button variant="contained" color="primary" className={classes.margin}
                                    onClick={() => {this.handleAppointment(trackAppointment);}}>
                                就诊预约
                            </Button>
                            <a href={"tel:"+trackAppointment.telphone } className={classes.telphone}>
                            <Button variant="contained" color="primary" className={classes.margin}>
                            拨打电话
                            </Button>
                            </a>
                            <Button variant="contained" color="primary" className={classes.margin}
                                    onClick={()=>{this.handleFailed(trackAppointment.id)}}>
                                追踪失败
                            </Button>
                        </Grid>
                    </CardContent>
                    <Modal
                        className={classes.modal}
                        open={this.state.showModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Grid item xs={10} className={classes.paper}>
                            <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                            <Grid item xs={12} justify='center' className={classes.paperDiv}>
                                <Grid item className={classes.tishiDiv}>
                                    对患者<span>{this.state.patientName}</span>进行就诊预约并提交
                                </Grid>
                                <Grid item xs={12} className={classes.shijianDiv}>
                                    预约时间：
                                    <TextField
                                        id="date"
                                        name="date"
                                        type="date"
                                        className={classes.textField}
                                        value={this.state.date}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.shijianDiv}>
                                    预约地点： <YddInput name="address" placeholder='请输入地址' value={this.state.address} onChange={this.handleInputChange}
                                />
                                </Grid>
                                <Grid item xs={12} className={classes.buttonDiv}>
                                    <Button variant="contained" color="primary" className={classes.buttons}
                                            onClick={this.handlequeding}>确定</Button>
                                    <Button variant="contained" color="primary" className={classes.butt}
                                            onClick={this.closeModal}>取消</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Modal>
                    <Modal
                        className={classes.modal}
                        open={this.state.showsModal}
                        onClose={this.ClosesModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Grid className={classes.papers}>
                            <Grid container justify="center" alignItems="center" >
                                <Typography className={classes.ridDiv}> 呼叫</Typography>
                            </Grid>
                        </Grid>
                    </Modal>

                    <Snackbar anchorOrigin={{vertical: this.state.vertical, horizontal: this.state.horizontal}}
                              open={this.state.visible}
                              onClose={this.handleClose}
                              autoHideDuration={1000}
                    >
                        <MySnackbarContentWrapper
                            variant="success"
                            className={classes.success}
                            message={this.state.warnMsg}
                        />
                    </Snackbar>

                </Grid>
            </Switch>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        trackAppointment: state.trackReducer.trackAppointment,
        appointmentSucceedCode:state.trackReducer.appointmentSucceedCode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTrackAppointment: (noticeId) => {
            dispatch(getTrackAppointment(noticeId)) //action/index页面
        },
        //提交预约信息
        submitAppointmentInfo: (appointInfo) => {
            dispatch(submitAppointmentInfo(appointInfo));
        }
    }
}


//
// const mapStateToProps = (state) => {
//   return {
//     serverInfo: state.serverInfo,
//     userId: state.SendUserIdReducer,
//     submitAppointmentReply: state.trackReducer.submitAppointmentReply,
//   }
// }

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

//css region
const styles = theme => ({

    spacemargin: {
        margin: theme.spacing(2),

    },
    fieldValueDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999',
    },
    card: {
        maxWidth: '100vw',
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        height: '100vh',
        overflow: 'auto',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '15vw',
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    papers: {
        width: '85vw',
        backgroundColor: '#ffffff',
        fontSize: '4.2vw',
    },
    ridDiv: {
        fontSize: '4.2vw',
        padding: theme.spacing(2),
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    margin: {
        display:'flex',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        height: '10vw',
        width: '23vw',
        margin: theme.spacing(1),
        color: '#ffffff',
        padding: theme.spacing(1),
    },
    headPortrait: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '23vw',
        justifyContent: 'center',
        marginTop: '9vw',
        flexShrink: '1',
    },

    avatar: {
        display: 'flex',
        width: '22vw',
        height: '22vw',
        maxWidth: '22vw',
        maxHeight: '22vw',
        flexShrink: '1',
        borderRadius: "50%"
    },
    information: {
        marginTop: '2vh',
        width: '100%',
        height: '62vh'
    },
    textshuru: {
        width: '90vw',
        height: '10vh',
        marginLeft: '3vw',
        backgroundColor: 'rgba(36,225,221,0.05)',
        border: '0.1vw solid #e6e6e6',
    },
    cardbutton: {
        marginTop: '3vh',
        width: '90vw',
        height: '10vw',
        display: 'flex',
        justifyContent: 'space-between'
    },
    cardbaocun: {
        width: '90vw',
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3vh',
    },
    marginbaocun: {
        width: '91vw',
        height: '7vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    span: {
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        color: '#999999'
    },
    title: {
        margin: 0,
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333',
        lineHeight: '10vw',
    },

    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '85vw',
        height: '55vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },
    titles: {
        marginLeft: '5vw',
    },

    hrs: {
        margin: 0,
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    telphone: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        color: '#ffffff'
    },
});
const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            marginLeft: theme.spacing(1),
            fontSize: 15,
            width: '45vw',
            height: '4vw',
            border: '1px solid #333333',
            outline: 'none',

            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    }),
)(InputBase);
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatientAppointment));
