import * as ActionTypes from '../actions/custom_action'



// The initial application state
let initialState = {
    patientId: 0,
    moduleId: 0
}

// Takes care of changing the application state
function customReducer(state = initialState, action) {

    switch (action.type) {
         case ActionTypes.CUSTOM_MODULE_INFO_SUCCESS://定制 短信/随访过期 -请求成功
            return {...state, customInfoRet: action.result}
         case ActionTypes.VALID_FOLLOWUP_CODE_SUCCESS://校验访视码 - 请求成功
            return {...state, validFollowupRet: action.result}
         case ActionTypes.SEND_SMS_CODE_TO_PATIENT_SUCCESS://短信验证码 - 请求成功
            return {...state, sendSmsRet: action.result}
         case ActionTypes.RE_ORDER_FOLLOWUP_SUCCESS://重新预约随访 - 请求成功
            return {...state, orderRet: action.result}
         case ActionTypes.SIGNED_CONFIRM_SUCCESS://重新预约随访 - 请求成功
            return {...state, signedRet: action.result}
         case ActionTypes.NOTICE_MANAGE_SUCCESS://通知数量 - 患者管理
            return {...state, noticeManageInfo: action.result.data}
         case ActionTypes.NOTICE_ALL_SUCCESS://通知数量 - 全部
            return {...state, noticeAllInfo: action.result.data}
         case ActionTypes.SERVICE_RELIEF_DEAL_COMMIT_SUCCEED://治疗减免提交 - 请求成功
            return {...state, reliefDealRet: action.result}
         case ActionTypes.SERVICE_DIRECT_DEAL_COMMIT_SUCCEED://治疗减免提交 - 请求成功
            return {...state, directDealRet: action.result}
         case ActionTypes.SERVICE_DIRECT_ANSWER_COMMIT_SUCCEED://治疗减免提交 - 督导测评
            return {...state, directAnswerRet: action.result}
         case ActionTypes.ADD_FOLLOWUP_IMAGE_SUCCESS://添加随访图片
            return {...state, addFollowupImageRet: action.result}
         case ActionTypes.ADD_SCREENING_DIABETES_SUCCESS://添加糖尿病筛查
            return {...state, screeningDiabetesAddResult: action.result}
         case ActionTypes.LIST_SCREENING_DIABETES_SUCCESS://添加糖尿病筛查
            return {...state, screeningDiabetesHistoryRet: action.result}
         case ActionTypes.REMOVE_SCREENING_DIABETES_SUCCESS://添加糖尿病筛查
            return {...state, screeningDiabetesRemoveRet: action.result}
         case ActionTypes.UPDATE_SCREENING_DIABETES_SUCCESS://添加糖尿病筛查
            return {...state, screeningDiabetesUpdateRet: action.result}
         case ActionTypes.UPDATE_FOLLOWUP_SIGN_SUCCESS://
            return {...state, updateFollowupSignRet: action.result}
         case ActionTypes.PATIENT_OUT_HOSPITAL_SUCCESS://
            return {...state, outHospitalRet: action.result}
         case ActionTypes.ADD_SCREENING_DIABETES_BJ_SUCCESS://糖尿病筛查北京
            return {...state, screeningDiabetesAddResult: action.result}
         case ActionTypes.LIST_SCREENING_DIABETES_BJ_SUCCESS://糖尿病筛查北京
            return {...state, screeningDiabetesHistoryRet: action.result}
         case ActionTypes.REMOVE_SCREENING_DIABETES_BJ_SUCCESS://糖尿病筛查北京
            return {...state, screeningDiabetesRemoveRet: action.result}
         case ActionTypes.UPDATE_SCREENING_DIABETES_BJ_SUCCESS://糖尿病筛查北京
            return {...state, screeningDiabetesUpdateRet: action.result}
         case ActionTypes.ADD_SCREENING_AGED_SUCCESS://添加老年人筛查
            return {...state, screeningAgedAddResult: action.result}
         case ActionTypes.LIST_SCREENING_AGED_SUCCESS://获取老年人筛查
            return {...state, screeningAgedHistoryRet: action.result}
         case ActionTypes.REMOVE_SCREENING_AGED_SUCCESS://删除老年人筛查
            return {...state, screeningAgedRemoveRet: action.result}
         case ActionTypes.UPDATE_SCREENING_AGED_SUCCESS://更新老年人筛查
            return {...state, screeningAgedUpdateRet: action.result}
         default:
             return state
     }
 
 }
 
 export default customReducer
 