import React from 'react';
import {connect} from 'react-redux';
import {createStyles, withStyles} from '@material-ui/core/styles';
// import {} from '../../redux/actions/index';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import axxxx from '../../assets/images/axxxx.png';
import {postNameDiv} from "../../redux/actions";

//我的-姓名-姓名更改页
class NameDiv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            showModal: false,
            showsModal: false,
            userId: localStorage.getItem("userId"),
            realName: "", //更改姓名
            name: "",
        };
        this.handleReturnClick = this.handleReturnClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    //点击返回
    handleReturnClick() {
        this.props.history.goBack();
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        this.setState({
            name : this.props.location.state.name
        })
    }

    //点击保存
    handleSaveClick() {
        const doctorName = {
            id:this.state.userId,
            name: this.state.name, //修改的名称
            type:1,
        };

        this.props.postNameDiv(doctorName);
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.returnCode != this.props.returnCode) {
            if (nextProps.returnCode === 200) {
                this.props.history.go(-1);
            }
        }
    }


    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.rootDiv}>

                <Grid container xs={12} justify="flex-start" alignContent="center" className={classes.Patient}>
                    <Grid item xs={5}>
                        <Typography>
                            <h4 className={classes.jiantou}><img src={axxxx} onClick={this.handleReturnClick}
                                                                 className={classes.jiantou}></img></h4>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>
                            <h4 className={classes.huanzhe}>姓名</h4>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography onClick={this.handleSaveClick} >
                            <h4 className={classes.xiabu}>保存</h4>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} justify="center">
                    <YddInput
                        name="name"
                        placeholder='请输入'
                        value={this.state.name}
                        onChange={this.handleInputChange}
                    />
                </Grid>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        doctorId: state.myInfoReducer.doctorId,
        returnCode: state.myInfoReducer.returnCode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        postNameDiv: (doctorName) => {
            dispatch(postNameDiv(doctorName))
        }
    }
}

const styles = theme => ({
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
        alignItems: 'center',
    },
    Patient: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '12vw',
        margin: 0
    },
    xiabu: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '12vw',
        Height: '12vw',
        margin: 0,
        padding: 0
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '14vw',
    },


});


const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            borderBottom: '0.5vw solid #B9E9E7',
            fontSize: 15,
            width: '80vw',
            height: '9vw',
            marginTop: '5vw',
            outline: 'none',
            '&:focus': {
                borderColor: '#B9E9E7',
            },
        },
    }),
)(InputBase);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NameDiv));
