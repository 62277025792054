//服药康复页面
import React,{Component,Fragment } from 'react'
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import MyMedRecord from './MyMedRecord';
import {postFollowUpVisitPaper1,querySputumCheckRecord,postTakeMedicineEvent,getTakeMedicineStatus} from '../../redux/actions/patient_manage_action';
import Resistant from "../../doctor/common/resistant";
import RemindList from "../../patient/medicineWidget/RemindList";
import Moment from "moment";
import Toast from "antd-mobile/lib/toast";


const columns = [
    {id: 'actualTime', label: '就诊日期', minWidth: 40, align: 'center'},
    {id: 'orderTime', label: '预约日期', minWidth: 50, align: 'center'},
];

//患者端服药康复页面
class MedicineWidget extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         patientId: localStorage.getItem("patientId"),
         bottomValue: 0,
         rows: [],
         hasTakeMedicine: 0,//'未服',
         styleBtnBackground: "#83E78A",
         today: Moment(new Date()).format('YYYY年MM月DD日'),
         displayNone: null
      };

      this.handleRowClick = this.handleRowClick.bind(this);
      this.handleMyMedRecord = this.handleMyMedRecord.bind(this);
      this.handleReminder = this.handleReminder.bind(this);
      this.handleReviseClick = this.handleReviseClick.bind(this);
   }

   componentDidMount() {
      this.props.getSputumRecord(this.state.patientId);
      this.props.getTakeMedicineStatusFun();
   }

   componentWillUnmount() {
      
   }


   //点击添加提醒
   handleReminder() {
      const path = `${this.props.match.path}/remindlist`;
      this.props.history.push(path);
   }

   //fired when props changed
   handleChange(event, newValue) {
      this.setState({value: newValue});
   };

   handleChangeIndex(index) {
      this.setState({index: index});
   }


   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if(this.props.takeMedicineAdmin != nextProps.takeMedicineAdmin){
         if(nextProps.takeMedicineAdmin.code == 200){
            this.setState({
               hasTakeMedicine: 1,//'已服'
               styleBtnBackground: '#FFD700'
            });
            if(nextProps.takeMedicineAdmin.data?.score != undefined){
               Toast.show("服药成功，获得积分"+nextProps.takeMedicineAdmin.data?.score)
            }
         }
         if(nextProps.takeMedicineAdmin.code == 201){
            Toast.show("服药失败！"+nextProps.takeMedicineAdmin.msg)
         }
      }
   }

   handleRowClick() {
   }

   handleMyMedRecord() {
      const path = `${this.props.match.path}/mymedrecord`;
      this.props.history.push(path);
   }

   // 卡片中的未服点击事件
   handleReviseClick() {
      if(this.props.getTakeMedicineStatus == 0){
         this.props.takeMedicineEvent(this.state.userId);
         /*
         this.setState({
            hasTakeMedicine: 1,//'已服'
            styleBtnBackground: '#FFD700'
         });
         window.location.reload();
         */
      }
   }


   render() {
      //get react-material css styles
      const {classes, sputumRecord,getTakeMedicineStatus} = this.props;
      if(getTakeMedicineStatus){
         this.state.hasTakeMedicine = getTakeMedicineStatus;
         this.state.styleBtnBackground = getTakeMedicineStatus > 0 ? '#FFD700' : "#83E78A";
      }

      if(window.location.search && window.location.search !== null && window.location.search !== ''){
         this.state.displayNone = window.location.search.split("flag=")[1]+'';
      }

      return (
         <Switch>
            <Route path={`${this.props.match.path}/mymedrecord`} component={MyMedRecord}/>
            <Route path={`${this.props.match.path}/remindlist`} component={RemindList}/>

            <Grid container spacing={0} className={classes.root}>
               {/* header */}
               <Grid className={this.state.displayNone=='11'?classes.displayHidden:classes.headerDiv} container item xs={12} justify="center" alignContent="center">
                  <h4 className={classes.defaultKang}>服药康复</h4>
               </Grid>

               <Grid container className={classes.bodyDiv} spacing={0} justify="center">
                  {/*  服药状态卡片 */}
                  <Grid item xs={12} className={this.state.displayNone=='9'?classes.displayHidden:classes.displayShow}>
                     <Card className={classes.medicineCard}>
                        <CardContent className={classes.cardDiv}>
                           <Grid container spacing={0}>
                              <Grid item xs={3}>
                                 <Typography className={classes.typoText} color="textSecondary" gutterBottom>
                                    今日
                                 </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                 <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                       <Typography className={classes.typoText} color="textPrimary">
                                          {this.state.today}
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                       {/* <Button className={classes.unfeedButton}>未服</Button> */}
                                       <Button className={classes.unfeedButton} onClick={this.handleReviseClick.bind(this)}
                                               style={{background: this.state.styleBtnBackground}}>
                                          {this.state.hasTakeMedicine==1 ? "已服" : "未服"}
                                       </Button>
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <Grid container direction="column" justify="flex-start" alignItems="center" alignContent="center" className={classes.cardsDiv}>
                                 <AccessAlarm className={classes.IconAlarm}/>
                                 <Typography className={classes.typoTexts} color="textSecondary" onClick={this.handleReminder}>
                                    添加提醒
                                 </Typography>
                              </Grid>
                           </Grid>
                        </CardContent>

                        <CardActions className={classes.cardDiv}>
                           <Grid container justify="space-between">
                              <Button size="small" className={classes.actionButtonLeft}>
                                 方案: {sputumRecord.resistMethod === null ? '--' : sputumRecord.resistMethod}</Button>
                              <Button size="small" className={classes.actionButtonRight} onClick={this.handleMyMedRecord}>我的服药记录></Button>
                           </Grid>
                        </CardActions>
                     </Card>
                  </Grid>


                  {/*我的复诊记录*/}
                  {/*<Typography h5="true" noWrap className={classes.spacemargin}></Typography>*/}
                  <Grid container justify="center" item xs={12} className={this.state.displayNone=='11'?classes.displayHidden:classes.displayShow}>
                     <h4 className={classes.subTitle}><span className={classes.spanDiv}>一</span>我的就诊计划<span
                        className={classes.spanDiv}>一</span></h4>
                  </Grid>
                  {/*<Typography h5="true" noWrap className={classes.spacemargin}></Typography>*/}
                  <Grid className={this.state.displayNone=='11'?classes.displayHidden:classes.displayShow} container justify='center' wrap="nowrap" direction="column" spacing={0}>
                     <Grid item container justify='center' className={classes.tableWrapper}>
                        <Fragment>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columns.map(column => (
                                       <TableCell aligncenter="true" key={column.id} align={column.align}
                                          padding={true ? 'none' : 'default'} style={{minWidth: column.minWidth}}>
                                          <Typography className={classes.titleBold} color="textSecondary">
                                             {column.label}
                                          </Typography>
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {(sputumRecord.orderCheckList || []).map((row, indexkey) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={indexkey}>
                                          {columns.map(column => {
                                             const value = row[column.id];
                                             return (
                                                <TableCell aligncenter="true" key={column.id} padding={true ? 'none' : 'default'} align={column.align} style={{minWidth: column.minWidth}}>
                                                   <Typography className={classes.titleNormal} color="textSecondary">
                                                      {value}
                                                   </Typography>
                                                </TableCell>
                                             );
                                          })}
                                       </TableRow>
                                    );
                                 })}
                              </TableBody>
                           </Table>
                        </Fragment>
                     </Grid>
                  </Grid>

                  {/* 我的查痰记录 */}
                  <Grid container justify="center" item xs={12} className={this.state.displayNone=='11'?classes.displayHidden:classes.displayShow}>
                     <h4 className={classes.subTitles}><span className={classes.spanDiv}>一</span>我的查痰计划<span
                        className={classes.spanDiv}>一</span></h4>
                  </Grid>
                  <Grid item xs={12} className={this.state.displayNone=='11'?classes.displayHidden:classes.displayShow}>
                     {/*<Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        耐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;药：<span
                        className={classes.spans}>{Resistant(sputumRecord.type)}</span>
                     </Typography>*/}
                     <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        患者类型：<span
                        className={classes.spans}>{Resistant(sputumRecord.type)}</span>
                     </Typography>
                     <hr className={classes.hr}></hr>
                     {/* <hr className={classes.hr}></hr> */}
                     <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        下发日期：<span className={classes.spans}>{sputumRecord.createTime}</span>
                     </Typography>
                     {/* 
                     <hr className={classes.hr}></hr>
                     <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        首次查痰：<span className={classes.spans}>{sputumRecord.firstSputumTime}</span>
                     </Typography>
                     
                     <hr className={classes.hr}></hr>
                     <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        二次查痰：<span className={classes.spans}>{sputumRecord.secondSputumTime}</span>
                     </Typography>
                     
                     <hr className={classes.hr}></hr>
                     <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        三次查痰：<span className={classes.spans}>{sputumRecord.thirdSputumTime}</span>
                     </Typography>
                      <Divider /> */}
                     <hr className={classes.hr}></hr>
                  </Grid>
               </Grid>
            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      loggedIn: state.loggedIn,
      sputumRecord: state.patientManageReducer.sputumRecord,
      ConfirmationManagement: state.patientManageReducer.ConfirmationManagement,
      takeMedicineAdmin: state.patientManageReducer.takeMedicineAdmin,
      getTakeMedicineStatus: state.patientManageReducer.getTakeMedicineStatus
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      //预约查痰记录
      getSputumRecord: (patientId) => {
         dispatch(querySputumCheckRecord(patientId))
      },

      // 治疗方案
      postFollowUpVisitPaper1: (patientId) => {  //定义的函数
         dispatch(postFollowUpVisitPaper1(patientId)) // action里面定义的
      },

      // 点击服药
      takeMedicineEvent: (userId)=>{
         dispatch(postTakeMedicineEvent(userId))
      },

      // 查询服药状态
      getTakeMedicineStatusFun: (userId)=>{
         dispatch(getTakeMedicineStatus(userId))
      }
   };
};


//css region
const styles = theme => ({
   root: {
      flexGrow: 1,
      overflow: 'hidden',
      height: '100vh'
      //padding: theme.spacing(0, 1),
   },
   headerDiv: {
      display: 'block',
      width: '100vw',
      height: '14vw',
      lineHeight: '14vw',
      textAlign: 'center',
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
      color: '#ffffff',
   },

   spacemargin: {
      margin: theme.spacing(1),
   },
   bodyDiv: {
      margin: theme.spacing(2),
      height: '100%',
      overflow: 'scroll'
   },
   displayHidden: {
      display: 'none'
   },
   displayShow: {
      display: 'block'
   },
   cardDiv: {
      padding: 0,
   },
   reseeDiv: {
      height: '3vh',
      background: '#ffffff',
      color: '#000000',
      marginTop: '1vh',
   },
   medicineCard: {
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
   },

   IconAlarm: {
      width: '13vw',
      height: '13vw',
      color: '#FFFFFF',
   },

   typoText: {
      margin: theme.spacing(1),
      color: '#ffffff',
      fontSize: '4vw'
   },

   typoTexts: {
      margin: theme.spacing(0),
      color: '#ffffff',
      fontSize: '3.8vw',
      textAlign: 'center',
      padding: 0
   },


   list: {
      maxHeight: '30vh',
      overflow: 'auto',
   },

   unfeedButton: {
      color: 'red',
      fontSize: '5vw',
      outline: 'none',
      width: '18vw',
      height: '18vw',
      textAlign: 'center',
      border: 'none',
      backgroundColor: '#83E78A',
      borderRadius: '50px',
      marginTop: '2vh',
   },

   actionButtonLeft: {
      padding: 0,
      color: '#ffffff',
      marginLeft: theme.spacing(1),
   },

   actionButtonRight: {
      color: '#ffffff',
      marginRight: '1px',
      marginLeft: 'auto',
   },
   tableWrapper: {
      width: '90vw',
      height: '35vw',
   },
   titleBold: {
      backgroundColor: '#00DFD7',
      height: '10vw',
      lineHeight: '10vw',
      color: '#ffffff'
   },
   titleNormal: {
      height: '12vw',
      lineHeight: '12vw',
      color: '#333333'
   },
   spanDiv: {
      color: '#8BEAF0',
      margin: '2vw'
   },
   spans: {
      color: '#999999'
   },
   titleDiv: {
      marginLeft: '3vw',
      fontSize: '4.2vw',
      lineHeight: '5vh',
      color: '#333333',
      marginTop: '2vw'
   },
   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',
      margin: '2vw',
   },

   subTitle: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      lineHeight: '5vw',
      padding: '0'
   },
   subTitles: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      lineHeight: '5vw',
      padding: '0'
   },

   defaultKang: {
      color: 'rgba(255,255,255,1)',
      fontSize: '4vw'
   },

   cardsDiv: {
      width: '20vw',
      marginTop: theme.spacing(1),

   },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MedicineWidget));
