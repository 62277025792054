import Api from './api';

const CustomApi = {

   init(appname, serveraddr, api_key, proto) {

   },

   //获取定制模块信息
   queryCustomInfo(customInfo) {
       let headers = new Headers()
       headers.append('Accept', 'application/json, text/plain, */*')
       headers.append('Content-Type', 'application/json;charset=UTF-8')
       let url = Api.getRootUrl() + '/api/custom/getCustomInfo';
       let params = customInfo
       params.userId = localStorage.getItem("userId")

       const init = {
           method: 'POST',
           headers: headers,
           body: JSON.stringify(params)
       }

       return fetch(
           url,
           init
       ).then(res => res.json())
           .then(data => {
               return data.data;
           })
           .catch(e => {
               return {error: e};
           })
   },
   //获取定制模块信息
   validFollowupCode(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/validFollowupCode';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    sendSmsToPatient(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/sendSmsToPatient';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    reOrderFollowup(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/reOrderFollowup';

        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    signedConfirm(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/signedConfirm';

        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    noticeManage(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/noticeManage';

        params.token = localStorage.getItem("token")

        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    noticeAll(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/noticeAll';

        params.token = localStorage.getItem("token")

        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    reliefDealConfirm(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/reliefDealConfirm';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    directDealConfirm(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/directDealConfirm';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    directAnswerConfirm(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/directAnswerConfirm';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    addFollowupImage(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/custom/addFollowupImage';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    addScreeningDiabetes(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/add';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    listScreeningDiabetes(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/list';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    removeScreeningDiabetes(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/remove';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    updateScreeningDiabetes(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/update';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    //老年人start
    addScreeningAged(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningAged/add';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    listScreeningAged(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningAged/list';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    removeScreeningAged(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningAged/remove';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    updateScreeningAged(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningAged/update';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    //老年人end

    //密接统计筛查start
    addReportContact(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/reportContact/add';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    listReportContact(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/reportContact/list';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    removeReportContact(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/reportContact/remove';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    updateReportContact(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/reportContact/update';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    //密接统计筛查end

    updateFollowupSign(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/followUp/updateFollowupSign';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    patientOutHospital(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/patient/patientOutHospital';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    addScreeningDiabetesBJ(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/addBJ';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    listScreeningDiabetesBJ(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/listBJ';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    removeScreeningDiabetesBJ(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/removeBJ';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },
    updateScreeningDiabetesBJ(params) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/screeningDiabetes/updateBJ';

        params.token = localStorage.getItem("token")
        params.userId = localStorage.getItem("userId")
        
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

}

CustomApi.init();

export default CustomApi