import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles, ThemeProvider } from '@material-ui/core/styles';
import {
   bindUnbindEvent,
   medicalKitListEvent,
   queryPatientDetail,
   untyingEvent
} from '../../redux/actions/patient_manage_action';

import { patientOutHospital } from '../../redux/actions/custom_action';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { DatePicker, Modal, Select, TimePicker, ConfigProvider,Input as Inputa } from 'antd';
import avatarImg from "../../assets/images/tj.png";
import returnImg from '../../assets/images/returnImg.png'
import { Route, Switch } from "react-router-dom";
import CaseVisitRecord from "./CaseVisitRecord";
import ManageRecord from "./ManageRecord";
import gender from '../common/sex'
import locale from "antd/es/date-picker/locale/zh_CN";
import clsx from 'clsx';
import Moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import Toast from "antd-mobile/lib/toast";
import { Input } from '@material-ui/core';

import Api from "../../redux/api/api";
import axios from "axios";

require('../../assets/css/select.css');

const { confirm } = Modal;
const { Option } = Select;

const useStyles = makeStyles({
   root: {

      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
         outline: '2px auto rgba(19,124,189,.6)',
         outlineOffset: 2,
      },
      'input:hover ~ &': {
         backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
         boxShadow: 'none',
         background: 'rgba(206,217,224,.5)',
      },
   },
   checkedIcon: {
      backgroundColor: '#00C9E4',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
         display: 'block',
         width: 16,
         height: 16,
         backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
         content: '""',
      },
      'input:hover ~ &': {
         backgroundColor: '#00C9E4',
      },
   },

});

function StyledRadio(props) {
   const classes = useStyles();
   return (
      <Radio
         className={classes.root}
         disableRipple
         color="default"
         checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
         icon={<span className={classes.icon} />}
         {...props}
      />
   );
}


//患者管理--患者详情页
class ManageMyDetail extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         viewportWidth: 0,
         viewportHeight: 0,
         direction: '',
         value: 0,
         index: 0,
         showModal: false,
         showsModal: false,
         patientId: 0,
         bangding: '未绑定',

         bindBoxVisible: false,   // 弹框
         bindBoxSelect: [],  // 药盒测试数据
         bindSelectValue: '',   // 默认药盒
         everyDayBoxTime: '',   // 每日提醒默认时间
         everyDayBoxTime2: '',   // 每日提醒默认时间2
         everyDayBoxTime3: '',   // 每日提醒默认时间3
         medicalCareBoxTime: '',   // 医疗停药默认时间
         recoveryBoxTime: '',   // 恢复服药默认时间
         bindBoxRadio: '',   // 单选

         editBoxVisible: false,

         outHospitalVisible: false,
         outHospitalTime: '',

         telphone: '',
         address: '',
         modifyVisible: false,

      };
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeIndex = this.handleChangeIndex.bind(this);
      this.handleNavBack = this.handleNavBack.bind(this);
      this.handleMedicineClick = this.handleMedicineClick.bind(this);
      this.handleSputumClick = this.handleSputumClick.bind(this);
      this.tryingEventClick = this.tryingEventClick.bind(this);
      this.bindSelectEventChange = this.bindSelectEventChange.bind(this);  // 下拉框选中事件
      this.everyDayOnchange = this.everyDayOnchange.bind(this);
      this.everyDayOnchange2 = this.everyDayOnchange2.bind(this);
      this.everyDayOnchange3 = this.everyDayOnchange3.bind(this);
      this.medicalCareOnchange = this.medicalCareOnchange.bind(this);
      this.recoveryOnchange = this.recoveryOnchange.bind(this);
      this.handleSubmitOk = this.handleSubmitOk.bind(this);    // 弹框提交按钮
      this.bindHandleInputChange = this.bindHandleInputChange.bind(this);  // 单选框事件
      this.handleEventCancel = this.handleEventCancel.bind(this);  // 弹框取消事件
      this.showMore = this.showMore.bind(this);

      this.outHospitalTimeOnchange = this.outHospitalTimeOnchange.bind(this);
      this.handleOutHospitalClick = this.handleOutHospitalClick.bind(this);
      this.handleOutHospitalOk = this.handleOutHospitalOk.bind(this);

      this.tryingEditClick = this.tryingEditClick.bind(this);
      this.handleEditOk = this.handleEditOk.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
   }

   componentDidMount() {
      this.state.patientId = this.props.location.state.patientId;
      this.props.getPatientDetail(this.state.patientId);

   }

   componentWillUnmount() {
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.bandingCode !== this.props.bandingCode) {
         if (nextProps.bandingCode.code == 200) {
            Toast.show("绑定成功！");
            this.setState({ bindBoxVisible: false })
            this.props.getPatientDetail(this.state.patientId);
         }
         if (nextProps.bandingCode.code == 201) {
            Toast.show(nextProps.bandingCode.msg + ',请联系管理员！');
            this.setState({ bindBoxVisible: false });
         }
      }
      if (nextProps.untyingCode !== this.props.untyingCode) {
         if (nextProps.untyingCode.code == 200) {
            Toast.show("解绑成功！");
            this.props.getPatientDetail(this.state.patientId);
         }
         if (nextProps.untyingCode.code == 201) {
            Toast.show(nextProps.bandingCode.msg + ',请联系管理员！');
         }
      }
      if (nextProps.retCode === 403) {
         window.location.href = "/doctor"
      }

      if (nextProps.outHospitalRet !== this.props.outHospitalRet) {
         if (nextProps.outHospitalRet.code == 200) {
            Toast.show("提交成功！");
            this.setState({
               outHospitalVisible: false
            })
            this.handleNavBack()
         }
         if (nextProps.outHospitalRet.code == 201) {
            Toast.show("提交失败！" + nextProps.outHospitalRet.msg);
         }
      }
   }

   //点击跳转患者服药记录
   handleMedicineClick() {
      const path = `${this.props.match.path}/Medicine`;
      this.props.history.push(path, { "patientId": this.state.patientId });
   }

   //点击跳转患者复诊查痰记录
   handleSputumClick() {
      const path = `${this.props.match.path}/Sputum`;
      this.props.history.push(path, { "patientId": this.state.patientId });
   }

   // 绑定 、 解绑 事件
   tryingEventClick(event) {
      const _this = this;
      if (_this.props.patientDetail.boxId && _this.props.patientDetail.boxId !== '') {
         let paramsUntying = _this.props.patientDetail.boxId;     // 药盒ID
         confirm({
            title: '提示',
            icon: ' ',
            content: '点击确认进行解绑！',
            okText: '确认',
            cancelText: '取消',
            onOk() {
               _this.props.untyingChange(paramsUntying);   // 解绑
               if (_this.props.untyingCode.code == 200) {
                  Toast.show("解绑成功！")
                  _this.setState({ bangding: '未绑定' })
               }
               if (_this.props.untyingCode.code == 201) {
                  Toast.show(_this.props.bandingCode.msg);
               }
            },
            onCancel() { },
         });
      } else {
         _this.props.medicalKitChange();   // 药盒列表
         _this.setState({ bindBoxVisible: true });
      }
   }

   handleOutHospitalClick() {
      this.setState({
         outHospitalVisible: true
      })
   }

   handleOutHospitalOk() {
      if (this.state.outHospitalTime.length < 4) {
         Toast.show("请填写出院时间！");
         return;
      }
      let params = {
         patientId: this.state.patientId,
         outHospitalTime: this.state.outHospitalTime,
         date: this.state.outHospitalTime,
      }
      console.log(params)
      this.props.outHospital(params)
   }

   // 弹框确认事件
   handleSubmitOk() {
      let boxParams = {};
      if (this.state.bindSelectValue === "" && this.state.manualBox == null) {
         Toast.show("请选择药盒！");
         return;
      }
      if (this.state.everyDayBoxTime === "") {
         Toast.show("请选择每日提醒！");
         return;
      }
      boxParams.box = this.state.bindSelectValue;
      boxParams.manualBox = this.state.manualBox;
      boxParams.patientId = this.state.patientId;
      boxParams.remindTime = this.state.everyDayBoxTime;
      boxParams.remindTime2 = this.state.everyDayBoxTime2;
      boxParams.remindTime3 = this.state.everyDayBoxTime3;
      if (this.state.medicalCareBoxTime !== "") {
         boxParams.restoreMedicine = this.state.medicalCareBoxTime;
      }
      if (this.state.recoveryBoxTime !== "") {
         boxParams.stopMedicine = this.state.recoveryBoxTime;
      }
      if (this.state.bindBoxRadio !== "") {
         boxParams.stopReason = this.state.bindBoxRadio;
      }
      this.props.bindUnbindChange(boxParams);
      if (this.props.bandingCode.code == 201) {
         Toast.show(this.props.bandingCode.msg);
      }
      if (this.props.bandingCode.code == 200) {
         Toast.show("绑定成功！");
         this.setState({ bangding: '已绑定' })
         this.setState({ bindBoxVisible: false });
      }
   }

   // 弹框取消事件
   handleEventCancel() {
      this.setState({
         bindSelectValue: '',
         everyDayBoxTime: '',
         medicalCareBoxTime: '',
         recoveryBoxTime: '',
         bindBoxRadio: '',
         telphone: '',
         address: '',
      })

      this.setState({
         bindBoxVisible: false,
         outHospitalVisible: false,
         modifyVisible: false,
      })
   }

   // 下拉框选中事件
   bindSelectEventChange(event) {
      this.setState({ bindSelectValue: event });
   }

   // 每日提醒时间框事件
   everyDayOnchange(event, value) {
      this.setState({ everyDayBoxTime: value });
      console.log(this.state)
   }
   // 每日提醒时间框事件2
   everyDayOnchange2(event, value) {
      this.setState({ everyDayBoxTime2: value });
   }
   // 每日提醒时间框事件3
   everyDayOnchange3(event, value) {
      this.setState({ everyDayBoxTime3: value });
   }

   // 医疗停药时间框事件
   medicalCareOnchange(event, value) {
      this.setState({ medicalCareBoxTime: value });
   }

   // 恢复服药时间框事件
   recoveryOnchange(event, value) {
      this.setState({ recoveryBoxTime: value });
   }

   // 出院时间框事件
   outHospitalTimeOnchange(event, value) {
      this.setState({ outHospitalTime: value });
   }

   // 单选框事件
   bindHandleInputChange(event) {
      this.setState({ bindBoxRadio: event.target.value })
   }

   handleChange(newValue) {
      this.setState({ value: newValue });
   };

   handleChangeIndex(index) {
      this.setState({ index: index });
   };


   handleNavBack() {
      this.props.history.goBack();
   }

   showMore() {
      const path = `${this.props.match.path}More`;
      this.props.history.push(path, { "patientId": this.state.patientId });
   }

   handleInputChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });

   }
   tryingEditClick() {
      this.setState({
         modifyVisible: true,
      })
   }

   handleEditOk() {
      const url = Api.getRootUrl() + '/api/patient/patientEditSave'
      const params = {
         patientId: this.state.patientId,
         telphone: this.state.telphone,
         address: this.state.address,
         userId: localStorage.getItem("userId"),
         token: localStorage.getItem("token")
      }
      let that = this;
      axios.post(url, params).then(function (rsp) {
         console.log(rsp.data.code)
         if (rsp.data.code == 200) {
            Toast.info("提交成功!")
            that.props.history.goBack();
         } else {
            Toast.info("提交失败!" + rsp.data.msg)
         }
      }).catch(function (err) {
         console.log(err)
         Toast.info("提交失败，请稍后再试！")
      })
   }


   render() {
      //get react-material css styles
      const { classes, patientDetail, yaoheList } = this.props;

      return (
         //总的大盒子
         <Switch>
            <Route path={`${this.props.match.path}/Medicine`} component={ManageRecord} />
            <Route path={`${this.props.match.path}/Sputum`} component={CaseVisitRecord} />


            <div className={classes.root}>
               {/* 标题栏 箭头 通知详情 */}
               <Grid item xs={12} className={classes.Patient}>
                  <img src={returnImg} className={classes.jiantou} onClick={this.handleNavBack} />
                  <Grid item justify="center" className={classes.headers} xs={10}>
                     <h4 className={classes.title}>患者详情</h4>
                  </Grid>
                  <Grid item justify="center" className={classes.hideMe} xs={2}>
                     <Typography h6 className={classes.title} onClick={this.showMore} >更多</Typography>
                  </Grid>
               </Grid>

               {/* 头像 */}
               <Grid container justify="center" className={classes.headPortrait}>
                  <Avatar src={avatarImg} className={classes.avatarSize} />
               </Grid>

               {/* 介绍 */}
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>姓名：</Typography>
                  <Typography className={classes.fieldValue}>{patientDetail.patientName}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>性别：</Typography>
                  <Typography className={classes.fieldValue}>{gender(patientDetail.sex)}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>年龄：</Typography>
                  <Typography className={classes.fieldValue}>{patientDetail.age}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>电话：</Typography>
                  <Typography className={classes.blueTextValue} onClick={this.tryingEditClick}>{patientDetail.telphone}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               {/*<Grid container justify="space-between">*/}
               {/*<Typography className={classes.fieldLable}>身份证号：</Typography>*/}
               {/*<Typography className={classes.fieldValue}>{patientDetail.patientIdNumber}</Typography>*/}
               {/*</Grid>*/}
               {/*<hr className={classes.hr}></hr>*/}
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>现住址：</Typography>
                  <Typography className={classes.blueTextValue} onClick={this.tryingEditClick}>{patientDetail.patientAddress} </Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>诊断结果：</Typography>
                  <Typography className={classes.fieldValue}>{patientDetail.result}</Typography>
               </Grid>

               <hr className={patientDetail?.weight != null ? classes.hr : classes.hideMe}></hr>
               <Grid container justify="space-between" className={patientDetail?.weight != null ? '' : classes.hideMe}>
                  <Typography className={classes.fieldLable}>体重(KG)：</Typography>
                  <Typography className={classes.fieldValue}>{patientDetail.weight}</Typography>
               </Grid>

               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>状态：</Typography>
                  <Typography className={classes.fieldValue}>{patientDetail.status}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between">
                  <Typography className={classes.fieldLable}>药盒绑定：</Typography>
                  <Typography onClick={this.tryingEventClick}
                     className={classes.blueTextValue}>{(patientDetail.boxId && patientDetail.boxId !== '') ? '已绑定' : '未绑定'}</Typography>
               </Grid>
               <hr className={classes.hr}></hr>


               <Grid container justify="space-between" className={classes.buttonBox}>
                  <Button variant="contained" color="primary" className={patientDetail.treatStatus == 10 ? classes.hideMe : classes.margin}
                     onClick={this.handleMedicineClick}>
                     服药记录
                  </Button>
                  <Button variant="contained" color="primary" className={patientDetail.treatStatus == 10 ? classes.btOutHospital : classes.hideMe}
                     onClick={this.handleOutHospitalClick}>
                     出院
                  </Button>
                  <Button variant="contained" color="primary" className={patientDetail.treatStatus == 10 ? classes.hideMe : classes.margin}
                     onClick={this.handleSputumClick}>
                     就诊查痰记录
                  </Button>
               </Grid>


               <Modal title="药盒绑定"
                  visible={this.state.bindBoxVisible}
                  afterClose={this.handleEventCancel}
                  onCancel={this.handleEventCancel}
                  destroyOnClose={true}
                  forceRender={true}
                  footer={[
                     <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                     <Button key="submit" onClick={this.handleSubmitOk}>提交</Button>,
                  ]}
               >
                  <Grid item xs={10} className={classes.bindContent}>
                     <Grid className={patientDetail.manualBinding == 1?classes.hideMe:classes.bindContentFlex}>
                        <Grid container alignItems="center" item className={classes.bindOptionLeftStyle}>绑定电子药盒：</Grid>
                        <ConfigProvider locale={locale}>
                           <Select defaultValue={this.state.bindSelectValue == '' ? '请选择药盒...' : this.state.bindSelectValue}
                              onChange={this.bindSelectEventChange}
                              className={classes.bindOptionRightStyle}
                           >
                              {yaoheList.map((column, index) => (
                                 <Option value={column.id}>{column.serialNo}</Option>
                              ))}
                           </Select>
                        </ConfigProvider>
                     </Grid>
                     <Grid className={patientDetail.manualBinding != 1?classes.hideMe:classes.bindContentFlex}>
                        <Grid container alignItems="center" item className={classes.bindOptionLeftStyle}>绑定电子药盒：</Grid>
                        <Inputa className={classes.bindOptionRightStyle} name="manualBox" value={this.state.manualBox} onChange={this.handleInputChange} placeholder="请输入药盒序列号" />
                     </Grid>

                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>每日提醒时间：</Grid>
                        <TimePicker className={classes.bindOptionRightStyle}
                           onChange={this.everyDayOnchange}
                           locale={locale}
                           format="HH:mm:ss"
                           placeholder="请选择时间"
                           defaultOpenValue={Moment(new Date())} />
                     </Grid>
                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>每日提醒时间2：</Grid>
                        <TimePicker className={classes.bindOptionRightStyle}
                           onChange={this.everyDayOnchange2}
                           locale={locale}
                           format="HH:mm:ss"
                           placeholder="请选择时间"
                           defaultOpenValue={Moment(new Date())} />
                     </Grid>
                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>每日提醒时间3：</Grid>
                        <TimePicker className={classes.bindOptionRightStyle}
                           onChange={this.everyDayOnchange3}
                           locale={locale}
                           format="HH:mm:ss"
                           placeholder="请选择时间"
                           defaultOpenValue={Moment(new Date())} />
                     </Grid>

                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>医疗停药时间：</Grid>
                        <DatePicker className={classes.bindOptionRightStyle}
                           locale={locale}
                           showTime
                           format="YYYY-MM-DD HH:mm:ss"
                           placeholder="请选择日期"
                           defaultValue={this.state.medicalCareBoxTime}
                           onChange={this.medicalCareOnchange} />
                     </Grid>

                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>恢复服药日期：</Grid>
                        <DatePicker className={classes.bindOptionRightStyle}
                           locale={locale}
                           showTime
                           format="YYYY-MM-DD HH:mm:ss"
                           placeholder="请选择日期"
                           defaultValue={this.state.recoveryBoxTime}
                           onChange={this.recoveryOnchange} />
                     </Grid>
                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>停药原因：</Grid>
                        <RadioGroup className={classes.bindOptionRightStyle} row aria-label="gender"
                           name="customized-radios"
                           onChange={this.bindHandleInputChange}>
                           <FormControlLabel value="1" control={<StyledRadio />}
                              label="不良反应" checked={this.state.bindBoxRadio == '1'} />
                           <FormControlLabel value="2" control={<StyledRadio />}
                              label="住院未进行抗结核治疗" checked={this.state.bindBoxRadio == '2'} />
                           <FormControlLabel value="3" control={<StyledRadio />}
                              label="住院继续抗结核治疗" checked={this.state.bindBoxRadio == '3'} />
                        </RadioGroup>
                     </Grid>

                  </Grid>
               </Modal>




               <Modal title="患者出院"
                  visible={this.state.outHospitalVisible}
                  afterClose={this.handleEventCancel}
                  onCancel={this.handleEventCancel}
                  destroyOnClose={true}
                  forceRender={true}
                  footer={[
                     <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                     <Button key="submit" onClick={this.handleOutHospitalOk}>提交</Button>,
                  ]}
               >
                  <Grid item xs={10} className={classes.bindContent}>

                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>出院日期：</Grid>
                        <DatePicker className={classes.bindOptionRightStyle}
                           locale={locale}
                           showTime
                           format="YYYY-MM-DD"
                           placeholder="请选择日期"
                           onChange={this.outHospitalTimeOnchange} />
                     </Grid>


                  </Grid>
               </Modal>

               <Modal title="修改信息"
                  visible={this.state.modifyVisible}
                  afterClose={this.handleEventCancel}
                  onCancel={this.handleEventCancel}
                  destroyOnClose={true}
                  forceRender={true}
                  footer={[
                     <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                     <Button key="submit" onClick={this.handleEditOk}>提交</Button>,
                  ]}
               >
                  <Grid item xs={10} className={classes.bindContent}>

                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>电话：</Grid>
                        <Input className={classes.bindOptionRightStyle}
                           name="telphone"
                           value={this.state.telphone}
                           onChange={this.handleInputChange} />
                     </Grid>
                     <Grid className={classes.bindContentFlex}>
                        <Grid className={classes.bindOptionLeftStyle}>现住址：</Grid>
                        <Input className={classes.bindOptionRightStyle}
                           name="address"
                           value={this.state.address}
                           onChange={this.handleInputChange} />
                     </Grid>


                  </Grid>
               </Modal>

            </div>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      retCode: state.patientManageReducer.retCode,
      serverInfo: state.serverInfo,
      patientDetail: state.patientManageReducer.patientDetail,
      bandingCode: state.patientManageReducer.bandingCode,
      untyingCode: state.patientManageReducer.untyingCode,
      yaoheList: state.patientManageReducer.yaoheList,
      outHospitalRet: state.customReducer.outHospitalRet,
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      getPatientDetail: (patientId) => {
         dispatch(queryPatientDetail(patientId))
      },
      // 解绑接口
      untyingChange: (params) => {
         dispatch(untyingEvent(params))
      },
      // 绑定接口
      bindUnbindChange: (params) => {
         dispatch(bindUnbindEvent(params))
      },
      // 药盒列表
      medicalKitChange: (params) => {
         dispatch(medicalKitListEvent(params))
      },
      outHospital: (params) => {
         dispatch(patientOutHospital(params))
      },
   }
}
const styles = theme => ({
   bindOptionLeftStyle: {
      width: '35% !important',
      paddingLeft: '5%'
   },
   bindOptionRightStyle: {
      width: '60%'
   },
   bindContentFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2% !important'
   },
   bindModalContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   bindContent: {
      backgroundColor: '#FFFFFF'
   },
   root: {
      flexGrow: 1,
      overflow: 'auto',
      backgroundColor: '#FFFFFF',
      height: '100vh',
   },

   spacemargin: {
      margin: theme.spacing(2),

   },

   buttonDiv: {
      height: '18vw',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
   },

   buttons: {
      width: '30vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      marginTop: '1vw',
      height: '8vw',
      fontSize: '4vw',
      padding: 0,

   },

   titles: {
      marginLeft: '5vw',

   },

   warm: {
      fontSize: '4vw',
      color: '#ffffff',
      marginLeft: '2vw',
      lineHeight: '5vh',
      marginTop: 0,
   },

   Patient: {
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '14vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },

   phoneDiv: {
      height: '6vw',
      marginRight: '1vw',
   },

   card: {
      maxWidth: '100vw'
   },

   media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
   },

   expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
         duration: theme.transitions.duration.shortest,
      }),
   },

   expandOpen: {
      transform: 'rotate(180deg)',
   },

   margin: {
      height: '12vw',
      width: '38vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      fontSize: '4vw',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },
   btOutHospital: {
      height: '12vw',
      width: '95vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      fontSize: '4vw',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },

   headPortrait: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
   },

   jiantou: {
      height: '4vw',
      float: 'left',
      marginLeft: '4vw'
   },

   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '85vw',
      padding: '0'
   },

   more: {
      float: 'right',
      marginRight: '4vw'
   },

   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
   },

   avatar: {
      width: '22vw',
      height: '22vw',
      maxWidth: '22vw',
      maxHeight: '22vw'
   },

   information: {
      marginTop: '4.5vw',
      width: '100%',

   },

   cardjiuzhen: {
      width: '90vw',
      height: '32vh',
      marginLeft: '5vw',
   },

   cardbutton: {
      width: '45vw',
      display: 'flex',
      flexDirection: 'row',
      marginbottom: '5vw',
      justifyContent: 'space-between',
   },

   span: {
      float: 'right',
      marginRight: '3vw',
      color: '#999999'
   },

   title: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      padding: '0',
      lineHeight: '0vw'
   },

   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',
      margin: '2vw',
   },

   textshuru: {
      width: '91.5vw',
      marginLeft: '3vw',
      height: '20vw',
      backgroundColor: 'rgba(36,225,221,0.05)',
      border: '0.1vw solid #e6e6e6',
      paddingLeft: '1.5vw',
      paddingTop: '1vw',
   },

   buttonBox: {
      width: '100vw',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
   },

   avatarSize: {
      width: '22vw',
      height: '22vw'
   },

   fieldLable: {
      marginLeft: theme.spacing(2),
      lineHeight: '10vw',
      height: '10vw',
      fontSize: '4.2vw',
      color: '#333333'
   },

   fieldValueDiv: {
      lineHeight: '10vw',
      width: '73vw',
      wordWrap: 'break-word',
      textAlign: 'right',
      marginRight: theme.spacing(2),
      color: '#999999'
   },

   fieldValue: {
      color: '#999999',
      marginRight: theme.spacing(2),
      lineHeight: '10vw',
      height: '10vw',
   },

   blueTextValue: {
      color: '#0000FF',
      marginRight: theme.spacing(2),
      lineHeight: '10vw',
      height: '10vw',
   },

   hideMe: {
      display: 'none'
   }

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageMyDetail));
