import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { postFirstFollowUp, queryFollowUpBasicInfo } from '../../redux/actions/patient_manage_action';
import { Route, Switch } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import SignaturePad from '../../lib/signature/index';
import SignPadPlus from '../../lib/signPadPlus';
import Toast from "antd-mobile/lib/toast";
import Moment from 'moment';
//弹出框

import { Upload, message, Icon } from 'antd';

// 引入antd 时间组件
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import FollowUpVisitPaper5 from './FollowUpVisitPaper5'
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
let jhjCheckResultIdDefaultValue;
let sputumCheckResultIdDefaultValue;
let patientTypeIdDefaultValue;
let patientTreatTypeIdDefaultValue;


const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//首次随访
class FollowUpVisitPaper3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientTreatTypeId: 0,
            patientTypeId: 0,
            jhjCheckResultId: 0,
            sputumCheckResultId: 0,
            fileList: [{ uid: '-1' }],
            picList: [],
            firstFollowUpTime: Moment(new Date(), 'YYYY年MM月DD日'), //首次访视时间
            userId: localStorage.getItem("userId"),
            pic: '',
            patientId: 0,
            noticeId: 0,
            followupType: 0,
            followupTypeOther: '',
            issue: '',
            issueOthers: '',
            useMethod: 0,
            drugType: 0,
            directType: 0,
            aloneLive: 0,
            cloud: 0,
            smoke: 0,
            drink: 0,
            smokeDay: 0,
            drinkDay: 0,
            nextSmoke: 0,
            nextDrink: 0,
            drugPlace: '',
            drugTimeStr: Moment(new Date()),
            nextDrugTimeStr: Moment(new Date()).add(30, 'days').format('YYYY年MM月DD日'),
            nextFollowUpTime: null,
            drugFrequency: '',
            cardSkill: 1,
            drugSkill: 1,
            issueSkill: 1,
            irregularHarm: 1,
            dealReact: 1,
            review: 1,
            takingMedicine: 1,
            livingHabit: 1,
            contactCheck: 1,

            nextTimeYear: '',
            nextTimeMonth: '',
            nextTimeDay: '',

            actualTimeYear: '',
            actualTimeMonth: '',
            actualTimeDay: '',

            drugTimeYear: '',
            drugTimeMonth: '',
            drugTimeDay: '',

            nextDrugTimeYear: '',
            nextDrugTimeMonth: '',
            nextDrugTimeDay: '',

            manageDoctor: '',

            resistMethod: '',
            doctorId: '',
            doctorName: '',

            open: false,
            vertical: 'top',
            horizontal: 'center',
            warnMsg: '',
            firstArrayCheck: [],
            drugTypeArrayCheck: [],

            hideOther: 1,
            suggestion: '',

            sign_town: '',
            sign_village: '',
            pics: '',
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.pickerOnChange = this.pickerOnChange.bind(this);
        this.drugPickerOnChange = this.drugPickerOnChange.bind(this);
        this.nextDrugPickerOnChange = this.nextDrugPickerOnChange.bind(this);
        this.nextPickerOnChange = this.nextPickerOnChange.bind(this);

        this.checkoutEventFirst = this.checkoutEventFirst.bind(this);
        this.checkoutDrugType = this.checkoutDrugType.bind(this)

    }

    componentDidMount() {
        this.state.patientName = this.props.location.state.patientName
        this.state.patientId = this.props.location.state.patientId
        this.props.queryFollowUpBasicInfo(this.state.patientId);

        let firstFollowUpTime = Moment(new Date()).format('YYYY年MM月DD日');
        this.state.actualTimeYear = firstFollowUpTime.split("年")[0]
        this.state.actualTimeMonth = firstFollowUpTime.split("年")[1].split("月")[0]
        this.state.actualTimeDay = firstFollowUpTime.split("月")[1].split("日")[0]

        this.state.drugTimeYear = firstFollowUpTime.split("年")[0]
        this.state.drugTimeMonth = firstFollowUpTime.split("年")[1].split("月")[0]
        this.state.drugTimeDay = firstFollowUpTime.split("月")[1].split("日")[0]


        let nextDrugTime = Moment(new Date()).add(30, 'days').format('YYYY年MM月DD日')
        this.state.nextDrugTimeYear = nextDrugTime.split("年")[0]
        this.state.nextDrugTimeMonth = nextDrugTime.split("年")[1].split("月")[0]
        this.state.nextDrugTimeDay = nextDrugTime.split("月")[1].split("日")[0]

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.createFirstFollowUpRet !== this.props.createFirstFollowUpRet) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.createFirstFollowUpRet.code == 200) {
                Toast.show("首次随访成功")

                //const path = `${this.props.match.path}/paper5`;
                //首次随访跳转到管理反馈通知单
                //this.props.history.push(path,{"followupId":this.props.followupId});

                const path = '/doctorIndex/manage/CloseScreening';   // 跳转到密接单
                var params = {
                    patientId: this.state.patientId,
                    followUpId: 0,
                }
                this.props.history.push(path, params);

            }
            if (nextProps.createFirstFollowUpRet.code == 201) {
                Toast.show("首次随访失败！" + nextProps.createFirstFollowUpRet.msg)
            }
        }
        if (nextProps.followUpBasic !== this.props.followUpBasic) {
            if (nextProps.followUpBasic !== undefined) {
                if (nextProps.followUpBasic.drugPlace !== undefined) {
                    this.setState({
                        drugPlace: nextProps.followUpBasic.drugPlace
                    })
                }
                if (nextProps.followUpBasic.showDirectAI !== undefined) {
                    this.setState({
                        showDirectAI: nextProps.followUpBasic.showDirectAI
                    })
                }
            }
        }
    }

    // picker  onchange事件
    pickerOnChange(date, dateString) {
        this.setState({
            firstFollowUpTime: dateString,
            actualTimeYear: dateString.split("年")[0],
            actualTimeMonth: dateString.split("年")[1].split("月")[0],
            actualTimeDay: dateString.split("月")[1].split("日")[0],
        })
    }

    //取药时间change
    drugPickerOnChange(date, dateString) {
        this.setState({
            drugTimeStr: dateString,
            drugTimeYear: dateString.split("年")[0],
            drugTimeMonth: dateString.split("年")[1].split("月")[0],
            drugTimeDay: dateString.split("月")[1].split("日")[0],

        })

    }

    //下次取药时间 change
    nextDrugPickerOnChange(date, dateString) {
        this.setState({
            nextDrugTimeStr: dateString,
            nextDrugTimeYear: dateString.split("年")[0],
            nextDrugTimeMonth: dateString.split("年")[1].split("月")[0],
            nextDrugTimeDay: dateString.split("月")[1].split("日")[0],
        })
    }

    //下次随访时间
    nextPickerOnChange(date, dateString) {
        this.setState({
            nextFollowUpTime: dateString,
            nextTimeYear: dateString.split("年")[0],
            nextTimeMonth: dateString.split("年")[1].split("月")[0],
            nextTimeDay: dateString.split("月")[1].split("日")[0],
        })
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

    }

    //关闭snackbar提示
    handleClose = () => {
        this.setState({ open: false });
    };

    checkoutEventFirst(event) {
        if (event.target.checked == true) {
            this.state.firstArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.firstArrayCheck.indexOf(event.target.value);
            this.state.firstArrayCheck.splice(numberInit, 1);
        }

        if (this.state.firstArrayCheck.indexOf("11") >= 0) {
            this.setState({
                hideOther: 0
            })
        } else {
            this.setState({
                hideOther: 1
            })
        }
    }
    checkoutDrugType(event) {
        console.log(event)
        console.log(this.state.drugTypeArrayCheck)
        if (event.target.checked == true) {
            this.state.drugTypeArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.drugTypeArrayCheck.indexOf(event.target.value);
            this.state.drugTypeArrayCheck.splice(numberInit, 1);
        }
        this.setState({
            drugTypeStr: this.state.drugTypeArrayCheck.join(",")
        })
    }


    //点击提交按钮
    handleSubmit() {
        var signPad = this.refs.patientSign;
        var signData = signPad.toDataURL();

        var signPadTown = this.refs.townSign;
        var signDataTown = signPadTown.toDataURL();

        var signPadVillage = this.refs.villageSign;
        var signDataVillage = signPadVillage.toDataURL();

        if (this.state.directType === 0) {
            Toast.show("请选择督导人员！");
            return;
        }
        if (this.state.nextTimeMonth === 0) {
            Toast.show("请选择随访方式！");
            return;
        }
        if (this.state.firstArrayCheck.length == 0) {
            Toast.show("请选择症状及体征！");
            return;
        }
        if (this.state.suggestion === "") {
            Toast.show("请输入处理意见！");
            return;
        }

        if (this.state.nextFollowUpTime === "") {
            Toast.show("请选择下次随访日期！");
            return;
        }

        //将数据打包成json,然后调用dispatch将数据发送出去
        const followUpInfo = {
            patientTreatTypeId: this.state.patientTreatTypeId === 0 ? this.props.followUpBasic.patientTreatTypeId : this.state.patientTreatTypeId,
            patientTypeId: this.state.patientTypeId === 0 ? this.props.followUpBasic.patientTypeId : this.state.patientTypeId,
            jhjCheckResultId: this.state.jhjCheckResultId === 0 ? this.props.followUpBasic.jhjCheckResultId : this.state.jhjCheckResultId,
            sputumCheckResultId: this.state.sputumCheckResultId === 0 ? this.props.followUpBasic.sputumCheckResultId : this.state.sputumCheckResultId,
            patientNoticeId: this.props.followUpBasic.patientNoticeId,
            userId: localStorage.getItem("userId"),
            sign: signData,
            signTown: signDataTown,
            signVillage: signDataVillage,
            patientId: this.state.patientId,
            noticeId: this.state.noticeId,
            followupType: this.state.followupType, //随访地点
            followupTypeOther: this.state.followupTypeOther,
            issue: this.state.firstArrayCheck.join(","), //症状及体征
            issueOthers: this.state.issueOthers, //症状及体征
            useMethod: this.state.useMethod, //用法
            //drugType: this.state.drugType, //药品剂型
            drugTypeStr: this.state.drugTypeArrayCheck.join(","),
            directType: this.state.directType,//督导人员
            aloneLive: this.state.aloneLive,//单独居住
            cloud: this.state.cloud, //通风环境
            smoke: this.state.smoke,// 是否抽烟
            drink: this.state.drink,//饮酒
            smokeDay: this.state.smokeDay,
            drinkDay: this.state.drinkDay,
            nextSmoke: this.state.nextSmoke,//抽烟下次目标
            nextDrink: this.state.nextDrink,//喝酒下次目标
            drugPlace: this.state.drugPlace,//取药地点
            drugFrequency: this.state.drugFrequency,//取药频次
            cardSkill: this.state.cardSkill,//服药记录卡填写；1
            drugSkill: this.state.drugSkill,//服药方法及药品保存
            issueSkill: this.state.issueSkill,//肺结核治疗疗程
            irregularHarm: this.state.irregularHarm,//不规律服药危害
            dealReact: this.state.dealReact,//不良反应；1
            review: this.state.review,//复诊查痰
            takingMedicine: this.state.takingMedicine,//外出期间服药
            livingHabit: this.state.livingHabit,//生活习惯
            contactCheck: this.state.contactCheck,//密切接触这检查
            nextTimeYear: this.state.nextTimeYear,//下次随访年
            nextTimeMonth: this.state.nextTimeMonth,//下次随访月
            nextTimeDay: this.state.nextTimeDay,//下次随访日
            actualTimeYear: this.state.actualTimeYear,//实际随访年
            actualTimeMonth: this.state.actualTimeMonth,//实际随访月
            actualTimeDay: this.state.actualTimeDay,//实际随访日

            drugTimeYear: this.state.drugTimeYear,//取药时间年
            drugTimeMonth: this.state.drugTimeMonth,//取药时间月
            drugTimeDay: this.state.drugTimeDay,//取药时间日

            suggestion: this.state.suggestion,

            nextDrugTimeYear: this.state.nextDrugTimeYear,//下次取药时间年
            nextDrugTimeMonth: this.state.nextDrugTimeMonth,//下次取药时间月
            nextDrugTimeDay: this.state.nextDrugTimeDay,//下次取药时间日

            doctorId: this.state.doctorId,
            doctorName: this.state.doctorName,
            attachment: this.state.pics,
            facePhoto: this.state.photos?this.state.photos.join(","):''
        };
        //提交首次随访
        this.props.addFirstFollowUp(followUpInfo);

        /*
        const path = '/doctorIndex/manage/PatientFeedback';   // 跳转到反馈单
        var params = {
          findPerson: 1,
          patientId: this.props.location.state.patientId,
          followUpId:this.props.location.state.followUpId,
          patientNoticeId: this.props.followUpBasic.patientNoticeId
        }
       this.props.history.push(path, params);
       */
    }

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes, followUpBasic } = this.props;
        if (!followUpBasic.nextFollowTime) { return null }
        const nextFollowTime = Moment(followUpBasic.nextFollowTime).format("YYYY年MM月DD日");

        if (this.state.nextTimeYear === "") {
            this.state.nextTimeYear = nextFollowTime.split("年")[0]
            this.state.nextTimeMonth = nextFollowTime.split("年")[1].split("月")[0]
            this.state.nextTimeDay = nextFollowTime.split("月")[1].split("日")[0]
        }

        let followupTypeOtherCtl = null
        if (followUpBasic.showFollowupOther === 1) {
            followupTypeOtherCtl = <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />} label="其他" />
        }



        var that = this;
        //let pics = '';
        //上传
        const uploadFile = {
            name: 'file',
            action: '/api/uploadFile',
            multiple: true,
            beforeUpload: (file) => {
                const isPNG = file.type === 'image/png';
                const isJPG = file.type === 'image/jpeg';
                if (!isPNG && !isJPG) {
                  message.error(`只支持PNG和JPG格式！`);
                  return false;
                }
                return isPNG || isJPG || Upload.LIST_IGNORE;
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                    if (info.fileList.length === 1) {
                        info.fileList.map((item, index) => {
                            that.state.pics += item.response.data
                        })
                    } else {
                        info.fileList.map((item, index) => {
                            that.state.pics += item.response.data + ","
                        })
                    }
                    //that.setState({
                    //    pic: pics
                    //})

                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败.`);
                }
                return that.state.pics;
            }
        };

        let photos = '';
        let photoList = new Array();

        //上传
        const uploadPhoto = {
            name: 'file',
            action: '/api/uploadPhoto',
            multiple: true,
            onChange(info) {
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                    (that.state.photos || []).map((item, index) => {
                        photoList.push(item)
                    })
                    that.setState({
                        photos: photoList
                    })
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败.`);
                }
                return photos;
            }
        };



        if (this.state.jhjCheckResultId != 0) {
            jhjCheckResultIdDefaultValue = this.state.sputumCheckResultId
        } else {
            jhjCheckResultIdDefaultValue = followUpBasic.sputumCheckResultId
        }

        if (this.state.sputumCheckResultId != 0) {
            sputumCheckResultIdDefaultValue = this.state.sputumCheckResultId
        } else {
            sputumCheckResultIdDefaultValue = followUpBasic.sputumCheckResultId
        }

        if (this.state.patientTypeId != 0) {
            patientTypeIdDefaultValue = this.state.patientTypeId
        } else {
            patientTypeIdDefaultValue = followUpBasic.patientTypeId
        }

        if (this.state.patientTreatTypeId != 0) {
            patientTreatTypeIdDefaultValue = this.state.patientTreatTypeId
        } else {
            patientTreatTypeIdDefaultValue = followUpBasic.patientTreatTypeId
        }


        return (
            <Switch>
                <Route path={`${this.props.match.path}/paper5`} component={FollowUpVisitPaper5} />
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者首次入户访视记录表</h5></Grid>
                    </Grid>
                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Typography className={classes.titleMain} h5 gutterBottom>
                                肺结核患者首次入户访视记录表
                            </Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    姓名：<span className={classes.title}>{this.state.patientName}</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    登记号：<span className={classes.title}>{followUpBasic.patientNumber}</span>
                                </Typography>
                            </Grid>

                        </Grid>
                        <hr className={followUpBasic.hideFollowupTime === 1 ? classes.hideMe : classes.hr}></hr>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2} className={followUpBasic.hideFollowupTime === 1 ? classes.hideMe : ""}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    <Grid>首次访视时间：</Grid>
                                    <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                        allowClear={false} className="date-picker-border" defaultValue={Moment(new Date())}
                                        onChange={this.pickerOnChange} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    访视地点：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="followupType" value={this.state.followupType} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="门诊" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="家庭" />
                                {followupTypeOtherCtl}
                            </RadioGroup>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0} className={this.state.followupType === "4" ? "" : classes.hideMe}>
                            <Grid item>
                                <Typography className={classes.titleBolds} gutterBottom>
                                    其他：<Input name="followupTypeOther" value={this.state.followupTypeOther} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid></Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    诊断病症：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="patientTypeId" >
                                <BeautyFormControlLabel className={classes.OptionDiv} value="5" checked={patientTypeIdDefaultValue == '5'} control={<StyledRadio />} label="肺结核" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="10" checked={patientTypeIdDefaultValue == '10'} control={<StyledRadio />} label="结核性胸膜炎" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="15" checked={patientTypeIdDefaultValue == '15'} control={<StyledRadio />} label="肺结核+结核性胸膜炎" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    患者类型：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="patientTreatTypeId">
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={patientTreatTypeIdDefaultValue == '1'} control={<StyledRadio />} label="初治" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={patientTreatTypeIdDefaultValue == '2'} control={<StyledRadio />} label="复治" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} className={followUpBasic.jhjDiagnosisResult === undefined ? classes.hideMe : ""}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    诊断结果：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="jhjDiagnosisResult">
                                <BeautyFormControlLabel className={classes.OptionDiv} value={followUpBasic.jhjDiagnosisResult} checked="true" control={<StyledRadio />} label={followUpBasic.jhjDiagnosisResultStr} />
                            </RadioGroup>
                        </Grid>
                        <hr className={followUpBasic.jhjDiagnosisResult === undefined ? classes.hideMe : classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    痰菌情况：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="sputumCheckResultId">
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={followUpBasic.sputumCheckResultId === 1} control={<StyledRadio />} label="阳性" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={followUpBasic.sputumCheckResultId === 2} control={<StyledRadio />} label="阴性" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={followUpBasic.sputumCheckResultId === 3} control={<StyledRadio />} label="未查痰" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    耐药情况：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="jhjCheckResultId" >
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={followUpBasic.jhjCheckResultId === 1} control={<StyledRadio />} label="耐药" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={followUpBasic.jhjCheckResultId === 2} control={<StyledRadio />} label="非耐药" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={followUpBasic.jhjCheckResultId === 3} control={<StyledRadio />} label="未检测" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    症状及体征：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    <RadioGroup row name="issue" value={this.state.issue} onChange={this.handleInputChange}>

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="没有症状"
                                            control={<GreenCheckbox value="0" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="咳嗽咳痰"
                                            control={<GreenCheckbox value="1" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="低热盗汗"
                                            control={<GreenCheckbox value="2" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="咳血或血痰"
                                            control={<GreenCheckbox value="3" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="胸痛消瘦"
                                            control={<GreenCheckbox value="4" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="恶心纳差"
                                            control={<GreenCheckbox value="5" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="关节疼痛"
                                            control={<GreenCheckbox value="6" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="头痛失眠"
                                            control={<GreenCheckbox value="7" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="视物模糊"
                                            control={<GreenCheckbox value="8" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="皮肤瘙痒、皮疹"
                                            control={<GreenCheckbox value="9" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="耳鸣听力下降"
                                            control={<GreenCheckbox value="10" color="primary" />} />

                                        <BeautyFormControlLabel onChange={this.checkoutEventFirst} label="其他"
                                            control={<GreenCheckbox value="11" color="primary" />} />


                                        {/*<BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="没有症状"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="咳嗽咳痰"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="低热盗汗"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="咳血或血痰"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio/>} label="胸痛消瘦"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="6" control={<StyledRadio/>} label="恶心纳差"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="7" control={<StyledRadio/>} label="关节疼痛"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="8" control={<StyledRadio/>} label="头痛失眠"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="9" control={<StyledRadio/>} label="视物模糊"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="10" control={<StyledRadio/>} label="皮肤瘙痒、皮疹"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="11" control={<StyledRadio/>} label="耳鸣听力下降"/>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="12" control={<StyledRadio/>} label="其他"/>*/}
                                    </RadioGroup>
                                </Typography>
                            </Grid>
                            <Grid item className={this.state.hideOther !== 1 ? "" : classes.hideMe}>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    其他：<br />
                                    <Input name="issueOthers" value={this.state.issueOthers} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>

                        <Grid container justify="space-between" direction="column" wrap="nowrap" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    用药情况：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    化疗方案：<span className={classes.span}>{followUpBasic.resistMethod}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    用 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;法：
                                </Typography>
                            </Grid>

                            <RadioGroup row name="useMethod" value={this.state.useMethod} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="每日" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="间歇" />
                            </RadioGroup>

                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    药品剂型：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="drugTypeStr" value={this.state.drugTypeStr} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel label="固定剂量复合制剂" checked={this.state.drugTypeArrayCheck?.indexOf("1") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="1" color="primary" />} />
                                <BeautyFormControlLabel label="散装药" checked={this.state.drugTypeArrayCheck?.indexOf("2") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="2" color="primary" />} />
                                <BeautyFormControlLabel label="板式组合药" checked={this.state.drugTypeArrayCheck?.indexOf("3") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="3" color="primary" />} />
                                <BeautyFormControlLabel label="注射剂" checked={this.state.drugTypeArrayCheck?.indexOf("4") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="4" color="primary" />} />
                                {/** 
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="固定剂量复合制剂"/>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="散装药"/>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="板式组合药"/>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="注射剂"/>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="24" control={<StyledRadio/>} label="散装药+注射剂"/>*/}
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    督导人员选择：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="directType" value={this.state.directType} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="医生" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="家属" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="自服药" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />} label="志愿者" />
                                <BeautyFormControlLabel className={this.state.showDirectAI == 1?classes.OptionDiv:classes.hideMe} value="5" control={<StyledRadio />} label="智能工具" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    家庭居住环境评估：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    单独的居室：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="aloneLive" value={this.state.aloneLive} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="否" />
                            </RadioGroup>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    通风情况：
                                </Typography>
                            </Grid>
                            <RadioGroup row name="cloud" value={this.state.cloud} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="良好" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="一般" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="差" />
                            </RadioGroup>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    生活方式评估：
                                </Typography>
                            </Grid> </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    吸烟：目前:<Input name="smoke" value={this.state.smoke} className={classes.input}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }}
                                    /><span className={followUpBasic.showFollowupDay === 1 ? "" : classes.hideMe}>/<Input name="smokeDay" value={this.state.smokeDay} onChange={this.handleInputChange}
                                        className={classes.input} inputProps={{ 'aria-label': 'description' }}
                                    /></span>支/天
                                    <span className={followUpBasic.hideFollowupTime === 1 ? classes.hideMe : ""}>，下次目标:<Input name="nextSmoke" value={this.state.nextSmoke} className={classes.input}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />支/天</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    饮酒：目前:<Input name="drink" value={this.state.drink} className={classes.input}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }}
                                    /><span className={followUpBasic.showFollowupDay === 1 ? "" : classes.hideMe}>/<Input name="drinkDay" value={this.state.drinkDay} onChange={this.handleInputChange}
                                        className={classes.input} inputProps={{ 'aria-label': 'description' }}
                                    /></span>两/天
                                    <span className={followUpBasic.hideFollowupTime === 1 ? classes.hideMe : ""}>，下次目标:<Input name="nextDrink" value={this.state.nextDrink} className={classes.input}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />两/天</span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                    健康教育及培训：
                                </Typography>
                            </Grid></Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBolds} gutterBottom>
                                    首次取药的地点，时间，频次和下次取药时间：
                                </Typography>
                            </Grid></Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBolds} gutterBottom>
                                    地点：<Input name="drugPlace" value={this.state.drugPlace} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid></Grid>
                        <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} gutterBottom>
                                    <Grid>时间：</Grid>
                                    <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={Moment(new Date())} onChange={this.drugPickerOnChange} />
                                </Typography>
                            </Grid></Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldss} color="textSecondary" gutterBottom>
                                    今后取药频次：<Input name="drugFrequency" value={this.state.drugFrequency} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    <Grid>下次取药时间：</Grid>
                                    <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false}
                                        placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={Moment(new Date()).add(30, 'days')} onChange={this.nextDrugPickerOnChange} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    服药记录卡的填写：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    <RadioGroup row defaultValue="1" name="cardSkill" onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                    </RadioGroup>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    服药方法及药品存放：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="drugSkill" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    肺结核治疗疗程：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="issueSkill" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    不规律服药危害：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="irregularHarm" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    服药后不良反应及处理：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="dealReact" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    治疗期间复诊查痰：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="review" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    外出期间如何坚持服药：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="takingMedicine" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    生活习惯及注意事项：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="livingHabit" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                    密切接触者检查：
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="1" name="contactCheck" onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    处理意见：<Input name="suggestion" value={this.state.suggestion} className={classes.inputs}
                                        onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                    <Grid>下次随访时间：</Grid>
                                    <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false}
                                        placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={Moment(followUpBasic.nextFollowTime)} onChange={this.nextPickerOnChange} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                    管理医生：<span className={classes.title}></span>
                                    <Input name="doctorName" value={this.state.doctorName} onChange={this.handleInputChange} className={classes.inputName}
                                        inputProps={{ 'aria-label': 'description' }} />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid className={(followUpBasic.showFollowupDay !== 1 && followUpBasic.hideFollowupTime === 1) ? classes.hideMe : ""} item>
                            <Typography className={classes.photosDIV} color="textSecondary" gutterBottom>
                                上传照片：<Upload {...uploadFile} showUploadList={{ "showDownloadIcon": false }} name="file">
                                    <Button className={classes.buttonsTwo}>
                                        <Icon type="upload" /> 点击上传
                                    </Button>
                                </Upload>
                            </Typography>
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} className={(followUpBasic.showFollowupDay !== 1 && followUpBasic.hideFollowupTime === 1) ? classes.hideMe : ""}>
                            <Grid item>
                                <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                    患者签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={(followUpBasic.showFollowupDay !== 1 && followUpBasic.hideFollowupTime === 1) ? classes.hideMe : ""}>
                            <SignPadPlus ref='patientSign' name="sign" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                    乡/镇签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='townSign' name="signTown" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                    村/社区签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='villageSign' name="signVillage" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                        </Grid>


                        <Grid item>
                            <Typography className={this.state.showFace==1?classes.photosDIV:classes.hideMe} color="textSecondary" gutterBottom>
                                上传人像：<Upload {...uploadPhoto} showUploadList={{ "showDownloadIcon": false }} name="file">
                                    <Button style={{ display: "" }} className={classes.buttonsTwo}>
                                        <Icon type="upload" /> 点击上传
                                    </Button>
                                </Upload>
                                {/*(this.state.pic || []).map((item,index) => {
                                return (
                                            <img style={{display:""}} key={index} justify="flex-start" src={item} onClick={()=>this.clickeImg(item)} className={classes.picSize}></img>
                                        )
                                })
                            */}

                                <ImageList rowHeight={180} className={classes.photoList}>
                                    {(this.state.photos || []).map((item) => (
                                        <ImageListItem key={item}>
                                            <img src={item} alt={item.title} />
                                        </ImageListItem>
                                    ))}
                                </ImageList>

                            </Typography>
                        </Grid>


                        <Grid container justify="center" wrap="nowrap" spacing={2}>
                            <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSubmit}>提&nbsp;&nbsp;&nbsp;交 </Button>
                        </Grid>
                    </Grid>
                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        followUpBasic: state.patientManageReducer.followUpBasicInfo,
        fileUrl: state.patientManageReducer.fileUrl,
        followupId: state.patientManageReducer.firstFollowUpId,
        createFirstFollowUpRet: state.patientManageReducer.createFirstFollowUpRet,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //查询随访基本信息
        queryFollowUpBasicInfo: (patientId) => {
            dispatch(queryFollowUpBasicInfo(patientId))
        },
        //提交日常随访信息
        addFirstFollowUp: followUpInfo => {
            dispatch(postFirstFollowUp(followUpInfo))
        }
    }
}


//css region
const styles = theme => ({
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    /**************** 分割线 **************/
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
    },

    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },

    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    file: {
        display: 'none',
        visibility: 'hidden',
    },

    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        width: '95vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: '10vw',
        fontSize: '4.5vw',
        marginTop: '5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    textField: {
        marginTop: theme.spacing(0),
        width: '90vw',
    },

    input: {
        margin: theme.spacing(0),
        width: '11vw'
    },

    inputs: {
        width: '90vw',
        marginBottom: theme.spacing(2),
    },

    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },

    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },

    span: {
        float: 'right'
    },

    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleButton: {
        marginLeft: theme.spacing(10),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleBoldDiv: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleTime: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleMain: {
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },

    titleBolds: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        color: '#333333'
    },

    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },

    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },

    titleBoldss: {
        marginTop: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333'

    },

    doctor: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    photosDIV: {
        marginBottom: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },
    hideMe: {
        display: 'none'
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper3));
