//患者端 模块声明
export const PATIENT_INDEX='PATIENT_INDEX' // 登录
export const ADD_REMIND = 'ADD_REMIND'
export const ADD_REMIND_SUCCEED = 'ADD_REMIND_SUCCEED'

// 提醒列表
export const REMIND_LIST_ACTION = 'REMIND_LIST_ACTION'
export const REMIND_LIST_ACTION_SUCCEED = 'REMIND_LIST_ACTION_SUCCEED'

// 开关提醒
export const REMIND_ON_CLOSE = 'REMIND_ON_CLOSE'
export const REMIND_ON_CLOSE_SUCCEED = 'REMIND_ON_CLOSE_SUCCEED'

// 删除提醒
export const DELETE_REMOVE_REMIND = 'DELETE_REMOVE_REMIND'
export const DELETE_REMOVE_REMIND_SUCCEED = 'DELETE_REMOVE_REMIND_SUCCEED'


//患者端 -首页
export function patientIndex(patientId) {
    return {
        type:PATIENT_INDEX,
        patientId
    }
}

//患者 添加提醒
export function addRemind(remindItem) {
    return {
        type:ADD_REMIND,
        remindItem
    }
}

//患者 提醒列表
export function remindListAction(userId) {
   return {
      type:REMIND_LIST_ACTION,
      userId
   }
}

//患者 开关提醒
export function remindOnClose(onCloseParams) {
   return {
      type:REMIND_ON_CLOSE,
      onCloseParams
   }
}

//患者 刪除提醒
export function deleteRemoveRemind(remindId) {
   return {
      type:DELETE_REMOVE_REMIND,
      remindId
   }
}


