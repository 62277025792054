//患者端 咨询医生
export const GET_CONSULT_LIST='GET_CONSULT_LIST' //获取聊天历史记录
export const GET_CONSULT_LIST_SUCCEED='GET_CONSULT_LIST_SUCCEED' //获取聊天历史记录成功
export const CONSULT_SEND_MESSAGE='CONSULT_SEND_MESSAGE' //发送消息

// 医生列表
export const DOCTOR_LIST = "DOCTOR_LIST"
export const DOCTOR_LIST_SUCCEED = "DOCTOR_LIST_SUCCEED"

//获取聊天历史记录
export function getConsultList(patientId) {
    return {
        type:GET_CONSULT_LIST,
        patientId
    }
}

//发送消息
export function sendMessage(message) {
    return {
        type:CONSULT_SEND_MESSAGE,
        message
    }
}


// 医生列表
export function doctorList(message) {
   return {
      type:CONSULT_SEND_MESSAGE,
      message
   }
}
