//患者追踪状态
export default function patientTrackStatus(status) {
    if(status==0){
        return "待追踪"
    }else if(status ==3 || status==1){
       return "追踪成功"
    }else if(status==2){
        return "追踪失败"
    }else if (status == 4) {
       return "追踪中"
    }else if (status == 10) {
        return "未到位（失败）"
     }
};
