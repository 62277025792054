//切换患者端和医生端的按钮界面
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


//患者端服药康复页面
class DoctorOrPatientView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
    };
    this.handleDoctor=this.handleDoctor.bind(this);
    this.handlePatient=this.handlePatient.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleDoctor(){
    const path = `/doctor`;
    this.props.history.push(path);
  }

  handlePatient(){
    const path = `/patient`;
    this.props.history.push(path);
}


  render() {
    //get react-material css styles
    const { classes } = this.props;
    return (
      <div className={classes.rootDiv}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
          <Button size="large" onClick={this.handleDoctor}>医生端</Button>
          </Grid>

          <Grid item xs={12}>
          <Button size="large" onClick={this.handlePatient}>患者端</Button>
          </Grid>

      </Grid>
      </div>
    );
  }

}



//css region
const styles = theme => ({
  rootDiv: {
    flexGrow:1,
    flexFlow:'row wrap',
  },
});
//end css region

//here we inject redux and react-material css styles
export default withStyles(styles)(DoctorOrPatientView);