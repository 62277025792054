//新建推介页面
import React from "react";
import {createStyles, makeStyles, withStyles, withTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {analyzeIDCard} from "../../lib/utils";
import {submitIntroduceNewInfo,resetIntroduceNewReply,getRegionList,getReasonList} from "../../redux/actions/index";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import Picker from "antd-mobile/lib/picker";
import "antd-mobile/lib/picker/style/css";
import Toast from "antd-mobile/lib/toast";
//弹出框
import clsx from 'clsx';


const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0
    },
    label: {
        fontSize: "1rem",
        color: "black",
        fontWeight: 395
    }
})(FormControlLabel);
const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#00C9E4",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#00C9E4"
        }
    }
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

// 住址信息选择器
// 如果不是使用 List.Item 作为 children
const CustomChildren = props => (
    <div onClick={props.onClick} style={{paddingLeft: 15}}>
        <div
            className="test"
            style={{display: "flex", height: "13.3vw", lineHeight: "13.3vw"}}
        >
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                {props.children}
            </div>
            <div style={{textAlign: "right", color: "#888"}}>{props.extra}</div>
        </div>
    </div>
);

//患者推介页面
class NewIntroduceWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "", //患者姓名
            idcardNum: "", //身份证号
            age: "", //年龄
            phoneNum: "", //电话号码
            highRegionIds: [],
            lowRegionIds: [],
            villiageData: [],
            reasons: [{"id":9,"reason":"因症就诊"},{"id":10,"reason":"老年人体检"},{"id":11,"reason":"密切接触者筛查"},{"id":12,"reason":"已知肺结核患者未纳入管理"},{"id":27,"reason":"糖尿病患者筛查或体检"}],
            detailAddress: "", //现住址
            gender: "male", //性别
            introduceReason: "", //推介原因包括因症就诊 老年人健康体检 密切接触者筛查 已知肺结核患者未纳入管理
            vertical: 'top',
            horizontal: 'center',
            open: false,
            warnMsg: '',
            doctorId:0,
            isSend:0
        };
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputEventChecked = this.handleInputEventChecked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHighRegionPickerOk = this.handleHighRegionPickerOk.bind(this);
        this.handleLowRegionPickerOk = this.handleLowRegionPickerOk.bind(this);
        this.resetState=this.resetState.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.GetVillageRegionList=this.GetVillageRegionList.bind(this);
    }

    resetState(){
      this.setState({
        name: "", //患者姓名
        idcardNum: "", //身份证号
        age: "", //年龄
        phoneNum: "", //电话号码
        detailAddress: "", //现住址
        gender: "male", //性别
        introduceReason: "", //推介原因包括因症就诊 老年人健康体检 密切接触者筛查 已知肺结核患者未纳入管理
        vertical: 'top',
        horizontal: 'center',
        //open: false,
        warnMsg: '',
        doctorId:0,
        isSend:0
      })
    }

    //通过省市区县的ID获得对应乡镇村的列表
    GetVillageRegionList(regionList,highRegionIds) {
        var villiages=[];
        //直接return一个json,包括两套数据
        let  [provinceId, cityId, countyId]=highRegionIds;
        regionList.forEach(function (val1, index1) {
            if (val1.value === provinceId) {
                val1.children.forEach(function (val2, index2) {
                    if (val2.value === cityId){
                        val2.children.forEach(function (val3, index3) {
                            if (val3.value === countyId) {
                                villiages=Array.from(val3.children)
                            }
                        })
                    }
                })
            }
        })

        //还要把一级的下面如果 children是null,就把自己复制过去
        for(var i=0;i<villiages.length;i++){
            if (villiages[i].children === null) {
               //强制添加下级
               villiages[i].children=[];
               let vill={};
               vill.label=villiages[i].label;
               vill.value=villiages[i].value;
               vill.children=null;
               villiages[i].children.push(vill);
            }
        }

        return villiages;
    }

    componentDidMount() {
         //获取住址信息
        this.props.getRegionList();
        this.props.getReasonList();
    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if(nextProps.regionObject!==this.props.regionObject){
            if(nextProps.regionObject==null){
                return;
            }else if(!nextProps.regionObject.currentRegion){
                return;
            }
            //将props给到state
            this.setState({
                highRegionIds:nextProps.regionObject.currentRegion.slice(0,3),
                lowRegionIds:nextProps.regionObject.currentRegion.slice(3,5),
                villiageData:this.GetVillageRegionList(nextProps.regionObject.allregion,nextProps.regionObject.currentRegion.slice(0,3)),
            })
        }
        if(nextProps.reasonObject!==this.props.reasonObject){
            if(nextProps.reasonObject==null){
                return;
            }else if(!nextProps.reasonObject.reasons){
                return;
            }
            //将props给到state
            this.setState({
                reasons: nextProps.reasonObject.reasons,
            })
        }
        if (nextProps.introduceNewReply !== this.props.introduceNewReply) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.introduceNewReply.code == 200) {
                // this.state.warnMsg = '推介成功';
                Toast.show("推介成功")
                this.props.history.push(this.props.match.path,{type: 'recommendOne'});
                window.location.reload();
                // this.props.resetIntroduceNewReply(403);
            }else {
                Toast.show("推介失败!"+nextProps.introduceNewReply.msg)
                // this.state.warnMsg = '推介失败';
                // this.setState({
                //     open: true
                // })
            }
        }
    }

    handleInputBlur(event) {
        const value = event.target.value;
        const name = event.target.name;

        //身份证需要自动解析
        if (name == "idcardNum") {
            let sexAndAge = analyzeIDCard(value);
            if (sexAndAge != null) {
                //自动填入性别 和 年龄
                this.setState({
                    gender: sexAndAge.sex ? "male" : "female",
                    age: sexAndAge.age
                });
            } else {
                //弹框提示
                alert("请输入正确身份证号");
                this.setState({
                    gender: "male",
                    age: ""
                });
            }
        }
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    /** 给患者发送推荐码 **/
    handleInputEventChecked(event) {
       const name = event.target.name;
       let labelText = null;
       event.target.checked == true ? labelText = 1 : labelText = 0;
       this.setState({
          [name]: labelText
       });
    }

    handleChange(event) {
        this.setState({
            introduceReason: event.target.value
        });
    }

    handleGenderChange(event) {
        this.setState({
            gender: event.target.value
        });
    }

    handleHighRegionPickerOk(value) {
        this.setState({
            highRegionIds:value
        })
    }

    handleLowRegionPickerOk(value) {
        this.setState({
            lowRegionIds:value
        })
    }

    //点击提交按钮
    handleSubmit() {
        //将数据打包成json,然后调用dispatch将数据发送出去

        if(this.state.name === "") {
            Toast.info('请输入患者姓名!');
            return;
        }
        if(this.state.idcardNum === "") {
            Toast.show('请输入身份证号!');
            return;
        }
        if(this.state.age === "") {
            Toast.show('请输入年龄!');
            return;
        }
        if(this.state.age>200) {
            Toast.show('你以为你是神仙啊!');
            return;
        }
        if(this.state.phoneNum === "") {
            Toast.show('请输入电话号码!');
            return;
        }

        if(this.state.highRegionIds === "" || this.state.highRegionIds === null || this.state.highRegionIds === undefined) {
           Toast.show('请选择省/市/县区域!');
           return;
        }
        if(this.state.lowRegionIds === "" || this.state.lowRegionIds === null || this.state.lowRegionIds === undefined) {
           Toast.show('请选择乡/镇/村区域!');
           return;
        }

        if(this.state.regionIds === "") {
            Toast.show('请选择区域!');
            return;
        }
        if(this.state.detailAddress === "") {
            Toast.show('请填写详细地址!');
            return;
        }
        if(this.state.introduceReason === "") {
            Toast.show('请选择推介原因!');
            return;
        }
        const introduceInfo = {
            name: this.state.name, //患者姓名
            idcardNum: this.state.idcardNum, //身份证号
            age: this.state.age, //年龄
            phoneNum: this.state.phoneNum, //电话号码
            regionIds: [...this.state.highRegionIds, ...this.state.lowRegionIds],//高低region合并
            detailAddress: this.state.detailAddress, //现住址
            gender: this.state.gender, //性别
            introduceReason: this.state.introduceReason,
            doctorId : this.state.doctorId,
            isSend : this.state.isSend,
        };
        this.props.submitIntroduceNewInfo(introduceInfo);

        // this.resetState();

    }

    handleClose = () => {
        this.setState({ open: false });
      };

    render() {
        //get react-material css styles
        const {classes} = this.props;
        const ReasonList = this.state.reasons.map((item)=>{
            return (<FormControlLabel className={classes.OptionDiv} key={item.id} value={item.id.toString()} control={<StyledRadio/>} label={item.reason}/>)
        });
        return (
            <Grid className={classes.rootDiv}>
                <Grid spacing={0}>
                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>姓名：</Grid>
                        <YddInput name="name" placeholder="请填写" value={this.state.name} onChange={this.handleInputChange}/>
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>身份证号：</Grid>
                        <YddInput name="idcardNum" placeholder="请填写" value={this.state.idcardNum} onChange={this.handleInputChange} onBlur={this.handleInputBlur}/>
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>性别：</Grid>
                        <RadioGroup row defaultValue="male" aria-label="gender" name="gender" onChange={this.handleInputChange}>
                            <BeautyFormControlLabel value="male" control={<StyledRadio/>} label="男" />
                            <BeautyFormControlLabel value="female" control={<StyledRadio/>} label="女" />
                        </RadioGroup>
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>年龄：</Grid>
                        <YddInput name="age" placeholder="请填写" value={this.state.age} onChange={this.handleInputChange} />
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>电话：</Grid>
                        <YddInput name="phoneNum" placeholder="请填写" value={this.state.phoneNum} onChange={this.handleInputChange} />
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>选择省/市/区县：</Grid>
                        <Picker title="选择省/市/区县" extra="请选择" data={this.props.regionObject.allregion} value={this.state.highRegionIds}
                            onChange={v => this.setState({highRegionIds: v})} onOk={this.handleHighRegionPickerOk} >
                            <CustomChildren></CustomChildren>
                        </Picker>
                    </Grid>

                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>选择乡镇/村：</Grid>
                        <Picker title="选择乡镇/村" extra="请选择" cols={2} data={this.state.villiageData} value={this.state.lowRegionIds}
                            onChange={v => this.setState({lowRegionIds: v})} onOk={this.handleLowRegionPickerOk}>
                            <CustomChildren></CustomChildren>
                        </Picker>
                    </Grid>
                    <Grid className={classes.dataDiv}>
                        <Grid container alignItems="center" item xs={3}>详细地址：</Grid>
                        <YddInput name="detailAddress" placeholder="请填写" value={this.state.detailAddress} onChange={this.handleInputChange} />
                    </Grid>
                    {/* 推介原因 */}
                    <Grid className={classes.SelectionboxDiv}>
                        <Grid className={classes.introLabelDiv}>
                            <FormLabel className={classes.referralreasonDiv} component="legend">推介原因：</FormLabel>
                        </Grid>
                        <Grid className={classes.introReasonItemsDiv}>
                            <RadioGroup aria-label="推介原因" name="introduceReason" value={this.state.introduceReason} onChange={this.handleInputChange}>
                                {ReasonList}
                                {/*
                                <FormControlLabel className={classes.OptionDiv} value="9" control={<StyledRadio/>} label="因症就诊"/>
                                <FormControlLabel className={classes.OptionDiv} value="10" control={<StyledRadio/>} label="老年人健康体检" />
                                <FormControlLabel className={classes.OptionDiv} value="11" control={<StyledRadio/>} label="密切接触者筛查" />
                                <FormControlLabel className={classes.OptionDiv} value="12" control={<StyledRadio/>} label="已知肺结核者未纳入管理" />
                                <FormControlLabel className={classes.OptionDiv} value="27" control={<StyledRadio/>} label="糖尿病患者筛查或体检" />
                                */}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <FormControlLabel onChange={this.handleInputEventChecked}
                        control={<GreenCheckbox name="isSend" value={this.state.isSend} color="primary"/>} label="给患者发送推介码" />
                    <Grid item xs={12} className={classes.gridButton}>
                        <Button variant="contained" className={classes.button} onClick={this.handleSubmit}>提 交</Button>
                    </Grid>
                </Grid>

                {/*<Snackbar anchorOrigin={{vertical: this.state.vertical, horizontal: this.state.horizontal}}*/}
                          {/*key={`${this.state.vertical},${this.state.horizontal}`} open={this.state.open}*/}
                          {/*onClose={this.handleClose} autoHideDuration={1000} message="推介成功">*/}
                {/*</Snackbar>*/}

            </Grid>
        );
    }
}

//redux region
const mapStateToProps = state => {
    return {
        introduceNewReply: state.introduceReducer.introduceNewReply,
        regionObject: state.introduceReducer.regionObject,
        reasonObject: state.introduceReducer.reasonObject
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //提交新建推介的信息
        submitIntroduceNewInfo: introInfo => {
            dispatch(submitIntroduceNewInfo(introInfo));
        },
        // resetIntroduceNewReply: code => {
        //     dispatch(resetIntroduceNewReply(code));
        // },

         // 获取住址信息
    getRegionList: () => {
        dispatch(getRegionList());
      },
        getReasonList:() => {
            dispatch(getReasonList());
        }
    };
};
//end of redux regian

//css region
const styles = theme => ({
    rootDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100vw",
        marginTop: "3vw",
        alignItems: "center",
        color: "#333333",
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: "100vh",
        overflow: 'auto',
    },

    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
        width: "90vw",
        fontSize: "9vw",
        bordersStyle: 0
    },

    input: {
        margin: theme.spacing(1),
        placeholderStyle: ""
    },

    genderField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        width: "10vw",
        borderStyle: "none",
        borderColor: "none",
        border: "0"
    },

    ageField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
        width: "90vw"
    },

    referralreasonDiv: {
        lineHeight: "6vw",
        color: "#333333",
        fontSize: "4.2vw",
        height: "1.5vw"
    },

    region: {
        position: "relative",
        fontSize: "3.2vw",
        textAlign: "left",
        lineHeight: "13.3vw",
        border: "none"
    },

    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
        borderBottom: "0.1vw solid #e6e6e6"
    },

    SelectionboxDiv: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: "5vw",
        minHeight: "65vw",
        width: "90vw",
        justifyContent: "spaceBetween"
    },

    introLabelDiv: {
        width: "90vw"
    },

    introReasonItemsDiv: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(36,225,221,0.05)",
        paddingLeft: "3vw",
        fontSize: "2vw",
        width: "87vw",
        border: "0.1vw solid #e6e6e6"
    },

    OptionDiv: {
        width: "90vw",
        height: "9vw"
    },
    OptionDivs: {
        marginTop: "3vw"
    },
    SelDiv: {
        backgroundColor: "#11ff44",
        fontSize: "4.5vW"
    },

    SubmitbuttonDiv: {
        backgroundColor: "#22ff44"
    },

    button: {
        backgroundColor: "#22ff44",
        fontSize: "4.8vW",
        width: "91vw",
        height: "12vw",
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
        marginTop: "6vw",
        marginBottom: "5vw",
        color: "#ffffff",
        lineHeight: "6vw"
    },

    gridButton: {
        marginBottom: "5vw"
    },
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paper: {
        width: "85vw",
        height: "47vw",
        backgroundColor: "#ffffff"
    },
    waraing: {
        height: "10vw",
        marginTop: 0,
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)"
    },
    tishi: {
        height: "10vw"
    },
    paperDiv: {
        height: "15vw",
        flexDirectio: "column"
    },
    buttonDiv: {
        height: "18vw",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    buttons: {
        width: "30vw",
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
        marginTop: "1vw",
        height: "8vw",
        fontSize: "4vw",
        padding: 0
    },
    tishiDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5vw"
    },
    spanDiv: {
        fontSize: "3.5vw",
        marginLeft: "4vw"
    },
    warm: {
        fontSize: "4vw",
        color: "#ffffff",
        marginLeft: "2vw",
        lineHeight: "10vw",
        marginTop: 0
    },
    addressList: {
        position: "relative",
        borderBottom: 0,
        "&:after": {
            content: "",
            position: "absolute",
            backgroundColor: "#ddd",
            display: "block",
            zIndex: 1,
            top: "auto",
            right: "auto",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "1px",
            transformOrigin: "50% 100%",
            transform: "scaleY(0.5)"
        }
    }
});
//end of css region

const YddInput = withStyles(theme =>
    createStyles({
        root: {
            "label + &": {
                marginTop: theme.spacing(3)
            }
        },
        input: {
            position: "relative",
            fontSize: 15,
            width: "66vw",
            height: "9vw",
            textAlign: "right",
            borderBottom: "1 solid #666666",
            outline: "none",

            "&:focus": {
                borderColor: theme.palette.primary.main
            }
        }
    })
)(InputBase);

//here we inject react-material css styles
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(withStyles(styles)(NewIntroduceWidget)));
