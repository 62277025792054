// The initial application global state
let initialState = {
    doctorId : 0,
    userId : 0
}

// Takes care of changing the application state
function appReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}

export default appReducer