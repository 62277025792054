import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {introduceNotice7Days } from '../../redux/actions/index';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Avatar from '@material-ui/core/Avatar';
import avatarImg from "../../assets/images/tj.png";
import returnImg from '../../assets/images/returnImg.png'

import gender from '../common/sex'
import introduceStatus from '../common/introduceStatus'

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio className={classes.root} disableRipple color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>} {...props} />
    );
}

//我的推介-通知-7天详情页面
class IntroduceNotify7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            id:0,
            introId:0,
            noticeType:7
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
    }

    //加载数据，包括数据
    componentDidMount() {
        //左边是属性值 ，右边是上个页面穿过来的值，this.props.location  这部分是固定写法，noticeId是上个页面的key值
        this.state.introId = this.props.location.state.noticeId;
        this.state.id = this.props.location.state.id //通知主键Id
        //getIntroduceNotice7Days是自定义的，需要在mapDispatchToProps中定义
        this.props.getIntroduceNotice7Days(this.state.introId);

        let notice ={}
        notice.id = this.state.id
        notice.type = this.state.noticeType

        this.props.noticeReaded(notice);
    }

    componentWillUnmount() {

    }

    handleSubmit() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        //get react-material css styles
        const {classes,introduceNoticeDays7} = this.props;

        return (
            //总的大盒子
            <div className={classes.root}>
                {/* 标题栏 箭头 通知详情 */}
                <Grid container className={classes.Patient}>
                    <img src={returnImg} className={classes.jiantou} alt='' onClick={this.handleNavBack}/>
                    <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.title}>通知详情</h4>
                    </Grid>
                </Grid>

                {/* 头像 */}
                <Grid container justify="center" className={classes.headPortrait}>
                    <Avatar src={avatarImg} className={classes.avatarSize}/>
                </Grid>

                {/* 介绍 */}
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>姓名：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDays7.name}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>性别：</Typography>
                    <Typography className={classes.fieldValue}>{gender(introduceNoticeDays7.sex)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>年龄：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDays7.age}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>电话：</Typography>
                    <Typography className={classes.fieldValue}>{introduceNoticeDays7.telphone}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>现住址：</Typography>
                    <Typography className={classes.fieldValueDiv}>{introduceNoticeDays7.address}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.fieldLable}>当前推介状态：</Typography>
                    <Typography className={classes.fieldValue}>{introduceStatus(introduceNoticeDays7.status)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                {/* 推介原因 */}
                <Grid container justify="space-between" direction="column" className={classes.SelectionboxDiv}>
                    <FormLabel className={classes.referralreasonDiv} component="legend">未就诊原因：</FormLabel>
                    <RadioGroup aria-label="未就诊原因" className={classes.radioDiv} name="introduceReason" value={this.state.introduceReason} onChange={this.handleChange}>
                        <FormControlLabel className={classes.OptionDiv} value="feelbad" control={<StyledRadio/>} label="拒绝就诊"/>
                        <FormControlLabel className={classes.OptionDiv} value="oldexam" control={<StyledRadio/>} label="经济困难"/>
                        <FormControlLabel className={classes.OptionDiv} value="screen" control={<StyledRadio/>} label="路途遥远"/>
                        <FormControlLabel className={classes.OptionDiv} value="phthisis" control={<StyledRadio/>} label="行动不便"/>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="无人陪护"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="其他原因"/>
                    </RadioGroup>
                </Grid>
                <Grid container justify="space-between" direction="column" className={classes.introLabelDiv}>
                    <Typography className={classes.titles} color="textSecondary" gutterBottom>其他原因：</Typography>
                    <textarea type="text" placeholder="请填写原因" className={classes.textshuru}></textarea>
                </Grid>
                <Grid className={classes.buttonBox}>
                    <Grid item xs={12} className={classes.gridButton}>
                        <Button variant="contained" className={classes.button} onClick={this.handleSubmit}>提 交</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        introduceNoticeDays7: state.introduceReducer.introduceNoticeDays7,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getIntroduceNotice7Days: (noticeId) => {
        dispatch(introduceNotice7Days(noticeId)) //action/index页面
    },
  }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        // padding: theme.spacing(0, 3),
        backgroundColor: '#ffffff',
    },
    SelectionboxDiv: {
        width: '90vw'
    },

    introLabelDiv: {
        marginTop: '3vw',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },

    radioDiv: {
        color: '#333333',
        backgroundColor: 'rgba(36,225,221,0.05)',
        border: '0.1vw solid #e6e6e6',
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.3),
    },
    gridButton: {
        marginBottom: '5vw',
        display: 'flex',
        justifyContent: 'center',
    },

    button: {
        backgroundColor: '#22ff44',
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '6vw',
        marginBottom: '5vw',
        color: '#ffffff',
        lineHeight: '6vw',

    },
    fieldValueDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999',
    },

    referralreasonDiv: {
        lineHeight: '6vw',
        color: '#333333',
        fontSize: '4.2vw',
        height: '1.5vw',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },

    margin: {
        margin: theme.spacing(1),
        height: '7vh',
        width: '38vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '5vw',
        marginTop: '5vw',
        marginbottom: '7vw'
    },
    headPortrait: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        margin: 0
    },

    avatar: {
        display: 'flex',
        width: '22vw',
        height: '22vw',
        maxWidth: '22vw',
        maxHeight: '22vw',
        flexShrink: '1',
        borderRadius: "50%"
    },
    information: {
        marginTop: '5vw',
        width: '100%'
    },

    cardjiuzhen: {
        width: '90vw',
        height: '32vh',
        marginLeft: '5vw',
    },

    OptionDiv: {
        width: '86vw',
        // height: '9vw',
        paddingLeft: theme.spacing(1.5)
    },

    cardbutton: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row'
    },

    span: {
        float: 'right',
        marginRight: '3vw',
        color: '#999999'
    },
    title: {
        color: '#ffffff',
        fontWeight: '500',
        padding: '0',
        lineHeight: '0vw'
    },

    titles: {
        color: '#333333',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize: '4.2vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },

    textshuru: {
        width: '89vw',
        marginLeft: theme.spacing(2),
        // marginTop:theme.spacing(2),
        height: '20vw',
        border: '0.1vw solid #e6e6e6'
    },

    buttonBox: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    introReasonItemsDiv: {
        backgroundColor: 'rgba(36,225,221,0.05)',
        color: '#787878'
    },

    fieldLable: {
        marginLeft: theme.spacing(2),
        lineHeight: '10vw',
        fontSize: '4.2vw',
        color: '#333333'
    },

    fieldValue: {
        color: '#999999',
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        width: theme.spacing(27),
        textAlign: 'right'

    },

    avatarSize: {
        width: '22vw',
        height: '22vw'
    },

    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        // height: '14vw',
        padding: '0'
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntroduceNotify7));