
import Api from '../api';

const consultApi = {
    // getRootUrl() {
    //     return 'http://localhost:8080';
    //     //return 'http://127.0.0.1:8080';
    // },

    init(appname, serveraddr, api_key, proto) {

    },

    //获取资讯的聊天历史记录
    getConsultList(userId) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/advisory/getAdvisoryList';

        let params = {userId:userId}

        let patientGId = localStorage.getItem("patientGId")
        let patientNick = localStorage.getItem("patientNick")
        if(patientGId != null){
            params.patientGId = patientGId;
            params.patientNick = patientNick;

            url = Api.getRootUrl() + '/api/generalAdvisory/getAdvisoryList';
        }

        const init = {
            method: 'POST',
            headers: initHeaders,
            body : JSON.stringify(params)
        }
       return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

        //患者咨询 发送消息
        sendMessage(message) {
            let initHeaders = new Headers()
            initHeaders.append('Accept', 'application/json, text/plain, */*')
            initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
            let url = Api.getRootUrl() + '/api/advisory/advisory';
            let messageBody={
                userId:localStorage.getItem("userId"),
                question:JSON.stringify(message),
            }

            let patientGId = localStorage.getItem("patientGId")
            let patientNick = localStorage.getItem("patientNick")
            if(patientGId != null){
                messageBody.patientGId = patientGId;
                messageBody.patientNick = patientNick;

                url = Api.getRootUrl() + '/api/generalAdvisory/advisory';
            }

            const init = {
                method: 'POST',
                headers: initHeaders,
                body : JSON.stringify(messageBody)
            }

            return fetch(
                url,
                init
            ).then(res => res.json())
                .then(data => {
                   return data.data;
                })
                .catch(e => {
                    return {error: e};
                })
        },

}

consultApi.init();

export default consultApi
