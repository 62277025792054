//common
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import DoctorOrPatientView from "./DoctorOrPatientView";
//for doctor
import DoctorLoginView from './doctor/LoginView';
import DoctorMainView from './doctor/MainView';
import AuthView from "./doctor/AuthView";
import PatientPhoneLogin from './patient/PhoneLogin';
import PatientMainView from './patient/MainView';
import ChatWindow from './patient/consultDoctor/ChatWindow';
import MyScoreWidget from './patient/myWidget/MyScoreWidget';
import ScoreStoreView from './patient/myWidget/ScoreStoreView';
import OrderStoreView from './patient/myWidget/OrderStoreView';
import OrderStoreHistoryView from './patient/myWidget/OrderStoreHistoryView'



function AppRouter() {
  return (
    <Router basename="/">

      <Switch>
      {/*doctor view*/}
        <Route exact path="/" component={DoctorOrPatientView} />
        {/*<Route exact path="/" component={RemindAdd} />*/}
        <Route path="/doctor" component={DoctorLoginView} />
        <Route path="/doctorIndex" component={DoctorMainView} />
        <Route path="/doctorAuth" component={AuthView} />


        {/*patient view*/}
         <Route path="/patient" component={PatientPhoneLogin} />
       <Route path="/patientIndex" component={PatientMainView} />
       <Route path="/jkwhChat" component={ChatWindow} />
       <Route path="/jf" component={MyScoreWidget} />
       <Route path="/jfStore" component={ScoreStoreView} />
       <Route path="/jfOrder" component={OrderStoreView} />
       <Route path="/jfOrderHistory" component={OrderStoreHistoryView} />

      </Switch>

    </Router>
  );
}


export default AppRouter;
