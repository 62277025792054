import * as ActionType from "../../actions/patients/index-action";
import api from "../../api/patients/index.js";
import {call, put, takeLatest} from "redux-saga/effects";


// 提醒列表
export function* addRemind(action){
   try {
      const result = yield call(api.addRemind,action.remindItem);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: ActionType.ADD_REMIND_SUCCEED, result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

// 添加提醒
export function* remindList(action){
   try {
      const result = yield call(api.remindList,action.userId);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: ActionType.REMIND_LIST_ACTION_SUCCEED, result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

// 开关提醒
export function* onCloseRemind(action){
   try {
      const result = yield call(api.onCloseRemind, action.onCloseParams);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: ActionType.REMIND_ON_CLOSE_SUCCEED, result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}

// 删除提醒
export function* deleteRemoveRemind(action){
   try {
      const result = yield call(api.deleteRemoveRemindApi, action.remindId);
      if (result.error !== undefined) {
         //请求出错了
         yield put({type: "SERVER_CONNECT_FAILED", message: result.error});
      } else {
         yield put({type: ActionType.DELETE_REMOVE_REMIND_SUCCEED, result});
      }
   }catch (e) {
      yield put({type: "SERVER_CONNECT_FAILED", message: e.message});
   }
}


export default function* root() {
   yield takeLatest(ActionType.ADD_REMIND,addRemind); // 患者管理 - 添加提醒
   yield takeLatest(ActionType.REMIND_LIST_ACTION,remindList); //患者管理 - 提醒列表
   yield takeLatest(ActionType.REMIND_ON_CLOSE,onCloseRemind); //患者管理 - 开关提醒
   yield takeLatest(ActionType.DELETE_REMOVE_REMIND,deleteRemoveRemind); //患者管理 - 删除提醒
}

