import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Slider from './MainWidget/Slider';
import QuickMenu from './MainWidget/QuickMenu';
import MainMenu from './MainWidget/MainMenu';
import IntroduceView from './IntroduceView';
import TrackView from './TrackView';
import ManageView from './PatientManageView';
import HealthInfoView from './HealthInfoView';
import News from './News';
import ScreeningDiabetesHistoryPaper from './screeningPaper/ScreeningDiabetesHistoryPaper';
import ScreeningNavPaper from './screeningPaper/ScreeningNavPaper';
import ScreeningDiabetesHistoryBJPaper from './screeningPaperBJ/ScreeningDiabetesHistoryPaper';
import MyView from './MyView';
import image1 from "../assets/images/zy1.png";
import image2 from "../assets/images/zy2.png";
import image3 from "../assets/images/zy3.png";
import image4 from "../assets/images/zy4.png";
import image5 from "../assets/images/zy5.png";
import image6 from "../assets/images/zy6.png";
import image7 from "../assets/images/zy7.png";
import image8 from "../assets/images/zy8.png";
import image9 from "../assets/images/zy9.png";
import image10 from "../assets/images/zy10.png";
import image11 from "../assets/images/zy11.png";
import imageConsult from "../assets/images/consult.png";
import zixun from "../assets/images/zixun.png";
import gongzuo from "../assets/images/gongzuo.png";
import wode from "../assets/images/wode.png";
import Header from "../lib/chatWindow/components/Header";
import Footer from "./common/footer"
import ChatWindow from "../lib/chatWindow/components/ChatWindow";

import {noticeAll} from "../redux/actions/custom_action";

//医生端主页面
class MainView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
         viewportWidth: 0,
         viewportHeight: 0,
         tabulation: true,
         showcase: false,
         business: false,
      };
      this.setBottomMenuIndex = this.setBottomMenuIndex.bind(this);
      this.handleIntroducePatient = this.handleIntroducePatient.bind(this);
      this.handleTrackPatient = this.handleTrackPatient.bind(this);
      this.handleManagePatient = this.handleManagePatient.bind(this);
      this.handleHealthInfo = this.handleHealthInfo.bind(this);
      this.handleLoginClick = this.handleLoginClick.bind(this);
      this.handClick = this.handClick.bind(this);
      this.leLoginClick = this.leLoginClick.bind(this);
      this.handleIconNoticeClick = this.handleIconNoticeClick.bind(this); // 图标跳转事件
      this.handleScreeningInfo = this.handleScreeningInfo.bind(this);
      this.handleScreeningInfoBJ = this.handleScreeningInfoBJ.bind(this);
   }

   handleLoginClick() {
      // this.resetAll();
      this.setState({
         tabulation: true
      });
   }

   resetAll() {
      this.setState({
         tabulation: false,
         showcase: false,
         business: false,
      });
   }

   componentDidMount() {
      let token = localStorage.getItem("token")
      let hospitalId = localStorage.getItem("hospitalId")
      if(token == null || hospitalId == null){
         window.location.href = '/doctor'
         return
      }
      this.props.getNotice({"userId":localStorage.getItem("userId")})
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.serverInfo != this.props.serverInfo) {

      }
   }

   setBottomMenuIndex(newValue) {
      this.setState({
         bottomMenuIndex: newValue,
      })
   }

   //患者推介
   handleIntroducePatient() {
      const path = `${this.props.match.path}/introduce`;
      this.props.history.push(path);
   }

   //患者追踪
   handleTrackPatient() {
      const path = `${this.props.match.path}/track`;
      this.props.history.push(path);
   }

   //患者管理
   handleManagePatient() {
      const path = `${this.props.match.path}/manage`;
      this.props.history.push(path);
   }

   //健康宣教
   handleHealthInfo() {
      const path = `${this.props.match.path}/health`;
      this.props.history.push(path);
   }

    //筛选上报
    handleScreeningInfo() {
      const path = `${this.props.match.path}/screening`;
      this.props.history.push(path);
   }

   //筛选上报
   handleScreeningInfoBJ() {
      const path = `${this.props.match.path}/screeningBJ`;
      this.props.history.push(path);
   }

   //我的
   leLoginClick() {
      this.setState({
         business: true
      })
      const path = `${this.props.match.path}/myview`;
      this.props.history.push(path);
   }

   //点击底部资讯
   handClick() {
      this.setState({
         showcase: true
      });
      const path = `${this.props.match.path}/news`;
      this.props.history.push(path);
   }

   // 轮播图下的图标点击跳转到对应的页面
   handleIconNoticeClick(keys) {
      // 判断如果是recommend，跳转到推介通知页面
      if (keys === 'recommend') {
         const path = `${this.props.match.path}/introduce`;
         this.props.history.push(path, {type: 'recommend'});
      }
      // 如果是track，跳转到追踪到位页面中的通知部分
      if (keys === 'track') {
         const path = `${this.props.match.path}/track`;
         this.props.history.push(path, {type: 'track'});
      }
      // 如果是visit，跳转到复诊页面中的通知部分
      if (keys === 'visit') {
         const path = `${this.props.match.path}/manage`;
         this.props.history.push(path, {type: 'visit'});
      }
      // 如果是caller, 跳转到待随访患者
      if (keys === 'caller') {
         const path = `${this.props.match.path}/manage`;
         this.props.history.push(path, {type: 'caller'});
      }
      // 如果是saliva， 跳转到查痰提醒
      if (keys === 'saliva') {
         const path = `${this.props.match.path}/manage`;
         this.props.history.push(path, {type: 'saliva'});
      }
      // 如果是drug，跳转到服药管理
      if (keys === 'drug') {
         const path = `${this.props.match.path}/manage`;
         this.props.history.push(path, {type: 'drug'});
      }

      // 如果是consult，跳转到患者咨询
      if (keys === 'consult') {
         const path = '/api/transfer/loadDoctorConsult';
         window.location.href = path;
      }
   }

   render() {
      //get react-material css styles
      const {classes} = this.props;
      return (
         <Switch>
            <Route path={`${this.props.match.path}/introduce`} component={IntroduceView}/>
            <Route path={`${this.props.match.path}/track`} component={TrackView}/>
            <Route path={`${this.props.match.path}/myview`} component={MyView}/>
            <Route path={`${this.props.match.path}/manage`} component={ManageView}/>
            <Route path={`${this.props.match.path}/health`} component={HealthInfoView}/>
            <Route path={`${this.props.match.path}/news`} component={News}/>
            <Route path={`${this.props.match.path}/Screening`} component={ScreeningNavPaper}/>
            <Route path={`${this.props.match.path}/ScreeningBJ`} component={ScreeningDiabetesHistoryBJPaper}/>

            <Grid className={classes.root}>

               <Grid container spacing={0}>
                  {/* header部分 */}
                  <Grid container item xs={12} justify="center" alignItems='center' className={classes.header}>
                     <h3 className={classes.title}>医生平台</h3>
                  </Grid>


                  {/* 轮播图 */}
                  <Grid item xs={12} className={classes.sliderImages}>
                     <Grid container justify="space-between" alignItems='center' className={classes.picListDiv}>
                        <Slider/>
                     </Grid>
                  </Grid>
                  <Grid container alignItems="flex-end" className={classes.rootDiv}>
                     {/*快捷菜单*/}
                     <Grid item xs={4}>
                        <QuickMenu
                           noticeNum={this.props.noticeAllInfo?.introduceLostNum}
                           tabImage={image10}
                           tabLabel={"推介未到位"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'recommend')}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <QuickMenu
                           noticeNum={this.props.noticeAllInfo?.trackLostNum}
                           tabImage={image9}
                           tabLabel={"追踪未到位"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'track')}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <QuickMenu
                           noticeNum={this.props.noticeAllInfo?.orderNum}
                           tabImage={image6}
                           tabLabel={"复诊未提醒"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'visit')}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <QuickMenu
                           noticeNum={this.props.noticeAllInfo?.followupNum}
                           tabImage={image5}
                           tabLabel={"待随访患者"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'caller')}
                        />
                     </Grid>
                     {/* 
                     <Grid item xs={4}>
                        <QuickMenu
                           tabImage={image1}
                           tabLabel={"查痰提醒"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'saliva')}
                        />
                     </Grid>
                     */}
                     <Grid item xs={4}>
                        <QuickMenu
                           tabImage={imageConsult}
                           tabLabel={"患者咨询"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'consult')}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <QuickMenu
                           noticeNum={1<2?0:this.props.noticeAllInfo?.medicationNum}
                           tabImage={image4}
                           tabLabel={"服药管理"}
                           imgListClick={this.handleIconNoticeClick.bind(this, 'drug')}
                        />
                     </Grid>

                     {/* 主菜单 */}
                     <Grid item xs={6} className={classes.gridDiv}>
                        <MainMenu
                           noticeNum={1<2?0:this.props.noticeAllInfo?.introduceNum}
                           label={"患者推介"}
                           detail={"将患者推介到上级"}
                           tabImage={image8}
                           onMenuClick={this.handleIntroducePatient}
                        />
                     </Grid>

                     <Grid item xs={6} className={classes.gridDiv}>
                        <MainMenu
                           noticeNum={1<2?0:this.props.noticeAllInfo?.trackNum}
                           label={"患者追踪"}
                           detail={"对推介患者进行追踪"}
                           tabImage={image7}
                           onMenuClick={this.handleTrackPatient}
                        />
                     </Grid>

                     <Grid item xs={6}>
                        <MainMenu
                           noticeNum={this.props.noticeAllInfo?.manageNum}
                           label={"患者管理"}
                           detail={"对患者进行管理"}
                           tabImage={image3}
                           onMenuClick={this.handleManagePatient}
                        />
                     </Grid>

                     <Grid item xs={6} className={this.props.noticeAllInfo?.showScreening > 0?classes.hideMe:''}>
                        <MainMenu
                           label={"健康宣教"}
                           detail={"疾病的健康教育"}
                           tabImage={image2}
                           onMenuClick={this.handleHealthInfo}
                        />
                     </Grid>

                     <Grid item xs={6} className={this.props.noticeAllInfo?.showScreening == 1?'':classes.hideMe}>
                        <MainMenu
                           label={"筛查汇报"}
                           detail={"糖尿病等筛查上报"}
                           tabImage={image11}
                           onMenuClick={this.handleScreeningInfo}
                        />
                     </Grid>

                     <Grid item xs={6} className={this.props.noticeAllInfo?.showScreening == 2?'':classes.hideMe}>
                        <MainMenu
                           label={"筛查汇报"}
                           detail={"糖尿病等筛查上报"}
                           tabImage={image11}
                           onMenuClick={this.handleScreeningInfoBJ}
                        />
                     </Grid>

                     {/* 最下方菜单 */}

                     <Footer urlPath={this.props} btnType={'tabulation'}/>
                     {/*<Grid container alignItems="flex-end" item xs={12} className={classes.footerDiv}>
                        <Grid className={classes.bott}>
                           <button className={this.state.showcase ? classes.buttonon : classes.buttonsdiv}
                                   onClick={this.handClick}>
                              <img src={zixun} className={classes.imgdiv}></img><p className={classes.ps}>资讯</p>
                           </button>
                           <button className={this.state.tabulation ? classes.buttonons : classes.buttonssdiv}
                                   onClick={this.handleLoginClick}>
                              <img src={gongzuo} className={classes.imgsdiv}></img><p className={classes.ps}>工作站</p>
                           </button>
                           <button className={this.state.business ? classes.buttonon : classes.buttonsdiv}
                                   onClick={this.leLoginClick}>
                              <img src={wode} className={classes.imgdiv}></img><p className={classes.ps}>我的</p>
                           </button>
                        </Grid>
                     </Grid>*/}
                  </Grid>
               </Grid>
            </Grid>
         </Switch>


      );
   }

}


MainView.propTypes = {
   urlPath: PropTypes.object.isRequired,
   btnType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      userId: state.SendUserIdReducer,
      createGroup: state.SendCreateGroupReducer,
      liveConnection: state.liveConnection,
      loggedIn: state.loggedIn,
      noticeAllInfo: state.customReducer.noticeAllInfo,
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      login: (userInfo) => {
         // dispatch(loginRequest(userInfo))
      },
      getNotice: (params) => {
         dispatch(noticeAll(params));
      },
   }
}


//css region
const styles = () => ({
   sliderImages:{
      height: '28vh',
   },
   header: {
      height: '8vh',
      lineHeight: '8vh',
      background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
      color: '#ffffff',
      alignContent: 'center',
      padding: 0
   },

   title: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      // wordSpacing:'normal',
      lineHeight: '0vw'
   },
   root: {
      flexGrow: 1,
      backgroundColor: '#FFFFFF',
      height: '100vh',
      overflow: 'auto',

   },

   rootDiv: {
      backgroundColor: '#FFFFFF',
   },

   gridDiv: {
      marginTop: '0vw',
      backgroundColor: '#FFFFFF',
   },

   // footerDiv: {
   //    display: 'flex',
   //    justifyContent: 'space-around',
   //    flexDirection: 'row',
   //    alignSelf: 'flex-end',
   //    marginTop: '17vw',
   //    border: '0.1vw solid #e6e6e6',
   //    width: '100vw',
   //    height: '14vw',
   // },
   picListDiv: {
      height: '100%',
      flexShrink: '1',
   },

   quickMenuDiv: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '85vw',
      alignItems: 'center',
      marginTop: '1vh',
      flexShrink: '1',
      backgroundColor: '#FFFFFF',
   },

   //底部样式
   footerDiv: {
      alignSelf: 'flex-end',
      marginTop: '17vw',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      border: '0.1vw solid #e6e6e6',
      width: '100vw',
      height: '14vw',
   },
   bott: {
      width: '80vw',
      height: '14vw',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
   },
   buttonon: {
      outline: 'none',
      border: 'none',
      borderRadius: '50%',
      marginBottom: '1vw',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',
   },
   buttonons: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',

   },
   buttonsdiv: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      filter: 'grayscale(100%)',
   },
   buttonssdiv: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      filter: 'grayscale(100%)',
   },
   imgdiv: {
      height: '6vw',

   },
   imgsdiv: {
      height: '7vw',
   },
   ps: {
      fontSize: '2.7vw',
      margin: 0,
   },
   hideMe:{
      display: 'none',
   },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainView));
