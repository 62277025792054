import Api from '../api';

const PatientManageApi = {
   init(appname, serveraddr, api_key, proto) {},

   //患者管理-提醒列表
   remindList(userId) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/medicationRecord/getRemindList';

      var params = {};
      params.userId = userId;
      params.token = localStorage.getItem("token")

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(params)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },

   //患者管理-添加提醒
   addRemind(remindItem) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/medicationRecord/addRemind';

      remindItem.userId = localStorage.getItem("userId")*1;

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(remindItem)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.code;
      }).catch(e => {
         return {error: e};
      })
   },

   //患者管理-开关提醒
   onCloseRemind(onCloseParams) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/medicationRecord/onClose';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(onCloseParams)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.code;
      }).catch(e => {
         return {error: e};
      })
   },

   //患者管理-删除提醒
   deleteRemoveRemindApi(remind) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/medicationRecord/delRemind';

      let remindParams = {};
      remindParams.id = remind.id;
      remindParams.timePos = remind.timePos;
      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(remindParams)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.code;
      }).catch(e => {
         return {error: e};
      })
   },






}

PatientManageApi.init();

export default PatientManageApi
