//患者端 模块声明
import {POST_NAMEDIV, POST_PHONENUMBER} from "../constants";

export const PATIENT_REGISTER = 'PATIENT_REGISTER'
export const PATIENT_REGISTER_ARRIVED = 'PATIENT_REGISTER_ARRIVED'

export const WX_BASE_LOGIN = 'WX_BASE_LOGIN' //微信授权登录
export const WX_BASE_LOGIN_SUCCEED = 'WX_BASE_LOGIN_SUCCEED' //成功

export const PATIENT_LOGIN0='PATIENT_LOGIN0' //这个才是最初的患者登录
export const PATIENT_LOGIN0_SUCCEED='PATIENT_LOGIN0_SUCCEED' //最初的患者登录成功
export const PATIENT_LOGIN='PATIENT_LOGIN' // 登录
export const PATIENT_LOGIN_SUCCEED = 'PATIENT_LOGIN_SUCCEED'  //登录成功

export const TUI_CHU_DENG_LU='TUI_CHU_DENG_LU' // 退出登录
export const TUI_CHU_DENG_LU_SUCCEED = 'TUI_CHU_DENG_LU_SUCCEED'  //退出登录成功


export const POST_PHONENUMBERS = 'POST_PHONENUMBERS'  // 患者端修改手机号码
export const POST_PHONENUMBERS_SUCCEED = 'POST_PHONENUMBERS_SUCCEED'  //

export const POST_NAMEDIVS = 'POST_NAMEDIVS' //患者端我的姓名修改提交
export const POST_NAMEDIVS_SUCCEED = 'POST_NAMEDIVS_SUCCEED'

export const PATIENT_MODIFY_PASSWORD = 'PATIENT_MODIFY_PASSWORD'
export const PATIENT_MODIFY_PASSWORD_SUCCEED = 'PATIENT_MODIFY_PASSWORD_SUCCEED'

//患者注册
export function patientRegister(patientInfo){
    return {
        type : PATIENT_REGISTER,
        patientInfo
    }
}

//患者登录
export function patientLogin(patientInfo){
    return {
        type : PATIENT_LOGIN0,
        patientInfo
    }
}
export function wxBaseLogin(baseInfo){
   return {
      type : WX_BASE_LOGIN,
      baseInfo
   }
}

//患者端退出登录
export function loginOutBranch() {
   return {
      type:TUI_CHU_DENG_LU,
   }
}


//患者端我的资料
export function getLoginView(patientId) {
    return {
        type:PATIENT_LOGIN,
        patientId
    }
}

//患者端我的手机号码修改
export function postMobileDiv(phoneNumber) {
    return {
        type:POST_PHONENUMBERS,
        phoneNumber
    }
}

//患者端修改密码
export function modifyPatientPwd(patientInfo){
    return {
        type: PATIENT_MODIFY_PASSWORD,
        patientInfo
    }
}

//患者端姓名修改提交
export function postNameDiv(patientInfo) {
    return {
        type:POST_NAMEDIV,
        patientInfo
    }
}
