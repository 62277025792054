import React from 'react';
import {connect} from 'react-redux';
import {createStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import {purple} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button/Button";

import {patientRegister} from '../redux/actions/patients/login-action';

//患者端注册
class NameDiv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            showModal: false,
            showsModal: false,
            phone:'',
            password:'',
            password2:''
        };
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkPwd = this.checkPwd.bind(this);
        this.checkPhone = this.checkPhone.bind(this);
    }
     //注册
    handleLoginClick() {
        if(!this.checkPhone(this.state.phone)){
            alert("请输入手机号");
            return
        }
        if(!this.checkPwd()){
            alert("两次密码输入不一致");
            return
        }
        let patientInfo ={}
        patientInfo.phone = this.state.phone
        patientInfo.password = this.state.password
        this.props.patientRegister(patientInfo);
    }

    checkPwd(){
        let password = this.state.password;
        let password2 = this.state.password2;

        if(password != password2){
            return false;
        }
        return true;
    }

    checkPhone(phone){
        if(phone == ''){
            return false;
        }
        return true;
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }
    handleChange(event, newValue) {
        this.setState({ value: newValue });
    };

    handleChangeIndex(index) {
        this.setState({ index: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.rootDiv}>
                <Grid container justify="center">
                    <YddInput name="phone" placeholder='请输入注册手机号' onChange={this.handleInputChange}/>
                </Grid>
               <Grid container justify="center">
                    <YddInput name="password" placeholder='请输入注册密码' type="Password" onChange={this.handleInputChange}/>
               </Grid>
               <Grid containe justify="center">
                    <YddInput name="password2" placeholder='请再次输入注冊密码' type="Password" onChange={this.handleInputChange}/>
               </Grid>
                <Grid item justify="center" wrap="nowrap" spacing={8}>
                    <ColorButton onClick={this.handleLoginClick} variant="contained" color="primary" className={classes.margin}>
                        注 &nbsp;&nbsp;  册
                    </ColorButton>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        userId: state.SendUserIdReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        patientRegister: (patientInfo) => {
            dispatch(patientRegister(patientInfo))
        },
    }
}
const styles = theme => ({
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        alignItems: 'center',
        color: '#333333',
        backgroundColor: '#ffffff',
        paddingTop: theme.spacing(10),
        justifyContent: 'flex-start',
        overflow: 'auto',
        height: '100vh'
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      },
    
    headers: {
        display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '85vw',
          height: '14vw',
      },

    margin: {
        border: '0.6px solid #23d76e',
        width: '85vw',
        height: '11vw',
        fontSize: '5vw',
        marginTop: '13vw',
        borderRadius: '1vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        lineHeight: '1vw',
    },

});

const ColorButton = withStyles((theme) => ({
    root: {
        width: '40vw',
        height: '8vh',
        fontSize: '3.5vh',
        boxShadow: 'inset 0 0 4px #fff',
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        '&:hover': {},
    },
}))(Button);

const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            borderBottom: '0.5vw solid #B9E9E7',
            fontSize: 15,
            width: '80vw',
            height: '9vw',
            marginTop:'5vw',
            outline:'none',
            '&:focus': {
                borderColor:'#B9E9E7',
            },
        },
    }),

)(InputBase);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NameDiv));