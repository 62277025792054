//添加服药提醒页面
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axxxx from '../../assets/images/axxxx.png';
import {ActivityIndicator, DatePickerView, List, Menu} from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css'
import {addRemind} from "../../redux/actions/patients/index-action";
import Toast from "antd-mobile/lib/toast";

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

const remindWeekDays = [
    {
        value: '1',
        label: '周日',
    },
    {
        value: '2',
        label: '周一',
    },
    {
        value: '3',
        label: '周二',
        isLeaf: true,
    },
    {
        value: '4',
        label: '周三',
    }, {
        value: '5',
        label: '周四',
    },
    {
        value: '6',
        label: '周五',
        isLeaf: true,
    },
    {
        value: '7',
        label: '周六',
        isLeaf: true,
    },
];

const remindTypes = [
    {
        value: '1',
        label: '服药',
        isLeaf: true,
    },
    {
        value: '2',
        label: '查痰',
    },
    {
        value: '3',
        label: '确诊',
    },
]


class RemindAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeValue: now,
            weekDaysValue:[],
            remindTypeValue:null,
            initWeekDays: '',
            initRemindTypes: '',
            weekDaysShow: false,
            remindTypesShow: false,
            time: now,
            selectedWeekDays:'每天',
            selectedRemindType:'服药',

        };
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleWeekDaysClick = this.handleWeekDaysClick.bind(this);
        this.handleRemindTypesClick = this.handleRemindTypesClick.bind(this);
        this.onWeekDaysOk = this.onWeekDaysOk.bind(this);
        this.onWeekDaysCancel = this.onWeekDaysCancel.bind(this);
        this.onWeekDaysMaskClick = this.onWeekDaysMaskClick.bind(this);
        this.onRemindTypesMaskClick = this.onRemindTypesMaskClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.onTimeChange=this.onTimeChange.bind(this);
        this.onTimeValueChange=this.onTimeValueChange.bind(this);
        this.onChangeWeekDays=this.onChangeWeekDays.bind(this);
        this.onChangeRemindType=this.onChangeRemindType.bind(this);
        this.selectedWeekDays=this.selectedWeekDays.bind(this);
        this.selectedRemindType=this.selectedRemindType.bind(this);
        this.formatDate=this.formatDate.bind(this);

    }

    onTimeChange(value){
        this.setState({timeValue:value});
    }

    onTimeValueChange(...args){

    }

    onChangeWeekDays(item){
       this.setState({
        weekDaysValue:item,
        selectedWeekDays:this.selectedWeekDays(item),
       });
    }

    onChangeRemindType(item){
        this.setState({
            remindTypeValue:item,
            remindTypesShow:false,
            selectedRemindType:this.selectedRemindType(item),
       });
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
       if (nextProps.addRemindCode != this.props.addRemindCode) {
          if (nextProps.addRemindCode === 200) {
             Toast.show("添加成功！");
          }
       }
    }

//点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    onWeekDaysOk(value) {
        this.onWeekDaysCancel();
    }

    onWeekDaysCancel() {
        this.setState({weekDaysShow: false});
    }

    //点击保存
    handleSaveClick() {
       let remindStatus = 1;
       if(this.state.remindTypeValue && this.state.remindTypeValue.length>0){
          remindStatus = this.state.remindTypeValue[0]*1;
       }else{
          remindStatus = 1;
       }
        let returnItem={
            timeValue:this.formatDate(this.state.timeValue)+':00',
            isOn:1,//是否开启
            weekDays:this.state.weekDaysValue,
            remindType: remindStatus
        };
        this.props.addRemind(returnItem);
       Toast.show("添加成功！");
       this.props.history.goBack();
       // this.props.history.push(`/patientIndex/remindlist`);
       window.location.reload();
    }

    onWeekDaysMaskClick() {
        this.setState({
            weekDaysShow: false,
        });
    }

    onRemindTypesMaskClick() {
        this.setState({
            remindTypesShow: false,
        });
    }

    handleWeekDaysClick(e) {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            weekDaysShow: !this.state.weekDaysShow,
        });

        this.setState({
                    initWeekDays: remindWeekDays,
                });
    }

    handleRemindTypesClick(e) {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            remindTypesShow: !this.state.remindTypeshow,
        });
        this.setState({
            initRemindTypes: remindTypes,
        });
    }

    selectedWeekDays(item){
        let showText='';
       if(item){
        item.forEach(element => {
            let index=Number(element);
            showText+=remindWeekDays[index-1].label;
            showText+=' ';
          });
          return showText;

       }
       else{
           return "请选择";
       }
    }

    selectedRemindType(item){
        let showText='';
       if(item){
        item.forEach(element => {
            let index=Number(element);
            showText+=remindTypes[index-1].label;
          });
          return showText;

       } else{
           return "服药";
       }
    }

    formatDate(date) {
        /* eslint no-confusing-arrow: 0 */
        const pad = n => n < 10 ? `0${n}` : n;
        const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
        return `${timeStr}`;
      }


    render() {
        const {classes} = this.props;
        const {initWeekDays, weekDaysShow, initRemindTypes, remindTypesShow} = this.state;
        const menuWeekDays = (
            <Menu
                className={classes.singleMultiFooMenu}
                data={initWeekDays}
                value={this.state.weekDaysValue}
                level={1}
                onChange={this.onChangeWeekDays}
                onOk={this.onWeekDaysOk}
                onCancel={this.onWeekDaysCancel}
                height={document.documentElement.clientHeight*0.6}
                multiSelect
            />
        );

        const menuRemindTypes = (
            <Menu
                className={classes.singleFooMenu}
                data={initRemindTypes}
                value={this.state.remindTypeValue}
                level={1}
                onChange={this.onChangeRemindType}
                height={document.documentElement.clientHeight * 0.6}
            />
        );

        const loadingWeekDays = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: document.documentElement.clientHeight * 0.6,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large"/>
            </div>
        );
        const loadingRemindTypes = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: document.documentElement.clientHeight * 0.6,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large"/>
            </div>
        );

        return (
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid className={classes.Patient}>

                    <Grid item xs={3} className={classes.headers}>
                        <img alt='' src={axxxx} className={classes.jiantou}
                             onClick={this.handleNavBack}></img><h4 className={classes.huanzhe}>添加提醒</h4></Grid>

                    <Grid item xs={4} className={classes.headers}>
                        <Typography>
                            <h4 onClick={this.handleSaveClick} className={classes.xiabu}>保存</h4>
                        </Typography>
                    </Grid>

                </Grid>

                <Grid container justify="flex-start">

                    <Grid container justify="center" alignItems="flex-start" spacing={0}>
                        <Grid item xs={12}>
                            <Grid className={classes.TimeDiv}>
                                <DatePickerView
                                    mode="time"
                                    value={this.state.timeValue}
                                    onChange={this.onTimeChange}
                                    onValueChange={this.onTimeValueChange}
                                />
                            </Grid>

                            <List style={{backgroundColor: 'white'}} className="picker-list">
                                <List.Item onClick={this.handleWeekDaysClick} arrow="horizontal">重复提醒 {this.state.selectedWeekDays}</List.Item>
                                <List.Item onClick={this.handleRemindTypesClick} arrow="horizontal">提醒类型 {this.state.selectedRemindType}</List.Item>
                            </List>
                            {weekDaysShow ? initWeekDays ? menuWeekDays : loadingWeekDays : null}
                            {weekDaysShow ?
                                <div className={classes.menuMask} onClick={this.onWeekDaysMaskClick}/> : null}

                            {remindTypesShow ? initRemindTypes ? menuRemindTypes : loadingRemindTypes : null}
                            {remindTypesShow ?
                                <div className={classes.menuMask} onClick={this.onRemindTypesMaskClick}/> : null}

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        addRemindCode: state.medicineReducer.addRemindCode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addRemind: (item) => {
          dispatch(addRemind(item))
        },
    }
}


//css region
const styles = theme => ({

    root: {
        flexGrow: 1,
        overflow: 'hidden',
        // padding: theme.spacing(0, 3),
        height: '100vh',
        background: '#ffffff',
    },

    TimeDiv: {
        paddingTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: theme.spacing(0),
        marginLeft: '0vw',
    },
    xiabu: {
        color: '#ffffff',
        fontWeight: '500',
        lineHeight: '14vw',
        Height: '14vw',
        marginLeft: '15vw',
        padding: 0,
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw',
        marginRight: '0vw',

    },
    headers: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
    },

    buttonDiv: {
        background: '#ffffff',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    spanDiv: {
        color: '#8BEAF0',
        margin: '2vw'
    },
    singleMultiFooMenu: {
        position: 'absolute',
        zIndex: '70 !important',
        width: '100%',
    },
    singleFooMenu: {
        position: 'absolute',
        zIndex: '90 !important',
        width: '100%',
    },
    menuMask: {
        content: ' ',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        opacity: 0.4,
        zIndex: 69,
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RemindAdd));
