import React from "react";
import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./redux/sagaEffects";
import patientManageSaga from './redux/sagaEffects/patient_manage'
import introduceReducer from "./redux/reducers/introduceReducer";
import trackReducer from "./redux/reducers/trackReducer";
import patientManageReducer from "./redux/reducers/patientManageReducer";
import myInfoReducer from "./redux/reducers/myInfoReducer";
import appReducer from "./redux/reducers/appReducer";
import loginReducer from "./redux/reducers/loginReducer";

//患者端-reducer 引入
import patientLoginReducer from "./redux/reducers/patients/loginReducer";
import medicineReducer from './redux/reducers/patients/indexReducer';
import consultReducer from './redux/reducers/patients/consultReducer';


//患者端-saga 引入
import patientConsultSaga from './redux/sagaEffects/patients/saga-consult'
import patientLoginSaga from './redux/sagaEffects/patients/saga-login'
import patientIndexSaga from './redux/sagaEffects/patients/saga-index'

//定制逻辑-reducer引入
import customReducer from "./redux/reducers/customReducer";

//定制逻辑-saga 引入
import customSaga from "./redux/sagaEffects/custom";

//news
import newsReducer from "./redux/reducers/newsReducer";

import AppRouter from "./AppRouter";
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//combine divided reducer
const reducer = combineReducers({
  //将2个小状态合并
  appReducer,
  introduceReducer, //患者推介
  trackReducer, //患者追踪
  patientManageReducer, //患者管理
  myInfoReducer, //我的
  loginReducer, //登录（医生端和患者端）
  medicineReducer,//患者服药管理

    patientLoginReducer,
    consultReducer,
    newsReducer,//所有新闻相关
    customReducer,//所有定制化相关
});

// mount it on the Store
const store = createStore(
  reducer,
  compose(
    applyMiddleware(sagaMiddleware)
  )
);

// then run the saga
sagaMiddleware.run(rootSaga);
sagaMiddleware.run(patientManageSaga);

//患者端saga引入
sagaMiddleware.run(patientIndexSaga);
sagaMiddleware.run(patientLoginSaga);
sagaMiddleware.run(patientConsultSaga);

//定制化saga
sagaMiddleware.run(customSaga);

function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
