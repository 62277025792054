//服药提醒列表
//添加服药提醒页面
import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {IconButton} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import axxxx from '../../assets/images/axxxx.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SwitchUI from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {remindListAction, remindOnClose, deleteRemoveRemind} from "../../redux/actions/patients/index-action";
import RemindAdd from './RemindAdd';
import Toast from "antd-mobile/lib/toast";

class RemindList extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         timeValue: null,
         remindList: [],
      };
      this.handleNavBack = this.handleNavBack.bind(this);
      this.handleToggle = this.handleToggle.bind(this);
      this.handleAddClick = this.handleAddClick.bind(this);
      this.formatWeekDay = this.formatWeekDay.bind(this);
      this.handleRemindDelete = this.handleRemindDelete.bind(this);
      this.handleOnClose = this.handleOnClose.bind(this);
   }

   componentDidMount() {
      this.props.queryRemindList(this.state.userId * 1);
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.remindItem !== this.props.remindItem) {
         this.setState({
            remindList: [...this.state.remindList, nextProps.remindItem]
         });
      }

   }

//点击返回
   handleNavBack() {
      this.props.history.goBack();
   }

   handleToggle(e) {

      let remindListTmp = [...this.props.remindList];
      let index = parseInt(e.target.value);
      remindListTmp[index].isOn = e.target.checked ? 1 : 0;
      this.setState({
         remindList: remindListTmp,
      })
   }

   /*开关提醒*/
   handleOnClose(event){
       let onCloseParam = {};
       onCloseParam.id = event.id;
       onCloseParam.isOn = event.isOn==1?0:1;
       this.props.remindOnClose(onCloseParam);
       Toast.show("修改成功！");
   }

   /*删除提醒*/
   handleRemindDelete(row) {
      this.props.deleteRemoveRemind(row);
      Toast.show("删除成功！");
      setTimeout(()=>{window.location.reload();},500)
      /*this.state.remindList.splice(index, 1);
      this.setState({
         remindList: [...this.state.remindList],
      })*/
   }

   handleAddClick() {
      const path = `${this.props.match.path}/remindadd`;
      // this.props.history.location.state = {}
      this.props.history.push(path);
   }

   formatWeekDay(item) {

      let formatText = "";
      let len = item.weekDays.length;
      if (len === 7 || len === 0) {
         formatText = "每天";
         return formatText;
      }
      for (let i = 0; i < item.weekDays.length; i++) {
         switch (item.weekDays[i]) {
            case '1':
               formatText += "周日 ";
               break;
            case '2':
               formatText += "周一 ";
               break;
            case '3':
               formatText += "周二 ";
               break;
            case '4':
               formatText += "周三 ";
               break;
            case '5':
               formatText += "周四 ";
               break;
            case '6':
               formatText += "周五 ";
               break;
            case '7':
               formatText += "周六 ";
               break;
            default:
               break;

         }
      }
      return formatText;
   }

   render() {
      const {classes, remindList} = this.props;

      let that = this;

      return (
         <Switch>
            <Route path={`${this.props.match.path}/remindadd`} component={RemindAdd}/>
            <Grid container direction="column" alignItems="center" className={classes.root}>
               <Grid container className={classes.title}>
                  <Grid container justify='center' alignItems="center" item xs={1}>
                     <img alt="" src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}/>
                  </Grid>
                  <Grid container item xs={10}>
                     <Grid container justify='center' alignItems="center" className={classes.headers}><h4 className={classes.huanzhe}>我的提醒</h4></Grid>
                  </Grid>
               </Grid>

               <Grid container direction="column" justify="space-evenly">
                  <Grid item>
                     <List>
                        {(remindList || []).map((item, index) => {
                           return (
                              <ListItem className={classes.timeDiv} key={index}>
                                 <ListItemText id={"switch-label-" + index} primary={item.timeValue}
                                               secondary={that.formatWeekDay(item)}/>

                                 <Grid item xs={4}>
                                    <Typography>{item.remindRtpe}</Typography>
                                 </Grid>

                                 <Grid item xs={4}>
                                    <ListItemSecondaryAction>
                                       <SwitchUI
                                          value={index.toString()}
                                          edge="end"
                                          onClick={this.handleOnClose.bind(this, item)}
                                          onChange={this.handleToggle} className={classes.timeDiv}
                                          checked={item.isOn > 0}
                                          inputProps={{'aria-labelledby': 'switch-list-label-wifi'}}
                                       />
                                       <IconButton onClick={this.handleRemindDelete.bind(this, item)}
                                                   className={classes.colorDiv} aria-label="delete">
                                          <DeleteIcon/>
                                       </IconButton>
                                    </ListItemSecondaryAction>
                                 </Grid>
                              </ListItem>
                           )
                        })
                        }

                     </List>
                  </Grid>

                  <Grid container direction="row" justify="center" alignItems="flex-end">
                     <Fab onClick={this.handleAddClick} aria-label="add" className={classes.addClickDIV}><AddIcon/></Fab>
                  </Grid>
               </Grid>
            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      remindItem: state.medicineReducer.remindItem,
      remindList: state.medicineReducer.remindList,
      onCloseRemind: state.medicineReducer.onCloseRemind,
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      queryRemindList: (userId) => {
         dispatch(remindListAction(userId))
      },
      remindOnClose: (onClose) => {
         dispatch(remindOnClose(onClose))
      },
      deleteRemoveRemind: (remind) => {
         dispatch(deleteRemoveRemind(remind))
      }
   }
}


//css region
const styles = theme => ({

   root: {
      overflow: 'auto',
      height: '100vh',
      background: '#ffffff',
   },

   TimeDiv: {
      paddingTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
   },
   title: {
      width: '100vw',
      height: '8vh',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      marginBottom: theme.spacing(3),
      marginLeft: '0vw',
   },
   xiabu: {
      color: '#ffffff',
      fontWeight: '500',
      lineHeight: '14vw',
      Height: '14vw',
      marginLeft: '15vw',
      padding: 0,

   },
   jiantou: {
      height: '5vw',
      float: 'left',
      marginLeft: '4vw',
      marginRight: '0vw',

   },
   headers: {
      height: '8vh',
   },

   buttonDiv: {
      background: '#ffffff',
   },

   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
   },
   spanDiv: {
      color: '#8BEAF0',
      margin: '2vw'
   },
   singleMultiFooMenu: {
      position: 'absolute',
      zIndex: '70 !important',
      width: '100%',
   },
   singleFooMenu: {
      position: 'absolute',
      zIndex: '90 !important',
      width: '100%',
   },
   menuMask: {
      content: ' ',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.4,
      zIndex: 69,
   },

   addClickDIV: {
      color: '#ffffff',
      backgroundColor: '#00DDD8',
      // marginTop: theme.spacing(30),
   },

   colorDiv: {
      color: '#00DDD8',
   },

   timeDiv: {
      color: '#00DDD8',
      fontWeight: '900',
      // fontSize: '10vw',
   },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RemindList));
