import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {querySputumCheckRecord} from '../../redux/actions/patient_manage_action';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import axxxx from '../../assets/images/axxxx.png';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Resistant from '../common/resistant'
import Modal from "@material-ui/core/Modal/Modal";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});
const columns = [
    {id: 'actualTime', label: '就诊日期', minWidth: 40, align: 'center'},
    {id: 'orderTime', label: '预约日期', minWidth: 50, align: 'center'},
];

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

//患者管理-通知-复诊查痰页面
class CaseVisitRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            rows: [],
            patientId: 0,
            showModal: false,
            showsModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId;
        this.props.patientSputumCheckRecord(this.state.patientId);
    }

    componentWillUnmount() {

    }

    handleRowClick() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    ClosesModal = () => {
        this.setState({showsModal: false})
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        //get react-material css styles
        const {classes, sputumRecord} = this.props;

        return (
            <Grid className={classes.root}>
                <Grid item xs={12} className={classes.Patient}><img src={axxxx} className={classes.jiantou}
                                                                    onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h4 className={classes.huanzhe}>复诊查痰记录</h4></Grid>
                </Grid>
                <Grid className={classes.rootDiv}>
                    <Grid item xs={7}>
                        <h4><span className={classes.spanDiv}>一</span><span>{sputumRecord.patientName}</span>就诊计划<span
                            className={classes.spanDiv}>一</span></h4>
                    </Grid>
                    <Grid container justify='center' wrap="nowrap" direction="column" spacing={0}>
                        <Grid item container justify='center'>
                            <div className={classes.tableWrapper}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <TableCell
                                                    alignCenter
                                                    key={column.id}
                                                    align={column.align}
                                                    padding={true ? 'none' : 'default'}
                                                    style={{minWidth: column.minWidth}}
                                                >
                                                    <Typography className={classes.titleBold} color="textSecondary">
                                                        {column.label}
                                                    </Typography>


                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(sputumRecord.orderCheckList || []).map(row => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.index}>
                                                    {columns.map(column => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell alignCenter key={column.id}
                                                                       padding={true ? 'none' : 'default'}
                                                                       align={column.align}
                                                                       style={{minWidth: column.minWidth}}>
                                                                <Typography className={classes.titleNormal}
                                                                            color="textSecondary">
                                                                    {value}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <h4><span className={classes.spanDiv}>一</span><span>{sputumRecord.patientName}</span>查痰计划<span
                            className={classes.spanDiv}>一</span></h4>
                    </Grid>
                    <Grid item xs={12} className={classes.informations}>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                           {/*患者类型<span className={classes.spans}>{Resistant(sputumRecord.type)}</span>*/}
                           患者类型：<span className={classes.spansPans}>{sputumRecord.type}</span>
                        </Typography>
                        <hr className={classes.hr}></hr>
                        {/* <hr className={classes.hr}></hr> */}
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            下发日期：<span className={classes.spans}>{sputumRecord.createTime}</span>
                        </Typography>
                        {/* <Divider /> */}
                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            首次查痰：<span className={classes.spans}>{sputumRecord.firstSputumTime}</span>
                        </Typography>
                        {/* <Divider /> */}
                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            二次查痰：<span className={classes.spans}>{sputumRecord.secondSputumTime}</span>
                        </Typography>
                        {/* <Divider /> */}
                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            三次查痰：<span className={classes.spans}>{sputumRecord.thirdSputumTime}</span>
                        </Typography>
                        {/* <Divider /> */}
                        <hr className={classes.hr}></hr>
                    </Grid>

                    <Grid container justify="center" className={classes.buttonBox}>
                        <a href={"tel:" + 13800138000}>  {/*TODO 还需要对接接口*/}
                            <Button variant="contained" color="primary" className={classes.margin}>
                                联系患者
                            </Button>
                        </a>
                    </Grid>
                </Grid>
                <Modal
                    className={classes.modal}
                    open={this.state.showsModal}
                    onClose={this.ClosesModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Grid className={classes.papers}>
                        <Grid container justify="center" alignItems="center">
                            <Typography className={classes.ridDiv}> 呼叫</Typography>
                        </Grid>
                    </Grid>

                </Modal>
            </Grid>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        sputumRecord: state.patientManageReducer.sputumRecord,
        dateList: state.patientManageReducer.takeMedicine.orderCheckList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        patientSputumCheckRecord: (patinetId) => {
            dispatch(querySputumCheckRecord(patinetId))
        },
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
        height: '100vh',
    },

    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    papers: {
        width: '85vw',
        backgroundColor: '#ffffff',
        fontSize: '4.2vw',
    },
    ridDiv: {
        fontSize: '4.2vw',
        padding: theme.spacing(2),
    },
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
        alignItems: 'center',
        background: '#ffffff',

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: theme.spacing(2),
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    spanDiv: {
        color: '#8BEAF0',
        margin: '2vw'
    },
    information: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    informations: {
        width: '90vw',
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        margin: '2vw',
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90vw',
        height: '10vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    pDiv: {
        color: '#ffffff',
        marginLeft: '15vw'
    },
    psDiv: {
        color: '#ffffff',
    },
    span: {
        float: 'right',
        marginRight: '10vw'
    },
    title: {
        marginLeft: '13vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333',
        marginTop: '2vw'
    },
   spansPans:{
      color: '#999999',
      marginLeft: '3vw'
   },
    spans: {
        color: '#999999'
    },
    titleDiv: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333',
        marginTop: '2vw'
    },
    cardbutton: {
        display: 'flex',
        flexDirection: 'row',
    },
    margin: {
        padding: 0,
        height: '10vw',
        width: '90vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '5vw',
        lineHeight: '10vw',
        marginTop: '7vw',
        marginBottom: '7vw'
    },

    tableWrapper: {
        width: '90vw',
        maxHeight: "40vh",
        marginBottom: theme.spacing(2),
        overflow: "auto"
    },
    titleBold: {
        backgroundColor: '#00DFD7',
        height: '10vw',
        lineHeight: '10vw',
        color: '#ffffff'
    },
    titleNormal: {
        height: '12vw',
        lineHeight: '12vw',
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CaseVisitRecord));
