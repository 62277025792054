import React, {useState} from 'react';
import clsx from "clsx";
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {queryPatientTakeMedicineRecord} from '../../redux/actions/patient_manage_action';
import Grid from '@material-ui/core/Grid';
import {createMuiTheme, IconButton} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import axxxx from '../../assets/images/axxxx.png';


// 引入ant design 日历控件
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import cnLocale from "date-fns/locale/zh-CN"
import lightBlue from "@material-ui/core/colors/lightBlue";
import ManageType from '../../doctor/common/ManageType'

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightBlue.A200,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            day: {
                color: lightBlue.A700,
            },
            daySelected: {
                backgroundColor: lightBlue["400"],
            },
            dayDisabled: {
                color: lightBlue["100"],
            },
            current: {
                color: lightBlue["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: lightBlue["400"],
            },
        },
    },
});



//服药康复-我的服药记录页面
class MyMedRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            currentDate: new Date(),
            mededDate: [],
            patientId:0,
           showTextEvent: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.renderWrappedMedDay = this.renderWrappedMedDay.bind(this);
        this.isWithinMededDates = this.isWithinMededDates.bind(this);
    }

    componentDidMount() {
        this.state.patientId = localStorage.getItem("patientId");
        this.props.getTakingMedicine(this.state.patientId);
    }

    componentWillUnmount() {

    }



    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

//点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    //判断某天是否是服药的天
    isWithinMededDates(date) {
        let dateClone = new Date(date.getTime());
        if (this.props.dateList) {
            this.state.mededDate = this.props.dateList;
            for (let i = 0; i < this.state.mededDate.length; i++) {
                let mededDate = new Date(this.state.mededDate[i]);
                if (isSameDay(dateClone, mededDate)) {
                    return true;
                }
            }
        }
        return false;
    }

    renderWrappedMedDay(date, selectedDate, dayInCurrentMonth) {
        const {classes} = this.props;

        let dateClone = new Date(date.getTime());

        let size = this.state.mededDate.length;
        const start = new Date(this.state.mededDate[0]);
        const end = new Date(this.state.mededDate[size - 1]);

        const dayIsBetween = this.isWithinMededDates(dateClone);
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d", {locale: cnLocale})} </span>
                </IconButton>
            </div>
        );
    }

    render() {
        const {classes,takeMedicine} = this.props;
        let detailHiddenText = '';
       if(window.location.search && window.location.search !== null && window.location.search !== ''){
          detailHiddenText = window.location.search.split("flag=")[1]+'';
       }

        return (
            <Grid container justify="center" className={classes.root}>
                <Grid className={detailHiddenText=='12'?classes.hiddenView:classes.Patient}>
                   <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h4 className={classes.huanzhe}>服药记录</h4></Grid>
                </Grid>
                <Grid container justify="center" spacing={0}>
                    <h4><span className={classes.spanDiv}>一</span>我的服药记录<span className={classes.spanDiv}>一</span></h4>
                </Grid>

                {/* 日历控件 */}

                <MuiPickersUtilsProvider utils={DateFnsUtils} theme={materialTheme} locale={cnLocale}>
                    <DatePicker
                        disableToolbar
                        autoOk
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={this.state.currentDate}
                        renderDay={this.renderWrappedMedDay}
                    />
                </MuiPickersUtilsProvider>


                <Grid container justify="center" spacing={3}>
                    <h4><span className={classes.spanDiv}>一</span>我的管理方式<span className={classes.spanDiv}>一</span></h4>
                </Grid>
                <Grid container justify="flex-start" spacing={0} className={classes.informations}>
                    <hr className={classes.hr}></hr>
                    <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        管理方式：
                       <span className={takeMedicine.type==0?classes.spanAppYaohe:classes.spanHiddenApp} >无</span>
                       <span className={classes.spans}>{ManageType(takeMedicine.type)}</span>
                    </Typography>
                    <hr className={classes.hr}></hr>
                    {/* <hr className={classes.hr}></hr> */}
                    <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        药盒状态：
                       <span className={takeMedicine.type==2?classes.spanAppYaohe:classes.spanHiddenApp}>
                           <span className={classes.spans}>电量：<span>{takeMedicine.battery}</span>  信号：<span>{takeMedicine.single}</span></span>
                       </span>
                       <span className={(takeMedicine.type==1 || takeMedicine.type==0)?classes.spanAppYaohe:classes.spanHiddenApp}>无</span>
                    </Typography>
                    {/* <Divider /> */}
                    <hr className={classes.hr}></hr>
                    <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                        依从性：<span className={classes.spans}>{takeMedicine.yicong}</span>
                    </Typography>
                    {/* <Divider /> */}
                    <hr className={classes.hr}></hr>
                </Grid>

            </Grid>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo : state.serverInfo,
        takeMedicine : state.patientManageReducer.takeMedicine,
        dateList: state.patientManageReducer.takeMedicine.recordList,
        sputumRecord : state.patientManageReducer.sputumRecord
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      //服药记录
      getTakingMedicine: (patientId) => {
          dispatch(queryPatientTakeMedicineRecord(patientId))
      },
  }
}


//css region
const styles = theme => ({
   spanHiddenApp:{
      display: 'none',

   },

   spanAppYaohe: {
      color: '#999999',
      display: 'inline-block'
   },
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        // padding: theme.spacing(0, 3),
        height: '100vh',
    },
   hiddenView: {
       display: 'none',
   },
   showView: {
      display: 'block',
   },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    spanDiv: {
        color: '#8BEAF0',
        margin: '2vw'
    },
    informations: {
        width: '90vw',
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        // margin:'2vw',
        width: "90vw"
    },
    spans: {
        color: '#999999'
    },
    title: {
        width: '80vw',
        textAlign: 'justify',
        textAlignLast: 'justify'
    },
    titleDiv: {
        // marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333',
        marginBottom: 0
    },
    margin: {
        margin: theme.spacing(1),
        height: '10vw',
        width: '90vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '5vw',
        marginTop: '7vw',
        marginBottom: '7vw'
    },
    buttonBox: {
        width: '100vw',
    },
    calendarList: {
        backgroundColor: 'gray',
    },

    //日历样式
    // dayWrapper: {
    //   position: "relative",
    // },
    day: {
        width: 40,
        height: 40,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,

    },
    highlightNonCurrentMonthDay: {
        color: "#676767",

    },
    highlight: {
        // background: theme.palette.primary.main,
        backgroundColor: '#00DFD7',
        color: theme.palette.common.white,
        borderRadius: "50%",
    },
    firstHighlight: {
        extend: "highlight",
        borderRadius: "50%",
        // borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderRadius: "50%",
        // borderBottomRightRadius: "50%",
    },
    pickerDiv: {
        width: '100vw',
        height: '100vw',
        backgroundColor: 'red'
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyMedRecord));
