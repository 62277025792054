import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
            viewportWidth: 0,
            viewportHeight: 0,
        };
    }

    render() {
        //get react-material css styles
        const {classes} = this.props;
        return (
            <div className={classes.divid}>
                <div className={classes.singleMenuDiv} onClick={this.props.onMenuClick}>
                    <div className={classes.singleMenuTextDiv}>
                        <p className={classes.labelDiv}>{this.props.label}</p>
                        <p className={classes.detailDiv}>{this.props.detail}</p>
                    </div>
                    {/* {this.props.icon} */}
                    {/*       */}
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={this.props.noticeNum} color="secondary">
                            <img src={this.props.tabImage} className={classes.menuImage2}/>
                        </Badge>
                    </IconButton>

              
                    
                    {/*<img src={this.props.tabImage} className={classes.menuImage2}/>*/}
                    
                </div>
            </div>
        )
    }
}

//css region
const styles = theme => ({
    divid: {
        // display:' inline-block',
        margin: '0px auto',
        width: '50vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    singleMenuDiv: {
        display: 'flex',
        flexDirection: ' row ',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50vw',
        height: '14vh',
        border: '0.1vw solid #e6e6e6',
    },

    singleMenuTextDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        lineHeight: '0vh',
        justifyContent: 'center',
        paddingLeft: '3vw',
        marginRight: '0',
    },

    labelDiv: {
        display: 'flex',
        fontSize: '4.2vw',
        justifyContent: 'flex-start',
    },

    detailDiv: {
        color: '#999999',
        fontSize: '3vw',
        marginTop: '0.8vh',
        marginRight: '0',
    },

    menuImage2: {
        width: '9vw',
        height: '9vw',
        maxWidth: '9vw',
        maxHeight: '9vw',
        borderRadius: "50%",
        marginRight: '5vw',


    },
});

export default withStyles(styles)(MainMenu);