import React from 'react';
import {connect} from 'react-redux';
import {createStyles, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import axxxx from '../../assets/images/axxxx.png';
import Toast from "antd-mobile/lib/toast";
import {modifyPatientPwd} from "../../redux/actions/patients/login-action";

//我的-设置-密码更改页
class NameDiv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password:'',
            ensurePwd:'',
            userId: localStorage.getItem("userId"),
            patientId: localStorage.getItem("patientId")
        };
        this.handleReturnClick = this.handleReturnClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
     //点击返回
     handleReturnClick() {
        this.props.history.goBack();
    }
    //点击保存
    handleSaveClick() {
        if(this.state.password != this.state.ensurePwd){
            Toast.show("两次输入密码不一致！")
            return false;
        }

        const patientInfo = {
            id : this.state.patientId,
            userId : this.state.userId,
            newpassword : this.state.password, //修改密码
            type : 3,
        };

        this.props.modifyPwd(patientInfo);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.modifyPwdSucceed != this.props.modifyPwdSucceed) {
            if (nextProps.modifyPwdSucceed === 200) {
                this.props.history.go(-1);
            }
        }
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.rootDiv}>

                <Grid container xs={12} justify="flex-start" alignContent="center" className={classes.Patient}>
                    <Grid item xs={5}>
                        <Typography>
                        <h4 className={classes.jiantou}><img src={axxxx} onClick={this.handleReturnClick} className={classes.jiantou}></img></h4>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>
                            <h4 className={classes.huanzhe}>密码</h4>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            <h4 onClick={this.handleSaveClick} className={classes.xiabu}>保存</h4>
                        </Typography>
                    </Grid>
                </Grid>

               <Grid container xs={12} justify="center">
                <YddInput
                        name="password"
                        placeholder='请输入新密码'
                        onChange={this.handleInputChange}
                        value={this.state.password}
                        type="Password"
                    />
               </Grid>


               <Grid container xs={12} justify="center">
                <YddInput
                        name="ensurePwd"
                        placeholder='请再次输入新密码'
                        onChange={this.handleInputChange}
                        value={this.state.ensurePwd}
                        type="Password"
                    />
               </Grid>


               

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modifyPwdSucceed : state.patientLoginReducer.modifyPwdSucceed
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        modifyPwd: (patientInfo) => {
            dispatch(modifyPatientPwd(patientInfo))
        }
    }
}
const styles = theme => ({
    rootDiv: {
        flexGrow: 1,
        overflow: 'hidden',
        height: '100vh',
    },
    Patient: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      },
      jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
      },
    huanzhe: {
        color: '#ffffff',
        fontWeight: '500',
        lineHeight:'12vw',
        margin:0,
    },
    xiabu: {
        color: '#ffffff',
        fontWeight: '500',
        lineHeight:'12vw',
        Height:'12vw',
        margin:0,
        padding:0
    },
    headers: {
        display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '85vw',
          height: '14vw',
      },
    
    
});


const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            borderBottom: '0.5vw solid #B9E9E7',
            fontSize: 15,
            width: '80vw',
            height: '9vw',
            marginTop:'5vw',
            outline:'none',
            '&:focus': {
                borderColor:'#B9E9E7',
            },
        },
    }),
)(InputBase);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NameDiv));