import React, {Fragment} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {myPatients, queryMedicationRecord} from "../../redux/actions/patient_manage_action";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const columns = [
    {id: "patientName", label: "姓名", minWidth: 50, align: "center"},
    {id: "status", label: "状态", minWidth: 40, align: "center"},
    {id: "createtime", label: "下发时间", minWidth: 40, align: "center", format: value => value.toLocaleString()},
    {id: "result", label: "诊断结果", minWidth: 50, align: "center", format: value => value.toLocaleString()}
];

const medicineColumns = [
    {id: "patientName", label: "姓名", minWidth: 50, align: "center"},
    {id: "medicationStatusName", label: "服药状态", minWidth: 40, align: "center"},
    {id: "manageTypeName", label: "管理方式", minWidth: 40, align: "center"},
    {id: "boxStatus", label: "药盒状态", minWidth: 50, align: "center"}
];

//我的患者-我的患者主页面
class ManageMy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId : localStorage.getItem("userId"),
            doctorId : localStorage.getItem("doctorId"),
            tabulation: true,
            rows: []
        };

        this.handleDetails = this.handleDetails.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleTakingMedicine = this.handleTakingMedicine.bind(this);
    }

    handleLogin() {
        this.setState({
            tabulation: false
        });
    }

    handleDetails() {
        this.setState({
            tabulation: true
        });
    }

    componentDidMount() {
        const loginInfo = {
            userId : this.state.userId,
        }
        this.props.getMyPatients(loginInfo);
        this.props.MedicationRecord(loginInfo);
    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.bottomIndex === 1) {
            this.setState({
                tabulation: 0,
            });
        }
        if(nextProps.retCode === 403){
            window.location.href = "/doctor"
        }
    }

    //我的患者行点击事件
    handleRowClick(patientId) {
        const path = `${this.props.match.path}/patientDetail`;
        this.props.history.push(path,{"patientId":patientId});
    }

    //服药情况点击表格的某一行
    handleTakingMedicine(patientId) {
        const path = `${this.props.match.path}/TakingMedicine`;
        this.props.history.push(path,{"patientId":patientId});
    }


    render() {
        const {classes, patients, medicationRecord} = this.props;

        return (
            <div className={classes.root}>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                {this.state.tabulation ? (
                    <Fragment>
                        <Grid container justify="center" className={classes.textDiv}>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>当前在管</Typography>
                                <Typography>
                                    <span className={classes.title}>{patients.manageNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>新增</Typography>
                                <Typography>
                                    <span className={classes.title}>{patients.newNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>已结案</Typography>
                                <Typography>
                                    <span className={classes.title}>{patients.stopTreatNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 患者列表 */}
                        <Grid container justify="center" wrap="nowrap" direction="column" spacing={1}>
                            <Grid item>
                                <div className={classes.tableWrapper}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map(column => (
                                                    <TableCell alignCenter key={column.id} align={column.align}
                                                               padding={true ? "none" : "default"} style={{minWidth: column.minWidth}}>
                                                        <Typography className={classes.titleBold} color="textSecondary">
                                                            {column.label}
                                                        </Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(patients.rows || []).map(row => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.patientId}
                                                        onClick={this.handleRowClick.bind(this, row.patientId)}>
                                                        {columns.map(column => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell alignCenter
                                                                    key={value} padding={true ? "none" : "default"} align={column.align} style={{minWidth: column.minWidth}}>
                                                                    <Typography className={classes.titleNormal} color="textSecondary">
                                                                        {value}
                                                                    </Typography>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid justify="center" wrap="nowrap" direction="column" alignItems="center" spacing={1}>
                        <Grid container justify="center" item xs={12}>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>当前在管</Typography>
                                <Typography>
                                    <span className={classes.title}>{medicationRecord.manageNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>已服药</Typography>
                                <Typography>
                                    <span className={classes.title}>{medicationRecord.takeMedicationNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>未服药</Typography>
                                <Typography>
                                    <span className={classes.title}>{medicationRecord.notTakeMedication}</span>&nbsp;人
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr className={classes.hr}></hr>
                        <Grid container justify="center" item xs={12}>
                            <Grid container className={classes.paperDiv} justify="center">
                                <Typography className={classes.quanDiv}>管理方式</Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>微信</Typography>
                                <Typography>
                                    <span className={classes.title}>{medicationRecord.appManageNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid item className={classes.paper}>
                                <Typography className={classes.quanDiv}>药盒</Typography>
                                <Typography>
                                    <span className={classes.title}>{medicationRecord.boxManageNum}</span>&nbsp;人
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* 服药情况列表 */}
                        <div className={classes.medTableWrapper}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {medicineColumns.map(column => (
                                            <TableCell alignCenter key={column.id} align={column.align} padding={true ? "none" : "default"}
                                                       style={{minWidth: column.minWidth}}>
                                                <Typography className={classes.titleBold} color="textSecondary">
                                                    {column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(medicationRecord.rows || []).map(row => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.patientId}
                                                onClick={this.handleTakingMedicine.bind(this, row.patientId)}>
                                                {medicineColumns.map(column => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell alignCenter key={column.patientId} padding={true ? "none" : "default"}
                                                                   align={column.align} style={{minWidth: column.minWidth}}>
                                                            <Typography className={value === "未服"?classes.titleRed:classes.titleNormal} color="textSecondary">
                                                                {value}
                                                            </Typography>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                )}
                <Grid container justify="center" wrap="nowrap" direction="row" spacing={2} className={classes.fixedBottom}>
                    <Grid item>
                        <Button variant="contained" color="primary" className={ this.state.tabulation ? classes.buttonsTwo : classes.buttonsTwos } onClick={this.handleDetails}>
                            患者情况
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary"
                            className={this.state.tabulation ? classes.buttonsTwos : classes.buttonsTwo} onClick={this.handleLogin}>
                            服药情况
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        retCode: state.patientManageReducer.retCode,
        patients: state.patientManageReducer.patients,
        medicationRecord: state.patientManageReducer.medicationRecord
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //患者管理-我的患者
        getMyPatients: (loginInfo) => {
            dispatch(myPatients(loginInfo))
        },
        MedicationRecord: (loginInfo) => {
            dispatch(queryMedicationRecord(loginInfo))
        }
    };
};

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        backgroundColor: "#ffffff"
    },
    root1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        marginTop: "7vw"
    },

    textDiv: {
        width: "100vw"
    },
    paper: {
        height: "18vw",
        margin: theme.spacing(0, 3)
    },
    paperDiv: {
        height: "18vw",
        width: "20vw",
        display: "flex",
        alignItems: "center"
    },
    hr: {
        height: "0.3vw",
        border: "1.3vw",
        borderBottom: "0.1vw solid #e6e6e6",
        width: "80vw"
    },
    title: {
        fontSize: "6vw",
        color: "#00DDD8",
        fontWeight: "bold"
    },
    quanDiv: {
        marginBottom: "0.5vw",
        padding: 0,
        fontSize: "4.2vw"
    },
    papers: {
        height: "8vw",
        width: "100vw",
        marginBottom: "2vw",
        backgroundColor: "#E9FCFB"
    },
    pDiv: {
        lineHeight: "8vw",
        margin: 0,
        fontSize: "3.8vw",
        color: "#333333"
    },
    spanDiv: {
        color: "#8CF0ED",
        fontSize: "4vw",
        marginLeft: "3vw",
        marginRight: "3vw",
        fontWeight: 700
    },
    table: {},

    tableWrapper: {
        maxHeight: "64vh",
        margin: theme.spacing(0, 2),
        overflow: "auto"
    },

    medTableWrapper: {
        maxHeight: "48vh",
        margin: theme.spacing(0, 2),
        overflow: "auto"
    },
    buttonBox: {
        width: "100vw",
        height: "30vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "4.5vw",
        marginTop: "3vw",
        marginBootm: "10vw"
    },
    cardbutton: {
        width: "45vw",
        display: "flex",
        flexDirection: "row",
        marginbottom: "5vw",
        justifyContent: "space-between"
    },
    spacemargin: {
        margin: theme.spacing(1)
    },
    margin: {
        margin: theme.spacing(1),
        height: "5vh",
        width: "38vw",
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
        fontSize: "4vw",
        marginTop: "2vh"
    },
    buttonsTwo: {
        marginTop: theme.spacing(3),
        width: "40vw",
        height: "10vw",
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
        color: "rgba(255,255,255,1)"
    },
    buttonsTwos: {
        marginTop: theme.spacing(3),
        width: "40vw",
        height: "10vw",
        background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
        color: "rgba(255,255,255,0.5)"
    },
    titleBold: {
        fontSize: "3.8vw",
        lineHeight: "9vw",
        fontWeight: "500",
        backgroundColor: "#00DFD7",
        color: "#ffffff"
    },
    titleNormal: {
        fontSize: "3.5vw",
        lineHeight: "10vw",
        fontWeight: "500",
        color: "#333333"
    },
    titleRed: {
        fontSize: "3.5vw",
        lineHeight: "10vw",
        fontWeight: "500",
        color: "red"
    },
    titleButton: {
        fontSize: "4.2vw",
        lineHeight: "5vh",
        fontWeight: "500",

        margin: theme.spacing(3)
    },
    fixedBottom: {
        position: "fixed",
        left: 0,
        bottom: "8vw"
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ManageMy));
