

// Odds and ends

// Make shortcut icon appear with a green dot + show unread count in title.
export function updateFavicon(count) {
  const oldIcon = document.getElementById('shortcut-icon');
  const head = document.head || document.getElementsByTagName('head')[0];
  const newIcon = document.createElement('link');
  newIcon.type = 'image/png';
  newIcon.id = 'shortcut-icon';
  newIcon.rel = 'shortcut icon';
  newIcon.href = 'img/logo32x32' + (count > 0 ? 'a' : '') + '.png';
  if (oldIcon) {
    head.removeChild(oldIcon);
  }
  head.appendChild(newIcon);

  document.title = (count > 0 ? '('+count+') ' : '') + 'Tinode';
}

// Create VCard which represents topic 'public' info
export function vcard(fn, imageDataUrl) {
  let card = null;

  if ((fn && fn.trim()) || imageDataUrl) {
    card = {};
    if (fn) {
      card.fn = fn.trim();
    }
    if (imageDataUrl) {
      const dataStart = imageDataUrl.indexOf(',');
      card.photo = {
        data: imageDataUrl.substring(dataStart+1),
        type: 'jpg'
      };
    }
  }
  return card;
}

// Deep-shallow compare two arrays: shallow compare each element.
export function arrayEqual(a, b) {
  if (a === b) {
    return true;
  }

  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  // Compare lengths first.
  if (a.length != b.length) {
    return false;
  }
  // Order of elements is ignored.
  a.sort();
  b.sort();
  for (let i = 0, l = a.length; i < l; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

//采用正则校验身份证号

//根据身份证号获取性别 年龄 出生日期
export function analyzeIDCard(IDCard) {
  //正则匹配
  var idcardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  if(!idcardReg.test(IDCard)){
    return null;
  }

  let sexAndAge = {};
  //获取性别
  if (parseInt(IDCard.substr(16, 1)) % 2 == 1) {
    sexAndAge.sex = '1';
  } else {
    sexAndAge.sex = '0';
  }
  //获取出生年月
  sexAndAge.year = IDCard.substring(6, 10);
  sexAndAge.month = IDCard.substring(10, 12);
  sexAndAge.day = IDCard.substring(12, 14);
  let myDate = new Date();
  let monthNow = myDate.getMonth() + 1;
  let dayNow = myDate.getDay();
  let age = myDate.getFullYear() - sexAndAge.year;
  if (monthNow < sexAndAge.month || (monthNow == sexAndAge.month && dayNow < sexAndAge.day)) {
    age--;
  }
  sexAndAge.age=age;

  return sexAndAge;
}

//递归的方法遍历层级比较深的json,适用于json对象或数组
function parseJson(jsonObj,id) {
  // 循环所有键
  for(var v in jsonObj){
      var element = jsonObj[v]
      // 1.判断是对象或者数组
      if( typeof(element) == 'object'){
          parseJson(element,id)
      }else{
          if(element == id){
          }
      }
  }
}

// 调用：

// parseJson(array,7) //id:7
// parseJson(json,3) // xiaoli:3 xiaohua:3

// var json = {
//   xiaomi:1,
//   xiaohong:2,
//   teamOne:{
//       xiaoli:3,
//       xiaohua:3
//   },
//   teamTwo:{
//       xiaoyong:4
//   }
// }

// var array = [{
//   id: 1,
//   children: [{
//       id: 2,
//       children: []
//   }]
// },
// {
//   id: 3,
//   children: []
// },
// {
//   id: 4,
//   children: [{
//       id: 5,
//       children: [{
//               id: 6,
//               children: []
//           },

//           {
//               id: 7,
//               children: []
//           }
//       ]
//   }]
// }
// ]
