import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {introduceDetail} from '../../redux/actions/index';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import phone from '../../assets/images/phone.png';
import returnImg from '../../assets/images/returnImg.png'
import tishi from '../../assets/images/tishi.png'
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';

//自定义组件
import gender from '../common/sex'
import introduceStatus from '../common/introduceStatus'

//患者推介-我的推介-患者详情页
class IntroduceMyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            showModal: false,
            showsModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
    }


  componentDidMount() {
    let introId = this.props.match.params.introId;
    this.props.getIntroduceById(introId)
  }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    handleOpen = () => {
        this.setState({showModal: true});
    }

    closeModal = () => {
        this.setState({showModal: false});
    }

    handleNavBack() {
        this.props.history.goBack();
    }


  render() {
    //get react-material css styles
    const { classes,introduceDetail } = this.props;
    return (
      <div className={classes.rootDiv}>
        <Grid container className={classes.Patient}>
          <img alt="" src={returnImg} className={classes.jiantou} onClick={this.handleNavBack} />
          <Grid container justify="center" className={classes.headers}>
            <h4 className={classes.title}>患者详情</h4>
          </Grid>
        </Grid>
        <Typography h5 noWrap className={classes.spacemargin}></Typography>
        {/* 介绍 */}
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>姓名：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.name}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>身份证号：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.idnumber}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>性别：</Typography>
          <Typography className={classes.fieldValue}>{gender(introduceDetail.sex)}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>年龄：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.age}</Typography>
        </Grid>
        {
            /*
            <hr className={classes.hr}></hr>
            <Grid container justify="space-between" >
            <Typography className={classes.fieldLable}>电话：</Typography>
            <Typography className={classes.fieldValue}>{introduceDetail.telphone}</Typography>
            </Grid>
            */
        }
        
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>现住址：</Typography>
          <Typography className={classes.fieldValueDiv}>{introduceDetail.address}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>推介日期：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.createTime}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>推介原因：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.reason}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>推介单位：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.hospitalName}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>

        <Grid container justify="space-between">
          <Typography className={classes.fieldLable}>到位时间：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.hereTime}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>
        <Grid container justify="space-between" >
          <Typography className={classes.fieldLable}>诊断结果：</Typography>
          <Typography className={classes.fieldValue}>{introduceDetail.diagnosisResult == null ? "无" : introduceDetail.diagnosisResult}</Typography>
        </Grid>
        <hr className={classes.hr}></hr>

                <Grid container justify="center" className={classes.buttonBox}>
                    <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleOpen}>
                        再次发送推介码
                    </Button>
                    <a href={"tel:"+introduceDetail.telphone}>
                    <Button variant="contained" color="primary" className={classes.margin}>
                        <img alt="" src={phone} className={classes.phoneDiv}></img>
                       拨打电话
                    </Button>
                    </a>
                </Grid>
                <Modal
                    className={classes.modal}
                    open={this.state.showModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Grid item xs={10} className={classes.paper}>
                        <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                        <Grid item xs={12} className={classes.paperDiv}>
                            <Grid item xs={12} className={classes.tishiDiv}>
                                <img className={classes.tishi} src={tishi}></img><span
                                className={classes.spanDiv}>患者推介发送成功!</span></Grid>
                            <Grid item xs={12} className={classes.buttonDiv}>
                                <Button variant="contained" color="primary" className={classes.buttons}
                                        onClick={this.closeModal}>确 &nbsp;认</Button></Grid>
                        </Grid>
                    </Grid>

                </Modal>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
  return {
    serverInfo: state.serverInfo,
    introduceDetail: state.introduceReducer.introduceDetail,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getIntroduceById: (introId) => {
      dispatch(introduceDetail(introId))
    },
  }
}
const styles = theme => ({
    rootDiv: {
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: '#ffffff',
        padding: '0',
        margin: theme.spacing(0),
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '85vw',
        height: '45vw',
        backgroundColor: '#ffffff',
    },

    hrs: {
        margin: 0,
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },
    spacemargin: {
        margin: theme.spacing(1),

    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    tishi: {
        height: '10vw'
    },

    buttonDiv: {
        height: '18vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        width: '30vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '4vw',
        padding: 0,

    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw',
    },
    spanDiv: {
        fontSize: '3.5vw',
        marginLeft: '4vw',
    },
    // hr: {
    //   height: '0.3vw',
    //   border: '1.3vw',
    //   borderBottom: '0.1vw solid #e6e6e6'
    // },

    fieldLable: {
        marginLeft: theme.spacing(2),
        lineHeight: '10vw',
        fontSize: '4.2vw',
        color: '#333333'
    },
    fieldValue: {
        color: '#999999',
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        width: theme.spacing(27),
        textAlign: 'right'

    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    phoneDiv: {
        height: '6vw',
        marginRight: '1vw',
    },

    // card: {
    //     maxWidth: '100vw',
    //     // height: '700px',
    // },
    // media: {
    //     height: 0,
    //     paddingTop: '56.25%', // 16:9
    // },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },

    margin: {
        height: '12vw',
        width: '38vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: '5vw',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '83vw',
        height: '14vw'
    },
    fieldValueDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999',
    },


    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    // avatar: {
    //     width: '22vw',
    //     height: '22vw',
    //     maxWidth: '22vw',
    //     maxHeight: '22vw',
    //     borderRadius: "50%"
    // },
    information: {
        marginTop: theme.spacing(1),
        width: '100%',

    },
    // cardjiuzhen: {
    //     width: '90vw',
    //     height: '32vh',
    //     marginLeft: '5vw',
    // },
    // cardbutton: {
    //     width: '50vw',
    //     display: 'flex',
    //     flexDirection: 'row',
    //     marginbottom: '5vw',
    //     justifyContent: 'space-between',
    // },
    // span: {
    //     float: 'right',
    //     marginRight: '3vw',
    //     color: '#999999'
    // },
    title: {
        margin: 0,
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '14vw',

        // // wordSpacing:'normal',
        // marginLeft:theme.spacing(2),
        // marginTop:theme.spacing(2),
        // marginBottom:theme.spacing(1),
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        margin: '2vw',
    },

    buttonBox: {
        // width: '100vw',
        marginBottom: theme.spacing(3)
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntroduceMyDetail));