import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal,Button } from '@material-ui/core';
import SignaturePad from 'signature_pad';


class SignPadPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            imgHeight: 300,
            imgData: [],
            signaturePad: null,
            isFullScreen: false,
            imgSrc: ''
        }
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleClear = this.handleClear.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }
    componentDidMount() {
        // 监听页面全屏事件
        window.onresize = () => {
            // 全屏
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true });
            }
            // 不是全屏
            else {
                this.state.isFullScreen = false
                this.setState({ isFullScreen: false });
            }
        }
    }

    handleSave = () => {
        this.setState({
            open: false
        })
        if (!this.state.signaturePad.isEmpty()) {
            this.setState({
                imgSrc: this.state.signaturePad.toDataURL()
            })
            if(this.props.onSave){
                this.props.onSave(this.state.signaturePad.toDataURL())
            }
        }
    }

    handleClear = () => {
        this.state.signaturePad.clear()
    }

    toDataURL() {
        if(!this.state.signaturePad) return null;
        // 压缩图片需要的一些元素和对象
        let img = new Image();
        img.src = this.state.signaturePad.toDataURL()
        
        // 缩放图片需要的canvas
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        // 图片原始尺寸
        var originWidth = img.width;
        var originHeight = img.height;
        // 最大尺寸限制
        var maxWidth = 300, maxHeight = 300;
        // 目标尺寸
        var targetWidth = originWidth, targetHeight = originHeight;
        // 图片尺寸超过400x400的限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
        }
            
        // canvas对图片进行缩放
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // 清除画布
        context.clearRect(0, 0, targetWidth, targetHeight);
        // 图片压缩
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        console.log(targetWidth+","+targetHeight)
        return canvas.toDataURL();
        //return this.state.signaturePad.toDataURL();
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true,
            imgSrc: ''
        })
        let that = this;
        setTimeout(() => {
            var canvas = document.querySelector("#cv");
            that.state.signaturePad = new SignaturePad(canvas);
            that.state.signaturePad.minWidth = 2;
            that.state.signaturePad.maxWidth = 6;
            that.state.signaturePad.penColor = "black";
            that.state.signaturePad.backgroundColor = "rgb(255,255,255)";
            that.state.signaturePad.clear()
        }, 500);

    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.centerDiv}>
                <Button type="button" variant="outlined" color="primary" onClick={this.handleOpen}>
                    点击签名
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div width={window.innerWidth} height={window.innerHeight} className={classes.signDiv}>
                        <canvas id="cv" height={this.state.height} width={this.state.width} />
                        <button className={this.state.open == true ? classes.btClear : classes.hideMe} onClick={this.handleClear} >清除</button>
                        <button className={this.state.open == true ? classes.btSave : classes.hideMe} onClick={this.handleSave} >保存</button>
                    </div>
                </Modal>
                <div className={this.state.imgSrc === '' ? classes.hideMe : classes.imgDiv}>
                    <img height={this.state.imgHeight} src={this.state.imgSrc} className={classes.img} />
                </div>
            </div>

        )
    }
}

//css region
const styles = theme => ({
    signDiv: {
        backgroundColor: 'rgba(100, 100, 100, 100)',
        height: '1024px'
    },
    img: {
        margin: '0 auto',
        transform: 'rotate(90deg)',
        border: '1px solid #EDEDED'
    },
    centerDiv: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
    },
    imgDiv: { 
        textAlign: 'center',
        margin: '0 auto',
        padding: 0,
        width: '100%'
    },
    btSave: {
        position: 'absolute',
        left: '0px',
        top: '15px',
        zIndex: '999',
        transform: 'rotate(-90deg)',
    },
    btClear: {
        position: 'absolute',
        left: '0px',
        bottom: '15px',
        zIndex: '999',
        transform: 'rotate(-90deg)',
    },
    hideMe: {
        display: 'block'
    }
})

export default withStyles(styles)(SignPadPlus);