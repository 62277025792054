
//声明模块
export const MY_PATIENTS='MY_PATIENTS' // 患者管理-我的患者
export const MY_PATIENTS_SUCCEED = 'MY_PATIENTS_SUCCEED' //患者管理-我的患者请求成功


export const MEDICATION_RECORD_LIST = 'MEDICATION_RECORD_LIST' //服药记录
export const MEDICATION_RECORD_SUCCEED = 'MEDICATION_RECORD_SUCCEED' //服药记录-获取数据成功

export const PATIENT_DEATIL = 'PATIENT_DETAIL'
export const PATIENT_DETAIL_SUCCEED = 'PATIENT_DETAIL_SUCCEED'

export const BANG_DING_JIE_BANG = 'BANG_DING_JIE_BANG'    // 绑定
export const BANG_DING_JIE_BANG_SUCCEED = 'BANG_DING_JIE_BANG_SUCCEED'    // 绑定成功

export const YAO_HE_UNTYING = 'YAO_HE_UNTYING'    // 解绑
export const YAO_HE_UNTYING_SUCCEED = 'YAO_HE_UNTYING_SUCCEED'    // 解绑成功

export const YAO_HE_LIST = 'YAO_HE_LIST'    // 绑定
export const YAO_HE_LIST_SUCCEED = 'YAO_HE_LIST_SUCCEED'    // 解绑

export const PATIENT_TAKE_MEDICINE = 'PATIENT_TAKE_MEDICINE'
export const PATIENT_TAKE_MEDICINE_SUCCEED = 'PATIENT_TAKE_MEDICINE_SUCCEED'


export const POST_MANAGENOTICE = 'POST_MANAGENOTICE'//通知
export const POST_MANAGENOTICE_SUCCEED = 'POST_MANAGENOTICE_SUCCEED'

export const POST_MANAGEWAIT = 'POST_MANAGENOTICE'//待随访
export const POST_MANAGEWAIT_SUCCEED = 'POST_MANAGEWAIT_SUCCEED'//待随访-获取数据成功

export const POST_FOLLOWUP_4 = 'POST_FOLLOWUP_4'//患者管理 -通知列表-停止治疗管理通知单
export const POST_FOLLOWUP_4_SUCCEED = 'POST_FOLLOWUP_4_SUCCEED'//患者管理 -通知列表-停止治疗管理通知单

export const POST_FOLLOWUP_1 = 'POST_FOLLOWUP_1'//患者管理 -通知列表-新患者确认管理通知单
export const POST_FOLLOWUP_1_SUCCEED = 'POST_FOLLOWUP_1_SUCCEED'//患者管理 -通知列表-新患者确认管理通知单



export const POST_TAKE_MEDICINE_EVENT = 'POST_TAKE_MEDICINE_EVENT'//患者管理 - 通知列表 - 点击服药
export const POST_TAKE_MEDICINE_EVENT_SUCCEED = 'POST_TAKE_MEDICINE_EVENT_SUCCEED'//患者管理 -通知列表 - 点击服药成功

export const GET_TAKE_MEDICINE_STATUS = 'GET_TAKE_MEDICINE_STATUS'//患者管理 - 通知列表 - 查询服药状态
export const GET_TAKE_MEDICINE_STATUS_SUCCEED = 'GET_TAKE_MEDICINE_STATUS_SUCCEED'//患者管理 -通知列表 - 查询服药状态


export const POST_FOLLOWUP_5 = 'POST_FOLLOWUP_5'//患者管理 -通知列表-患者治疗管理反馈单
export const POST_FOLLOWUP_5_SUCCEED = 'POST_FOLLOWUP_5_SUCCEED'//患者管理 -通知列表-患者治疗管理反馈单

export const FEEDBACKS_SUBMISSION = 'FEEDBACKS_SUBMISSION'//患者管理 -通知列表-患者治疗管理反馈单（提交）
export const FEEDBACKS_SUBMISSION_SUCCEED = 'FEEDBACKS_SUBMISSION_SUCCEED'//患者管理 -通知列表-患者治疗管理反馈单（提交成功）

export const SUBMITRETURN = 'SUBMITRETURN'//患者管理 -通知列表-患者治疗管理反馈单（返回）
export const SUBMITRETURN_SUCCEED = 'SUBMITRETURN_SUCCEED'//患者管理 -通知列表-患者治疗管理反馈单（返回成功）

export const SURE_MANAGE = 'SURE_MANAGE'
export const SURE_MANAGE_OK = 'SURE_MANAGE_OK'

export const QUERY_CHECK_SPUTUM = 'CHECK_CHECK_SPUTUM' //患者管理-患者详情-查痰记录
export const QUERY_CHECK_SPUTUM_SUCCEED = 'QUERY_CHECK_SPUTUM_SUCCEED'

export const POST_FOLLOWPAPER = 'POST_FOLLOWPAPER'//待随访 - 日常随访
export const POST_FOLLOWPAPER_SUCCEED = 'POST_FOLLOWPAPER_SUCCEED'//待随访 -日常随访-获取数据成功

//首次随访
export const QUERY_FIRST_FOLLOWUP = 'QUERY_FIRST_FOLLOWUP'  // 查询基本信息
export const QUERY_FIRST_FOLLOWUP_SUCCEED = 'QUERY_FIRST_FOLLOWUP_SUCCEED'  // 基本信息查询成功

export const POST_FOLLOWPAPERNEWINFO = 'POST_FOLLOWPAPERNEWINFO'//待随访 - 首次随访
export const POST_FOLLOWPAPERNEWINFO_SUCCEED = 'POST_FOLLOWPAPERNEWINFO_SUCCEED'//待随访 -首次随访-获取数据成功
export const POST_FOLLOWPAPERNEWINFOTWO = 'POST_FOLLOWPAPERNEWINFOTWO'//待随访 - 首次随访枚举值
export const POST_FOLLOWPAPERNEWINFOTWO_SUCCEED = 'POST_FOLLOWPAPERNEWINFOTWO_SUCCEED'//待随访 -首次随访枚举值-获取数据成功

//图片上传
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCEED = 'UPLOAD_FILE_SUCCEED'

export const FOLLOW_UP_HISTORY = 'FOLLOW_UP_HISTORY'
export const FOLLOW_UP_HISTORY_SUCCEED = 'FOLLOW_UP_HISTORY_SUCCEED'

export const STOP_FOLLOW_UP = 'STOP_FOLLOW_UP'
export const STOP_FOLLOW_UP_SUCCEED = 'STOP_FOLLOW_UP_SUCCEED'

export const READ = 'READ'
export const READ_SUCCEED = 'READ_SUCCEED'
export const CONFIRMSTOPTREAT = 'CONFIRMSTOPTREAT'
export const CONFIRMSTOPTREAT_SUCCEED = 'CONFIRMSTOPTREAT_SUCCEED'


export const QUERY_SERVICE_PROTOCOL_INFO = 'QUERY_SERVICE_PROTOCOL_INFO'
export const QUERY_SERVICE_PROTOCOL_INFO_SUCCEED = 'QUERY_SERVICE_PROTOCOL_INFO_SUCCEED'

export const SERVICE_SIGN_AGREEMENT_COMMIT = 'SERVICE_SIGN_AGREEMENT_COMMIT'
export const SERVICE_SIGN_AGREEMENT_COMMIT_SUCCEED = 'SERVICE_SIGN_AGREEMENT_COMMIT_SUCCEED'

export const CLOSE_SCREENING_LIST = 'CLOSE_SCREENING_LIST'// 获取密切接触单
export const CLOSE_SCREENING_LIST_SUCCEED = 'CLOSE_SCREENING_LIST_SUCCEED'// 创建密切接触单

export const FINISH_TREAT = "FINISH_TREAT"
export const FINISH_TREAT_SUCCEED = "FINISH_TREAT_SUCCEED"


export const QUERY_TRACK_NOTICE = 'QUERY_TRACK_NOTICE'
export const QUERY_TRACK_NOTICE_SUCCEED = 'QUERY_TRACK_NOTICE_SUCCEED'

export const TRACK_FEEDBACK_SUBMIT = 'TRACK_FEEDBACK_SUBMIT'
export const TRACK_FEEDBACK_SUBMIT_SUCCEED = 'TRACK_FEEDBACK_SUBMIT_SUCCEED'


export const QUERY_TRACK_FEEDBACK = 'QUERY_TRACK_FEEDBACK'
export const QUERY_TRACK_FEEDBACK_SUCCEED = 'QUERY_TRACK_FEEDBACK_SUCCEED'


//患者管理 -我的患者
export function myPatients(loginInfo) {
    return {
        type:MY_PATIENTS,
        loginInfo
    }
}

//患者管理 - 服药情况List
export function queryMedicationRecord(loginInfo) {
    return {
        type: MEDICATION_RECORD_LIST,
        loginInfo
    }
}

//患者管理 - 我的患者- 患者详情
export function queryPatientDetail(patientId) {
    return {
        type : PATIENT_DEATIL,
        patientId
    }
}

//患者管理 - 我的患者- 药盒绑定
export function bindUnbindEvent(boxBangding) {
   return {
      type : BANG_DING_JIE_BANG,
      boxBangding
   }
}

//患者管理 - 我的患者- 药盒解绑
export function untyingEvent(untyingParams) {
   return {
      type : YAO_HE_UNTYING,
      untyingParams
   }
}

//患者管理 - 我的患者- 药盒列表
export function medicalKitListEvent() {
   return {
      type : YAO_HE_LIST,
   }
}



//患者管理 - 我的患者- 患者详情 - 服药记录
export function queryPatientTakeMedicineRecord(patientId) {
    return {
        type : PATIENT_TAKE_MEDICINE,
        patientId
    }
}

//患者管理 - 我的患者 - 患者详情 - 预约查痰记录
export function querySputumCheckRecord(patientId) {
    return {
        type : QUERY_CHECK_SPUTUM,
        patientId
    }
}

//患者管理 -通知列表
export function getManageNotice(userId) {
    return {
        type:POST_MANAGENOTICE,
        userId
    }
}

export function sureManage(obj) {
    return {
        type : SURE_MANAGE,
        obj
    }
}


//患者管理 -待随访
export function queryManageWait(userInfo) {
    return {
        type:POST_MANAGEWAIT,
        userInfo
    }
}

//患者管理 - 待随访 - 添加随访记录 - 查询基本信息
export function queryFollowUpBasicInfo(patientId){
    return {
        type : QUERY_FIRST_FOLLOWUP,
        patientId
    }
}


//患者管理 -通知列表-停止治疗管理通知单
export function stopTreatBill(patientInfo) {
    return {
        type:POST_FOLLOWUP_4,
        patientInfo
    }
}



//患者管理 -通知列表-新患者确认管理通知单
export function postFollowUpVisitPaper1(patientId) {
    return {
        type:POST_FOLLOWUP_1,
        patientId
    }
}
//患者管理 -患者主页面-点击服药
export function postTakeMedicineEvent(userId) {
   return {
      type:POST_TAKE_MEDICINE_EVENT,
      userId
   }
}

//患者管理 - 患者主页面 - 查询服药状态
export function getTakeMedicineStatus(userId) {
   return {
      type:GET_TAKE_MEDICINE_STATUS,
      userId
   }
}


//患者管理 -通知列表-患者治疗管理反馈单
export function queryFollowUpVisitPaper5(params) {
    return {
        type:POST_FOLLOWUP_5,
        params
    }
}

//患者管理 -待随访 - 日常随访
export function followUpVisitPaper(visitorInfo) {
    return {
        type:POST_FOLLOWPAPER,
        visitorInfo
    }
}

//患者管理 -待随访 - 添加首次随访记录
export function postFirstFollowUp(visitorNewInfo) {
    return {
        type:POST_FOLLOWPAPERNEWINFO,
        visitorNewInfo
    }
}
//患者管理 -待随访 - 首次随访枚举值
export function followUpVisitPaperNewInfo2(visitorNewInfo2) {
    return {
        type:POST_FOLLOWPAPERNEWINFOTWO,
        visitorNewInfo2
    }
}

//患者管理 -通知列表-患者治疗管理反馈单(提交)
export function postfeedbackSubmission(feedBack) {
    return {
        type : FEEDBACKS_SUBMISSION,
        feedBack
    }
}

export function uploadFile(file) {
    return {
        type : UPLOAD_FILE,
        file
    }
}

export function followUpHistory(patientId) {
    return {
        type: FOLLOW_UP_HISTORY,
        patientId
    }
}

//随访结案
export function followUpStop(patientId){
    return {
        type : STOP_FOLLOW_UP,
        patientId
    }
}

//患者管理 -通知已读
export function read(readInfo){
    return {
        type: READ,
        readInfo
    }
}
//确认停止治疗
export function confirmStopTreat(id){
    return {
        type: CONFIRMSTOPTREAT,
        id
    }
  }

//结案
export function finishTreat(params){
    return {
        type : FINISH_TREAT,
        params
    }
}

  //签约服务协议查询
export function queryServiceProtocolInfo(params){
    return {
        type : QUERY_SERVICE_PROTOCOL_INFO,
        params
    }
}

//服务签约协议提交
export function commitServiceSignAgreement(serviceSign){
    return {
        type : SERVICE_SIGN_AGREEMENT_COMMIT,
        serviceSign
    }
}

// 创建密切接触单
export function createCloseScreeningList(closeParams){
   return {
      type : CLOSE_SCREENING_LIST,
      closeParams
   }
}


export function queryTrackNotice(params){
    return {
        type : QUERY_TRACK_NOTICE,
        params
     }
}


export function trackFeedbackSubmit(params){
    return {
        type : TRACK_FEEDBACK_SUBMIT,
        params
     }
}

export function queryTrackFeedback(params){
    return {
        type : QUERY_TRACK_FEEDBACK,
        params
     }
}