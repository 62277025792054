import * as ActionTypes from "../actions/constants";

// The initial application state
let initialState = {
    patientBasicNewsList: [],
    patientMessageNewsList: [],
    patientNewsDetail: {},
    doctorBasicNewsList: [],
    doctorMessageNewsList: [],
    doctorNewsDetail: [],
};

// Takes care of changing the application state
function newsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PATIENT_BASIC_NEWS_LIST_ARRIVED: //
            return {...state, patientBasicNewsList: action.result};
        case ActionTypes.PATIENT_MESSAGE_NEWS_LIST_ARRIVED: //
            return {...state, patientMessageNewsList: action.result};
        case ActionTypes.PATIENT_NEWS_DETAIL_ARRIVED: 
            return {...state, patientNewsDetail: action.result};
        case ActionTypes.DOCTOR_BASIC_NEWS_LIST_ARRIVED:
            return {...state,doctorBasicNewsList: action.result}
        case ActionTypes.DOCTOR_MESSAGE_NEWS_LIST_ARRIVED: 
            return {...state,doctorMessageNewsList:action.result};
        case ActionTypes.DOCTOR_NEWS_DETAIL_ARRIVED:
            return {...state,doctorNewsDetail:action.result};
        default:
            return state;
    }

}

export default newsReducer;
