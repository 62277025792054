/* 患者管理主页面 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {getMyPatientList} from '../redux/actions/index';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import {Route, Switch} from "react-router-dom";
import ManageMy from './manageWidget/ManageMy';
import ManageWait from './manageWidget/ManageWait';
import ManageNotice from './manageWidget/ManageNotice';
import ManageMyDetail from './manageWidget/ManageMyDetail';
import ManageMyDetailMore from './manageWidget/ManageMyDetailMore';
import CloseScreeningList from './followupvisitpaper/CloseScreeningList';
import FollowUpVisitPaper1 from './followupvisitpaper/FollowUpVisitPaper1';
import FollowUpVisitPaper4 from './followupvisitpaper/FollowUpVisitPaper4';
import FollowUpVisitPaper5 from './followupvisitpaper/FollowUpVisitPaper5';
import FollowUpVisitPaper3 from './followupvisitpaper/FollowUpVisitPaper3';
import FollowUpVisitPaper2 from './followupvisitpaper/FollowUpVisitPaper2';
import FollowUpVisitPaper7 from './followupvisitpaper/FollowUpVisitPaper7';
import FollowUpHistory from './followupvisitpaper/FollowUpHistory';
import ServiceSignAgreement from './followupvisitpaper/ServiceSignAgreement';
import FollowUpHistoryDetail from './followupvisitpaper/FollowUpHistoryDetails'; //随访历史详情页
import FirstFollowUpHistoryDetail from './followupvisitpaper/FirstFollowUpHistoryDetails'; //随访历史详情页

import ReliefDeal from './shanghai/ReliefDeal';
import DirectDeal from './shanghai/DirectDeal';
import DirectAnswer from './shanghai/DirectAnswer';
import MedicineScorePaper from './medicineScore/MedicineScorePaper';


import CaseVisitRecord from './manageWidget/CaseVisitRecord';
import ManageRecord from './manageWidget/ManageRecord';

import {noticeManage} from "../redux/actions/custom_action";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


//患者管理页面
class PatientManageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
            // manageTopMenuIndex: 0,//上方三个菜单(我的患者 待随访 通知)的index
            // 判断页面是否传参，将参数对应到上方三个菜单(我的患者 待随访 通知)的index
            manageTopMenuIndex: this.props.location.state == undefined ? 0 :
                (this.props.location.state.type === "visit" || this.props.location.state.type === "saliva" || this.props.location.state.type === "drug") ? 2 :
                this.props.location.state.type === "caller" ? 1 : 0,
            bMedicine:0,//我的患者中下方的按钮，0为患者情况 1为服药情况
        };
        this.a11yProps = this.a11yProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTab=this.handleTab.bind(this);
    }

    componentDidMount() {
        this.props.getNotice({"userId":localStorage.getItem("userId")})
    }

    componentWillUnmount() {
        
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if(nextProps.noticeInfo !== this.props.noticeInfo){
            if(nextProps.noticeInfo.code === 200){

            }
        }
    }

    a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    handleChange(event, newValue) {
        this.setState({
            manageTopMenuIndex: newValue,
        })
    }
    //在我的患者 待随访 通知之间切换(0  1   2)
    handleTab(index){
      this.setState({
        manageTopMenuIndex:index,
        bMedicine:1,
      });
    }


    render() {
        //get react-material css styles
        const {classes} = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/followupvisitpaper`} component={FollowUpVisitPaper3}/>
                <Route path={`${this.props.match.path}/followupvisitpaper2`} component={FollowUpVisitPaper2}/>
                <Route path={`${this.props.match.path}/followuphistory`} component={FollowUpHistory}/>
                <Route path={`${this.props.match.path}/stopTreat`} component={FollowUpVisitPaper7}/>
                <Route path={`${this.props.match.path}/visitrecord`} component={CaseVisitRecord}/>
                <Route path={`${this.props.match.path}/record`} component={ManageRecord}/>
                <Route path={`${this.props.match.path}/visitpaper`} component={FollowUpVisitPaper1}/>
                <Route path={`${this.props.match.path}/visitpaper1`} component={FollowUpVisitPaper4}/>
                <Route path={`${this.props.match.path}/patientDetail`} component={ManageMyDetail}/>
                <Route path={`${this.props.match.path}/patientDetailMore`} component={ManageMyDetailMore}/>
               <Route path={`${this.props.match.path}/TakingMedicine`} component={ManageRecord}/>
               <Route path={`${this.props.match.path}/CloseScreening`} component={CloseScreeningList}/>
               <Route path={`${this.props.match.path}/PatientFeedback`} component={FollowUpVisitPaper5}/>
               <Route path={`${this.props.match.path}/FamilyAgreement`} component={ServiceSignAgreement}/>
               <Route path={`${this.props.match.path}/FollowUpHistoryDetail`} component={FollowUpHistoryDetail}/>
               <Route path={`${this.props.match.path}/FirstFollowUpHistoryDetail`} component={FirstFollowUpHistoryDetail}/>

               <Route path={`${this.props.match.path}/reliefdeal`} component={ReliefDeal}/>
               <Route path={`${this.props.match.path}/directdeal`} component={DirectDeal}/>
               <Route path={`${this.props.match.path}/directanswer`} component={DirectAnswer}/>
               <Route path={`${this.props.match.path}/medicineScore`} component={MedicineScorePaper}/>

                <div className={classes.rootDiv}>
                    {/* header */}
                    <AppBar position="static" color="default">
                        <Tabs
                            className={classes.navigation}
                            value={this.state.manageTopMenuIndex}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            aria-label="introduce tabs">
                            <Tab className={classes.manageMy} label="我的患者" {...this.a11yProps(0)} />
                            <Tab className={classes.default} label={<Badge badgeContent={this.props.noticeManageInfo?.followupNum} color="secondary">待随访</Badge>} {...this.a11yProps(1)} />
                            <Tab className={classes.notification} label={<Badge badgeContent={this.props.noticeManageInfo?.noticeNum} color="secondary">通知</Badge>} {...this.a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    {(() => {
                            switch (this.state.manageTopMenuIndex) {
                                case 0:
                                    return <ManageMy bottomIndex={this.state.bMedicine} history={this.props.history} match={this.props.match}/>;
                                case 1:
                                    return <ManageWait history={this.props.history} match={this.props.match}/>;
                                case 2:
                                    return <ManageNotice history={this.props.history} match={this.props.match} handleTab={this.handleTab}/>;
                                default:
                                    return <ManageMy bottomIndex={this.state.bMedicine}/>;
                            }
                        }
                    )()}

                </div>
            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        noticeManageInfo: state.customReducer.noticeManageInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotice: (params) => {
            dispatch(noticeManage(params));
        },
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
        height: '100vh',
    },

    navigation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',

    },

    notification: {
        fontSize: '4vw',
        color: 'rgba(255,255,255,1)',
    },
    manageMy: {
        fontSize: '4vw',
        color: 'rgba(255,255,255,1)',
    },
    default: {
        color: 'rgba(255,255,255,1)',
        fontSize: '4vw'
    }
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(PatientManageView)));
