/* 资讯 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {getDoctorMessageNewsList} from '../redux/actions/index';
import {Route, Switch} from "react-router-dom";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HealthInfo1 from '../assets/images/healthinfo1.jpg';
import Grid from '@material-ui/core/Grid';
import returnImg from '../assets/images/returnImg.png'
import zixun from "../assets/images/zixun.png";
import gongzuo from "../assets/images/gongzuo.png";
import wode from '../assets/images/wode.png';
import MainView from './MainView';
import MyView from './MyView';
import Paper from '@material-ui/core/Paper';
import NewsDetail from './NewsDetail';
import Footer from "./common/footer";

// pick a date util library

const StyledAvatar = withStyles({
   root: {
      borderRadius: 3,
      border: 0,
      height: 60,
      width: 90,
      marginRight: 8,
   },
})(Avatar);


//资讯
class News extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.handClick = this.handClick.bind(this);
      this.handleLoginClick = this.handleLoginClick.bind(this);
      this.leLoginClick = this.leLoginClick.bind(this);
      this.handleNavBack = this.handleNavBack.bind(this);
   }

   handleLoginClick() {
      this.resetAll();
      this.setState({
         tabulation: true
      });
      const path = `${this.props.match.path}/vinview`;
      this.props.history.push(path);
   }

   handClick() {
      this.resetAll();
      this.setState({
         showcase: true
      });
   }

   leLoginClick() {
      this.resetAll();
      this.setState({
         business: true
      });
      const path = `${this.props.match.path}/myview`;
      this.props.history.push(path);
   }

   resetAll() {
      this.setState({
         tabulation: false,
         showcase: false,
         business: false,
      });
   }


   componentDidMount() {
      this.handleDetailClick = this.handleDetailClick.bind(this);
      this.props.getDoctorMessageNewsList()
   }

   componentWillUnmount() {

   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {

   }

   handleChangeIndex(index) {

      // const path='/introduce/'
      // this.setState({
      //     introduceTopMenuIndex: index,
      // })
   }

   handleDetailClick(index) {
      //应该是在登录成功之后,才调用
      const path = `${this.props.match.path}/newsdetail` + index;
      this.props.history.push(path);
   }

   //后退按钮
   handleNavBack() {
      this.props.history.goBack();
   }

   render() {
      //get react-material css styles
      const {classes, newsList} = this.props;
      return (
         <Switch>
            <Route path={`${this.props.match.path}/newsdetail:index`} component={NewsDetail}/>
            <Route path={`${this.props.match.path}/myview`} component={MyView}/>
            <Grid container alignContent="space-between" className={classes.rootDiv}>
               <Grid spacing={0}>
                  {/* header */}
                  {/* 标题栏 箭头 通知详情 */}
                  <Grid className={classes.Patient}>
                     <img src={returnImg} className={classes.jiantou} onClick={this.handleNavBack}/>
                     <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.title}>资讯</h4>
                     </Grid>
                  </Grid>
                  {(newsList.data || []).map((item, index) => {
                     return (
                        <div key={item.id} onClick={() => {
                           this.handleDetailClick(item.id);
                        }}>
                           <Grid className={classes.newsDiv}>
                              <Grid container item xs={12} justify="flex-start">
                                 <Grid item xs={3}>
                                    <ListItemAvatar className={classes.avatarDIV}>
                                       <StyledAvatar alt="Remy Sharp"
                                                     src={item.photo1 === "" ? HealthInfo1 : item.photo1}/>
                                    </ListItemAvatar>
                                 </Grid>
                                 <Grid item xs={7} container alignItems="stretch">
                                    <Typography className={classes.subtitleDIV} noWrap>{item.title}</Typography>
                                    <Typography className={classes.contentDIV} noWrap></Typography>
                                    <Grid container justify="space-between">
                                       <Typography className={classes.dateDIV}>{item.createTime}</Typography>
                                       <Typography className={classes.readDIV}>{item.readcount}阅读</Typography>
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <hr className={classes.hr}></hr>
                           </Grid>
                        </div>
                     )
                  })
                  }

               </Grid>

               {/*底部*/}
               <Footer urlPath={this.props} btnType={'showcase'}/>

               {/*<Grid container alignItems="flex-end" item xs={12} className={classes.footerDiv}>
                  <div className={classes.bott}>
                     <button className={this.state.showcase ? classes.buttonon : classes.buttonsdiv}
                             onClick={this.handClick}>
                        <img src={zixun} className={classes.imgdiv}></img><p className={classes.ps}>资讯</p>
                     </button>
                     <button className={this.state.tabulation ? classes.buttonons : classes.buttonssdiv}
                             onClick={this.handleLoginClick}>
                        <img src={gongzuo} className={classes.imgsdiv}></img><p className={classes.ps}>工作站</p>
                     </button>
                     <button className={this.state.business ? classes.buttonon : classes.buttonsdiv}
                             onClick={this.leLoginClick}>
                        <img src={wode} className={classes.imgdiv}></img><p className={classes.ps}>我的</p>
                     </button>
                  </div>
               </Grid>*/}


            </Grid>

         </Switch>
      );
   }
}
News.propTypes = {
   urlPath: PropTypes.object.isRequired,
   btnType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
   return {
      newsList: state.newsReducer.doctorMessageNewsList
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      //请求通知列表数据
      getDoctorMessageNewsList: () => {
         dispatch(getDoctorMessageNewsList())
      },
   }
}


//css region
const styles = theme => ({
   rootDiv: {
      flexGrow: 1,
      overflow: 'auto',
      height: '100vh',
      // margin: theme.spacing(2),
   },

   avatarDIV: {
      marginRight: '20vw',
      width: '100vw',
   },

   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',
   },

   dateDIV: {
      padding: '0',
      color: '#999999',
      fontSize: '3.5vw',
      marginTop: '2vw'

   },
   readDIV: {
      padding: '0',
      color: '#999999',
      fontSize: '3.5vw',
      marginTop: '2vw'
   },

   subtitleDIV: {
      padding: '0',
      color: '#333333',
      fontSize: '4.2vw',
      marginTop: '0vw'
   },

   contentDIV: {
      padding: '0',
      color: '#999999',
      fontSize: '4vw',
      marginTop: '0vw'
   },

   jiantou: {
      height: '4vw',
      marginLeft: theme.spacing(2),
   },
   Patient: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '14vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },

   title: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      // wordSpacing:'normal',
      lineHeight: '0vw'
   },

   newsDiv: {
      margin: theme.spacing(2),
      width: '100vw',
   },


   //底部样式
   footerDiv: {
      alignSelf: 'flex-end',
      marginTop: '17vw',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      border: '0.1vw solid #e6e6e6',
      width: '100vw',
      height: '14vw',
   },
   bott: {
      width: '80vw',
      height: '14vw',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
   },
   buttonon: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      color: '#55E8E5',
      filter: 'none',
   },
   buttonons: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',

   },
   buttonsdiv: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      filter: 'grayscale(100%)',
   },
   buttonssdiv: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      filter: 'grayscale(100%)',
   },
   imgdiv: {
      height: '6vw',

   },
   imgsdiv: {
      height: '7vw',
   },
   ps: {
      fontSize: '2.7vw',
      margin: 0,
   },


});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(News)));
