import React, {Component} from 'react';
// import closeIcon from './../assets/close-icon.png';
import {createStyles, makeStyles, withStyles, withTheme} from "@material-ui/core/styles";
import returnImg from "../../../assets/images/returnImg.png";
import Grid from "@material-ui/core/Grid";
import axxxx from "../../../assets/images/axxxx.png";


class Header extends Component {

   render() {
      const {classes} = this.props;

      let hiddenShow = null;
      let hiddenText = 'block';
      if(window.location.search && window.location.search !== null && window.location.search !== ''){
         hiddenShow = window.location.search.split("flag=")[1]+'';
      }
      hiddenShow == '2'?hiddenText = 'none':hiddenText = 'block';

      return (
         <Grid className="sc-header">

            <Grid style={{display:hiddenText}} item className="sc-header--close-button">
               <img src={axxxx} alt="" onClick={this.props.onClose}></img>
            </Grid>
            <Grid item justify="center" className="sc-header--team-name">
               <h5 className="sc-header--close-text">{this.props.teamName}</h5>
            </Grid>

            {/*<div className="sc-header--close-button" onClick={this.props.onClose}>
               <img src={returnImg} alt=""/>
            </div>
            <div className="sc-header--team-name"> {this.props.teamName} </div>*/}

         </Grid>
      );
   }
}

export default Header;
