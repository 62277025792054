import Api from './api';

const PatientManageApi = {
     // getRootUrl() {
     //      return 'http://localhost:8080';
     //      return 'http://127.0.0.1:8080';
     //     return 'http://192.168.1.3:8080';
     // },

    init(appname, serveraddr, api_key, proto) {

    },

    //患者管理-我的患者
    queryMyPatients(loginInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/doctor/patientList';
        let params = {}
        params.userId = loginInfo.userId;
        params.pageNum = 1;
        params.pageSize = 214748364;
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理-我的患者-服药情况list
    queryMedicationRecordList(loginInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/medicationRecord/medicationRecordList';
        let params = {}
        params.userId = loginInfo.userId;
        params.pageNum = 1;
        params.pageSize = 214748364;
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理-我的患者-患者详情
    queryPatientDetail(patientId) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let params = {}
        params.patientId = patientId;
        params.userId = localStorage.getItem("userId")
        params.token = localStorage.getItem("token")

        let url = Api.getRootUrl() + '/api/patient/patientDetail';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                //return data.data;
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

   //患者管理-我的患者-药盒列表
   queryMedicalKitdApi() {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/medicationRecord/noUselist';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(localStorage.getItem('userId')*1)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者管理-我的患者-绑定
   queryBindUnbindApi(params) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/medicationRecord/saveBox';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(params)
      }

      console.log("--------绑定事件-----", params);

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者管理-我的患者-解绑
   queryUntyingApi(params) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/medicationRecord/unBind';

      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(params)
      }

      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

    //患者管理-我的患者-患者情况 - 患者详情 -服药记录
    queryPatientTakeMedicineRecord(patientId) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/medicationRecord/patientMedicationRecord';
        let params = new Object();
        params.patientId = patientId
        params.userId = localStorage.getItem("userId")
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者查痰记录
    queryCheckSputumRecord(patientId) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/orderCheck/patientOrderCheckList';
        let params = {
            patientId:patientId,
            userId:localStorage.getItem("userId")
        };

       const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(url,init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理-通知列表
    getManageNotice(userId) {
        if(userId === undefined){
            return;
        }
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/notice/getNotice';
        let params= {};
        params.userId = userId;
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //确认管理
    sureManage(obj){
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/notice/confirmManage';

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(obj)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理 - 待随访
    PostManageWait(userInfo) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/followUp/followUpList';

        userInfo.pageNum = 1;
        userInfo.pageSize = 214748364;
        userInfo.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(userInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                //return data.data.rows;
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },


    //患者管理 -通知列表-停止治疗管理通知单
    queryStopTreatBill(patientInfo) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/stopTreat/stopTreat';

        patientInfo.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(patientInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                //return data.data;
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    finishTreat(params){
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/stopTreat/finishTreat';

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },


//患者管理 -通知列表-获取通知单信息
    postFollowUpVisitPaper1(patientId) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/notice/getPatientNotice';
        let params = {}
        params.patientId = patientId;
        params.userId = localStorage.getItem("userId")
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                //return data.data;
                return data;
            })
            .catch(e => {
                return {error: e};
            })

    },


    //患者管理 -通知列表-患者治疗管理反馈单(通知反馈单)
    postFollowUpVisitPaper5(params) {
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/notice/getNoticeFeedback';

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },


    //患者管理 -通知列表-患者治疗管理反馈单提交(查无此人提交)
    postfeedbackSubmission(feedBack) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        //url代表的是从远程服务器请求数据
        let url = Api.getRootUrl() + '/api/notice/findNoPatient';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(feedBack)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

   //患者管理 - 患者主页 - 点击服药
   postTakeMedicineEvent(takeStatus) {
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')

      //url代表的是从远程服务器请求数据
      let url = Api.getRootUrl() + '/api/medicationRecord/take';
      let params = {};
      params.userId = takeStatus.userId;
      params.token = localStorage.getItem("token")
      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(params)
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   //患者管理 - 患者主页 - 查询服药状态
   getTakeMedicineStatus() {
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

      let url = Api.getRootUrl() + '/api/medicationRecord/health';
      let params = {}
      params.userId = localStorage.getItem("userId")
      params.token = localStorage.getItem("token")

      const init = {
         method: 'POST',
         headers: initHeaders,
         body: JSON.stringify(params)
      }

      return fetch(url, init).then(res => res.json()).then(data => {
         return data.data;
      }).catch(e => {
         return {error: e};
      })
   },



    //患者管理 -待随访-日常随访
    saveFollowUpVisit(visitInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/followUp/dailyFollowUp';
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(visitInfo)
        }

        return fetch(url,init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理 - 待随访-首次随访枚举值
    postFirstFollowUp(followUpInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/followUp/firstFollowUp';

        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(followUpInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //首次随访 - 查询基本信息
    queryFollowUpBasicInfo(patientId){
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/followUp/followUpInfo';
        let params = {}
        params.patientId = patientId;
        params.userId = localStorage.getItem("userId")
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
               return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //上传图片
    uploadFile(file){
        const formData = new FormData();
        formData.append('pic', file);

        let url = Api.getRootUrl() + '/api/followUp/uploadPic';
        const init = {
            method: 'POST',
            body: formData
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //查询随访历史
    queryFollowUpHistory(patientId){
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/followUp/followUpHistory';
        let params = {}
        params.patientId = patientId;

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //随访结案
    stopFollowUp(patientId){
        let initHeaders = new Headers()
        initHeaders.append('Accept', 'application/json, text/plain, */*')
        initHeaders.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/stopTreat/getPatientStopTreat';
        let params = {}
        params.patientId = patientId;
        params.userId = localStorage.getItem("userId");
        params.token = localStorage.getItem("token")

        const init = {
            method: 'POST',
            headers: initHeaders,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
               return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理 - 通知已读
    read(readInfo) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let url = Api.getRootUrl() + '/api/notice/read';
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(readInfo)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //患者管理 - 通知已读
    confirmStopTreat(id) {
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')
        let param = {}
        param.id = id
        let url = Api.getRootUrl() + '/api/stopTreat/confirmStopTreat';
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(param)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.code;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //查询签约协议
    queryServiceProtocol(params){
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/sign/getSignInfo';
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data.data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    //签约协议提交
    commitServiceSignAgreement(serviceSign){
        let headers = new Headers()
        headers.append('Accept', 'application/json, text/plain, */*')
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        let url = Api.getRootUrl() + '/api/sign/addSign';
        const init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(serviceSign)
        }

        return fetch(
            url,
            init
        ).then(res => res.json())
            .then(data => {
                return data;
            })
            .catch(e => {
                return {error: e};
            })
    },

    // 创建密切接触单
   createCloseScreeningList(closeParams){
      let headers = new Headers()
      headers.append('Accept', 'application/json, text/plain, */*')
      headers.append('Content-Type', 'application/json;charset=UTF-8')
      let url = Api.getRootUrl() + '/api/closeContact/add';
      const init = {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(closeParams)
      }
      return fetch(
         url,
         init
      ).then(res => res.json())
         .then(data => {
            return data;
         })
         .catch(e => {
            return {error: e};
         })
   },

   queryTrackNotice(params){
    let headers = new Headers()
    headers.append('Accept', 'application/json, text/plain, */*')
    headers.append('Content-Type', 'application/json;charset=UTF-8')
    let url = Api.getRootUrl() + '/api/patientTrack/queryTrackNotice';

    params.token = localStorage.getItem("token")
    params.userId = localStorage.getItem("userId")


    const init = {
       method: 'POST',
       headers: headers,
       body: JSON.stringify(params)
    }
    return fetch(
       url,
       init
    ).then(res => res.json())
       .then(data => {
          return data;
       })
       .catch(e => {
          return {error: e};
       })
 },

 trackFeedbackSubmit(params){
    let headers = new Headers()
    headers.append('Accept', 'application/json, text/plain, */*')
    headers.append('Content-Type', 'application/json;charset=UTF-8')
    let url = Api.getRootUrl() + '/api/patientTrack/trackFeedbackSubmit';

    params.token = localStorage.getItem("token")
    params.userId = localStorage.getItem("userId")


    const init = {
       method: 'POST',
       headers: headers,
       body: JSON.stringify(params)
    }
    return fetch(
       url,
       init
    ).then(res => res.json())
       .then(data => {
          return data;
       })
       .catch(e => {
          return {error: e};
       })
 },
 
 queryTrackFeedback(params){
    let headers = new Headers()
    headers.append('Accept', 'application/json, text/plain, */*')
    headers.append('Content-Type', 'application/json;charset=UTF-8')
    let url = Api.getRootUrl() + '/api/patientTrack/queryTrackFeedback';

    params.token = localStorage.getItem("token")
    params.userId = localStorage.getItem("userId")


    const init = {
       method: 'POST',
       headers: headers,
       body: JSON.stringify(params)
    }
    return fetch(
       url,
       init
    ).then(res => res.json())
       .then(data => {
          return data;
       })
       .catch(e => {
          return {error: e};
       })
 },
 


}

PatientManageApi.init();

export default PatientManageApi
