import React from 'react';
import { connect } from 'react-redux';
//弹出框
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Toast from "antd-mobile/lib/toast";

// 引入moment 时间组件
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {screeningDiabetesUpdate,screeningDiabetesRemove} from "../../redux/actions/custom_action";
import {listScreeningDiabetesHistory} from "../../redux/actions/custom_action";

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
   root: {
      color: "#00C9E4",
      "&$checked": {
         color: "#00C9E4"
      }
   },
   checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
                <Radio className={classes.root} disableRipple color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>} icon={<span className={classes.icon}/>} {...props}/>
            );
}


//随访历史详情页
class ScreeningDiabetesHistoryDetailPaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail:{}
        };
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.state.detail = this.props.location.state.detail
        this.setState({
            id: this.state.detail.id,
            year: this.state.detail.year,
            quarter:this.state.detail.quarter,
            number: this.state.detail.number,
            screeningNumber:this.state.detail.screeningNumber,
            suspiciousNumber:this.state.detail.suspiciousNumber,
            inspectNumber:this.state.detail.inspectNumber,
            diagnosisNumber:this.state.detail.diagnosisNumber,
            followupNumber:this.state.detail.followupNumber,
            introduceNumber:this.state.detail.introduceNumber,
        })

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.screeningDiabetesRemoveRet != this.props.screeningDiabetesRemoveRet) {
            if (nextProps.screeningDiabetesRemoveRet.code == 200) {
                Toast.info('删除成功!');
                let params = {
                    userId: localStorage.getItem("userId"),
                    token: localStorage.getItem("token")
                }
                this.props.queryScreeningDiabetesHistory(params)
                this.handleNavBack();
            }
            if(nextProps.screeningDiabetesRemoveRet.code == 201){
                Toast.info("删除失败！"+nextProps.screeningDiabetesRemoveRet.msg);
            }
        }
        if (nextProps.screeningDiabetesUpdateRet != this.props.screeningDiabetesUpdateRet) {
            if (nextProps.screeningDiabetesUpdateRet.code == 200) {
                Toast.info('更新成功!');
                let params = {
                    userId: localStorage.getItem("userId"),
                    token: localStorage.getItem("token")
                }
                this.props.queryScreeningDiabetesHistory(params)
                this.handleNavBack();
            }
            if(nextProps.screeningDiabetesUpdateRet.code == 201){
                Toast.info("更新失败！"+nextProps.screeningDiabetesUpdateRet.msg);
            }
        }
    }

    //获取表单值
    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
        [name]: value
        });

    }
    //点击返回
    handleNavBack() {
        window.history.go(-1);
    }

    handleUpdate(){
        const params = {
            token: localStorage.getItem("token"),
            userId: localStorage.getItem("userId"),
            id: this.state.id,
            year: this.state.year,
            quarter:this.state.quarter,
            number: this.state.number,
            screeningNumber:this.state.screeningNumber,
            suspiciousNumber:this.state.suspiciousNumber,
            inspectNumber:this.state.inspectNumber,
            diagnosisNumber:this.state.diagnosisNumber,
            followupNumber:this.state.followupNumber,
            introduceNumber:this.state.introduceNumber,
         };
   
         this.props.updateRecord(params);
    }

    //点击提交按钮
   handleSubmit() {
        const params = {
            token: localStorage.getItem("token"),
            userId: localStorage.getItem("userId"),
            id: this.state.id,
        };

        this.props.removeRecord(params);
    }

    render() {
        const { classes } = this.props;

        let checkOutIssue = '';
        if(this.state.issue){
           checkOutIssue = this.state.issue;
        }

        return (
            <div className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>糖尿病筛查季度报</h5></Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                <Grid container justify="center" wrap="nowrap" spacing={2}>
                    <Typography className={classes.titleMain} h5 color="textSecondary" gutterBottom>糖尿病筛查季度报</Typography>
                </Grid>
                <Grid className={classes.tableDIV}>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    
                    <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        年份：
                        <Input name="year" value={this.state.year} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                        &nbsp;&nbsp;季度：
                        <Input name="quarter" value={this.state.quarter} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>

               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        管理的糖尿病患者数：
                        <Input name="number" value={this.state.number} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        完成季度随访人数：
                        <Input name="followupNumber" value={this.state.followupNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        进行症状筛查人数：
                        <Input name="screeningNumber" value={this.state.screeningNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        肺结核可疑症状人数：
                        <Input name="suspiciousNumber" value={this.state.suspiciousNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        开具推介转诊单人数：
                        <Input name="introduceNumber" value={this.state.introduceNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        到结核病定点机构就诊人数：
                        <Input name="inspectNumber" value={this.state.inspectNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        诊断为肺结核人数：
                        <Input name="diagnosisNumber" value={this.state.diagnosisNumber} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}} />
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
                    
                    <Grid container justify="center" wrap="nowrap" spacing={2}>
                        <Grid item xs={12} className={classes.buttonDiv}>
                            <Button variant="contained" color="secondary" className={classes.buttonUpdate}
                                    onClick={this.handleUpdate}>
                                更新
                            </Button>
                            <Button variant="contained" color="secondary" className={classes.buttonRemove}
                                    onClick={this.handleSubmit}>
                                删除
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
       serverInfo: state.serverInfo,
       screeningDiabetesRemoveRet: state.customReducer.screeningDiabetesRemoveRet,
       screeningDiabetesUpdateRet: state.customReducer.screeningDiabetesUpdateRet,
    }
 }
 
 const mapDispatchToProps = (dispatch) => {
    return {
       updateRecord: params => {
          dispatch(screeningDiabetesUpdate(params));
       },
       removeRecord: params => {
          dispatch(screeningDiabetesRemove(params));
       },
       queryScreeningDiabetesHistory: (params) => {
          dispatch(listScreeningDiabetesHistory(params))
       }
    }
 }
 

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    titleMain: {
        marginLeft: '3vw',
        fontSize: '5.5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    file : {
        display:'none',
        visibility:'hidden',
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        position: 'relative',
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw',
        fontSize:'4.2vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
    },
    buttonUpdate: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    buttonRemove: {
        width: '25vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontWeight: '300',
        width: '91vw',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    input: {
        width: '13vw'
    },
    inputs: {
        marginBottom: theme.spacing(1),
        width: '88vw'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    inputDiv: {
        width: '12vw',
        textAlign: 'center',
    },
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500'
    },

    titleBold: {
        margin: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    doctor: {
        marginTop: theme.spacing(1),
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    title: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },
    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },
    click: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color:'#00C9E4',
        textDecoration: 'none',
        underline:'none'
    },

    clickDiv: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },

    tableDIV: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    inputShort: {
        width: '10vw',
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreeningDiabetesHistoryDetailPaper));
