import React from 'react';
import {connect} from 'react-redux';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import {Switch} from "react-router-dom";
import {
    queryServiceProtocolInfo ,
   commitServiceSignAgreement,
   queryPatientDetail
   
} from "../../redux/actions/patient_manage_action";
import Toast from "antd-mobile/lib/toast";
import SignaturePad from '../../lib/signature/index';
import Input from "@material-ui/core/Input/Input";
import Moment from "moment";
import {DatePicker,Modal as ModalAnt,Radio as RadioAnt} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";

import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {signedConfirm,commitDirectAnswer} from "../../redux/actions/custom_action";

import SignPadPlus from '../../lib/signPadPlus';




const useStyles = makeStyles({
    root: {
       '&:hover': {
          backgroundColor: 'transparent',
       },
    },
    icon: {
       borderRadius: '50%',
       width: 14,
       height: 14,
       boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
       backgroundColor: '#f5f8fa',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
       '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
       },
       'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
       },
       'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
       },
    },
    checkedIcon: {
       backgroundColor: '#00C9E4',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
       '&:before': {
          display: 'block',
          width: 14,
          height: 14,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
       },
       'input:hover ~ &': {
          backgroundColor: '#00C9E4',
       },
    },
 
 });


function StyledRadio(props) {
    const classes = useStyles();
 
    return (
       <Radio
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
          icon={<span className={classes.icon}/>}
          {...props}
       />
    );
 }
 


//患者管理-督导员答题
class DirectAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: 0,
            relation: '',
            directName: '',
            age: '',
            directSign: '',
            patientSign: '',
            doctorSign: '',
            signTime: Moment(new Date()),
            signTimeYear: '',
            signTimeMonth: '',
            signTimeDay: '',
            s1: 0,
            s2: 0,
            s3: 0,
            s4: 0,
            s5: 0,
            c1: 0,
            c2: 0,
            c3: 0,
            c4: 0,
            c5: 0,
            rightN: 0,
            wrongN: 0,
            score: 0,
            boxVisible: false,
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCalc = this.handleCalc.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSKip = this.handleSKip.bind(this);
        // ant 时间选择器控件事件
        this.applicantSignTimePickerOnChange = this.applicantSignTimePickerOnChange.bind(this);
        this.signTimePickerOnChange = this.signTimePickerOnChange.bind(this);
        this.handleEventCancel = this.handleEventCancel.bind(this);
        this.handleSubmitOk = this.handleSubmitOk.bind(this);
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

     // 弹框取消事件
    handleEventCancel() {
        this.setState({
            boxVisible: false
        })
    }

    handleSubmitOk(){
        let params = {
            userId: localStorage.getItem("userId"),
            patientId: this.props.location.state.patientId,
            signed: this.state.signed
        }
        this.props.signedConfirm(params)
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          signed: e.target.value,
        });
      };

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId
        //this.props.getPatientDetail(this.state.patientId);
    }


    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if(nextProps.directAnswerRet != this.props.directAnswerRet){

            if(nextProps.directAnswerRet?.code === 200){
                Toast.info("提交成功！");
                this.handleSKip()
            }
            if(nextProps.directAnswerRet?.code === 201){
                Toast.info("提交失败！"+nextProps.directAnswerRet.msg);
            }
        }
    }

    handleCalc(){
        this.state.rightN = 0
        this.state.wrongN = 0

        if(this.state.s1 == 4){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.s2 == 5){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.s3 == 5){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.s4 == 2){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.s5 == 4){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.c1 == 1){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.c2 == 1){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.c3 == 2){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.c4 == 1){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        if(this.state.c5 == 1){
            this.state.rightN += 1
        }else{
            this.state.wrongN += 1
        }
        this.state.score = this.state.rightN
        this.setState({
            boxVisible: true
        })
    }

    //提交event
    handleSubmit(){
        var directSignPad=this.refs.directSign;
        var directSignData=directSignPad.toDataURL();


        if(this.state.signTimeYear != ''){
            this.state.signTime = this.state.signTimeYear+"-"+this.state.signTimeMonth+"-"+this.state.signTimeDay
        }else{
            this.state.signTime = Moment(new Date()).format("YYYY-MM-DD")
        }

        const serviceSign = {
            patientId:this.state.patientId,
            directName: this.state.directName,
            sex: this.state.sex,
            age: this.state.age,
            degreee: this.state.degreee,
            telphone: this.state.telphone,
            directSign: directSignData,
            relation: this.state.relation,
            signTime: this.state.signTime,
            answer:this.state.s1+","+this.state.s2+","+this.state.s3+","+this.state.s4+","+this.state.s5+","+this.state.c1+","+this.state.c2+","+this.state.c3+","+this.state.c4+","+this.state.c5,
            rightNumber: this.state.rightN,
            wrongNumber:this.state.wrongN,
            score:this.state.score,
        }
       //this.props.history.push('/doctorIndex/manage',{type: 'caller'});
        this.props.commitServiceSignAgreement(serviceSign);
        console.log(serviceSign)
    }

   // 医生签字时间
   signTimePickerOnChange(date, dateString) {
      this.setState({
         signTime:dateString,
         signTimeYear: dateString.split("年")[0],
         signTimeMonth: dateString.split("年")[1].split("月")[0],
         signTimeDay: dateString.split("月")[1].split("日")[0],
      })
   }

   // 申请人签字时间
   applicantSignTimePickerOnChange(date, dateString) {
      this.setState({
        applicantSignTime:dateString,
        applicantSignTimeYear: dateString.split("年")[0],
        applicantSignTimeMonth: dateString.split("年")[1].split("月")[0],
        applicantSignTimeDay: dateString.split("月")[1].split("日")[0],
      })
   }


    //执行跳过
    handleSKip(){
        this.props.history.push('/doctorIndex/manage/directdeal',{patientId:this.state.patientId});
    }

    render() {
        const { classes,patientDetail } = this.props;

        return (
            <Switch>
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={10} className={classes.headers}><h5 className={classes.huanzhe}>培训效果考核题</h5>
                        </Grid>
                        <Grid item xs={2} className={classes.headers} onClick={this.handleSKip}><h5 className={classes.huanzhe}>跳过</h5> </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>

                    <Grid className={classes.rootDiv}>
                        <Grid container justify="center" wrap="nowrap" spacing={0}>
                            <Typography className={classes.title1}>督导员结核病防治知识考核题</Typography>
                        </Grid>
                        <br/>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>督导员姓名：</Typography>
                            </Grid>
                            <Grid item>
                                <Input name="directName" value={this.state.directName} className={classes.input}
                                   onChange={this.handleInputChange}/>
                            </Grid>
                        </Grid>
                        
                       
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>年龄：</Typography>
                            </Grid>
                            <Grid item>
                                <Input name="age" value={this.state.age} className={classes.input}
                                   onChange={this.handleInputChange}/>
                            </Grid>
                        </Grid>


                        <Typography gutterBottom>
                            <span className={classes.title}>文化程度：</span>
                            <Input name="degreee" value={this.state.degreee} className={classes.input}
                                   onChange={this.handleInputChange}/>
                            <span className={classes.title}>联系电话：</span>
                            <Input name="telphone" value={this.state.telphone} className={classes.input}
                                   onChange={this.handleInputChange}/>
                            
                        </Typography>
                        <Typography gutterBottom>
                            <span className={classes.title}>与病人关系及居住情况：</span>
                            <Input name="relation" value={this.state.relation} className={classes.input2}
                                   onChange={this.handleInputChange}/>
                            
                            
                        </Typography>

                        
                        

                        <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0} >
                            <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Typography className={classes.textDiv} gutterBottom>
                                
            
                                    <p className={classes.spanDiv}> 一、单选题： </p>
                                    <Typography className={classes.textDiv}> 1、人感染结核杆菌，导致发病的主要途径是：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="s1" value={this.state.s1}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="A.土壤接触"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="B.随食物进入"/>
                                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="C.皮肤接触"/>
                                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="D.飞沫传播"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                          
                                    <Typography className={classes.textDiv}> 2、肺结核病人从呼吸道排出结核杆菌的方式有：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="s2" value={this.state.s2}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="A.咳嗽"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="B.打喷嚏"/>
                                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="C.大声说话"/>
                                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="D.用力吐痰"/>
                                                <FormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio/>} label="E.以上都是"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 3、在督导病人服药程中，如有一般的药物不良反应，您的处理方式是：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="s3" value={this.state.s3}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="A.立即停药"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="B.立即减量"/>
                                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="C.立即改药"/>
                                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="D.立即住院"/>
                                                <FormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio/>} label="E.立即报告"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 4、肺结核病人的管理重点是：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="s4" value={this.state.s4}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="A.督导病人休息"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="B.督导病人按时复查、规则足量用药"/>
                                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="C.指导病人消毒"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 5、怀疑得了肺结核，下列四种检查方法中最应该做的是：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="s5" value={this.state.s5}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="A.验血"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="B.结核菌素皮试"/>
                                                <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="C.肺功能测定"/>
                                                <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="D.肺部X线检查"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <p className={classes.spanDiv}> 二、判断题： </p>

                                    <Typography className={classes.textDiv}> 1、肺结核病是由结核杆菌感染引起的慢性呼吸道传染病：  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="c1" value={this.state.c1}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="对"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="错"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 2、与传染性病人密切接触者要接受症状筛查，有症状者要进一步临床检查。  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="c2" value={this.state.c2}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="对"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="错"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 3、只要是肺结核病，到任何医院治疗的费用都能享受政府减免政策。  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="c3" value={this.state.c3}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="对"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="错"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 4、听从医生嘱咐，坚持规范治疗，绝大多数肺结核病人是可以治好的。  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="c4" value={this.state.c4}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="对"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="错"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    <Typography className={classes.textDiv}> 5、不规则治疗会使结核杆菌耐药，后果很严重。  </Typography>
                                    <br/>
                                    <Grid container justify="center" wrap="nowrap" spacing={0} style={{marginLeft:20}}>
                                            <RadioGroup row defaultValue="0" name="c5" value={this.state.c5}
                                                        onChange={this.handleInputChange}>
                                                <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="对"/>
                                                <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="错"/>
                                            </RadioGroup>
                                    </Grid>
                                    <br/>

                                    
                                </Typography>
                            </Grid>
                        </Grid>

                       <br />
                        <Typography className={classes.titleBold} gutterBottom >
                                督导员签名：
                        </Typography>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='directSign' name="directSign" clearButton="true" height={300} className={classes.SignaturePadDiv}/>
                        </Grid>
                        <br />
                        <Typography className={classes.titleBold} gutterBottom>
                            签名日期：<DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                        allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={this.state.signTime}
                                        onChange={this.signTimePickerOnChange}/>
                        </Typography>
                           
                        <br />
                        
                        <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleCalc}>提交</Button>
                    </Grid>


                    <ModalAnt title="答题评分"
                      visible={this.state.boxVisible}
                      afterClose={this.handleEventCancel}
                      onCancel={this.handleEventCancel}
                      destroyOnClose={true}
                      forceRender={true}
                      footer={[
                         <Button key="back" onClick={this.handleEventCancel}>取消</Button>,
                         <Button key="submit" onClick={this.handleSubmit}>确定</Button>,
                      ]}
                    >
                        <Grid item xs={10} className={classes.bindContent}>
                            <Grid className={classes.bindContentFlex}>
                                <label>正确  {this.state.rightN}  题，错误  {this.state.wrongN}  题, 得分 {this.state.score}    </label>
                            </Grid>
                        </Grid>
                    </ModalAnt>

                    
                </div>
            </Switch>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        patientDetail : state.patientManageReducer.patientDetail,
        directAnswerRet : state.customReducer.directAnswerRet,
        signedRet: state.customReducer.signedRet
    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
        getPatientDetail: (params) => {  //定义的函数
            dispatch(queryPatientDetail(params)) // action里面定义的
        },
        commitServiceSignAgreement : (serviceSign) => {
            dispatch(commitDirectAnswer(serviceSign))
        },
        signedConfirm: (params) => {
            dispatch(signedConfirm(params))
        },
    };
};

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
    },
    spacemargin: {
        margin: theme.spacing(2),

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        width: '30vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    input: {
        margin: theme.spacing(0),
        width: '25vw'
    },

    signDate:{
        margin: theme.spacing(0),
        width: '15vw'
    },

    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw'
    },
    titleBold: {
       fontSize: '4.2vw',
        lineHeight: '8vw',
        textAlign: 'justify',
        margin: 0,
        fontWeight: '700',
        color: '#333333'
    },
    text: {
        fontSize: '4.2vw'
    },
    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },

    spanDiv: {
        textIndent: '0',
        fontWeight: '700',
    },


    buttonBox: {
        height: '30vw',

    },
    cardbutton: {
        width: '50vw',
        display: 'flex',
        flexDirection: 'row',
        marginbottom: '5vw',
        justifyContent: 'space-between',
    },
    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '43vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '7vw',
        marginbottom: '20vw',
        color: '#ffffff'
    },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    //modal弹窗
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    papersDiv: {
        width: '85vw',
        height: '55vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    // buttons: {
    //     width: '25vw',
    //     background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    //     marginTop: '1vw',
    //     height: '8vw',
    //     fontSize: '3.5vw',
    //     padding: 0,
    // },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },

    textDiv: {
        lineHeight: '8vw',
        fontSize: '4.2vw',
    },

    yearBold: {
        textAlign: 'right',
        marginBottom: '10vh',
        fontWeight: '700',
    },

    textBold: {
        marginBottom: '6vh',
        fontWeight: '700',
    },

    buttons: {
        margin: 'auto',
        fontSize: '4.8vW',
        marginBottom: '3vh',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },




    bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important',
        justifyContent: 'center',
     },
     bindModalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     },
     bindContent: {
        backgroundColor: '#FFFFFF'
     },
     hideMe:{
        display: 'none',
     },


});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DirectAnswer));
