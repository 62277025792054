import * as ActionTypes from '../../actions/patients/consult-action'

// The initial application state
let initialState = {
    messageList:[],
}


// Takes care of changing the application state
function consultReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_CONSULT_LIST_SUCCEED:
            return {...state,messageList : action.result }
        default:
            return state
    }

}

export default consultReducer