/* 患者管理主页面 */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import {getDoctorBasicNewsList} from '../redux/actions/index';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HealthInfo1 from '../assets/images/healthinfo1.jpg';
import Grid from '@material-ui/core/Grid';
import returnImg from '../assets/images/returnImg.png'
// pick a date util library
import Paper from '@material-ui/core/Paper';
import NewsDetail from './NewsDetail'

const StyledAvatar = withStyles({
    root: {
        borderRadius: 3,
        border: 0,
        height: 60,
        width: 90,
        marginRight: 3,
    },
})(Avatar);


//健康宣教页面
class HealthInfoView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};


  this.handleNavBack = this.handleNavBack.bind(this);


    }

    componentDidMount() {
        this.handleDetailClick = this.handleDetailClick.bind(this);
        this.props.getDoctorBasicNewsList()
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChangeIndex(index) {

    }

    handleNavBack() {
        this.props.history.goBack();
    }
    handleDetailClick(index) {
        //应该是在登录成功之后,才调用
        const path = `${this.props.match.path}/newsdetail`+index;
        this.props.history.push(path);
    }

    render() {
        //get react-material css styles
        const {classes, newsList} = this.props;
        return (
            <Switch>
            <Route path={`${this.props.match.path}/newsdetail:index`} component={NewsDetail}/>
            <Grid className={classes.rootDiv}>
                <Grid spacing={0}>
                    {/* header */}
                    {/* 标题栏 箭头 通知详情 */}
                    <Grid className={classes.Patient}>
                        <img src={returnImg} className={classes.jiantou} onClick={this.handleNavBack}/>
                        <Grid container justify="center" className={classes.headers}>
                            <h4 className={classes.title}>健康宣教</h4>
                        </Grid>
                    </Grid>
                    {(newsList.data || []).map((item, index) => {
                        return (
                            <div key={item.id} onClick={() => {
                                this.handleDetailClick(item.id);
                            }}>
                            <Grid className={classes.newsDiv}>
                                <Grid container  item xs={12} justify="flex-start">
                                    <Grid item xs={3}>
                                        <ListItemAvatar  className={classes.avatarDIV} >
                                            <StyledAvatar alt="Remy Sharp" src={item.photo1 === "" ? HealthInfo1 : item.photo1}/>
                                        </ListItemAvatar>
                                    </Grid>
                                    <Grid item xs={8} container alignItems="stretch" >
                                        <Typography className={classes.subtitleDIV} wrap="nowrap" >{item.title}</Typography>
                                        {/* <Typography className={classes.contentDIV} noWrap></Typography> */}
                                        <Grid container justify="space-between">
                                            <Typography className={classes.dateDIV}>{item.createTime}</Typography>
                                            <Typography className={classes.readDIV}>{item.readcount}阅读</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr className={classes.hr}></hr>
                            </Grid>
                            </div>
                                )
                            })
                        }

                </Grid>
            </Grid>
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newsList: state.newsReducer.doctorBasicNewsList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //
        getDoctorBasicNewsList: () => {
            dispatch(getDoctorBasicNewsList())
        },
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        flexGrow: 1,
        overflow: 'auto',
        height: '100vh',
        backgroundColor: '#FFFFFF',
    },

    avatarDIV: {
        marginRight: '10vw',
        width: '100vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    dateDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw'

    },

    readDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw'
    },

    subtitleDIV: {
        padding: '0',
        color: '#333333',
        fontSize: '4.2vw',
        marginTop: '0vw',
    
        display:  '-webkit-box',
        overflow:  'hidden ',
        textOverflow:  'ellipsis',
        wordWrap:  'break-word ',
        whiteSpace:  'normal !important ',
        webkitLineClamp: ' 2',
        WebkitBoxOrient: ' vertical ' ,
    },

    contentDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '4vw',
        marginTop: '0vw'
    },

    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    title: {
        color: '#ffffff',
        fontWeight: '500',
    },

    newsDiv: {
        margin: theme.spacing(2),
        width: '100vw',
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(HealthInfoView)));