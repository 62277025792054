import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

class QuickMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
            viewportWidth: 0,
            viewportHeight: 0,
        };
    }

    render() {
        //get react-material css styles
        const {classes} = this.props;
        return (
            <div className={classes.singleTab}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={this.props.noticeNum} color="secondary">
                        <img src={this.props.tabImage} className={classes.menuImage} onClick={this.props.imgListClick}/>
                        
                    </Badge>
                </IconButton>
                <p className={classes.shortcutIcon}>{this.props.tabLabel}</p>
            </div>
        )
    }
}

//css region
const styles = theme => ({
    singleTab: {
        display: 'flex',
        flexDirection: 'column',
        width: '33vw',
        height: '25vw',
        justifyContent: 'center',
        alignItems: 'center',
        // margin:0
    },

    menuImage: {
        width: '9vw',
        height: '9vw',
        maxWidth: '9vw',
        maxHeight: '9vw',
        borderRadius: "50%",

    },

    shortcutIcon: {
        fontSize: '3.3vw',
        //marginTop: '1.5vh',
        marginBottom: '0vh',
    },

});

export default withStyles(styles)(QuickMenu);