import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {getTrackAppointment,postTrackfailure} from '../../redux/actions/index';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import avatar from "../../assets/images/tj.png";
import axxxx from '../../assets/images/axxxx.png'
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import tishi from '../../assets/images/tishi.png'
import Backdrop from '@material-ui/core/Backdrop';
import Avatar from '@material-ui/core/Avatar';
import gender from "../common/sex";
import Toast from "antd-mobile/lib/toast";
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

//追踪失败原因页面
class Trackfailure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            traceId:0,
            reasonId: 0,
            showModal:false,
            reasonDesc: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.state.traceId = this.props.match.params.traceId;
    }

    componentWillUnmount() {

    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = () => {
        //将数据打包成json,然后调用dispatch将数据发送出去
        if(this.state.reasonId === 0) {
            Toast.show("请选择追踪失败原因!");
            return;
        }

        const failureReason = {
            id: this.state.traceId, //主键Id
            reasonId: this.state.reasonId, //身份证号
            reasonDesc: this.state.reasonDesc,//失败描述
        };

        this.props.postTrackfailure(failureReason);
    }

    closeModal = () => {
        this.setState({showModal: false});
        this.handleNavBack();
    }

    //点击返回按钮
    handleNavBack() {
        this.props.history.goBack();
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.trackFailureCode !== this.props.trackFailureCode) {
            //获得发送结果之后，再调用路由切入对应页面
            if (nextProps.trackFailureCode === 200) {
                this.setState({showModal: true});
            }

        }
    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    render() {
        //get react-material css styles
        const {classes, trackAppointment} = this.props;

        return (
            <div className={classes.root}>
                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.huanzhe}>患者追踪失败</h4>
                    </Grid>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                <Grid container justify="center" spacing={0}>
                    <Avatar alt="Remy Sharp" src={avatar} className={classes.avatar}/>
                </Grid>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                <Grid container justify="space-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        姓名：
                    </Typography>
                    <Typography className={classes.span}>{trackAppointment.name}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        性别：
                    </Typography>
                    <Typography className={classes.span}>{gender(trackAppointment.sex)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        年龄：
                    </Typography>
                    <Typography className={classes.span}>{trackAppointment.age}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        现住址：
                    </Typography>
                    <Typography className={classes.fieldValueDiv}>{trackAppointment.address}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        通知原因：
                    </Typography>
                    <Typography className={classes.span}>{trackAppointment.reason}</Typography>

                </Grid>
                <hr className={classes.hr}></hr>


                <Grid container justify="flex-start" spacing={0}>
                    <FormLabel className={classes.referralreasonDiv} component="legend">追踪失败原因：</FormLabel>
                </Grid>
                <Grid item xs={12} className={classes.cardjiuzhen}>
                    <RadioGroup defaultValue="1" aria-label="reason" name="customized-radios"
                                name="reasonId"
                                value={this.state.reasonId}
                                onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="19" control={<StyledRadio/>}
                                          label="查无此人"/>
                        <FormControlLabel className={classes.OptionDiv} value="20" control={<StyledRadio/>}
                                          label="拒绝就诊"/>
                        <FormControlLabel className={classes.OptionDiv} value="21" control={<StyledRadio/>}
                                          label="短期外出（一到二周）"/>
                        <FormControlLabel className={classes.OptionDiv} value="22" control={<StyledRadio/>}
                                          label="常驻外地"/>
                        <Input name="reasonDesc" placeholder="请填写（可选）" value={this.state.resonDesc}
                                        onChange={this.handleInputChange} className={classes.input} inputProps={{'aria-label': 'description'}}/>
                        <FormControlLabel className={classes.OptionDiv} value="23" control={<StyledRadio/>} label="死亡"/>
                        <FormControlLabel className={classes.OptionDiv} value="25" control={<StyledRadio/>}
                                          label="其他原因"/>
                        <FormControlLabel className={classes.OptionDiv} value="24" control={<StyledRadio/>}
                                          label="地址不详"/>
                    </RadioGroup>
                </Grid>


                <Grid container justify="center">
                    <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleSubmit}>
                        提交
                    </Button>
                </Grid>

                <Modal
                    className={classes.modal}
                    open={this.state.showModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Grid item xs={10} className={classes.paper}>
                        <Grid item xs={12} className={classes.waraing}><p className={classes.warm}>温馨提示</p></Grid>
                        <Grid item xs={12} className={classes.paperDiv}>
                            <Grid item xs={12} className={classes.tishiDiv}>
                                <img className={classes.tishi} src={tishi}></img><span
                                className={classes.spanDiv}>提交成功!</span></Grid>
                            <Grid item xs={12} className={classes.buttonDiv}>
                                <Button variant="contained" color="primary" className={classes.buttons}
                                        onClick={this.closeModal}>确 &nbsp;认</Button></Grid>
                        </Grid>
                    </Grid>

                </Modal>
            </div>
        );
    }

}



const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        appointmentSucceedCode:state.trackReducer.appointmentSucceedCode,
        trackAppointment : state.trackReducer.trackAppointment,
        trackFailureCode : state.trackReducer.trackFailureCode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTrackAppointment:(traceId) => {
            dispatch(getTrackAppointment(traceId))
        },
        postTrackfailure: (failureReason) => {
            dispatch(postTrackfailure(failureReason)) //action/index页面
        }
    }
}



//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: '#FFFFFF',
        height: '100vh',
    },

    card: {
        maxWidth: '100vw',
    },

    spacemargin: {
        margin: theme.spacing(2),
    },

    fieldValueDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999',
    },

    SelectionboxDiv: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginTop: '1vw',
        height: '60vw',
        width: '90vw',
        justifyContent: 'space-between',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '83vw',
        padding: 0,
        margin: 0,
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        padding: 0
    },

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },

    expandOpen: {
        transform: 'rotate(180deg)',
    },

    margin: {
        backgroundColor: '#22ff44',
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        color: '#ffffff',
        lineHeight: '6vw',
    },

    headPortrait: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '23vw',
        justifyContent: 'center',
        marginTop: '9vw',
        flexShrink: '1',
    },

    avatar: {
        display: 'flex',
        width: '22vw',
        height: '22vw',
        maxWidth: '22vw',
        maxHeight: '22vw',
        flexShrink: '1',
        borderRadius: "50%"
    },

    information: {
        marginTop: '5vh',
        width: '100%',
        height: '55vh',
    },

    cardjiuzhen: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(36,225,221,0.05)',
        paddingLeft: theme.spacing(2),
        width: '90vw',
        height: '75vw',
        border: '0.1vw solid #e6e6e6',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        color: '#787878',
    },

    cardbutton: {
        width: '100vw',
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '9vh'
    },

    span: {
        marginRight: theme.spacing(2),
        lineHeight: '10vw',
        color: '#999999'
    },

    title: {
        margin: 0,
        marginLeft: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333',
        lineHeight: '10vw',
    },

    hr: {
        height: '0.3vw',
        // backgroundColor:'#e6e6e6',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    },

    referralreasonDiv: {
        // lineHeight: '6vw',
        // color: '#eeee11',
        fontSize: '4.2vw',
        height: '5vw',
        marginTop: '4vw',
        marginLeft: '3vw',
        display: 'flex',
        marginBootm: '20vw'
    },

    OptionDiv: {
        width: '90vw',
        height: '8.5vw'
    },

    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    paper: {
        width: '85vw',
        height: '45vw',
        backgroundColor: '#ffffff'
    },

    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    tishi: {
        height: '10vw'
    },

    paperDiv: {
        height: '15vw',
        flexDirectio: 'column',
    },

    buttonDiv: {
        height: '18vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },

    buttons: {
        width: '30vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '4vw',
        padding: 0,

    },

    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw',
    },

    spanDiv: {
        fontSize: '3.5vw',
        marginLeft: '4vw',
    },

    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    input: {
        margin: theme.spacing(1),
        width: '75vw',
        height: '4vw',
        lineHeight: '4vw',
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Trackfailure));