import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import zixun from "../../assets/images/zixun.png";
import gongzuo from "../../assets/images/gongzuo.png";
import wode from "../../assets/images/wode.png";

//医生端主页面 页脚
class Footer extends React.Component{
   constructor(props) {
      super(props);
      this.state = {
         tabulation: this.props.btnType=='tabulation'?true:false,
         showcase: this.props.btnType=='showcase'?true:false,
         business: this.props.btnType=='business'?true:false,
      };
      this.handleLoginClick = this.handleLoginClick.bind(this);
      this.handClick = this.handClick.bind(this);
      this.leLoginClick = this.leLoginClick.bind(this);
   }

   //资讯
   handClick() {
      const path = `${this.props.urlPath.match.path}/news`;
      this.props.urlPath.history.push(path);
   }

   //工作站
   handleLoginClick() {
      this.props.urlPath.history.push('/doctorIndex');
   }

   //我的
   leLoginClick() {
      const path = `${this.props.urlPath.match.path}/myview`;
      this.props.urlPath.history.push(path);
   }

   render() {
      const {classes} = this.props;
      return (
         <Grid container alignItems="flex-end" item xs={12} className={classes.footerDiv}>
            <Grid className={classes.bott}>
               <button className={this.state.showcase ? classes.buttonon : classes.buttonsdiv}
                       onClick={this.handClick}>
                  <img src={zixun} className={classes.imgdiv}></img><p className={classes.ps}>资讯</p>
               </button>
               <button className={this.state.tabulation ? classes.buttonons : classes.buttonssdiv}
                       onClick={this.handleLoginClick}>
                  <img src={gongzuo} className={classes.imgsdiv}></img><p className={classes.ps}>工作站</p>
               </button>
               <button className={this.state.business ? classes.buttonon : classes.buttonsdiv}
                       onClick={this.leLoginClick}>
                  <img src={wode} className={classes.imgdiv}></img><p className={classes.ps}>我的</p>
               </button>
            </Grid>
         </Grid>
      );
   }
}
//css region
const styles = () => ({

   //底部样式
   footerDiv: {
      alignSelf: 'flex-end',
      marginTop: '0vw',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      border: '0.1vw solid #e6e6e6',
      width: '100vw',
      height: '14vw',
   },
   bott: {
      width: '80vw',
      height: '14vw',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
   },
   buttonon: {
      outline: 'none',
      border: 'none',
      borderRadius: '50%',
      marginBottom: '1vw',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',
   },
   buttonons: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      color: '#55E8E5',
      filter: 'none',

   },
   buttonsdiv: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      marginBottom: '1vw',
      filter: 'grayscale(100%)',
   },
   buttonssdiv: {
      outline: 'none',
      // height: '16vw',
      marginBottom: '3vw',
      borderRadius: '50%',
      border: '0.1vw solid #e6e6e6',
      backgroundColor: '#e6e6e6',
      filter: 'grayscale(100%)',
   },
   imgdiv: {
      height: '6vw',

   },
   imgsdiv: {
      height: '7vw',
   },
   ps: {
      fontSize: '2.7vw',
      margin: 0,
   },

});
//end css region

//here we inject redux and react-material css styles
export default connect()(withStyles(styles)(Footer));
