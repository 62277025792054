import React from 'react';
import clsx from "clsx";
import { connect } from 'react-redux';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { queryPatientTakeMedicineRecord, querySputumCheckRecord } from '../../redux/actions/patient_manage_action';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from '@material-ui/core/Grid';
import { IconButton } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import cnLocale from "date-fns/locale/zh-CN"
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Resistant from "../../doctor/common/resistant";
import Radio from "@material-ui/core/Radio/Radio";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Api from '../../redux/api/api';
import Toast from "antd-mobile/lib/toast";
import axios from "axios";

import axxxx from '../../assets/images/axxxx.png';
import medbottleImg from '../../assets/images/medbottle.png';
import goldImg from '../../assets/images/gold.png';


//我的页面-我的积分页

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightBlue.A200,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiPickersDay: {
            day: {
                color: lightBlue.A700,
            },
            daySelected: {
                backgroundColor: lightBlue["400"],
            },
            dayDisabled: {
                color: lightBlue["100"],
            },
            current: {
                color: lightBlue["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: lightBlue["400"],
            },
        },
    },
});
const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});
const columns = [
    { id: 'actualTime', label: '就诊日期', minWidth: 40, align: 'center' },
    { id: 'orderTime', label: '预约日期', minWidth: 50, align: 'center' },
];

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//我的页面-我的积分页
class MyScoreWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            score: '-',
            scoreHistory: [],
            patientId: 0,
            userId: 0,
            open: false,
        };

        this.handleOpenRule = this.handleOpenRule.bind(this)
        this.handleCloseRule = this.handleCloseRule.bind(this)
        this.handleNavBack = this.handleNavBack.bind(this)
        this.handleStore = this.handleStore.bind(this)
    }



    componentDidMount() {
        this.state.userId = localStorage.getItem("userId")
        this.state.patientId = localStorage.getItem("patientId")
        //加载数据
        let postData = {
            "patientId": this.state.patientId,
            "token": localStorage.getItem("token"),
            "userId": this.state.userId
        }
        const url = Api.getRootUrl() + '/api/patient/scoreHistory'
        let that = this;
        axios.post(url, postData).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200 && rsp.data.data != undefined) {
                //Toast.info("提交成功!")
                that.setState({
                    score: rsp.data.data.score,
                    scoreHistory: rsp.data.data.scoreHistory,
                })
            }
        }).catch(function (err) {
            console.log(err)
        })

    }

    handleOpenRule() {
        this.setState({
            open: true
        })
    }

    handleCloseRule() {
        this.setState({
            open: false
        })
    }

    handleNavBack(){
        window.history.back()
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleStore(){
        this.props.history.push(`/jfStore`)
    }


    render() {
        //get react-material css styles
        const { classes, takeMedicine, sputumRecord } = this.props;

        return (
            <div className={classes.rootDiv}>
                {/* header */}
                <div className={classes.header}>
                    <Grid className={classes.headerNav}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                    </Grid>
                    <Grid className={classes.headerDiv}>
                        <Grid xs={12} justify="center" container >
                            <Grid xs={6} className={classes.total}>
                                <div className={classes.totalTitle}>可用积分</div>
                                <div className={classes.totalScore}>{this.state.score}</div>
                            </Grid>
                            <Grid xs={6} className={classes.info}>
                                <div className={classes.jfgz} onClick={this.handleOpenRule}>积分规则 ></div>
                            </Grid>

                        </Grid>
                    </Grid>
                </div>

                <Grid className={classes.papers}>
                    <Grid container justify="flex-start" item xs={8}>
                        <span className={classes.title}>积分记录</span>
                    </Grid>
                    <Grid container justify="flex-end" item xs={4}>
                        <span className={classes.title}><a onClick={()=>this.handleStore()}>积分商城>></a></span>
                    </Grid>
                </Grid>

                {(this.state.scoreHistory || []).map((item) => {
                    return (
                        <Grid className={classes.papers}>
                            <Grid justify="center" className={classes.titleImgBox} item xs={2}>
                                <img src={medbottleImg} className='' ></img>
                            </Grid>
                            <Grid justify="flex-start" item xs={8} style={{}}>
                                <div style={{ marginLeft: '10px', borderBottom: '1px solid #979797' }}>
                                    <div className={classes.title}><span >{item.eventName}</span></div>
                                    <div className={classes.subTitle}>{item.createTime}</div>
                                </div>
                            </Grid>
                            <Grid justify="flex-start" item xs={3} className={classes.scoreThis} style={{ borderBottom: '1px solid #979797' }}>
                                <span >{item.scoreChange}</span>
                                <img src={goldImg} className='' ></img>
                            </Grid>
                        </Grid>
                    )
                })
                }
                {/*
                <Grid className={classes.papers}>
                    <Grid justify="center" className={classes.titleImgBox} item xs={2}>
                        <img src={medbottleImg} className='' ></img>
                    </Grid>
                    <Grid justify="flex-start" item xs={8} style={{}}>
                        <div style={{ marginLeft: '10px', borderBottom: '1px solid #979797' }}>
                            <div className={classes.title}><span >每日服药</span></div>
                            <div className={classes.subTitle}>2022-03-01 11:21:18</div>
                        </div>
                    </Grid>
                    <Grid justify="flex-start" item xs={3} className={classes.scoreThis} style={{ borderBottom: '1px solid #979797' }}>
                        <span >+2</span>
                        <img src={goldImg} className='' ></img>
                    </Grid>
                </Grid>
                <Grid className={classes.papers}>
                    <Grid justify="center" className={classes.titleImgBox} item xs={2}>
                        <img src={medbottleImg} className='' ></img>
                    </Grid>
                    <Grid justify="flex-start" item xs={8} style={{}}>
                        <div style={{ marginLeft: '10px', borderBottom: '1px solid #979797' }}>
                            <div className={classes.title}><span >每日服药</span></div>
                            <div className={classes.subTitle}>2022-03-01 11:21:18</div>
                        </div>
                    </Grid>
                    <Grid justify="flex-start" item xs={3} className={classes.scoreThis} style={{ borderBottom: '1px solid #979797' }}>
                        <span >+2</span>
                        <img src={goldImg} className='' ></img>
                    </Grid>
                </Grid>
                 */}


                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseRule}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"用户积分规则"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            提交每日服药，可获得2积分。另外，连续提交服药的天数累计到一定数值，会给予一定积分奖励！
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseRule} color="primary">
                            关闭
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}


//css region
const styles = theme => ({
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
    },
    header: {
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
    },
    papers: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
    headerDiv: {
        display: 'flex',
        //justifyContent: 'center',
        //alignItems: 'center',
        width: '100vw',
        height: '12vh',
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
        color: '#ffffff',
    },
    headerNav: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '5vh',
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
        color: '#ffffff',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    total: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    totalTitle: {
        color: '#302C2C',
        fontSize: '2.5vw',
    },
    totalScore: {
        fontSize: '9vw',
        fontWeight: 600,
    },
    info: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    jfgz: {
        marginRight: '25px',
        marginBottom: '25px',
        fontSize: '2.5vw',
    },
    titleImgBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: '4vw',
        fontWeight: 600,
        color: '#5D5959',
        lineHeight: '6vw',
    },
    subTitle: {
        fontSize: '2.5vw',
        fontWeight: 600,
        color: '#979797',
        margin: '7px 0',
    },
    scoreThis: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '4.2vw',
        color: '#F65A1F',
    },


    spanDiv: {
        color: '#8BEAF0',
        margin: '2vw'
    },
    day: {
        width: 40,
        height: 40,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,

    },
    highlightNonCurrentMonthDay: {
        color: "#676767",

    },
    highlight: {
        backgroundColor: '#00DFD7',
        color: theme.palette.common.white,
        borderRadius: "50%",
    },
    firstHighlight: {
        extend: "highlight",
        borderRadius: "50%"
    },
    endHighlight: {
        extend: "highlight",
        borderRadius: "50%"
    },
    pickerDiv: {
        width: '100vw',
        height: '100vw',
        backgroundColor: 'red'
    },
    tableWrapper: {
        width: '90vw',
        height: '50vw',
    },
    titleBold: {
        backgroundColor: '#00DFD7',
        height: '10vw',
        lineHeight: '10vw',
        color: '#ffffff'
    },
    titleNormal: {
        height: '12vw',
        lineHeight: '12vw',
        color: '#333333'
    },
    spans: {
        color: '#999999'
    },
    titleDiv: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333',
        marginTop: '2vw'
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        margin: '2vw',
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(MyScoreWidget)));