import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {getIntroduceNotifyTreated} from '../../redux/actions/index';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import avatar from "../../assets/images/tj.png";
import gender from '../common/sex';
import introduceStatus from '../common/introduceStatus';
import returnImg from '../../assets/images/returnImg.png'


//我的推介-通知-已就诊患者详情页面
class IntroduceNotifyTreated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0,
            noticeId:0,
            noticeType:8
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleNavBack = this.handleNavBack.bind(this)
    }

    componentDidMount() {
        this.state.introId = this.props.location.state.noticeId
        this.state.id = this.props.location.state.id //通知主键Id

        this.props.getIntroduceNotifyTreated(this.state.introId)

        let notice ={}
        notice.id = this.state.id
        notice.type = this.state.noticeType
        this.props.noticeReaded(notice);
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    };

    //点击返回
    handleNavBack() {
        this.props.history.goBack();
    }

    render() {
        //get react-material css styles
        const {classes,introduceNotifyTreated} = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.Patient}>
                    <img alt="" src={returnImg} className={classes.jiantou} onClick={this.handleNavBack}/>
                    <Grid container justify="center" className={classes.headers}>
                        <h4 className={classes.titles}>已就诊患者详情</h4>
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.headPortrait}>
                    <Avatar alt="Remy Sharp" src={avatar} className={classes.avatar}/>
                </Grid>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        姓名：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.name==null?"未知用户":introduceNotifyTreated.name}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        性别：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.sex==null?"男":gender(introduceNotifyTreated.sex)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        年龄：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.age==null?20:introduceNotifyTreated.age}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        电话：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.telphone==null?"13800138000":introduceNotifyTreated.telphone}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        现住址：
                    </Typography>
                    <Typography className={classes.spanDiv}>{introduceNotifyTreated.address==null?"中华人民共和国":introduceNotifyTreated.address}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        到位诊断：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.name==null?"到位就诊":introduceNotifyTreated.name}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        当前推介状态：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.status==null?"推介状态":introduceStatus(introduceNotifyTreated.status)}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
                <Grid container justify="space-between" spacing={0}>
                    <Typography className={classes.fieldLable} color="textSecondary" gutterBottom>
                        诊断结果：
                    </Typography>
                    <Typography className={classes.span}>{introduceNotifyTreated.diagnosisResult==null?"诊断结果":introduceNotifyTreated.diagnosisResult}</Typography>
                </Grid>
                <hr className={classes.hr}></hr>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        introduceNotifyTreated: state.introduceReducer.introduceNotifyTreated,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getIntroduceNotifyTreated: (noticeId) => {
          dispatch(getIntroduceNotifyTreated(noticeId)) //action/index页面
    },
  }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
        height: '100vh',
    },

    card: {
        maxWidth: '100vw',
        height: '100vh',
    },

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },

    fieldLable: {
        margin: 0,
        marginLeft: theme.spacing(2),
        lineHeight: '10vw',
        fontSize: '4.2vw',
        color: '#333333'
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },

    margin: {
        margin: theme.spacing(1),
        height: '7vh',
        width: '90vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '5vw',
    },

    headPortrait: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },

    avatar: {
        display: 'flex',
        width: '22vw',
        height: '22vw',
        maxWidth: '22vw',
        maxHeight: '22vw',
        flexShrink: '1',
        borderRadius: "50%"
    },

    information: {
        marginTop: '5vh',
        width: '100%',
        height: '60vh'
    },

    cardjiuzhen: {
        width: '90vw',
        height: '32vh',
        marginLeft: '5vw'
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '83vw',
        height: '14vw'
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        // wordSpacing:'normal',
    },

    cardbutton: {
        width: '100vw',
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        // background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    span: {
        marginRight: theme.spacing(2),
        color: '#999999',
        lineHeight: '10vw',
    },

    spanDiv: {
        lineHeight: '10vw',
        width: '73vw',
        wordWrap: 'break-word',
        textAlign: 'right',
        marginRight: theme.spacing(2),
        color: '#999999',
    },

    title: {
        margin: 0,
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '14vw',
    },

    titles: {
        margin: 0,
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
        lineHeight: '14vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6'
    }

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntroduceNotifyTreated));