import React from 'react';
import {connect} from 'react-redux';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axxxx from '../../assets/images/axxxx.png';
import Typography from '@material-ui/core/Typography';
import {Switch} from "react-router-dom";
import {
    queryServiceProtocolInfo ,
   commitServiceSignAgreement,
   queryPatientDetail
   
} from "../../redux/actions/patient_manage_action";
import Toast from "antd-mobile/lib/toast";
import SignaturePad from '../../lib/signature/index';
import Input from "@material-ui/core/Input/Input";
import Moment from "moment";
import {DatePicker,Modal as ModalAnt,Radio as RadioAnt} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";

import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {signedConfirm,commitReliefDeal} from "../../redux/actions/custom_action";

import SignPadPlus from '../../lib/signPadPlus';




const useStyles = makeStyles({
    root: {
       '&:hover': {
          backgroundColor: 'transparent',
       },
    },
    icon: {
       borderRadius: '50%',
       width: 14,
       height: 14,
       boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
       backgroundColor: '#f5f8fa',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
       '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
       },
       'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
       },
       'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
       },
    },
    checkedIcon: {
       backgroundColor: '#00C9E4',
       backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
       '&:before': {
          display: 'block',
          width: 14,
          height: 14,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
       },
       'input:hover ~ &': {
          backgroundColor: '#00C9E4',
       },
    },
 
 });


function StyledRadio(props) {
    const classes = useStyles();
 
    return (
       <Radio
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
          icon={<span className={classes.icon}/>}
          {...props}
       />
    );
 }
 


//患者管理-结核病耐多药减免治疗协议
class ReliefDeal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reliefType: 1,
            patientId: 0,
            patientName: '',
            sex: 0,
            age: 0,
            patientNumber: '',
            telphone: '',
            address:'',
            idNumber: '',
            permanentAddress: '',
            residenceNumber: '',
            relation: 0,
            applicantSign: '',
            applicantSignTime: Moment(new Date()),
            applicantSignTimeYear: '',
            applicantSignTimeMonth: '',
            applicantSignTimeDay: '',
            doctorSign: '',
            doctorSignTime: Moment(new Date()),
            doctorSignTimeYear: '',
            doctorSignTimeMonth: '',
            doctorSignTimeDay: '',
        };

        this.handleNavBack = this.handleNavBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSKip = this.handleSKip.bind(this);
        // ant 时间选择器控件事件
        this.applicantSignTimePickerOnChange = this.applicantSignTimePickerOnChange.bind(this);
        this.doctorSignTimePickerOnChange = this.doctorSignTimePickerOnChange.bind(this);
        //this.handleEventCancel = this.handleEventCancel.bind(this);
        this.handleSubmitOk = this.handleSubmitOk.bind(this);
    }

    handleNavBack() {
        this.props.history.goBack();
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

     // 弹框取消事件
    handleEventCancel() {
        this.setState({
            boxVisible: false
        })
    }

    handleSubmitOk(){
        let params = {
            userId: localStorage.getItem("userId"),
            patientId: this.props.location.state.patientId,
            signed: this.state.signed
        }
        this.props.signedConfirm(params)
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          signed: e.target.value,
        });
      };

    componentDidMount() {
        this.state.patientId = this.props.location.state.patientId;
        this.props.getPatientDetail(this.state.patientId);
    }


    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if(nextProps.reliefDealRet != this.props.reliefDealRet){
            console.log(nextProps.reliefDealRet)
            if(nextProps.reliefDealRet?.code === 200){
                Toast.info("提交成功！");
                this.handleSKip()
            }
            if(nextProps.reliefDealRet?.code === 201){
                Toast.info("提交失败！"+nextProps.reliefDealRet.msg);
            }
        }
    }

    //提交event
    handleSubmit(){
        var patientSignPad=this.refs.patientSign;
        var patientSignData=patientSignPad.toDataURL();
        var doctorSignPad=this.refs.doctorSign;
        var doctorSignData=doctorSignPad.toDataURL();

        if(this.state.applicantSignTimeYear != ''){
            this.state.applicantSignTime = this.state.applicantSignTimeYear+"-"+this.state.applicantSignTimeMonth+"-"+this.state.applicantSignTimeDay
        }else{
            this.state.applicantSignTime = Moment(new Date()).format("YYYY-MM-DD")
        }

        if(this.state.doctorSignTimeYear != ''){
            this.state.doctorSignTime = this.state.doctorSignTimeYear+"-"+this.state.doctorSignTimeMonth+"-"+this.state.doctorSignTimeDay
        }else{
            this.state.doctorSignTime = Moment(new Date()).format("YYYY-MM-DD")
        }

        const serviceSign = {
            reliefType: this.props.patientDetail.jhjCheckResult == 1?1:2,
            hospitalId: this.state.hospitalId,
            patientId:this.state.patientId,
            applicantSign: patientSignData,
            doctorSign: doctorSignData,
            address: this.state.address,
            permanentAddress: this.state.permanentAddress,
            residenceNumber: this.state.residenceNumber,
            relation: this.state.relation,
            applicantSignTime: this.state.applicantSignTime,
            doctorSignTime: this.state.doctorSignTime,
        }
       //this.props.history.push('/doctorIndex/manage',{type: 'caller'});
        this.props.commitServiceSignAgreement(serviceSign);
    }

   // 医生签字时间
   doctorSignTimePickerOnChange(date, dateString) {
      this.setState({
         doctorSignTime:dateString,
         doctorSignTimeYear: dateString.split("年")[0],
         doctorSignTimeMonth: dateString.split("年")[1].split("月")[0],
         doctorSignTimeDay: dateString.split("月")[1].split("日")[0],
      })
   }

   // 申请人签字时间
   applicantSignTimePickerOnChange(date, dateString) {
      this.setState({
        applicantSignTime:dateString,
        applicantSignTimeYear: dateString.split("年")[0],
        applicantSignTimeMonth: dateString.split("年")[1].split("月")[0],
        applicantSignTimeDay: dateString.split("月")[1].split("日")[0],
      })
   }


    //执行跳过
    handleSKip(){
        this.props.history.push('/doctorIndex/manage/directanswer',{patientId:this.state.patientId});
    }

    render() {
        const { classes,patientDetail } = this.props;

        return (
            <Switch>
                <div className={classes.root}>
                    <Grid item xs={12} className={classes.Patient}>
                        <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
                        <Grid item xs={10} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者申请减免治疗协议书</h5>
                        </Grid>
                        <Grid item xs={2} className={classes.headers} onClick={this.handleSKip}><h5 className={classes.huanzhe}>跳过</h5> </Grid>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>

                    <Grid className={classes.rootDiv}>
                        <Grid container justify="center" wrap="nowrap" spacing={0} className={patientDetail?.jhjCheckResult == 1?'':classes.hideMe}>
                            <Typography className={classes.title1}>耐药肺结核患者申请减免治疗协议书</Typography>
                        </Grid>
                        <Grid container justify="center" wrap="nowrap" spacing={0} className={patientDetail?.jhjCheckResult == 1?classes.hideMe:''}>
                            <Typography className={classes.title1}>普通肺结核患者申请减免治疗协议书</Typography>
                        </Grid>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>

                        <Typography gutterBottom>
                            <span className={classes.titleBold}>患者基本情况：</span>
                        </Typography>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>姓名：</Typography>
                            </Grid>
                            <Grid item>
                                <Input name="patientName" value={patientDetail?.patientName} className={classes.input}
                                   onChange={this.handleInputChange1}/>
                            </Grid>
                        </Grid>
                        
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>性别：</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="0" name="drugType" value={this.state.drugType}
                                            onChange={this.handleInputChange1}>
                                    <FormControlLabel className={classes.OptionDiv} checked={patientDetail?.sex === 1} value="1" control={<StyledRadio/>} label="男"/>
                                    <FormControlLabel className={classes.OptionDiv} checked={patientDetail?.sex === 2} value="2" control={<StyledRadio/>} label="女"/>
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>年龄：</Typography>
                            </Grid>
                            <Grid item>
                                <Input name="age" value={patientDetail?.age} className={classes.input}
                                   onChange={this.handleInputChange1}/>
                            </Grid>
                        </Grid>



                        <Typography gutterBottom>
                            <span className={classes.title}>登记编号：</span>
                            <Input name="patientNumber" value={patientDetail?.patientNumber} className={classes.input2}
                                    onChange={this.handleInputChange1}/>
                            
                        </Typography>

                        <Typography gutterBottom>
                            <span className={classes.title}>联系电话：</span>
                            <Input name="telphone" value={patientDetail?.telphone} className={classes.input2}
                                    onChange={this.handleInputChange1}/>
                            
                            
                        </Typography>

                        
                        <Typography gutterBottom>
                            <span className={classes.title}>现住地址：</span>
                            <Input name="address" value={this.state.address} className={classes.input2}
                                   onChange={this.handleInputChange}/>
                            
                            
                        </Typography>
                        <Typography gutterBottom>
                           
                            <span className={classes.title}>居住证号：</span>
                            <Input name="residenceNumber" value={this.state.residenceNumber} className={classes.input2}
                                   onChange={this.handleInputChange}/>
                            
                        </Typography>
                        <Typography gutterBottom>
                            <span className={classes.title}>户籍地址：</span>
                            <Input name="permanentAddress" value={this.state.permanentAddress} className={classes.input2}
                                   onChange={this.handleInputChange}/>
                            
                            
                        </Typography>
                        <Typography gutterBottom>
                            
                            <span className={classes.title}>身份证号：</span>
                            <Input name="idNumber" value={patientDetail?.patientIdNumber} className={classes.input2}
                                   onChange={this.handleInputChange}/>
                            
                        </Typography>
                        <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>与持居住证者的关系：</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row defaultValue="0" name="relation" value={this.state.drugType}
                                            onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio/>} label="无"/>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="本人"/>
                                    <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="配偶"/>
                                    <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="子女"/>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        

                        <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0} className={patientDetail?.jhjCheckResult == 1?'':classes.hideMe}>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Typography className={classes.textDiv} gutterBottom>
                                    本人申请耐多药/利福平耐药肺结核病减免治疗费用，并承诺履行以下责任和义务：
                                    <Typography className={classes.textDiv}> 1、本人提供真实的现住地址及身份证、居住证和相关证明材料，如在治疗期间更换住址将及时告知社区化疗管理医务人员；  </Typography>
                                    <Typography className={classes.textDiv}> 2、遵照医嘱，按时服药，不漏服、不少服，完成规定的疗程，并在服药后由家庭督导员按时做好记录；若在疗程中无故自行停药，1次大于7天或累计大于18天者，不得享受减免治疗；  </Typography>
                                    <Typography className={classes.textDiv}> 3、严格按主治医师预约的复查时间，到医院取药，留取合格痰标本送检及复查X线、胸部CT、肝功能等； </Typography>
                                    <Typography className={classes.textDiv}> 4、如在服药期间出现副反应，及时与主治医师联系，不擅自停药或终止治疗； </Typography>
                                    <Typography className={classes.textDiv}> 5、若不能履行上述各条款之义务，将不得享受治疗费用减免。 </Typography>
                                    申请人在履行上述义务，遵医嘱完成治疗后享受下列减免项目：
                                    <Typography className={classes.textDiv}> 1、抗结核治疗药物  </Typography>
                                    <Typography className={classes.textDiv}>  异胭肼、利福平（利福喷丁）、吡嗪酰胺、链霉素、乙胺丁醇、左氧氟沙星、氧氟沙星、莫西沙星、环丝氨酸、阿米卡星、卡那霉素、卷曲霉素、丙硫异烟胺、氯法齐明、利福布汀、利奈唑胺和对氨基水杨酸（含对氨基水杨酸异烟肼）等； </Typography>
                                    <Typography className={classes.textDiv}>  2、检查项目</Typography>
                                    <Typography className={classes.textDiv}> 全疗程规定的X线胸片、胸部CT、肝肾功能检查，痰结核菌检查、菌型鉴定、药敏检测等。 </Typography>
                                    
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" wrap="nowrap" className={classes.mainDiv} spacing={0} className={patientDetail?.jhjCheckResult == 1?classes.hideMe:''}>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={0}>
                                <Typography className={classes.textDiv} gutterBottom>
                                    本人申请肺结核病减免治疗费用，并承诺履行以下责任和义务：
                                    <Typography className={classes.textDiv}> 1、本人提供真实的现住地址及身份证、居住证和相关证明材料，如在治疗期间更换住址将及时告知社区化疗管理医务人员；  </Typography>
                                    <Typography className={classes.textDiv}> 2、遵照医嘱，按时服药，不漏服、不少服，完成规定的疗程，并在服药后由家庭督导员按时做好记录；若在疗程中无故自行停药，1次大于7天或累计大于18天者，不得享受减免治疗；  </Typography>
                                    <Typography className={classes.textDiv}> 3、严格按诊治医师预约的复查时间，到医院取药，留取合格痰标本送检及复查X线、肝功能等； </Typography>
                                    <Typography className={classes.textDiv}> 4、如在服药期间出现副反应，及时与诊治医师联系，不得擅自停药或终止治疗； </Typography>
                                    <Typography className={classes.textDiv}> 5、若不能履行上述各条款之义务，将不得享受治疗费用减免。 </Typography>
                                    申请人在履行上述义务，遵医嘱完成治疗后享受下列减免项目：
                                    <Typography className={classes.textDiv}> 1、抗结核治疗药物  </Typography>
                                    <Typography className={classes.textDiv}>  异胭肼、利福平（利福喷丁）、吡嗪酰胺、链霉素、乙胺丁醇，或上述药物的复合制剂；左氧氟沙星、氧氟沙星、丙硫异烟胺、卡那霉素、阿米卡星、卷曲霉素、对氨基水杨酸（含对氨基水杨酸异烟肼）等二线抗结核药物（需定点医院证明）。 </Typography>
                                    <Typography className={classes.textDiv}>  2、检查项目</Typography>
                                    <Typography className={classes.textDiv}> 全疗程规定的X线胸片、痰结核菌检查、菌型鉴定、药敏检测、肝功能检查等。 </Typography>
                                    
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classes.titleBold} gutterBottom>
                            申请签名日期：<DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                        allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={this.state.applicantSignTime}
                                        onChange={this.applicantSignTimePickerOnChange}/>
                        </Typography>
                        <br />
                        <Typography className={classes.titleBold} gutterBottom >
                                申请者签名：
                        </Typography>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='patientSign' name="patientSign" clearButton="true" height={300} className={classes.SignaturePadDiv}/>
                        </Grid>
                        <br />
                        <Typography className={classes.titleBold} gutterBottom>
                            医生签名日期：<DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                        allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                        defaultValue={this.state.doctorSignTime}
                                        onChange={this.doctorSignTimePickerOnChange}/>
                        </Typography>
                           
                        <br />
                        <Typography className={classes.titleBold} gutterBottom >
                                医生签名：
                        </Typography>
                        <Grid container spacing={2}>
                            <SignPadPlus ref='doctorSign' name="doctorSign" clearButton="true" height={300} className={classes.SignaturePadDiv}/>
                        </Grid>
                        <br />
                        
                        <Button variant="contained" color="primary" className={classes.buttons} onClick={this.handleSubmit}>提交</Button>
                    </Grid>

                    
                </div>
            </Switch>
        );
    }

}

// reducer里面定义好的接收结果的变量
const mapStateToProps = state => {
    return {
        patientDetail : state.patientManageReducer.patientDetail,
        reliefDealRet : state.customReducer.reliefDealRet,
        signedRet: state.customReducer.signedRet
    };
};

//从远程分发请求
const mapDispatchToProps = dispatch => {
    return {
        getPatientDetail: (params) => {  //定义的函数
            dispatch(queryPatientDetail(params)) // action里面定义的
        },
        commitServiceSignAgreement : (serviceSign) => {
            dispatch(commitReliefDeal(serviceSign))
        },
        signedConfirm: (params) => {
            dispatch(signedConfirm(params))
        },
    };
};

//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
    },
    spacemargin: {
        margin: theme.spacing(2),

    },
    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },
    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },
    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    buttonsTwo: {
        margin: theme.spacing(1),
        width: '30vw',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    input: {
        margin: theme.spacing(0),
        width: '25vw'
    },

    signDate:{
        margin: theme.spacing(0),
        width: '15vw'
    },

    rootDiv: {
        margin: theme.spacing(2),
        textAlign: 'justify',
    },
    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },
    title1: {
        marginLeft: '3vw',
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },
    span: {
        float: 'right',
        marginRight: '3vw'
    },
    titleBold: {
       fontSize: '4.2vw',
        lineHeight: '8vw',
        textAlign: 'justify',
        margin: 0,
        fontWeight: '700',
        color: '#333333'
    },
    text: {
        fontSize: '4.2vw'
    },
    title: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
    },

    spanDiv: {
        textIndent: '0',
        fontWeight: '700',
    },


    buttonBox: {
        height: '30vw',

    },
    cardbutton: {
        width: '50vw',
        display: 'flex',
        flexDirection: 'row',
        marginbottom: '5vw',
        justifyContent: 'space-between',
    },
    margin: {
        margin: theme.spacing(0),
        height: '12vw',
        width: '43vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '4vw',
        marginTop: '7vw',
        marginbottom: '20vw',
        color: '#ffffff'
    },

    mainDiv: {
        fontSize: '4.2vw',
        textIndent: '2em',
        color: '#333333',
        lineHeight: '8vw',
        margin: 0,
    },

    distance: {
        marginTop: theme.spacing(2),
    },

    OptionDiv: {
        fontSize: '4.2vw',
    },
    //modal弹窗
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    papersDiv: {
        width: '85vw',
        height: '55vw',
        backgroundColor: '#ffffff'
    },
    waraing: {
        height: '10vw',
        marginTop: 0,
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    warm: {
        fontSize: '4vw',
        color: '#ffffff',
        marginLeft: '2vw',
        lineHeight: '10vw',
        marginTop: 0,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '45vw',
    },
    paperDiv: {
        height: '30vw'
    },
    tishiDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vw',
        fontSize: '3.8vw'
    },
    shijianDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2vw',
        fontSize: '3.8vw'
    },
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    // buttons: {
    //     width: '25vw',
    //     background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    //     marginTop: '1vw',
    //     height: '8vw',
    //     fontSize: '3.5vw',
    //     padding: 0,
    // },
    butt: {
        width: '25vw',
        backgroundColor: '#ffffff',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
        color: 'black',
        border: '0.3vw solid #999999'
    },

    textDiv: {
        lineHeight: '8vw',
        fontSize: '4.2vw',
    },

    yearBold: {
        textAlign: 'right',
        marginBottom: '10vh',
        fontWeight: '700',
    },

    textBold: {
        marginBottom: '6vh',
        fontWeight: '700',
    },

    buttons: {
        margin: 'auto',
        fontSize: '4.8vW',
        marginBottom: '3vh',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },




    bindContentFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '2% !important',
        justifyContent: 'center',
     },
     bindModalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     },
     bindContent: {
        backgroundColor: '#FFFFFF'
     },
     hideMe:{
        display: 'none',
     },


});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReliefDeal));
