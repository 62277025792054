import React from 'react';
import {connect} from 'react-redux';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
import {getMyTrackList} from '../../redux/actions/index';
import Typography from '@material-ui/core/Typography';
import avatar from "../../assets/images/tj.png";
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import {Grid} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';

import PatientTrackStatus from '../common/patientTrackStatus'

//患者追踪->我的追踪 页面
class TrackList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleDetailClick = this.handleDetailClick.bind(this);
    }

    componentDidMount() {
        const doctorId = localStorage.getItem("doctorId");
        this.props.patientTrackList(doctorId)
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({value: newValue});
    }

    handleChangeIndex(index) {
        this.setState({index: index});
    }

    handleDetailClick(item) {

        if(item.trackType === 12001 || item.trackType === 2100){
            //北京地区追踪模块或通用模块
            const path = `${this.props.match.path}/patientTrackNotice` + item.id;
            this.props.history.push(path);
            return;
        }

        const path = `${this.props.match.path}/patientTrackDetail` + item.id;
        this.props.history.push(path);
    }

    render() {
        //get react-material css styles
        const {classes, trackList} = this.props;
        return (
            <div className={classes.root}>
                <Grid container  item xs={12} justify="center" alignItems="center" className={classes.papers}>
                    <Grid container item xs={4} justify="center" alignItems="center" alignContent="center" wrap="nowrap" direction="column" spacing={0}>
                        <Grid className={classes.quanbuDiv}>
                            <Typography className={classes.quanDiv}>全部 </Typography>
                            <Typography><span className={classes.title}>{trackList.count}</span>&nbsp;人 </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="flex-start" className={classes.boddeDiv}>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid alignItems="stretch" className={classes.bodderDiv}>
                                <Typography className={classes.quanDiv}>待追踪 </Typography>
                                <Typography> <span className={classes.title}>{trackList.placefalse}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid alignContent="center" justify="center" className={classes.bodderDiv}>
                                <Typography className={classes.quanDiv}>追踪成功 </Typography>
                                <Typography> <span className={classes.title}>{trackList.diagnse}</span>&nbsp;人
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid alignItems="stretch" className={classes.bodderDiv}>
                                <hr className={classes.hr}></hr>
                            </Grid>
                        </Grid>

                        <Grid container justify="flex-start" alignItems="center">
                            <Grid alignContent="center" className={classes.bodderDiv}>
                                <Typography className={classes.quanDiv}>追踪中 </Typography>
                                <Typography> <span className={classes.title}>{trackList.placetrue}</span>&nbsp;人
                                </Typography>
                            </Grid>
                            <Grid alignContent="center" className={classes.bodderDiv}>
                                <Typography className={classes.quanDiv}>追踪失败 </Typography>
                                <Typography> <span className={classes.title}>{trackList.fall}</span>&nbsp;人
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {(trackList.list || []).map((item) => {
                    return (
                        <Paper key={item.id} className={classes.paper} onClick={() => { this.handleDetailClick(item); }}>
                            <Grid container xs={4} className={classes.avatarDiv} justify="center" alignItems="center" alignContent="center" wrap="nowrap" direction="column" spacing={0}>
                                <Grid item alignContent="center">
                                    <img src={avatar} alt={avatar} className={classes.avatar}/>
                                </Grid>
                                <Grid item xs={7} >
                                    <Link className={classes.click} component="button" variant="body2"> 查看详情</Link>
                                </Grid>
                            </Grid>
                            <Grid container item xs={11}>
                                <ThemeProvider theme={theme}>
                                    <Typography component="p">患者姓名：{item.name}</Typography>
                                    <Typography component="p">下发时间：{item.createTime}</Typography>
                                    <Typography component="p">追踪原因：{item.reason}</Typography>
                                    <Typography component="p">追踪状态：{PatientTrackStatus(item.patientStatus)}</Typography>
                                </ThemeProvider>
                            </Grid>
                        </Paper>
                    )
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        trackList: state.trackReducer.trackList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        patientTrackList: (doctorId) => {
            dispatch(getMyTrackList(doctorId))
        },
    }
}
const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛
        MuiTypography: {
            body1: {
                fontSize: '4.2vw',
                paddingRight: '5vw',
                lineHeight: '7vw'
            },
        },
    },
});


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: 'auto',
        padding: theme.spacing(0, 2),
        backgroundColor: '#FFFFFF',
        height: '100vh'
    },
    spacemargin: {
        margin: theme.spacing(2),

    },
    titleBold: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '500',
        height: '8vw',
        width: '90vw',
        marginTop: theme.spacing(1),
        backgroundColor: '#E9FCFB',
        color: '#333333'
    },

    paper: {
        display: 'flex',
        // height: '33vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 0)

    },
    papers: {
        margin: theme.spacing(2, 0),
        // width:'100vw'
    },

    headPortrait: {
        display: 'flex',
        flexDirection: 'column',
        // background: 'red',
        alignItems: 'center',
        height: '29vw',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(0, 3)
    },

    avatar: {
        width: '16vw',
        height: '16vw',
        maxWidth: '16vw',
        maxHeight: '16vw',
        borderRadius: "50%",
        margin: theme.spacing(0)
    },

    avatarDiv: {
       padding: theme.spacing(0),
       margin: theme.spacing(0),
    },


    click: {
        color: '#00DDD8',
        fontSize: '3vw',
        padding: theme.spacing(0),
        margin: theme.spacing(0)
    },
    title: {
        fontSize: '6.5vw',
        color: '#00DDD8'
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        width: '50vw',
    },
    boddeDiv: {
        width: '60vw'
    },
    bodderDiv: {
        width: '30vw',
    },

    quanDiv: {
        marginBottom: '0.5vw',
        padding: 0,
        fontSize: '4.2vw',
    },

    quanbuDiv: {
        width: '25vw',
        marginLeft: '10vw',
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackList));
