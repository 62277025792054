import React from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {getManageNotice, read} from "../../redux/actions/patient_manage_action";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import jiantoux from '../../assets/images/jiantoux.png'


//我的患者-通知主页面
class ManageNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:0,
            viewportWidth: 0,
            viewportHeight: 0,
            direction: '',
            value: 0,
            index: 0
        };
        this.handleRecord = this.handleRecord.bind(this);
    }


    //患者管理 -通知 -查看详情
    handleRecord(item) {
        let noticeType = item.type;
        if (noticeType == 1) {
            const path = `${this.props.match.path}/visitrecord`;
            this.props.history.push(path, {"patientId": item.patientId, "noticeType": noticeType});
        }
        if (noticeType == 2) {
            const path = `${this.props.match.path}/visitrecord`;
            this.props.history.push(path, {"patientId": item.patientId, "noticeType": noticeType});
        }
        if (noticeType == 3) {
            this.props.handleTab(0);//切换到我的患者
           const userId = localStorage.getItem("userId");
           this.props.getManageNotice(userId)
        }
        if (noticeType == 4) {
            const path = `${this.props.match.path}/visitpaper`;
            this.props.history.push(path, {"patientId": item.patientId, "patientType": noticeType});
        }
        if (noticeType == 5) {
            const path = `${this.props.match.path}/visitpaper1`;
            this.props.history.push(path, {"patientId": item.patientId, "noticeType": noticeType});
        }
        let readInfo = {
            id:item.id,
            type:item.type
        }
        this.props.read(readInfo);
    }

    componentDidMount() {
        const userId = localStorage.getItem("userId");
        this.props.getManageNotice(userId)
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event) {
        this.setState({
            introduceReason: event.target.value,
        })
    }

    render() {
        //get react-material css styles
        const {classes, manageNotice} = this.props;
        return (
            <div className={classes.rootDiv}>
                <Typography h5 noWrap className={classes.spacemargin}></Typography>
                {(manageNotice || []).map((item) => {
                    return (
                        <div key={item.patientId}>
                            <Grid container justify="flex-start" wrap="nowrap">
                                <Typography className={classes.ReferralnoticeDiv}>{item.title}</Typography>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap">
                                <Typography className={classes.NoticedateDiv}>{item.time}</Typography>
                            </Grid>
                            <Grid container justify="flex-end" wrap="nowrap">
                                <Link className={classes.linkDiv} component="button" data-days={item.patientId}
                                      variant="body2" onClick={() => { this.handleRecord(item)}}>
                                    <span className={classes.xiangDiv}> 查看详情 </span>
                                    <img className={classes.jiantoux} src={jiantoux}/>
                                    <div className={classes.pointDiv}></div>
                                </Link>
                            </Grid>
                            <hr className={classes.hr}></hr>
                        </div>
                    )
                })
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        patients: state.patientManageReducer.patients,
        manageNotice: state.patientManageReducer.manageNotice,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //患者管理-我的患者
        getManageNotice: (userId) => {
            dispatch(getManageNotice(userId))
        },
        //患者管理-通知已读
        read: (readInfo) => {
            dispatch(read(readInfo))
        }
    };
};

//end of redux regian

//css region
const styles = theme => ({
    rootDiv: {
        width: '100vw',
        paddingTop: theme.spacing(2),
        backgroundColor: '#FFFFFF',
        height: '100vh',
        overflow: 'auto'
    },

    paper: {
        display: 'flex',
        width: '100vw',
        height: '18vw',
        justifyContent: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    NotificationboxDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        borderBottom: '0.1vw solid #e6e6e6',
        height: '20vw'
    },

    ReferralnoticeDiv: {
        marginLeft: '3.5vw',
        fontSize: '4.2vw',
        color: '#333333',
        marginRight: '3.5vw',
    },

    NoticedateDiv: {
        marginLeft: '3.5vw',
        fontSize: '3.7vw',
        color: '#999999',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    linkDiv: {
        marginRight: '3.5vw',
        color: '#00DDD8',
        fontSize: '3.5vw',
        display: 'flex',
        alignItems: 'center',
    },

    jiantoux: {
        height: '1.7vh',
        lineHeight: '1.7vh',
        marginLeft: '1vw',
    },
    xiangDiv: {
        color: '#00DDD8',
        fontSize: '3.5vw',
        padding: '0vw',
    },

    pointDiv: {
        position:'absolute',
        top: 0,
        right: '-5px',
        width: '6px',
        height: '6px',
        background: 'red',
        borderRadius: '50%',
    }


});
//end of css region

//here we inject react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(ManageNotice)));
