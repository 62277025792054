import * as ActionTypes from '../actions/constants'

// The initial application state
let initialState = {
    trackList: [],
    tracknoticeList: [],
    trackAppointment:{},
    appointmentSucceedCode:-1,
    trackFailureCode:-1,
    isRead:-1
}

// Takes care of changing the application state
function trackReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MYTRACKLIST_ARRIVED: //患者追踪-我的追踪 数据获取成功
            return {...state, trackList: action.result}

        case ActionTypes.MYTRACKNOTICELIST_ARRIVED: //拿到我的追踪通知列表
            return {...state, tracknoticeList: action.result}
        case ActionTypes.NOTICE_READED_SUCCEED:
            return {...state,isRead:action.result}

        case ActionTypes.TRACK_APPO_INTMENT_SUCCEED://患者追踪-通知-详情页
            return {...state,trackAppointment:action.result}

        case ActionTypes.TRACK_FAILURE_SUCCEED://患者追踪-详情页-追踪失败
            return {...state,trackFailureCode:action.result}

        case ActionTypes.SUBMITAPPOINTMENTINFO_ARRIVED:
            return {...state,appointmentSucceedCode: action.result}

        default:
            return state

    }
}

export default trackReducer