import React from 'react';
import clsx from "clsx";
import {connect} from 'react-redux';
import {makeStyles, withStyles, withTheme} from '@material-ui/core/styles';
import {queryPatientTakeMedicineRecord, querySputumCheckRecord} from '../../redux/actions/patient_manage_action';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from '@material-ui/core/Grid';
import {IconButton} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import cnLocale from "date-fns/locale/zh-CN"
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import {createMuiTheme} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Resistant from "../../doctor/common/resistant";
import Radio from "@material-ui/core/Radio/Radio";

//我的页面-我的治疗记录页

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightBlue.A200,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiPickersDay: {
            day: {
                color: lightBlue.A700,
            },
            daySelected: {
                backgroundColor: lightBlue["400"],
            },
            dayDisabled: {
                color: lightBlue["100"],
            },
            current: {
                color: lightBlue["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: lightBlue["400"],
            },
        },
    },
});
const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});
const columns = [
    {id: 'actualTime', label: '就诊日期', minWidth: 40, align: 'center'},
    {id: 'orderTime', label: '预约日期', minWidth: 50, align: 'center'},
];

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

//我的页面-我的治疗记录页
class AcographyWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            mededDate: [],
            rows: [],
            patientId:0
        };
        this.renderWrappedMedDay = this.renderWrappedMedDay.bind(this);
        this.isWithinMededDates = this.isWithinMededDates.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);

    }

    handleRowClick() {

    }

    componentDidMount() {
        this.state.patientId = 98;
        this.props.getTakingMedcine(this.state.patientId);
        this.props.getSputumRecord(this.state.patientId);
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }
    handleChange(event, newValue) {
        this.setState({value: newValue});
    };

    handleChangeIndex(index) {
        this.setState({index: index});
    }

    //判断某天是否是服药的天
    isWithinMededDates(date) {
        let dateClone = new Date(date.getTime());
        if (this.props.dateList) {
            this.state.mededDate = this.props.dateList;
            for (let i = 0; i < this.state.mededDate.length; i++) {
                let mededDate = new Date(this.state.mededDate[i]);
                if (isSameDay(dateClone, mededDate)) {
                    return true;
                }
            }
        }
        return false;
    }

    renderWrappedMedDay(date, selectedDate, dayInCurrentMonth) {
        const {classes} = this.props;

        let dateClone = new Date(date.getTime());

        let size = this.state.mededDate.length;
        const start = new Date(this.state.mededDate[0]);
        const end = new Date(this.state.mededDate[size - 1]);

        const dayIsBetween = this.isWithinMededDates(dateClone);
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d", {locale: cnLocale})} </span>
                </IconButton>
            </div>
        );
    }

    render() {
        //get react-material css styles
        const {classes,takeMedicine,sputumRecord} = this.props;
        return (
            <div className={classes.rootDiv}>
                {/* header */}
                <Grid item xs={12}>
                    <div className={classes.headerDiv}>
                        <div className={classes.DoctorPlatformDiv}>
                            <h3>我的治疗记录</h3>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.papers}>
                    <Grid container justify="center" item xs={12}>
                        <h4><span className={classes.spanDiv}>一</span>我的服药记录<span className={classes.spanDiv}>一</span>
                        </h4>
                    </Grid>

                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} theme={materialTheme} locale={cnLocale}>
                            <DatePicker
                                disableToolbar
                                autoOk
                                orientation="landscape"
                                variant="static"
                                openTo="date"
                                value={this.state.currentDate}
                                renderDay={this.renderWrappedMedDay}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid container justify="center" item xs={12}>
                        <h4><span className={classes.spanDiv}>一</span>我的就诊计划<span className={classes.spanDiv}>一</span>
                        </h4>
                    </Grid>
                    <Grid container justify='center' wrap="nowrap" direction="column" spacing={0}>
                        <Grid item container justify='center'>
                            <div className={classes.tableWrapper}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <TableCell
                                                    alignCenter
                                                    key={column.id}
                                                    align={column.align}
                                                    padding={true ? 'none' : 'default'}
                                                    style={{minWidth: column.minWidth}}
                                                >
                                                    <Typography className={classes.titleBold} color="textSecondary">
                                                        {column.label}
                                                    </Typography>


                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(sputumRecord.orderCheckList || []).map(row => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.index} >
                                                    {columns.map(column => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell alignCenter key={column.id}
                                                                       padding={true ? 'none' : 'default'}
                                                                       align={column.align}
                                                                       style={{minWidth: column.minWidth}}>
                                                                <Typography className={classes.titleNormal}
                                                                            color="textSecondary">
                                                                    {value}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" item xs={12}>
                        <h4><span className={classes.spanDiv}>一</span>我的查痰计划<span className={classes.spanDiv}>一</span>
                        </h4>
                    </Grid>
                    <Grid item xs={12} className={classes.informations}>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            耐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;药：<span className={classes.spans}>{Resistant(sputumRecord.type)}</span>
                        </Typography>
                        <hr className={classes.hr}></hr>

                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            下发日期：<span className={classes.spans}>{sputumRecord.createTime}</span>
                        </Typography>

                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            首次查痰：<span className={classes.spans}>{sputumRecord.firstSputumTime}</span>
                        </Typography>

                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            二次查痰：<span className={classes.spans}>{sputumRecord.secondSputumTime}</span>
                        </Typography>

                        <hr className={classes.hr}></hr>
                        <Typography className={classes.titleDiv} color="textSecondary" gutterBottom>
                            三次查痰：<span className={classes.spans}>{sputumRecord.thirdSputumTime}</span>
                        </Typography>

                        <hr className={classes.hr}></hr>
                    </Grid>
                </Grid>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        takingMedicine: state.patientManageReducer.medicationRecord,
        dateList: state.patientManageReducer.takeMedicine.recordList,
        sputumRecord : state.patientManageReducer.sputumRecord
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //服药记录
        getTakingMedcine: (patientId) => {
            dispatch(queryPatientTakeMedicineRecord(patientId))
        },
        //预约查痰记录
        getSputumRecord: (patientId) => {
            dispatch(querySputumCheckRecord(patientId))
        },
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw'
    },
    papers: {
        margin: theme.spacing(2)
    },
    headerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to left,#00c9e4, 50%, #00dfd7)',
        color: '#ffffff',
    },

    reseeDiv: {
        width: '100vw',
        height: '3vh',
        background: '#ffffff',
        color: '#000000',
        marginTop: '1vh',
    },

    dayWrapper: {
        position: "relative",
    },

    spanDiv: {
        color: '#8BEAF0',
        margin: '2vw'
    },
    day: {
        width: 40,
        height: 40,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,

    },
    highlightNonCurrentMonthDay: {
        color: "#676767",

    },
    highlight: {
        backgroundColor: '#00DFD7',
        color: theme.palette.common.white,
        borderRadius: "50%",
    },
    firstHighlight: {
        extend: "highlight",
        borderRadius: "50%"
    },
    endHighlight: {
        extend: "highlight",
        borderRadius: "50%"
    },
    pickerDiv: {
        width: '100vw',
        height: '100vw',
        backgroundColor: 'red'
    },
    tableWrapper: {
        width: '90vw',
        height: '50vw',
    },
    titleBold: {
        backgroundColor: '#00DFD7',
        height: '10vw',
        lineHeight: '10vw',
        color: '#ffffff'
    },
    titleNormal: {
        height: '12vw',
        lineHeight: '12vw',
        color: '#333333'
    },
    spans: {
        color: '#999999'
    },
    titleDiv: {
        marginLeft: '3vw',
        fontSize: '4.2vw',
        lineHeight: '5vh',
        color: '#333333',
        marginTop: '2vw'
    },
    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
        margin: '2vw',
    },
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(AcographyWidget)));