import React from 'react';
import logo from '../assets/images/yidudao.png';
import {connect} from 'react-redux';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {doctorLogin} from '../redux/actions/index';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import {purple} from '@material-ui/core/colors';
import yonghu from '../assets/images/yonghu.png';
import mima from '../assets/images/mima.png';
import Grid from '@material-ui/core/Grid';
import MainView from './MainView';
import {Route, Switch} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Toast from "antd-mobile/lib/toast";
import TencentCaptcha from '@carpenter/react-tencent-captcha';
import md5 from 'md5';
import Checkbox from '@material-ui/core/Checkbox';
import { Helmet } from 'react-helmet';

class LoginView extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loginValue: localStorage.getItem("username")?localStorage.getItem("username"):'', //may means name、phone、emal etc..
         passwd: localStorage.getItem("password")?localStorage.getItem("password"):'',
         userId: '',
         doctorState: '',
         captcha: '',
         agreement: false
      };
      //绑定事件
      this.handleNameChange = this.handleNameChange.bind(this);
      this.handlePasswdChange = this.handlePasswdChange.bind(this);
      this.handleAgreementChange = this.handleAgreementChange.bind(this);

      this.handleLoginClick = this.handleLoginClick.bind(this);
      this.captchaCallback = this.captchaCallback.bind(this);
   }

   onRefChild = ref => {
      this.captcha = ref;
    }
  
    captchaCallback = res => {
      console.log(res);

      if(res.ret !== 0 || res.ticket === undefined ||res.ticket?.length < 8){
         Toast.show("验证失败："+res.ret+","+res.ticket)
         return;
      }
      Toast.show("正在登陆...")
      let ts = new Date().getTime()
      try{
         const loginInfo = {
            loginName: this.state.loginValue,
            password: this.state.passwd,
            captcha: res,
            ts: ts,
            ticket: res.ticket,
            as: md5(this.state.passwd+ts+res.ticket)
         }
         let flag = this.inputCheck();
         if (flag) {this.props.loginDoctorApi(loginInfo);}
      }catch(e){
         Toast.show("错误："+e);
      }
      

      //let userName = localStorage.getItem("username");
      //let flag = this.inputCheck();
     
      //if (flag) {this.props.loginDoctorApi(loginInfo);}
    }
  
    // 销毁
    destroy = () => {
      this.captcha.destroy();
    }

   componentDidMount() {
   }


   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.loginRet != this.props.loginRet) {
         // 登录成功
         if (  nextProps.loginRet.code === 200) {
            localStorage.setItem("password", this.state.passwd);
            //this.props.history.push(`/doctorIndex`);
            console.log(nextProps.loginRet)
            if(nextProps.loginRet.data.url === undefined || nextProps.loginRet.data.url === null || navigator.userAgent.toLowerCase().indexOf("micromessenger") < 0){
               this.props.history.push(`/doctorIndex`);
            }else{
               window.location.href = nextProps.loginRet.data.url;
            }
            
         }else if (nextProps.loginRet.code === 201) {
            Toast.show(nextProps.loginRet.msg)
         }else{
            Toast.show("未知错误")
         }
      }
      /*
      if (nextProps.loginSucceed != this.props.loginSucceed) {
         // 登录成功
         if (  nextProps.loginSucceed === 200) {
            localStorage.setItem("password", this.state.passwd);
            this.props.history.push(`/doctorIndex`);
         }
         // 登录失败
         if (nextProps.loginSucceed === 201) {
            Toast.show("用户名或密码错误")
         }
      }
      */
   }

   handleNameChange(event) {
      this.setState({loginValue: event.target.value})
   }

   handlePasswdChange(event) {
      this.setState({
         passwd: event.target.value,
      })
   }

   handleAgreementChange(event){
      console.log(event)
      this.setState({
         agreement: this.state.agreement?false:true
      })
   }

   //触发登录点击事件
   handleLoginClick(params) {
      const loginInfo = {
         loginName: this.state.loginValue,
         password: this.state.passwd
      }
      let userName = localStorage.getItem("username");
      let flag = this.inputCheck();
     
      if (flag) {this.props.loginDoctorApi(loginInfo);}
      
   }

   //非空检验
   inputCheck() {
      if (this.state.loginValue == '') {Toast.show("用户名不能为空!");return false;}
      if (this.state.passwd == '') {Toast.show("密码不能为空!");return false;}
      if(!this.state.agreement) {Toast.show("请选择同意《隐私政策》!");return false;}
      return true;
   }

   render() {
      const {classes} = this.props;
      return (
         <Switch>
            <Route path={`${this.props.match.path}/Login`} component={MainView}/>
            <Grid className={classes.root}>
               <Helmet>
                  <meta name="referrer" content="never" />
               </Helmet>
               <Grid item justify="center" wrap="nowrap" spacing={3}>
                  <img src={logo} alt="" className={classes.tupian}></img>
               </Grid>

               <Grid>
                  <Typography className={classes.typoText} gutterBottom>
                     贵州医生端
                  </Typography>
               </Grid>

               <Grid item justify="center" wrap="nowrap" spacing={2}>
                  <Grid className={classes.myno}>
                     <img src={yonghu} className={classes.email}></img>
                     <YddInput
                        name="userName"
                        placeholder='请输入用户名'
                        value={this.state.loginValue}
                        onChange={this.handleNameChange}
                     />
                  </Grid>
               </Grid>

               <Grid item justify="center" wrap="nowrap" spacing={2}>
                  <Grid className={classes.myPassword}>
                     <img src={mima} className={classes.passwordpicture}></img>
                     <YddInput name="password" placeholder='请输入密码' type='password' value={this.state.passwd}
                               onChange={this.handlePasswdChange}/>
                  </Grid>
               </Grid>

               <Grid item justify="center" wrap="nowrap" spacing={2}>
                  <Grid className={classes.agreement}>
                        <Checkbox className={classes.agreementBox} checked={this.state.agreement} onChange={this.handleAgreementChange} name="agreement" color="primary" />
                        <Typography className={classes.agreementText}>我已阅读并同意《<a href="http://terms.yidudao.cn/agreement.html">隐私政策</a>》</Typography>
                  </Grid>
               </Grid>

               {/*<div class="g-recaptcha" data-sitekey="6Le14MsZAAAAAMmxUqX7I9W4hYzukiDYvJKjmGT8"></div>
                                                data-sitekey="6Le14MsZAAAAAMmxUqX7I9W4hYzukiDYvJKjmGT8" 
                                 data-callback='onSubmit' 
               */}

               <Grid item justify="center" wrap="nowrap" spacing={2}>
                  <TencentCaptcha
                     appid="2096770183"
                     callback={this.captchaCallback}
                     onRefChild={this.onRefChild}
                     >
                  <ColorButton onClick2={this.handleLoginClick} variant="contained" color="primary" 
                               className={classes.margin}>
                     登 &nbsp;&nbsp;  录
                  </ColorButton>
                  </TencentCaptcha>
               </Grid>

               <div style={{height:"0px"}}></div>
               <Grid item justify="right" wrap="nowrap" spacing={2}>
                  <Grid className={classes.agreement} >
                        <Typography className={classes.agreementText} style={{marginLeft:"auto"}}><a href="https://gz.yidudao.cn/Reg/findPassword">忘记密码？</a></Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Switch>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      loginSucceed: state.loginReducer.loginSucceed,
      loginRet: state.loginReducer.loginRet
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      loginDoctorApi: (userInfo) => {
         dispatch(doctorLogin(userInfo))
      }
   }
}

//css region
const styles = theme => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      color: '#333333',
      backgroundColor: '#FFFFFF',
   },
   paper: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      backgroundColor: '#FFFFFF',
   },

   warning: {
      marginTop: theme.spacing(67)
   },

   message: {
      display: 'flex',
      alignItems: 'center',
   },

   iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
   },
   icon: {
      fontSize: 20,
   },


   tupian: {
      width: '40vw',
      marginBottom: '2vw',
      marginTop: '29vw',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
   },

   typoText: {
      fontSize: '4.5vw',
      marginBottom: '15vw',
      color: '#00d5dd',
      fontWeight: '550',
      letterSpacing: '1.5vw',
   },

   loginWayDiv: {
      display: 'flex',
      justifyContent: 'flex-start',
   },

   forgetPasswdDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
   },

   myno: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.5vw',
      borderRadius: '1vw',
      flexDirection: 'row',
      width: '85vw',
      height: '12vw',
      border: '0.6px solid #666666',
   },

   email: {
      width: '4.5vw',
      paddingLeft: '1vw',
      height: '5vw',
      marginRight: '2vw',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
   },

   userName: {
      width: '80vw',
      fontSize: '4vw',
      paddingLeft: '1vw',
      borderRadius: '0.3vw',
      color: '#666666',
      border: '0',
      outline: 'none',
      height: '11vw',
      backgroundColor: '#FFFFFF',
      lineHeight: '11vw',
   },

   myPassword: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '3vw',
      borderRadius: '1vw',
      flexDirection: 'row',
      width: '85vw',
      height: '12vw',
      border: '0.6px solid #666666',
      marginTop: '2vw'
   },

   passwordpicture: {
      width: '4.5vw',
      paddingLeft: '1vw',
      height: '5vw',
      marginRight: '2vw',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
   },

   passWord: {
      // width: '80vw',
      fontSize: '4vw',
      paddingLeft: '1vw',
      borderRadius: '0.3vw',
      color: '#a8adaa',
      border: '0',
      outline: 'none',
      height: '11vw',
      backgroundColor: 'transparent',
      lineHeight: '11vw',
   },

   agreement: {
      display: 'flex',
      alignItems: 'left',
      borderRadius: '1vw',
      flexDirection: 'row',
      width: '85vw',
   },
   agreementBox: {
      paddingLeft:'0',
   },
   agreementText: {
      marginTop:'2vw',
   },


   margin: {
      border: '0.6px solid #23d76e',
      width: '85vw',
      height: '11vw',
      fontSize: '5vw',
      marginTop: '10vw',
      borderRadius: '1vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      display: 'flex',
      lineHeight: '1vw',
   },

   modalRoot: {
      height: 300,
      flexGrow: 1,
      transform: 'translateZ(0)',
      '@media all and (-ms-high-contrast: none)': {
         display: 'none',
      },
   },

   modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
   },

   modalPaper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   }
});

const ColorButton = withStyles((theme) => ({
   root: {
      width: '40vw',
      height: '8vh',
      fontSize: '3.5vh',
      boxShadow: 'inset 0 0 4px #fff',
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      '&:hover': {},
   },
}))(Button);

const YddInput = withStyles((theme) =>
   createStyles({
      root: {
         'label + &': {
            marginTop: theme.spacing(3),
         },
      },
      input: {
         position: 'relative',
         fontSize: 15,
         width: '66vw',
         height: '8vw',
         borderBottom: '1 solid #666666',
         outline: 'none',
         backgroundColor: 'transparent',
         '&:focus': {
            borderColor: theme.palette.primary.main,
         },
      },
   }),
)(InputBase);
//end css region

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginView));
