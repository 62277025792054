import React from 'react';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {getPatientNewsDetail} from '../../redux/actions/index';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HealthInfo1 from '../../assets/images/healthinfo1.jpg';
import Grid from '@material-ui/core/Grid';
import returnImg from '../../assets/images/returnImg.png'
// pick a date util library

const StyledAvatar = withStyles({
    root: {
        borderRadius: 3,
        border: 0,
        height: 72,
        width: 72,
        marginRight: 3,
    },
})(Avatar);


//健康宣教页面
class NewsDetailForPatient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};


  this.handleNavBack = this.handleNavBack.bind(this);


    }

    componentDidMount() {
        let newsId = this.props.match.params.index;
        this.props.getPatientNewsDetail(newsId)
    }

    componentWillUnmount() {

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChangeIndex(index) {

    }

    handleNavBack() {
        this.props.history.goBack();
    }


    render() {
        //get react-material css styles
        const {classes, newsDetail} = this.props;
        var show= (newsDetail.photo2 === "" || newsDetail.photo2 === null )? "none":"block";
        var style = {
            display:show,
            fontSize: 85
        }
        return (
            <Grid className={classes.rootDiv}>
                <Grid spacing={0}>
                    {/* header */}
                    {/* 标题栏 箭头 通知详情 */}
                    <Grid className={classes.Patient}>
                        <img src={returnImg} className={classes.jiantou} onClick={this.handleNavBack}/>
                        <Grid container justify="center" className={classes.headers}>
                            <h4 className={classes.title}>健康宣教</h4>
                        </Grid>
                    </Grid>

                    <Grid className={classes.subtitle} >
                    <Grid item xs={12} container alignItems="stretch" justify="center">
                        <Typography className={classes.titleDIV} noWrap>{newsDetail.title}</Typography>
                    </Grid>
                    <Grid container justify="center">
                        <Typography className={classes.dateDIV}>{newsDetail.createTime}</Typography>
                        <Typography className={classes.readDIV}>{newsDetail.readcount}阅读</Typography>
                    </Grid>
                    <Grid item justify="center" wrap="nowrap" spacing={3} style={style}>
                        <img src={newsDetail.photo2} alt="" className={classes.tupian}></img>
                    </Grid>    
                    <Grid item xs={12} container alignItems="stretch">
                      <Typography className={classes.subtitleDIV} noWrap>{newsDetail.part1}</Typography>
                    </Grid>
                    <Grid item xs={12} container alignItems="stretch">
                        <Typography className={classes.subtitleDIV} noWrap>{newsDetail.part2}</Typography>
                    </Grid>
                    <Grid item xs={12} container alignItems="stretch">
                        <Typography className={classes.subtitleDIV} noWrap>{newsDetail.part3}</Typography>
                    </Grid>
                    <Grid item xs={12} container alignItems="stretch">
                        <Typography className={classes.subtitleDIV} noWrap>{newsDetail.part4}</Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newsDetail: state.newsReducer.patientNewsDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //请求新闻详情
        getPatientNewsDetail: (newsId) => {
            dispatch(getPatientNewsDetail(newsId))
        },
    }
}


//css region
const styles = theme => ({
    rootDiv: {
        flexGrow: 1,
        overflow: 'auto',
        height: '100vh',
        backgroundColor: '#FFFFFF',
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',
    },

    dateDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw',
        marginRight: '5vw'
    },

    readDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '3.5vw',
        marginTop: '2vw',
        marginLeft: '5vw'
    },

    subtitleDIV: {
        padding: '0',
        color: '#333333',
        fontSize: '4.2vw',
        marginTop: '0vw',
        textIndent: '2em',
    },

    contentDIV: {
        padding: '0',
        color: '#999999',
        fontSize: '4vw',
        marginTop: '0vw'
    },

    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    title: {
        color: '#ffffff',
        fontWeight: '500',
    },

    newsDiv: {
        margin: theme.spacing(2),
    },
    tupian: {
        width: '60vw',
        marginBottom: '2vw',
        marginTop: '30vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: 'red',
        Color: 'red',
    },

    subtitle: {
       margin: theme.spacing(2),
    },

    titleDIV: {
        fontSize: '5vw',
        fontWeight: '700',
    },


});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(NewsDetailForPatient)));