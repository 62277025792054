import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
   followUpHistory,
   followUpVisitPaper,
   queryFollowUpBasicInfo,
   uploadFile
} from '../../redux/actions/patient_manage_action';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import Toast from "antd-mobile/lib/toast";

//弹出框
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import {amber, green} from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
// 引入antd 时间组件
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';
import Moment from "moment";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { Upload, message, Icon } from 'antd';

import SignPadPlus from '../../lib/signPadPlus';

import Api from '../../redux/api/api';

const useStyles1 = makeStyles(theme => ({
   success: {
      backgroundColor: green[600],
   },
   error: {
      backgroundColor: theme.palette.error.dark,
   },
   info: {
      backgroundColor: theme.palette.primary.main,
   },
   warning: {
      backgroundColor: amber[700],
   },
   icon: {
      fontSize: 20,
   },
   iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
   },
   message: {
      display: 'flex',
      alignItems: 'center',
   },
}));

const GreenCheckbox = withStyles({
   root: {
      color: "#00C9E4",
      "&$checked": {
         color: "#00C9E4"
      }
   },
   checked: {}
})(props => <Checkbox color="default" {...props} />);

function MySnackbarContentWrapper(props) {
   const classes = useStyles1();
   const {className, message, variant, ...other} = props;
   const Icon = variantIcon[variant];

   return (
      <SnackbarContent
         className={clsx(classes[variant], className)}
         aria-describedby="client-snackbar"
         message={
            <span id="client-snackbar" className={classes.message}>
                      <Icon className={clsx(classes.icon, classes.iconVariant)}/>
               {message}
                    </span>
         }
         {...other}
      />
   );
}

const variantIcon = {
   success: CheckCircleIcon,
   warning: WarningIcon,
   error: ErrorIcon,
   info: InfoIcon,
};

const useStyles = makeStyles({
   root: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   icon: {
      borderRadius: '50%',
      width: 14,
      height: 14,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
         outline: '2px auto rgba(19,124,189,.6)',
         outlineOffset: 2,
      },
      'input:hover ~ &': {
         backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
         boxShadow: 'none',
         background: 'rgba(206,217,224,.5)',
      },
   },
   checkedIcon: {
      backgroundColor: '#00C9E4',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
         display: 'block',
         width: 14,
         height: 14,
         backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
         content: '""',
      },
      'input:hover ~ &': {
         backgroundColor: '#00C9E4',
      },
   },

});

function StyledRadio(props) {
   const classes = useStyles();

   return (
      <Radio
         className={classes.root}
         disableRipple
         color="default"
         checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
         icon={<span className={classes.icon}/>}
         {...props}
      />
   );
}


//日常随访单
class FollowUpVisitPaper2 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userId: localStorage.getItem("userId"),
         pic: '',
         patientId: 0,
         noticeId: 0,
         followupType: 0, //随访地点
         issue: '',
         useMethod: 0,    //用法用量（1:每日；2:间歇）
         drugType: 0,
         directType: 0,
         missDay: 0,
         wrongReact: 0,
         complication: 0,
         referralDept: '',
         referralReason: '',
         result: '',
         suggestion: '',
         nextTimeStr: '',
         treatMonthAmount: 0,
         smoke: 0,
         drink: 0,
         smokeDay: 0,
         drinkDay: 0,
         nextSmoke: 0,
         nextDrink: 0,

         firstFollowUpTime: Moment(new Date()).format('YYYY年MM月DD日'),
         nextFollowUpTime: '',

         nextTimeYear: " ",
         nextTimeMonth: " ",
         nextTimeDay: " ",

         orderDate: this.props.history.location.state.orderDate,
         actualTime: "",
         actualTimeYear: '',
         actualTimeMonth: '',
         actualTimeDay: '',

         drugTimeYear: '',
         drugTimeMonth: '',
         drugTimeDay: '',

         nextDrugTimeYear: '',
         nextDrugTimeMonth: '',
         nextDrugTimeDay: '',

         doctorId: 0,
         doctorName: '',

         open: false,
         vertical: 'top',
         horizontal: 'center',
         warnMsg: '',
         stopTreat: '',
         wrongReactTextInput: 'none',  // 不良反应说明input显示状态
         wrongReactDesc: "", // 不良反应说明
         complicationText: 'none',  // 判断并发症有时，添加input
         complicationDesc: "",  // 并发症说明
         issueOthers: "",
         checkBoxArray: [],
         drugTypeArrayCheck: [],

         hideOther: 1,
         resistMethod: " ",
         pics: "",
      };

      this.handleNavBack = this.handleNavBack.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.checkBoxhandleInputChange = this.checkBoxhandleInputChange.bind(this);

      this.pickerOnChange = this.pickerOnChange.bind(this);
      this.nextPickerOnChange = this.nextPickerOnChange.bind(this);

      this.handleHistoryFollowUpDetails = this.handleHistoryFollowUpDetails.bind(this);
      this.checkoutDrugType = this.checkoutDrugType.bind(this)
   }

   componentDidMount() {
      this.state.patientName = this.props.location.state.patientName
      this.state.patientId = this.props.location.state.patientId

      this.state.stopTreat = this.props.location.state.stopTreat

      //查询随访患者基本信息
      this.props.queryFollowUpBasicInfo(this.state.patientId)

      //查询随访历史
      //this.props.queryFollowUpHistory(this.state.patientId)

      this.state.resistMethod = " "
   }

   componentWillUnmount(){
      this.state.resistMethod = " "
      console.log("destroy->"+this.state.resistMethod)
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.followUpVisitPaperRet != this.props.followUpVisitPaperRet) {
         if (nextProps.followUpVisitPaperRet?.code == 200) {
            Toast.info('添加日常随访记录成功!');
            if (this.state.stopTreat) {
               const path = `/doctorIndex/manage/stopTreat`;
               this.props.history.push(path, {"patientId": this.state.patientId});
            }else if(nextProps.followUpVisitPaperRet.data?.needAddMedicineScore == 1){
               const path = `/doctorIndex/manage/MedicineScore`;
               this.props.history.push(path, {"patientId": this.state.patientId,"patientName": this.state.patientName});
            } else {
               const path = `/doctorIndex/manage/ServiceAgreement`;
               this.props.history.push(path, {"patientId": this.state.patientId});
            }
         }
         if(nextProps.followUpVisitPaperRet?.code == 201){
            Toast.info(nextProps.followMessage);
         }
      }

      if(nextProps.followUpBasic !== this.props.followUpBasic){
         if(nextProps.followUpBasic.resistMethod !== undefined && nextProps.followUpBasic.resistMethod.length > 1){
            this.setState({
               resistMethod: nextProps.followUpBasic.resistMethod
            })
         }
         this.setState({
            showDirectAI: nextProps.followUpBasic.showDirectAI
         })
      }
   }

   // picker  onchange事件
   pickerOnChange(date, dateString) {
      if(dateString && date !== null){
         this.setState({
            actualTime: dateString,
            actualTimeYear: dateString.split("年")[0],
            actualTimeMonth: dateString.split("年")[1].split("月")[0],
            actualTimeDay: dateString.split("月")[1].split("日")[0],
         })
      }else{
         
      }
   }

   //下次随访时间 picker change event
   nextPickerOnChange(date, dateString) {
      if(dateString && date !== null){
         this.setState({
            nextTimeYear: dateString.split("年")[0],
            nextTimeMonth: dateString.split("年")[1].split("月")[0],
            nextTimeDay: dateString.split("月")[1].split("日")[0],
         })
      }else{
         this.setState({
            nextTimeYear: null,
            nextTimeMonth: null,
            nextTimeDay: null,
         })
      }
   }

   // 复选框选中事件
   checkBoxhandleInputChange(event){
      if(event.target.checked == true){
         this.state.checkBoxArray.push(event.target.value);
      }
      if(event.target.checked == false){
         let numberInit = this.state.checkBoxArray.indexOf(event.target.value);
         this.state.checkBoxArray.splice(numberInit, 1);
      }

      if(this.state.checkBoxArray.indexOf("11") >= 0){
         this.setState({
             hideOther: 0
         })
      }else{
         this.setState({
            hideOther: 1
         })
      }
   }

   //获取表单值
   handleInputChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });
      // 并发症说明
      if (name == 'complication') {
         value == '1' ? this.setState({complicationText: "block"}) : this.setState({complicationText: "none"});
      }
      // 不良反应说明
      if (name == 'wrongReact') {
         value == '1' ? this.setState({wrongReactTextInput: 'block'}) : this.setState({wrongReactTextInput: 'none'});
      }

   }
   checkoutDrugType(event) {
      console.log(event)
      console.log(this.state.drugTypeArrayCheck)
      if (event.target.checked == true) {
          this.state.drugTypeArrayCheck.push(event.target.value);
      }
      if (event.target.checked == false) {
          let numberInit = this.state.drugTypeArrayCheck.indexOf(event.target.value);
          this.state.drugTypeArrayCheck.splice(numberInit, 1);
      }
      this.setState({
          drugTypeStr: this.state.drugTypeArrayCheck.join(",")
      })
  }
   //点击提交按钮
   handleSubmit() {
      var signPad = this.refs.patientSign;
      var signData = signPad.toDataURL();
      
      if (this.state.firstFollowUpTime === "") {
         Toast.show("请选择首次随访日期！");
         return;
      }
      if (this.state.directType === 0) {
         Toast.show("请选择督导人员！");
         return;
      }
      if (this.state.followupType === 0) {
         Toast.show("请选择随访方式！");
         return;
      }
      if (this.state.checkBoxArray.length < 1) {
         Toast.show("请选择症状及体征！");
         return;
      }
      if (this.state.suggestion === "") {
         Toast.show("请输入处理意见！");
         return;
      }

      if (this.state.nextFollowUpTime === "") {
         Toast.show("请选择下次随访日期！");
         return;
      }
      console.log("pic->"+this.state.pic)
      // var signPad=this.refs.patientSign;
      // var signData=signPad.toDataURL();
      //将数据打包成json,然后调用dispatch将数据发送出去
      // sign:signData,
      const visitorInfo = {
         userId: localStorage.getItem("userId"),
         patientId: this.state.patientId,
         noticeId: this.state.noticeId,
         followupType: this.state.followupType, //随访地点
         // issue: this.state.issue, //症状及体征
         issue: this.state.checkBoxArray.join(","), //症状及体征
         issueOthers: this.state.issueOthers,  // 其他描述
         useMethod: this.state.useMethod==0?'':this.state.useMethod, //用法
         //drugType: this.state.drugType, //药品剂型
         drugTypeStr: this.state.drugTypeArrayCheck.join(","),
         directType: this.state.directType,//督导人员
         missDay: this.state.missDay,//漏服天数
         wrongReact: this.state.wrongReact, //不良反应
         complication: this.state.complication, //并发症
         referralDept: this.state.referralDept,//科室
         referralReason: this.state.referralReason,//原因
         result: this.state.result,//两周随访结果
         suggestion: this.state.suggestion,//处理建议
         nextTimeStr: this.state.nextTimeStr,
         treatMonthAmount: this.state.treatMonthAmount,//治疗月序
         smoke: this.state.smoke,// 是否抽烟
         drink: this.state.drink,//饮酒
         smokeDay: this.state.smokeDay,
         drinkDay: this.state.drinkDay,
         nextSmoke: this.state.nextSmoke,//抽烟下次目标
         nextDrink: this.state.nextDrink,//喝酒下次目标
         nextTimeYear: this.state.nextTimeYear,//下次随访年
         nextTimeMonth: this.state.nextTimeMonth,//下次随访月
         nextTimeDay: this.state.nextTimeDay,//下次随访日
         actualTimeYear: this.state.actualTimeYear,//实际随访年
         actualTimeMonth: this.state.actualTimeMonth,//实际随访月
         actualTimeDay: this.state.actualTimeDay,//实际随访日
         drugTimeYear: this.state.drugTimeYear,
         drugTimeMonth: this.state.drugTimeMonth,
         drugTimeDay: this.state.drugTimeDay,
         nextDrugTimeYear: this.state.nextDrugTimeYear,
         nextDrugTimeMonth: this.state.nextDrugTimeMonth,
         nextDrugTimeDay: this.state.nextDrugTimeDay,
         doctorId: this.state.doctorId,
         doctorName: this.state.doctorName,
         complicationDesc: this.state.complicationDesc, // 并发症说明
         wrongReactDesc: this.state.wrongReactDesc,   // 不良反应说明
         resistMethod: this.state.resistMethod,
         facePhoto: this.state.photos?this.state.photos.join(","):'',
         attachment: this.state.pics,
         faceValid: this.state.faceValid,
         sign: signData,
      };
      // 判断是否停止治疗
      // if (this.state.stopTreat) {
      //    const path = `/doctorIndex/manage/stopTreat`;
      //    this.props.history.push(path, {"patientId": this.state.patientId});
      // } else {
      //    this.handleNavBack();
      // }
      this.props.saveVisitInfo(visitorInfo);
   }

   //点击返回
   handleNavBack() {
      window.history.go(-1);
   }

   //随访历史明细页
   handleHistoryFollowUpDetails(item) {
      var params = {
         complicationDesc: this.state.complicationDesc,
         wrongReactDesc: this.state.wrongReactDesc
      }
      if (item.source === 1) {
         const path = `/doctorIndex/manage/FirstFollowUpHistoryDetail`;
         this.props.history.push(path, {"detail": item});
      } else if (item.source === 2) {
         const path = `/doctorIndex/manage/FollowUpHistoryDetail`;
         this.props.history.push(path, {"detail": item});
      }
   }

   render() {
      const {classes, followUpBasic, fileUrl, followUpHistoryList} = this.props;
      //this.state.pic = fileUrl;

      let firstFollowUpTime = Moment(new Date()).format('YYYY年MM月DD日');
      if(this.state.actualTimeYear === ''){
         if(this.state.orderDate && followUpBasic.hideFollowupTime != 1){
            this.state.actualTimeYear = this.state.orderDate.split("-")[0]
            this.state.actualTimeMonth = this.state.orderDate.split("-")[1]
            this.state.actualTimeDay = this.state.orderDate.split("-")[2]
            this.state.firstFollowUpTime = this.state.orderDate
         }else{
            this.state.actualTimeYear = firstFollowUpTime.split("年")[0]
            this.state.actualTimeMonth = firstFollowUpTime.split("年")[1].split("月")[0]
            this.state.actualTimeDay = firstFollowUpTime.split("月")[1].split("日")[0]
         }
      }
      if(this.state.treatMonthAmount === 0){
         console.log(this.props.history.location.state.treatMonth)
         if(this.props.history.location.state.treatMonth && this.props.history.location.state.treatMonth > 0){
            this.setState({
               treatMonthAmount: this.props.history.location.state.treatMonth
            })
         }else{
            this.setState({
               treatMonthAmount: 1
            })
         }
      }


      const nextFollowTime = Moment(followUpBasic.nextFollowTime).format("YYYY年MM月DD日");

      // this.state.nextTimeYear = nextFollowTime.split("年")[0]
      // this.state.nextTimeMonth = nextFollowTime.split("年")[1].split("月")[0]
      // this.state.nextTimeDay = nextFollowTime.split("月")[1].split("日")[0]

      this.state.nextFollowUpTime = nextFollowTime

      console.log(followUpBasic.nextFollowTime)
      /*
      if(this.state.resistMethod === " " && followUpBasic.resistMethod !== undefined && followUpBasic.resistMethod.length > 1){
         this.state.resistMethod = followUpBasic.resistMethod
      }
      */

      if(this.state.nextTimeYear === " " && followUpBasic.nextFollowTime !== undefined){
         this.state.nextTimeYear = followUpBasic.nextFollowTime.split("-")[0];
         this.state.nextTimeMonth = followUpBasic.nextFollowTime.split("-")[1];
         this.state.nextTimeDay = followUpBasic.nextFollowTime.split("-")[2].split(" ")[0];
      }


      var that = this;
      //let pics = '';
      //上传
      const uploadFile = {
         name: 'file',
         action: Api.getRootUrl()+'/api/uploadFile',
         multiple: true,
         beforeUpload: (file) => {
               const isPNG = file.type === 'image/png';
               const isJPG = file.type === 'image/jpeg';
               if (!isPNG && !isJPG) {
               message.error(`只支持PNG和JPG格式！`);
               return false;
               }
               return isPNG || isJPG || Upload.LIST_IGNORE;
         },
         onChange(info) {
               if (info.file.status !== 'uploading') {
               }
               if (info.file.status === 'done') {
                  if(info.fileList != null && info.fileList.length > 0){
                     if(info.fileList[0].response.code == 200){
                        message.success(`${info.file.name} 上传成功`);
                        if (info.fileList.length === 1) {
                           info.fileList.map((item, index) => {
                              that.state.pics += item.response.data
                           })
                        } else {
                           info.fileList.map((item, index) => {
                              that.state.pics += item.response.data + ","
                           })
                        }
                        //that.setState({
                        //   pic: pics
                        //})
                     }else{
                        message.error(`${info.file.name} 上传失败!`);
                     }
                  }
                  

               } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} 上传失败.`);
               }
               return that.state.pics;
         }
      };



      //人脸识别
      //let that = this
      let photos = '';
      let photoList = new Array();
      //上传
      const validPhoto = {
         name: 'file',
         action: Api.getRootUrl()+'/api/validPhoto',
         multiple: true,
         data: {"patientId":this.state.patientId},
         onChange(info) {
               if (info.file.status !== 'uploading') {
               }
               if (info.file.status === 'done') {
                  if(info.file.response.code == 200){
                     message.success("认证成功！")
                     this.state.faceValid = 1
                     (that.state.photos || []).map((item, index) => {
                        photoList.push(item)
                     })
                     that.setState({
                        photos: photoList
                     })
                  }else{
                     message.error("认证失败！"+info.file.response.msg);
                  }
                  
                  
               } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} 上传失败.`);
               }
               return photos;
         }
      };

      return (
         <div className={classes.root}>
            <Grid item xs={12} className={classes.Patient}>
               <img src={axxxx} className={classes.jiantou} onClick={this.handleNavBack}></img>
               <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者随访服务记录表</h5></Grid>
            </Grid>
            <Typography h5 noWrap className={classes.spacemargin}></Typography>
            <Grid container justify="center" wrap="nowrap" spacing={2}>
               <Typography className={classes.titleMain} h5 color="textSecondary" gutterBottom>肺结核患者随访服务记录表</Typography>
            </Grid>
            <Grid className={classes.tableDIV}>
               <Typography h5 noWrap className={classes.spacemargin}></Typography>
               <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        姓名：<span className={classes.title}>{this.state.patientName}</span>
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography className={classes.titleBold} gutterBottom>
                        登记号：<span className={classes.title}>{followUpBasic.patientNumber}</span>
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={followUpBasic.hideFollowupTime === 1?classes.hideMe:classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2} className={followUpBasic.hideFollowupTime === 1?classes.hideMe:""}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        随访时间：<span className={classes.title}>
                                                <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" "
                                                            allowClear={false}
                                                            placeholder="请选择日期" className="date-picker-border"
                                                            defaultValue={Moment(this.state.firstFollowUpTime, 'YYYY年MM月DD日')}
                                                            onChange={this.pickerOnChange}/>
                                            </span>
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        治疗月序：<span className={classes.title}>
                                            第<Input name="treatMonthAmount" className={classes.input}
                                                    value={this.state.treatMonthAmount}
                                                    onChange={this.handleInputChange}
                                                    inputProps={{'aria-label': 'description'}}/>月
                                        </span>
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>督导人员：</Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row name="directType" aria-label="directType" value={this.state.directType}
                                 onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="医生"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="家属"/>
                        <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="自服药"/>
                        <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="志愿者"/>
                        <FormControlLabel className={this.state.showDirectAI == 1?classes.OptionDiv:classes.hideMe} value="5" control={<StyledRadio />} label="智能工具" />
                     </RadioGroup>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>随访方式：</Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row name="followupType" value={this.state.followupType}
                                 onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="门诊"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="家庭"/>
                        <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="电话"/>
                     </RadioGroup>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        症状及体征：
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        <RadioGroup row name="issue" value={this.state.issue} onChange={this.handleInputChange}>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="没有症状"
                                             control={<GreenCheckbox  value="0" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="咳嗽咳痰"
                                             control={<GreenCheckbox  value="1" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="低热盗汗"
                                             control={<GreenCheckbox  value="2" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="咳血或血痰"
                                             control={<GreenCheckbox  value="3" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="胸痛消瘦"
                                             control={<GreenCheckbox  value="4" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="恶心纳差"
                                             control={<GreenCheckbox  value="5" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="关节疼痛"
                                                   control={<GreenCheckbox  value="6" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="头痛失眠"
                                             control={<GreenCheckbox  value="7" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="视物模糊"
                                             control={<GreenCheckbox  value="8" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="皮肤瘙痒、皮疹"
                                             control={<GreenCheckbox  value="9" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="耳鸣听力下降"
                                             control={<GreenCheckbox  value="10" color="primary"/>}/>

                           <FormControlLabel onChange={this.checkBoxhandleInputChange} label="其他"
                                             control={<GreenCheckbox  value="11" color="primary"/>}/>
                           {/*
                           <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>}
                                             label="没有症状"/>
                           <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>}
                                             label="咳嗽咳痰"/>
                           <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>}
                                             label="低热盗汗"/>
                           <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>}
                                             label="咳血或血痰"/>
                           <FormControlLabel className={classes.OptionDiv} value="5" control={<StyledRadio/>}
                                             label="胸痛消瘦"/>
                           <FormControlLabel className={classes.OptionDiv} value="6" control={<StyledRadio/>}
                                             label="恶心纳差"/>
                           <FormControlLabel className={classes.OptionDiv} value="7" control={<StyledRadio/>}
                                             label="头痛失眠"/>
                           <FormControlLabel className={classes.OptionDiv} value="8" control={<StyledRadio/>}
                                             label="视物模糊"/>
                           <FormControlLabel className={classes.OptionDiv} value="9" control={<StyledRadio/>}
                                             label="皮肤瘙痒、皮疹"/>
                           <FormControlLabel className={classes.OptionDiv} value="10" control={<StyledRadio/>}
                                             label="耳鸣听力下降"/>
                           <FormControlLabel className={classes.OptionDiv} value="11" control={<StyledRadio/>}
                                             label="其他"/>
                           */}
                        </RadioGroup>
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2} className={this.state.hideOther !== 1?"":classes.hideMe}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        其他：<br/>
                        <Input id="standard-multiline-static"
                               name="issueOthers"
                               value={this.state.issueOthers}
                               onChange={this.handleInputChange}
                               className={classes.textField}
                               inputProps={{'aria-label': 'description'}}
                        />
                        {/*<TextField id="standard-multiline-static" rows="0" placeholder="请填写症状体征"*/}
                        {/*value={this.state.issueOthers} onChange={this.handleInputChange}*/}
                        {/*className={classes.textField} margin="normal"/>*/}
                     </Typography>
                  </Grid>
               </Grid>

               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        生活方式评估：
                     </Typography>
                  </Grid> </Grid>
               <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        吸烟：目前: <Input name="smoke" value={this.state.smoke} onChange={this.handleInputChange}
                                      className={classes.inputShort} inputProps={{'aria-label': 'description'}}
                     /><span className={followUpBasic.showFollowupDay === 1?classes.inputShort:classes.hideMe}>/<Input name="smokeDay" value={this.state.smokeDay} onChange={this.handleInputChange}
                                       className={classes.inputShort} inputProps={{'aria-label': 'description'}}
                     /></span>支/天
                     <span className={followUpBasic.hideFollowupTime === 1?classes.hideMe:""}>，下次目标:<Input name="nextSmoke" value={this.state.nextSmoke} onChange={this.handleInputChange}
                                       className={classes.inputShort} inputProps={{'aria-label': 'description'}}/>支/天</span>
                     </Typography>
                  </Grid></Grid>
               <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        饮酒：目前:<Input
                        name="drink" className={classes.inputShort} value={this.state.drink}
                        onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}
                     /><span className={followUpBasic.showFollowupDay === 1?classes.inputShort:classes.hideMe}>/<Input name="drinkDay" value={this.state.drinkDay} onChange={this.handleInputChange}
                        className={classes.inputShort} inputProps={{'aria-label': 'description'}}
                        /></span>两/天
                     <span className={followUpBasic.hideFollowupTime === 1?classes.hideMe:""}>，下次目标:<Input
                        name="nextDrink" value={this.state.nextDrink} className={classes.inputShort}
                        onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}
                     />两/天</span>
                     </Typography>
                  </Grid></Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-star" direction="column" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>用药情况：</Typography>
                  </Grid>
               </Grid>
               <Grid container justify="flex-star" direction="column" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        化疗方案： <Input name="resistMethod" value={this.state.resistMethod} onChange={this.handleInputChange}
                                 className={classes.inputName} inputProps={{'aria-label': 'description'}}/>
                                 
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>用法：</Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row defaultValue="1" name="useMethod" value={this.state.useMethod}
                                 onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="每日"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="间歇"/>
                     </RadioGroup>
                  </Grid>
               </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>药品剂型：</Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row defaultValue="1" name="drugTypeStr" value={this.state.drugTypeStr} onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} label="固定剂量复合制剂" checked={this.state.drugTypeArrayCheck?.indexOf("1") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="1" color="primary" />} />
                        <FormControlLabel className={classes.OptionDiv} label="散装药" checked={this.state.drugTypeArrayCheck?.indexOf("2") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="2" color="primary" />} />
                        <FormControlLabel className={classes.OptionDiv} label="板式组合药" checked={this.state.drugTypeArrayCheck?.indexOf("3") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="3" color="primary" />} />
                        <FormControlLabel className={classes.OptionDiv} label="注射剂" checked={this.state.drugTypeArrayCheck?.indexOf("4") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="4" color="primary" />} />
                        {/* 
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="固定剂量复合制剂"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="散装药"/>
                        <FormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio/>} label="板式组合药"/>
                        <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio/>} label="注射剂"/>
                        <FormControlLabel className={classes.OptionDiv} value="24" control={<StyledRadio/>} label="散装药+注射剂"/>*/}
                     </RadioGroup>
                  </Grid>
               </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        漏服药次数：<Input name="missDay" className={classes.input} value={this.state.missDay}
                                     onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>天
                     </Typography>
                  </Grid>
               </Grid>
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        服药不良反应：
                     </Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row defaultValue="1" name="wrongReact" value={this.state.wrongReact}
                                 onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="有"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="无"/>
                     </RadioGroup>
                  </Grid>
               </Grid>
               <Input name="wrongReactDesc" value={this.state.wrongReactDesc}
                      onChange={this.handleInputChange}
                      className={classes.inputTexts}
                      style={{display: this.state.wrongReactTextInput}}
                      inputProps={{'aria-label': 'description'}}
               />
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        并发症或合并症：
                     </Typography>
                  </Grid>
                  <Grid item>
                     <RadioGroup row defaultValue="1" name="complication" value={this.state.complication}
                                 onChange={this.handleInputChange}>
                        <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio/>} label="有"/>
                        <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio/>} label="无"/>
                     </RadioGroup>
                  </Grid>
               </Grid>
               <Input name="complicationDesc" value={this.state.complicationDesc}
                      onChange={this.handleInputChange}
                      className={classes.inputTexts}
                      style={{display: this.state.complicationText}}
                      inputProps={{'aria-label': 'description'}}
               />
               <hr className={classes.hr}></hr>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        患者不良反应就诊：
                     </Typography>
                  </Grid> </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        就诊机构及科室：<Input name="referralDept" value={this.state.referralDept}
                                       onChange={this.handleInputChange} className={classes.inputs}
                                       inputProps={{'aria-label': 'description'}}/>
                     </Typography>
                  </Grid></Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        原因：<Input name="referralReason" value={this.state.referralReason}
                                  onChange={this.handleInputChange} className={classes.inputs}
                                  inputProps={{'aria-label': 'description'}}/>
                     </Typography>
                  </Grid> </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        2周内随访结果：<Input name="result" value={this.state.result} className={classes.inputs}
                                       onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                     </Typography>
                  </Grid></Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>
                        处理意见：<Input name="suggestion" value={this.state.suggestion} className={classes.inputs}
                                    onChange={this.handleInputChange} inputProps={{'aria-label': 'description'}}/>
                     </Typography>
                  </Grid> </Grid>
               <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                        下次随访时间：<DatePicker locale={locale} format="YYYY年MM月DD日"
                                           key={followUpBasic.nextFollowTime}
                                           className="date-picker-border"
                                           defaultValue={followUpBasic.nextFollowTime == null?undefined:Moment(followUpBasic.nextFollowTime, 'YYYY年MM月DD日')}
                                           placeholder="请选择日期"
                                           onChange={this.nextPickerOnChange} />
                        {/*<DatePicker locale={locale} format="YYYY年MM月DD日"*/}
                                           {/*suffixIcon=" " allowClear={true}*/}
                                           {/**/}
                                           {/*defaultValue={followUpBasic.nextFollowTime == null?undefined:Moment(followUpBasic.nextFollowTime, 'YYYY年MM月DD日')}*/}
                                           {/*placeholder="请选择日期" onChange={this.nextPickerOnChange}/>*/}
                     </Typography>
                  </Grid>
               </Grid>

               <Grid item>
                  <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                     管理医生：<Input name="doctorName" value={this.state.doctorName} onChange={this.handleInputChange}
                                 className={classes.inputName} inputProps={{'aria-label': 'description'}}/>
                  </Typography>
               </Grid>
               <Grid item>
                     <Typography className={classes.photosDIV} color="textSecondary" gutterBottom>
                        上传照片：<Upload {...uploadFile} showUploadList={{ "showDownloadIcon": false }} name="file">
                           <Button className={classes.buttonsTwo}>
                                 <Icon type="upload" /> 点击上传
                           </Button>
                        </Upload>
                     </Typography>
               </Grid>

               <Grid container justify="flex-start" wrap="nowrap" spacing={2} className={(followUpBasic.showFollowupDay !== 1 && followUpBasic.hideFollowupTime === 1) ? classes.hideMe : ""}>
                            <Grid item>
                                <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                    签名：
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={(followUpBasic.showFollowupDay !== 1 && followUpBasic.hideFollowupTime === 1) ? classes.hideMe : ""}>
                            <SignPadPlus ref='patientSign' name="sign" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                        </Grid>
               

               <Grid item>
                  <Typography className={this.state.showFace==1?classes.photosDIV:classes.hideMe} color="textSecondary" gutterBottom>
                        人像验证：<Upload {...validPhoto} showUploadList={{ "showDownloadIcon": false }} name="file">
                           <Button style={{ display: "" }} className={classes.buttonsTwo}>
                              <Icon type="upload" /> 点击拍照
                           </Button>
                        </Upload>
                        {/*(this.state.pic || []).map((item,index) => {
                        return (
                                    <img style={{display:""}} key={index} justify="flex-start" src={item} onClick={()=>this.clickeImg(item)} className={classes.picSize}></img>
                              )
                        })
                  */}

                        <ImageList rowHeight={180} className={classes.photoList}>
                           {(this.state.photoList || []).map((item) => (
                              <ImageListItem key={item}>
                                    <img src={item} alt={item.title} />
                                   
                              </ImageListItem>
                           ))}
                        </ImageList>

                  </Typography>
               </Grid>

               {/*<Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                            患者（家属）签字：<SignaturePad ref='patientSign' name="sign" value={this.state.pic} className={classes.SignaturePadDiv}
                                        onChange={this.handleInputChange} clearButton="true" height={300} />
                            </Typography>
                        </Grid>
                    </Grid>*/}
               <hr className={classes.hr}></hr>
               <Grid container justify="center" wrap="nowrap" spacing={2}>
                  <Grid item>
                     <Button variant="contained" color="primary" className={classes.buttons}
                             onClick={this.handleSubmit}>
                        提交
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Snackbar anchorOrigin={{vertical: this.state.vertical, horizontal: this.state.horizontal}}
                      open={this.state.open} autoHideDuration={1500}>
               <MySnackbarContentWrapper variant="success" className={classes.success} message={this.state.warnMsg}/>
            </Snackbar>
            
            {/*
            <Grid container justify="center" wrap="nowrap" spacing={2}>
               <h4 className={classes.title1}><strong>- 历史随访记录 -</strong></h4>
            </Grid>
            <hr className={classes.hr}></hr>

            <Grid className={classes.clickDiv}>
               {(followUpHistoryList || []).map((item) => {
                  return (
                     <Grid container key={item.id} justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                           <Link className={classes.click} onClick={() => {
                              this.handleHistoryFollowUpDetails(item)
                           }} component="button" variant="body2"> <span>{item.followUpTime}</span>随访记录</Link>
                        </Grid>
                     </Grid>
                  )
               })
               }
            </Grid>
             */}
         </div>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      serverInfo: state.serverInfo,
      followUpBasic: state.patientManageReducer.followUpBasicInfo,
      followUpVisitPaperResult: state.patientManageReducer.followUpVisitPaperResult,
      followUpVisitPaperRet: state.patientManageReducer.followUpVisitPaperRet,
      followMessage: state.patientManageReducer.followMessage,
      fileUrl: state.patientManageReducer.fileUrl,
      followUpHistoryList: state.patientManageReducer.followUpHistoryList
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      //查询随访基本信息
      queryFollowUpBasicInfo: (patientId) => {
         dispatch(queryFollowUpBasicInfo(patientId))
      },
      //提交日常随访信息
      saveVisitInfo: visitorInfo => {
         dispatch(followUpVisitPaper(visitorInfo));
      },
      uploadFile: file => {
         dispatch(uploadFile(file))
      },
      queryFollowUpHistory: (patientId) => { //查询随访历史
         dispatch(followUpHistory(patientId))
      }
   }
}

//css region
const styles = theme => ({
   root: {
      flexGrow: 1,
      overflow: 'hidden',
      background: '#ffffff',
   },
   Patient: {
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '8vh',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   titleMain: {
      marginLeft: '3vw',
      fontSize: '5.5vw',
      lineHeight: '8vw',
      color: '#333333',
      fontWeight: '700',
   },
   jiantou: {
      height: '4vw',
      float: 'left',
      marginLeft: '4vw'
   },
   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '77vw',
      height: '8vh',
   },
   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
   },
   paper: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
   },
   file: {
      display: 'none',
      visibility: 'hidden',
   },
   buttonsTwo: {
      width: '25vw',
      height: '8vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
      fontSize: '3vw',
      marginBottom: 'theme.spacing(2)',

      margin: theme.spacing(1),
      position: 'relative',
      border: '1px solid #00C9E4',
      borderRadius: '5px',
      padding: '4px 12px',
      overflow: 'hidden',
      color: '#fff',
      textDecoration: 'none',
      lineHeight: '8vw'
  },

   buttons: {
      margin: theme.spacing(2),
      fontSize: '4.8vW',
      width: '91vw',
      height: '12vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   hr: {
      height: '0.3vw',
      border: '1.3vw',
      borderBottom: '0.1vw solid #e6e6e6',

   },
   textField: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      fontWeight: '300',
      width: '91vw',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
   },
   input: {
      width: '13vw'
   },
   inputs: {
      marginBottom: theme.spacing(1),
      width: '88vw'
   },
   inputTexts: {
      // width: '28vw'
   },

   inputName: {
      marginBottom: theme.spacing(1),
      fontWeight: '500',
      width: '67vw'
   },

   inputDiv: {
      width: '12vw',
      textAlign: 'center',
   },
   rootDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
   },
   parge: {
      width: '100vw',
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '30vh'
   },
   title1: {
      color: '#000000',
      fontSize: '4vw',
      fontWeight: '500'
   },

   titleBold: {
      margin: theme.spacing(0),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   doctor: {
      marginTop: theme.spacing(1),
      fontSize: '4.2vw',
      lineHeight: '5vh',
      fontWeight: '700',
      color: '#333333'
   },

   title: {
      marginBottom: 0,
      fontSize: '4.2vw',
      lineHeight: '8vw',
      fontWeight: '500',
      color: '#333333'
   },
   margin: {
      margin: theme.spacing(2),
   },

   spacemargin: {
      margin: theme.spacing(2),

   },
   click: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: '#00C9E4',
      textDecoration: 'none',
      underline: 'none'
   },

   clickDiv: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
   },

   tableDIV: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },

   inputShort: {
      width: '10vw',
   },

   hideMe: {
      display:'none'
   },
   photosDIV: {
      marginBottom: theme.spacing(2),
      fontSize: '4.2vw',
      lineHeight: '8vw',
      fontWeight: '700',
      color: '#333333'
  },

  picSize: {
      float: 'left',
      width: '25%',
      boxSizing: 'border-box',
      padding: '2vw',
      minWidth: '8vw'
  },

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FollowUpVisitPaper2));
