// 密接单页面
import React from "react";
import {createStyles, makeStyles, withStyles, withTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {createCloseScreeningList} from "../../redux/actions/patient_manage_action";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import "antd-mobile/lib/picker/style/css";
//弹出框
import clsx from 'clsx';
import axxxx from "../../assets/images/axxxx.png";
import Typography from "@material-ui/core/Typography/Typography";
import {DatePicker, Select} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import Toast from "antd-mobile/lib/toast";

const { Option } = Select;


const BeautyFormControlLabel = withStyles({
   root: {
      margin: 0
   },
   label: {
      fontSize: "1rem",
      color: "black",
      fontWeight: 395
   }
})(FormControlLabel);
const GreenCheckbox = withStyles({
   root: {
      color: "#00C9E4",
      "&$checked": {
         color: "#00C9E4"
      }
   },
   checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
   root: {
      "&:hover": {
         backgroundColor: "transparent"
      }
   },
   icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow:
         "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
         "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
         outline: "2px auto rgba(19,124,189,.6)",
         outlineOffset: 2
      },
      "input:hover ~ &": {
         backgroundColor: "#ebf1f5"
      },
      "input:disabled ~ &": {
         boxShadow: "none",
         background: "rgba(206,217,224,.5)"
      }
   },
   checkedIcon: {
      backgroundColor: "#00C9E4",
      backgroundImage:
         "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
         display: "block",
         width: 16,
         height: 16,
         backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
         content: '""'
      },
      "input:hover ~ &": {
         backgroundColor: "#00C9E4"
      }
   }
});

function StyledRadio(props) {
   const classes = useStyles();

   return (
      <Radio
         className={classes.root}
         disableRipple
         color="default"
         checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
         icon={<span className={classes.icon}/>}
         {...props}
      />
   );
}

//密接接触单
class CloseScreeningList extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         name: "", //患者姓名
         relation: "", // 患者关系
         age: "", //年龄
         phoneNum: "", //电话号码
         closeTimeString: '',
         closeTimeStr: '',
         closeTimeYear: '',//密接时间年
         closeTimeMonth: '',//密接时间月
         closeTimeDay: '',//密接时间日
         gender: "", //性别
         isCoughSputum: '', // 是否咳嗽咳痰
         isHemoptysis: '', // 是否咯血
         isFever: '', // 是否发热
         isChestPain: '', // 是否胸痛
         isWeaknessNightSweats: '', // 是否乏力盗汗
         isOther: '', // 其他
         otherDesc: '', // 其他描述
         ppdTestResult: '', // PPD试验结果
         xrayHospital: '', // X线胸片检查医院
         xrayResult: '', // X线胸片结果
         sputumTbResult: '', // 痰结核菌检查结果
         conclusion: '', // 筛查结论
         highRegionIds: [],
         lowRegionIds: [],
         villiageData: [],
         doctorId:'',
         isSend:'',
         ppdHardenSize: '',
         ppdHardenDiameter: '',
         ppdBlister: '',
         ppdDoubleLoop: '',
         ppdNecrosis: '',
         ppdLymphangitis: '',
         sputumSmear: '',
         sputumCulture: '',
         sputumMolecularBiology: '',
         visitRecord: '',
         conclusionOther: '',
      };
      this.handleNavBack = this.handleNavBack.bind(this); // 返回按钮
      this.handleClickNext = this.handleClickNext.bind(this); // 跳过按钮
      this.handleSubmit = this.handleSubmit.bind(this);   // 提交按钮
      this.handleInputChange = this.handleInputChange.bind(this); // 姓名
      this.handleChange = this.handleChange.bind(this); // 选择结果下拉框选中事件
      this.closePickerOnChange = this.closePickerOnChange.bind(this); // 时间选择器
      this.handleSKip = this.handleSKip.bind(this); // 跳过
      this.selectOnEventDoctor = this.selectOnEventDoctor.bind(this);   // 症状选择事件
      this.handleChangeConclusion = this.handleChangeConclusion.bind(this);
      this.handleChangeSputumMolecularBiology = this.handleChangeSputumMolecularBiology.bind(this);
   }

   //初始化加载
   componentDidMount() {
      this.state.patientId = this.props.location.state.patientId;
      this.state.followupId = this.props.location.state.followUpId;

   }

   componentWillUnmount() {}

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if(nextProps.closeScreeningCode!==this.props.closeScreeningCode){
         if(nextProps.closeScreeningCode?.code === 200){
             Toast.show("提交成功，您可以继续添加！")
             this.setState({
               name: "", //患者姓名
               relation: "", // 患者关系
               age: "", //年龄
               phoneNum: "", //电话号码
               closeTimeString: '',
               closeTimeStr: '',
               closeTimeYear: '',//密接时间年
               closeTimeMonth: '',//密接时间月
               closeTimeDay: '',//密接时间日
               gender: "", //性别
               isCoughSputum: '', // 是否咳嗽咳痰
               isHemoptysis: '', // 是否咯血
               isFever: '', // 是否发热
               isChestPain: '', // 是否胸痛
               isWeaknessNightSweats: '', // 是否乏力盗汗
               isOther: '', // 其他
               otherDesc: '', // 其他描述
               ppdTestResult: '', // PPD试验结果
               xrayHospital: '', // X线胸片检查医院
               xrayResult: '', // X线胸片结果
               sputumTbResult: '', // 痰结核菌检查结果
               conclusion: '', // 筛查结论
               highRegionIds: [],
               lowRegionIds: [],
               villiageData: [],
               doctorId:'',
               isSend:'',
               ppdHardenSize: '',
               ppdHardenDiameter: '',
               ppdBlister: '',
               ppdDoubleLoop: '',
               ppdNecrosis: '',
               ppdLymphangitis: '',
               sputumSmear: '',
               sputumCulture: '',
               sputumMolecularBiology: '',
               visitRecord: '',
               conclusionOther: '',
             });
             window.scrollTo(0,0);
             setTimeout(()=>{
               window.location.reload();
             },3000)
            
         }

      }
   }

   handleInputChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });
   }

   // 症状类型选择事件
   selectOnEventDoctor(event){
      const name = event.target.name;
      const value = event.target.value;
      this.setState({
         [name]: value
      });
   }

   handleChangeSputumMolecularBiology(value){
      this.setState({
         'sputumMolecularBiology': value
      });
   }
   handleChangeConclusion(value){
      this.setState({
         'conclusion': value
      });
   }



   //点击提交按钮
   handleSubmit(event) {
      let screeningTime = null;
      if(this.state.closeTimeYear != undefined && this.state.closeTimeYear.length > 2){
         screeningTime = this.state.closeTimeYear+"-"+this.state.closeTimeMonth+"-"+this.state.closeTimeDay
      }
      const closeContact = {
         followupId:this.props.location.state.followUpId,
         patientId: this.props.location.state.patientId,
         name:this.state.name, //姓名
         relation: this.state.relation, //与患者关系
         gender:this.state.gender,//性别
         age:this.state.age,//年龄
         phoneNum:this.state.phoneNum,//联系电话
         screeningTime: screeningTime,  // 症状筛查时间
         isCoughSputum: this.state.isCoughSputum*1, // 是否咳嗽咳痰
         isHemoptysis: this.state.isHemoptysis*1, // 是否咯血
         isFever: this.state.isFever*1, // 是否发热
         isChestPain: this.state.isChestPain*1, // 是否胸痛
         isWeaknessNightSweats: this.state.isWeaknessNightSweats*1, // 是否乏力盗汗
         isOther: this.state.isOther, // 其他
         otherDesc: this.state.otherDesc, // 其他描述
         ppdTestResult: this.state.ppdTestResult, // PPD试验结果
         xrayHospital: this.state.xrayHospital, // X线胸片检查医院
         xrayResult: this.state.xrayResult, // X线胸片结果
         sputumTbResult: this.state.sputumTbResult, // 痰结核菌检查结果
         conclusion: this.state.conclusion, // 筛查结论

         ppdHardenSize: this.state.ppdHardenSize,
         ppdHardenDiameter: this.state.ppdHardenDiameter,
         ppdBlister: this.state.ppdBlister,
         ppdDoubleLoop: this.state.ppdDoubleLoop,
         ppdNecrosis: this.state.ppdNecrosis,
         ppdLymphangitis: this.state.ppdLymphangitis,
         sputumSmear: this.state.sputumSmear,
         sputumCulture: this.state.sputumCulture,
         sputumMolecularBiology: this.state.sputumMolecularBiology,
         visitRecord: this.state.visitRecord,
         conclusionOther: this.state.conclusionOther,
         isFamily: this.state.isFamily,
      };

      //if(closeContact.screeningTime.length < 7){
         //let date = new Date().getDate();
         //let month = new Date().getMonth() + 1;
         //let year = new Date().getFullYear();
         //closeContact.screeningTime = null;//date+"-"+month+"-"+year;
      //}

      // console.log("params===" + JSON.stringify(closeContact))
       this.props.createCloseScreeningList(closeContact);  // 创建密接单

       const path = '/doctorIndex/manage/FamilyAgreement';
       //let paramsList = this.props.location.state.parperData;

       var params = {
          patientId: this.props.location.state.patientId,
          followUpId:this.props.location.state.followUpId,
          //closeTime: paramsList.createYear+"-"+paramsList.createMonth+"-"+paramsList.createDay
       }
      //this.props.history.push(path,params);  // 跳转到家庭签约协议

   }

   // 点击左上角返回
   handleNavBack() { this.props.history.goBack(); }

   // 点击跳过
   handleClickNext(event){
      const path = '/doctorIndex/manage/FamilyAgreement';
      //let paramsList = this.props.location.state.parperData;
      //console.log(paramsList);
      var params = {
         patientId: this.props.location.state.patientId,
         followUpId:this.props.location.state.followUpId,
         //closeTime: paramsList.createYear+"-"+paramsList.createMonth+"-"+paramsList.createDay
      }
      this.props.history.push(path, params);  // 跳转到家庭协议
   }

   // 时间选择器选择事件
   closePickerOnChange(date, dateString){
      this.setState({
         closeTimeStr:dateString,
         closeTimeYear: dateString.split("年")[0],
         closeTimeMonth: dateString.split("年")[1].split("月")[0],
         closeTimeDay: dateString.split("月")[1].split("日")[0],
      })
   }
   // 选择结果事件
   handleChange(event){
       this.setState({ conclusion:event })
   }

   // 跳过
   handleSKip(){
      const path = '/doctorIndex/manage/FamilyAgreement';
      let paramsList = this.props.location.state.parperData;
      console.log(paramsList);
      var params = {
         patientId: this.props.location.state.patientId,
         followUpId:this.props.location.state.followUpId,
         closeTime: paramsList.createYear+"-"+paramsList.createMonth+"-"+paramsList.createDay
      }
      this.props.history.push(path, params);  // 跳转到家庭协议
   }

   render() {
      //get react-material css styles
      const {classes} = this.props;

      return (
         <div className={classes.root}>
            <Grid item xs={12} className={classes.Patient}>
               <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
               <Grid item xs={12} justify="center"  className={classes.headers}><h5 className={classes.huanzhe}>密接筛查单</h5></Grid>
               <Grid item xs={3} className={classes.headers} onClick={this.handleClickNext}><h5 className={classes.next}>跳过</h5> </Grid>
            </Grid>

            <Typography h5 noWrap className={classes.spacemargin}></Typography>
            <Grid container justify="center"  wrap="nowrap" spacing={2}>
               <Typography className={classes.titleMain} h5 gutterBottom>
                  密切接触者筛查信息
               </Typography>
            </Grid>
            <Typography h5 noWrap className={classes.spacemargin}></Typography>


            <Grid spacing={0}>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>姓名：</Grid>
                  <YddInput name="name" placeholder="请填写" value={this.state.name} onChange={this.handleInputChange}/>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}>与患者的关系：</Grid>
                  <YddInput name="relation" placeholder="请填写"
                            value={this.state.relation} onChange={this.handleInputChange}/>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>性别：</Grid>
                  <RadioGroup row defaultValue={this.state.gender} onChange={this.selectOnEventDoctor} aria-label="gender" name="gender">
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="男" />
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="女" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>是否亲属：</Grid>
                  <RadioGroup row defaultValue={this.state.isFamily} onChange={this.selectOnEventDoctor} aria-label="isFamily" name="isFamily">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="是" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="否" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>年龄：</Grid>
                  <YddInput name="age" placeholder="请填写" value={this.state.age} onChange={this.handleInputChange} />
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}> 联系电话：</Grid>
                  <YddInput name="phoneNum" placeholder="请填写" value={this.state.phoneNum} onChange={this.handleInputChange} />
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={5}> 症状筛查时间：</Grid>
                  <Typography className={classes.titleBoldBox} gutterBottom>
                     <DatePicker  locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                  defaultValue={this.state.closeTimeString} onChange={this.closePickerOnChange}/>
                  </Typography>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>咳嗽咳痰：</Grid>
                  <RadioGroup row defaultValue={this.state.isCoughSputum} onChange={this.selectOnEventDoctor} aria-label="isCoughSputum" name="isCoughSputum">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>咯血：</Grid>
                  <RadioGroup row defaultValue={this.state.isHemoptysis} onChange={this.selectOnEventDoctor} aria-label="isHemoptysis" name="isHemoptysis">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>发热：</Grid>
                  <RadioGroup row defaultValue={this.state.isFever} onChange={this.selectOnEventDoctor} aria-label="isFever" name="isFever">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>胸痛：</Grid>
                  <RadioGroup row defaultValue={this.state.isChestPain} onChange={this.selectOnEventDoctor} aria-label="isChestPain" name="isChestPain">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>乏力盗汗：</Grid>
                  <RadioGroup row defaultValue={this.state.isWeaknessNightSweats} onChange={this.selectOnEventDoctor} aria-label="isWeaknessNightSweats" name="isWeaknessNightSweats">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>其他：</Grid>
                  <YddInput name="otherDesc" placeholder="请填写" value={this.state.otherDesc} onChange={this.handleInputChange} />
               </Grid>



               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> 硬结最大横径*纵径(mm)：</Grid>
                  <YddInput name="ppdHardenSize" placeholder="请填写" value={this.state.ppdHardenSize} onChange={this.handleInputChange} />
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> 硬结平均直径(mm)：</Grid>
                  <YddInput name="ppdHardenDiameter" placeholder="请填写" value={this.state.ppdHardenDiameter} onChange={this.handleInputChange} />
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>是否水泡：</Grid>
                  <RadioGroup row defaultValue={this.state.ppdBlister} onChange={this.selectOnEventDoctor} aria-label="ppdBlister" name="ppdBlister">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>是否双圈：</Grid>
                  <RadioGroup row defaultValue={this.state.ppdDoubleLoop} onChange={this.selectOnEventDoctor} aria-label="ppdDoubleLoop" name="ppdDoubleLoop">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>是否坏死：</Grid>
                  <RadioGroup row defaultValue={this.state.ppdNecrosis} onChange={this.selectOnEventDoctor} aria-label="ppdNecrosis" name="ppdNecrosis">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>是否淋巴管炎：</Grid>
                  <RadioGroup row defaultValue={this.state.ppdLymphangitis} onChange={this.selectOnEventDoctor} aria-label="ppdLymphangitis" name="ppdLymphangitis">
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="有" />
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="无" />
                  </RadioGroup>
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> PPD其他：</Grid>
                  <YddInput name="ppdTestResult" placeholder="请填写" value={this.state.ppdTestResult} onChange={this.handleInputChange} />
               </Grid>




               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> X线胸片检查医院：</Grid>
                  <YddInput name="xrayHospital" placeholder="请填写" value={this.state.xrayHospital} onChange={this.handleInputChange} />
               </Grid>

               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> X线胸片结果：</Grid>
                  <YddInput name="xrayResult" placeholder="请填写" value={this.state.xrayResult} onChange={this.handleInputChange} />
               </Grid>


               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>痰涂片</Grid>
                  <RadioGroup row value={this.state.sputumSmear} onChange={this.selectOnEventDoctor} aria-label="sputumSmear" name="sputumSmear">
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="阳性" />
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="阴性" />
                     <BeautyFormControlLabel value="0" control={<StyledRadio/>} label="未查" />
                  </RadioGroup>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={3}>痰培养</Grid>
                  <RadioGroup row value={this.state.sputumCulture} onChange={this.selectOnEventDoctor} aria-label="sputumCulture" name="sputumCulture">
                     <BeautyFormControlLabel value="1" control={<StyledRadio/>} label="阳性" />
                     <BeautyFormControlLabel value="2" control={<StyledRadio/>} label="阴性" />
                     <BeautyFormControlLabel value="3" control={<StyledRadio/>} label="污染" />
                     <BeautyFormControlLabel value="0" control={<StyledRadio/>} label="未查" />
                  </RadioGroup>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}>分子生物学：</Grid>
                  <Select name="sputumMolecularBiology" value={this.state.sputumMolecularBiology} style={{ width:180, paddingTop:'2.5%' }} onChange={this.handleChangeSputumMolecularBiology}>
                     <Option value="1">结核分枝杆菌核酸阳性</Option>
                     <Option value="2">未检出结核分枝杆菌</Option>
                     <Option value="3">利福平耐药</Option>
                     <Option value="10">不确定</Option>
                     <Option value="0">未查</Option>
                  </Select>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> 痰结核菌结果其他：</Grid>
                  <YddInput name="sputumTbResult" placeholder="请填写" value={this.state.sputumTbResult} onChange={this.handleInputChange} />
               </Grid>




               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}>筛查结论：</Grid>
                  <Select name="conclusion" value={this.state.conclusion} style={{ width:180, paddingTop:'2.5%' }} onChange={this.handleChangeConclusion}>
                     <Option value="1">无异常发现</Option>
                     <Option value="2">活动性肺结核</Option>
                     <Option value="3">非活动性肺结核</Option>
                     <Option value="4">非结核性疾病</Option>
                     <Option value="5">PPD强反应者</Option>
                     <Option value="6">肺外结核</Option>
                  </Select>
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> 筛查结论其他说明：</Grid>
                  <YddInput name="conclusionOther" placeholder="" value={this.state.conclusionOther} onChange={this.handleInputChange} />
               </Grid>
               <Grid className={classes.dataDiv}>
                  <Grid container alignItems="center" item xs={4}> 催访记录：</Grid>
                  <YddInput name="visitRecord" placeholder="" value={this.state.visitRecord} onChange={this.handleInputChange} />
               </Grid>


               <Grid item xs={12} className={classes.gridButton}>
                  <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSubmit}>提 交</Button>
                  <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClickNext}>跳 过</Button>
               </Grid>
            </Grid>


         </div>
      );
   }
}

//redux region
const mapStateToProps = state => {
   return {
      closeScreeningCode: state.patientManageReducer.closeScreeningCode
   };
};

const mapDispatchToProps = dispatch => {
   return {
       // 创建密接单
      createCloseScreeningList: closeParams=> {
          dispatch(createCloseScreeningList(closeParams))
      }
   };
};
//end of redux regian

//css region
const styles = theme => ({
   root: {
      flexGrow: 1,
      backgroundColor: '#FFFFFF',
  },
   rootDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100vw",
      // marginTop: "3vw",
      alignItems: "center",
      color: "#333333",
      backgroundColor: '#FFFFFF',
      flexGrow: 1,
      height: "100vh",
      overflow: 'auto',
   },

   titleBoldBox: {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(11),
      fontSize: '4.2vw',
      lineHeight: '8vw',
      // fontWeight: '800',
      marginBottom: theme.spacing(0),
      color: '#333333',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: "center",
      textAlign: "right",

   },

   Patient: {
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '8vh',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },

   jiantou: {
      height: '4vw',
      float: 'left',
      marginLeft: '4vw'
   },

   headers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '77vw',
      height: '8vh',
   },

   huanzhe: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      marginLeft: theme.spacing(5),
      margin: '0vw',
   },

   next: {
      color: '#ffffff',
      fontSize: '4vw',
      fontWeight: '500',
      marginRight: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '0vw',
   },

   spacemargin: {
      margin: theme.spacing(2),

   },
   titleMain: {
      fontSize: '5vw',
      lineHeight: '8vw',
      color: '#333333',
      fontWeight: '700',
   },



   textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(4),
      width: "90vw",
      fontSize: "9vw",
      bordersStyle: 0
   },

   input: {
      margin: theme.spacing(1),
      placeholderStyle: ""
   },

   genderField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: "10vw",
      borderStyle: "none",
      borderColor: "none",
      border: "0"
   },

   ageField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(4),
      width: "90vw"
   },

   referralreasonDiv: {
      lineHeight: "6vw",
      color: "#333333",
      fontSize: "4.2vw",
      height: "1.5vw"
   },

   region: {
      position: "relative",
      fontSize: "3.2vw",
      textAlign: "left",
      lineHeight: "13.3vw",
      border: "none"
   },

   dataDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "90vw",
      height: "13.3vw",
      fontSize: "4.2vw",
      borderBottom: "0.1vw solid #e6e6e6",
      margin: "auto"
   },

   SelectionboxDiv: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: "5vw",
      height: "50vw",
      width: "90vw",
      justifyContent: "spaceBetween"
   },

   introLabelDiv: {
      width: "90vw"
   },

   introReasonItemsDiv: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(36,225,221,0.05)",
      paddingLeft: "3vw",
      fontSize: "2vw",
      width: "87vw",
      border: "0.1vw solid #e6e6e6"
   },

   OptionDiv: {
      width: "90vw",
      height: "9vw"
   },
   OptionDivs: {
      marginTop: "3vw"
   },
   SelDiv: {
      backgroundColor: "#11ff44",
      fontSize: "4.5vW"
   },

   SubmitbuttonDiv: {
      backgroundColor: "#22ff44"
   },
   buttonList: {
      margin: 'auto',
      fontSize: '4.8vW',
      marginBottom: '3vh',
      width: '91vw',
      height: '12vw',
      background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
   },
   button: {
      backgroundColor: "#22ff44",
      fontSize: "4.8vW",
      width: "91vw",
      height: "12vw",
      background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
      marginTop: "6vw",
      marginBottom: "5vw",
      color: "#ffffff",
      lineHeight: "6vw"
   },

   gridButton: {
      marginBottom: "5vw",
      textAlign: "center"
   },
   modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
   },
   paper: {
      width: "85vw",
      height: "47vw",
      backgroundColor: "#ffffff"
   },
   waraing: {
      height: "10vw",
      marginTop: 0,
      background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)"
   },
   tishi: {
      height: "10vw"
   },
   paperDiv: {
      height: "15vw",
      flexDirectio: "column"
   },
   buttonDiv: {
      height: "18vw",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
   },
   buttons: {
      width: "30vw",
      background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)",
      marginTop: "1vw",
      height: "8vw",
      fontSize: "4vw",
      padding: 0
   },
   tishiDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "5vw"
   },
   spanDiv: {
      fontSize: "3.5vw",
      marginLeft: "4vw"
   },
   warm: {
      fontSize: "4vw",
      color: "#ffffff",
      marginLeft: "2vw",
      lineHeight: "10vw",
      marginTop: 0
   },
   addressList: {
      position: "relative",
      borderBottom: 0,
      "&:after": {
         content: "",
         position: "absolute",
         backgroundColor: "#ddd",
         display: "block",
         zIndex: 1,
         top: "auto",
         right: "auto",
         bottom: 0,
         left: 0,
         width: "100%",
         height: "1px",
         transformOrigin: "50% 100%",
         transform: "scaleY(0.5)"
      }
   }
});
//end of css region

const YddInput = withStyles(theme =>
   createStyles({
      root: {
         "label + &": {
            marginTop: theme.spacing(3)
         }
      },
      input: {
         position: "relative",
         fontSize: 15,
         width: "58vw",
         height: "9vw",
         textAlign: "right",
         borderBottom: "1 solid #666666",
         outline: "none",

         "&:focus": {
            borderColor: theme.palette.primary.main
         }
      }
   })
)(InputBase);

//here we inject react-material css styles
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withTheme(withStyles(styles)(CloseScreeningList)));
