import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  getMyIntroduceList,
  getNoticeList,
  getRegionList
} from "../redux/actions/index";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Route, Switch } from "react-router-dom";
import IntroduceNew from "./introduceWidgets/IntroduceNew";
import IntroduceMy from "./introduceWidgets/IntroduceMy";
import IntroduceNotify from "./introduceWidgets/IntroduceNotify";
import IntroduceMyDetail from "./introduceWidgets/IntroduceMyDetail";
import IntroduceNotify3 from "./introduceWidgets/IntroduceNotify3";
import IntroduceNotify7 from "./introduceWidgets/IntroduceNotify7";
import IntroduceNotifyTreated from "./introduceWidgets/IntroduceNotifyTreated";
import Grid from "@material-ui/core/Grid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

//患者推介页面
class IntroduceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomMenuIndex: 0, //下方菜单选中的选项 0 资讯  1 工作站  2 我的
      // introduceTopMenuIndex: 0, //上方三个菜单(新建推介 我的推介 通知)的index
      // 判断页面跳转时是否传参，将参数对应到上方三个菜单(新建推介 我的推介 通知)的index
      introduceTopMenuIndex: this.props.location.state == undefined ? 0 :
         this.props.location.state.type === "recommend" ? 2 : this.props.location.state.type === "recommendOne" ? 1 :0
    };
    this.a11yProps = this.a11yProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //请求数据,先请求我的推介
    this.props.getMyIntroduceList();
  }

  componentWillUnmount() {}

  //fired when props changed
  componentWillReceiveProps(nextProps) {}

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  }

  handleChange(event, newValue) {
    this.setState({
      introduceTopMenuIndex: newValue
    });
  }

  handleChangeIndex(index) {
    this.setState({
      introduceTopMenuIndex: index
    });

  }

  render() {
    //get react-material css styles
    const { classes } = this.props;
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/detail:introId`}
          component={IntroduceMyDetail}
        />
        <Route
          path={`${this.props.match.path}/treated`}
          component={IntroduceNotifyTreated}
        />
        <Route
          path={`${this.props.match.path}/three`}
          component={IntroduceNotify3}
        />
        <Route
          path={`${this.props.match.path}/seven`}
          component={IntroduceNotify7}
        />

        <div className={classes.rootDiv}>
          {/* header */}
          <Grid>
            <AppBar position="static" color="default">
              <Tabs
                className={classes.navigation}
                value={this.state.introduceTopMenuIndex}
                onChange={this.handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="introduce tabs"
              >
                <Tab
                  className={classes.newIntro}
                  label="新建推介"
                  {...this.a11yProps(0)}
                />
                <Tab
                  className={classes.default}
                  label="我的推介"
                  {...this.a11yProps(1)}
                />
                <Tab
                  className={classes.notification}
                  label="通知"
                  {...this.a11yProps(2)}
                />
              </Tabs>
            </AppBar>
          </Grid>

          {(() => {
            switch (this.state.introduceTopMenuIndex) {
              case 0:
                return (
                  <IntroduceNew
                    history={this.props.history}
                    match={this.props.match}
                  />
                );
              case 1:
                return (
                  <IntroduceMy
                    history={this.props.history}
                    match={this.props.match}
                  />
                );
              case 2:
                return (
                  <IntroduceNotify
                    history={this.props.history}
                    match={this.props.match}
                  />
                );
              default:
                return <IntroduceNew />;
            }
          })()}
        </div>
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    serverInfo: state.serverInfo,
    userId: state.SendUserIdReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //请求我的推介列表数据
    getMyIntroduceList: () => {
      dispatch(getMyIntroduceList());
    },

    //请求通知列表数据
    getNoticeList: () => {
      dispatch(getNoticeList());
    },

  };
};

//css region
const styles = () => ({
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100vw",
    height: "100vh",
    backgroundColor: '#FFFFFF',
  },
  indicatorColor: {
    color: "yellow"
  },

  topAppBar: {
    width: "100vw"
  },

  headerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "5vh",
    backgroundColor: "#179DD7"
  },

  navigation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "12vw",
    background: "linear-gradient(to right,#00C9E4, 50%, #00DFD7)"
  },

  notification: {
    fontSize: "4vw",
    color: "rgba(255,255,255,1)"
  },
  newIntro: {
    fontSize: "4vw",
    color: "rgba(255,255,255,1)"
  },
  default: {
    color: "rgba(255,255,255,1)",
    fontSize: "4vw"
  },

  footerDiv: {
    alignSelf: "flex-end",
    marginTop: "17vw",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    border: "0.1vw solid #e6e6e6",
    width: "100vw",
    height: "14vw",
    position: "absolute",
    bottom: 0,
    zIndex: 99999
  },
  buttonsdiv: {
    outline: "none",
    width: "12vw",
    border: "none",
    backgroundColor: "transparent",
    borderRadius: "50%",
    marginBottom: "1vw",
    filter: "grayscale(100%)"
  },

  bott: {
    width: "80vw",
    height: "14vw",
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row"
  },
  buttonssdiv: {
    outline: "none",
    width: "16vw",
    height: "16vw",
    marginBottom: "3vw",
    borderRadius: "50%",
    border: "0.1vw solid #e6e6e6",
    backgroundColor: "#e6e6e6",
    filter: "grayscale(100%)"
  },
  imgdiv: {
    height: "6vw"
  },
  imgsdiv: {
    height: "7vw"
  },
  ps: {
    fontSize: "2.7vw",
    margin: 0
  },
  buttonon: {
    outline: "none",
    width: "12vw",
    border: "none",
    backgroundColor: "transparent",
    borderRadius: "50%",
    marginBottom: "1vw",
    color: "#55E8E5",
    filter: "none"
  },
  buttonons: {
    outline: "none",
    width: "16vw",
    height: "16vw",
    marginBottom: "3vw",
    borderRadius: "50%",
    border: "0.1vw solid #e6e6e6",
    backgroundColor: "#e6e6e6",
    color: "#55E8E5",
    filter: "none"
  }
});
//end css region

//here we inject redux and react-material css styles
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(IntroduceView)));
