import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import Carousel from 'nuka-carousel';
import image1 from "../../assets/images/bg7.jpg";
import image2 from "../../assets/images/bg2.jpg";
import image3 from "../../assets/images/bg3.jpg";

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomMenuIndex: 0,//下方菜单选中的选项 0 资讯  1 工作站  2 我的
            viewportWidth: 0,
            viewportHeight: 0,
        };
    }

    render() {
        //get react-material css styles
        const { classes } = this.props;
        return (
            <Carousel
            className={classes.Carousele}
                autoplay={true}
                renderCenterLeftControls={({ previousSlide }) => (
                    <ArrowBackIosIcon onClick={previousSlide}></ArrowBackIosIcon>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                    <ArrowForwardIos onClick={nextSlide}></ArrowForwardIos>
                )}>
                <div>
                    <img
                        className={classes.slickImage}
                        src={image1}
                    />
                </div>
                <div>
                    <img
                        className={classes.slickImage}
                        src={image2}
                    />
                </div>
                <div>
                    <img
                        className={classes.slickImage}
                        src={image3}
                    />
                </div>
            </Carousel>
        )
    }
}

//css region
const styles = theme => ({   
    slickImage: {
        width: 'auto',
        height: '50vh',
        maxWidth: '100%',
        maxHeight: '100%',
    },

    Carousele: {
        width: '100vw',
        height: '50vh',
        maxWidth: '100%',
        maxHeight: '100%',
    },


});

export default withStyles(styles)(Slider);