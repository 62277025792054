import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {getMyTrackList, getMyTrackNoticeList } from '../redux/actions/index';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Route, Switch} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import TrackList from './trackWidget/TrackList';
import TrackNoticeList from './trackWidget/TrackNoticeList';
import TrackAppointment from './trackWidget/TrackAppointment';
import TrackNotice from './trackWidget/TrackNotice';
import TrackFeedback from './trackWidget/TrackFeedback';


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

//患者追踪主页面
class TrackView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomMenuIndex: 0, //下方菜单选中的选项 0 资讯  1 工作站  2 我的
            // trackTopMenuIndex: 1, //上方两个菜单(追踪列表 追踪通知列表)的index
            // 判断页面是否传参，将参数对应到上方三个菜单(追踪列表 追踪通知列表)的index
            trackTopMenuIndex: this.props.location.state == undefined ? 0 : this.props.location.state.type === "track" ? 1 : 0,
        };
        this.a11yProps = this.a11yProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {

    }

    handleChange(event, newValue) {
        this.setState({
            trackTopMenuIndex: newValue,
        })
    }


    render() {
        //get react-material css styles
        const {classes} = this.props;

        return (
            <Switch>
                <Route path={`${this.props.match.path}/patientTrackDetail:traceId`} component={TrackAppointment}/>
                <Route path={`${this.props.match.path}/patientTrackNotice:traceId`} component={TrackNotice}/>
                <Route path={`${this.props.match.path}/patientTrackFeedback:traceId`} component={TrackFeedback}/>
                <div className={classes.rootDiv}>
                    <AppBar position="static" color="default">
                        <Tabs
                            className={classes.navigation}
                            value={this.state.trackTopMenuIndex}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            // textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab className={classes.default} label="我的追踪" {...a11yProps(0)} />
                            <Tab className={classes.default} label="通知" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    {/* <SwipeableViews
                    axis={this.state.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.trackTopMenuIndex}
                    onChangeIndex={this.handleChangeIndex}
                > */}
                    {(() => {
                            switch (this.state.trackTopMenuIndex) {
                                case 0:
                                    return <TrackList history={this.props.history} match={this.props.match}/>;
                                    break;
                                case 1:
                                    return <TrackNoticeList history={this.props.history} match={this.props.match}/>;
                                    break;
                                default:
                                    return <TrackList/>;
                            }
                        }
                    )()}
                    {/* <Route path={`${this.props.match.path}/mylist`} component={TrackList} />
                    <Route path={`${this.props.match.path}/noticelist`} component={TrackNoticeList} /> */}

                    {/* </SwipeableViews> */}
                </div>

            </Switch>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        serverInfo: state.serverInfo,
        userId: state.SendUserIdReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMyTrackList: () => {
      dispatch(getMyTrackList())
    },
    getMyTrackNoticeList: () => {
      dispatch(getMyTrackNoticeList())
    },
  }
}


//css region
const styles = theme => ({
    rootDiv: {
        backgroundColor: theme.palette.background.paper,
        top: '0px',
        bottom: '0px',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '5vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',

    },
    default: {
        color: 'rgba(255,255,255,1)',
        fontSize: '4vw'
    },
    zhuizong: {
        backgroundColor: '#EBEBED',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    zhuizongq: {
        // width:'50vw',
        backgroundColor: '#EBEBED',
    }
});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackView));