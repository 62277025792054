import React from 'react';
import Linkify from 'react-linkify';


const TextMessage = (props) => {
  return <div className="sc-message--text">{
    <Linkify properties={{ target: '_blank' }}>{props.data.text}</Linkify>
  }<p style={{display: ((props.name && props.name.length>1)?"":"none"),fontSize:"8px",color:"#808000"}}>{props.name}</p></div>;
};

export default TextMessage;
