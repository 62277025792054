// Single message, sent or received.
import React from 'react';
import {connect} from 'react-redux';
import {
   getConsultList,
   sendMessage,
} from "../../redux/actions/patients/consult-action";
import {Launcher} from '../../lib/chatWindow';
import Api from '../../redux/api/api';

function getUrlParams(name, str) {
   const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
   if(!str) return null;
   const r = str.substr(0).match(reg);
   if (r != null) return  decodeURIComponent(r[2]); return null;
}

class ChatWindow extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         progress: 0,
         messageList: [],
         timer: null,
         ts: 0,
      };

      this.handlePreviewImage = this.handlePreviewImage.bind(this);
   }

   componentDidMount() {

      let patientGId = getUrlParams ('patientGId',window.location.href.split('?')[1])
      let patientNick = getUrlParams ('patientNick',window.location.href.split('?')[1])
      if(patientGId == null || patientGId.length < 3){
         localStorage.removeItem("patientGId")
         localStorage.removeItem("patientNick")
      }else{
         localStorage.setItem("patientGId",patientGId)
         localStorage.setItem("patientNick",patientNick)
      }

      console.log(window.location.href.split('?')[1])
      console.log(patientGId)
      console.log(patientNick)

      //此处请求聊天历史记录
      let userId = localStorage.getItem("userId");
      if((userId == undefined || userId == null) && (patientGId == undefined || patientGId == null) ){
         window.location.href="/patient?s=chat&t="+(new Date()).valueOf();
         return
      }


      

      this.props.getConsultList(userId);

   }

   componentWillUnmount() {
   }

   //fired when props changed
   componentWillReceiveProps(nextProps) {
      if (nextProps.messageList !== this.props.messageList) {
         if(this.state.timer){
            clearTimeout(this.state.timer);
         }

         let that = this
         let curTs = this.state.ts
         nextProps.messageList.map((message, i) => {
            if(message.ts && message.ts > curTs){
               curTs = message.ts
            }
         })
         console.log(curTs)
         console.log(this.state.ts)
         if(curTs > this.state.ts){
            console.log("hit")
            this.setState({
               //messageList: [...this.state.messageList, ...nextProps.messageList]
               messageList: nextProps.messageList
            });
            this.state.ts = curTs
         }

         
         
         this.state.timer = setTimeout(()=>{
            let userId = localStorage.getItem("userId");
            this.props.getConsultList(userId);
         },3000); 
      }
   }

   handlePreviewImage(e) {
      e.preventDefault();
      this.props.onImagePreview({
         url: e.target.src,
         filename: e.target.title,
         width: e.target.dataset.width,
         height: e.target.dataset.height,
         size: e.target.dataset.size,
         type: e.target.dataset.mime
      });
   }

   _onMessageWasSent(message) {
      this.setState({
         messageList: [...this.state.messageList, message]
      });
      //添加特殊参数
      message.patientGId = this.state.patientGId;
      message.patientNick = this.state.patientNick;
      //将消息发送给服务器
      this.props.sendMessage(message);
   }

   _onFilesSelected(fileList) {
      var that = this;
      //此处进行异步上传
      let initHeaders = new Headers()
      initHeaders.append('Accept', 'application/json, text/plain, */*')
      //initHeaders.append('Content-Type', "multipart/form-data")
      const formdata = new FormData();
      formdata.append('file', fileList[0]);
      const url = Api.getRootUrl() + '/api/uploadFile';
      fetch(url, {
         method: 'POST',
         headers: initHeaders,
         body: formdata,
      }).then(response => {
         return response.json();
      }).then(data => {
         let message = {
            type: 'file', author: 'me',
            data: {
               url: data.data,
               fileName: fileList[0].name
            }
         };
         that.setState({
            messageList: [...this.state.messageList, message]
         });
         //将消息发送给服务器
         that.props.sendMessage(message);
      }).catch(
         error => console.log(error)
      );

      // const objectURL = window.URL.createObjectURL(fileList[0]);
      // this.setState({
      //   messageList: [...this.state.messageList, {
      //     type: 'file', author: 'me',
      //     data: {
      //       url: objectURL,
      //       fileName: fileList[0].name
      //     }
      //   }]
      // });
   }

   _sendMessage(text) {
      if (text.length > 0) {
         const newMessagesCount = this.state.isOpen ? this.state.newMessagesCount : this.state.newMessagesCount + 1;
         this.setState({
            newMessagesCount: newMessagesCount,
            messageList: [...this.state.messageList, {
               author: 'them',
               type: 'text',
               data: {text}
            }]
         });
      }
   }

   _handleClick() {
      window.location.reload();
   }

   render() {
      return (
         <div>
            <Launcher
               agentProfile={{ teamName: '与医生交流（只供参考，不作为就医、用药、保健等用途依据）' }}
               onMessageWasSent={this._onMessageWasSent.bind(this)}
               onFilesSelected={this._onFilesSelected.bind(this)}
               messageList={this.state.messageList}
               newMessagesCount={this.state.newMessagesCount}
               handleClick={this._handleClick.bind(this)}
               isOpen={true}
               showEmoji
            />
         </div>
      );
   }
};

const mapStateToProps = (state) => {
   return {
      messageList: state.consultReducer.messageList,
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      getConsultList: (patientId) => {
         dispatch(getConsultList(patientId))
      },
      sendMessage: (message) => {
         dispatch(sendMessage(message))
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
